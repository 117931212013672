import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import React, {useEffect, useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";

export const AccessoryForm = ({index, accessory, formik}) => {
    const isMourningEvent = useMemo(() => formik.values.occasion === 5 || formik.values.occasion === '5', [formik.values.occasion]);
    const [quantity, setQuantity] = useState(0);
    const orderAccessories = formik.values.accessoriesMap;

    const handleButtonAddChange = () => {
        if (orderAccessories.has(index)) {
            orderAccessories.get(index).quantity += 1
        } else {
            orderAccessories.set(index, {
                item_id: accessory.id,
                quantity: 1,
                unitAmountVat: accessory.amountWithVat,
                item: {
                    reference: accessory.reference,
                    label: accessory.label
                },
            });
        }

        formik.setFieldValue('accessories', orderAccessories);
        setQuantity(formik.values.accessoriesMap.get(index).quantity);
    }

    const handleButtonMinusChange = () => {
        orderAccessories.get(index).quantity -= 1;
        formik.setFieldValue('accessories', orderAccessories);
        setQuantity(orderAccessories.get(index).quantity);

        if (formik.values.accessoriesMap.get(index).quantity === 0) {
            orderAccessories.delete(index);
            formik.setFieldValue('accessories', orderAccessories);
        }
    }

    useEffect(() => {
        let totalAmountAccessories = 0;
        let numberOfAccessories = 0;

        if (formik.values.bubble) {
            totalAmountAccessories += parseFloat(4);
        }

        if (formik.values.ruban.length === 1) {
            totalAmountAccessories += parseFloat(10);
        }

        formik.values.accessoriesMap.forEach((accessory) => {
            totalAmountAccessories += (accessory.unitAmountVat * accessory.quantity);
            numberOfAccessories += accessory.quantity;
        });

        formik.setFieldValue('totalAmountAccessories', parseFloat(totalAmountAccessories));
        formik.setFieldValue('numberOfAccessories', numberOfAccessories);
    }, [formik.values.accessoriesMap.get(index), quantity, formik.values.hasRibbon, formik.values.bubble]);

    return (
        <>
            <Col key={index} xs={12} sm={3} className={`${index < 4 ? 'mb-5' : 'mb-4'} pl-2 pr-2`}>
                <Card>
                    <Card.Img variant={"top"} src={`https://medias.florajet.com/_w_/design/acc/${accessory.reference}/${accessory.reference}_amb.jpg`} />
                    <Card.ImgOverlay className={'p-2'}>
                        <Card.Header className={'text-center p-1 bg-white'} style={{fontSize: '11px'}}><b>{accessory.label}</b></Card.Header>
                        <Card.Footer className={'p-0 bg-white d-flex align-items-center'}>
                            {isMourningEvent
                                ? (
                                    <small className={'m-0 h-auto text-center my-auto w-100 p-1'}>Indisponible</small>
                                ) : (
                                    <>
                                        <Button
                                            onClick={handleButtonMinusChange}
                                            disabled={!formik.values.accessoriesMap.has(index) || formik.values.accessoriesMap.has(index) && formik.values.accessoriesMap.get(index).quantity <= 0 || isMourningEvent}
                                            className={'btn-add'}
                                            size={'sm'}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        <p className={'m-0 h-auto text-center my-auto w-100 h5'}>
                                            {formik.values.accessoriesMap.has(index) ? formik.values.accessoriesMap.get(index).quantity : 0}
                                        </p>
                                        <Button
                                            onClick={handleButtonAddChange}
                                            disabled={formik.values.numberOfAccessories >= 3 || isMourningEvent}
                                            className={'btn-minus'}
                                            size={'sm'}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </>
                                )
                            }
                        </Card.Footer>
                    </Card.ImgOverlay>
                </Card>
            </Col>
        </>
    );
}

AccessoryForm.propTypes = {
    index: PropTypes.number.isRequired,
    accessory: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
}