import PropTypes from "prop-types";

/**
 *
 * @param {{
 *   boxContentBg,
 *   boxContentBorder,
 *   boxContentBorderColor,
 *   boxContentClassNames,
 *   boxContentTextColor,
 *   boxContentRounded,
 *   boxContentPadding,
 *   boxClassNames,
 *   headerBg,
 *   headerBord,
 *   headerBorderColor,
 *   headerClassNames,
 *   headerContent,
 *   headerPadding,
 *   headerRounded,
 *   headerTextColor
 *   }} useBoxProps
 * @param {boolean|null} sticky
 *
 * @returns {{contentBoxClassNames: string, headerBoxClassNames: string}}
 */
const useBox = (useBoxProps, sticky = false) => {
  const classParts = ['Bg', 'Border', 'BorderColor', 'TextColor', 'Rounded', 'Padding', 'ClassNames']
  const classPartsPatterns = ['bg-{{value}}', 'border', 'border-{{value}}', 'text-{{value}}', 'rounded-{{value}}', '{{value}}', '{{value}}']

  const handleClassnames = (boxPart) => {
    const classnames = classParts.map((part, i) =>
      useBoxProps[`${boxPart}${part}`] && classPartsPatterns[i]
        && classPartsPatterns[i].replace('{{value}}', useBoxProps[`${boxPart}${part}`])
    )

    return classnames
      .filter((classname) => ![undefined, '', null].includes(classname))
      .join(' ')
      .trim()
  }

  return {
    headerBoxClassNames: `${handleClassnames('header')}${sticky && ' sticky-top' || ''}`,
    contentBoxClassNames: handleClassnames('boxContent')
  }
}

export {useBox}
