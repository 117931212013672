import React from 'react'
import {useLocation} from 'react-router-dom'

import Title, {Heading} from '../../ui/page/Title'
import ModalCustom from '../../ui/modal/ModalCustom'
import PasswordForm from '../../ui/form/login/PasswordForm'
import {updatePassword} from '../../../hooks/usePassword'
import {ADMIN_ROLE, USER_ROLE} from '../../../constants/Constants'
import PasswordFormAlert from '../../ui/form/login/PasswordFormAlert'

/**
 * Utilisateurs
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Utilisateurs = () => {
  const {state, handleSubmit, handlePasswordModals} = updatePassword()

  return (
    <>
      <Title title="Gestion des utilisateurs" />

      <div className="bg-grey-light col-10 col-md-4 mx-auto ml-md-3 p-4 rounded d-flex flex-wrap justify-content-start">
        <Heading
          title="Mots de passes"
          headingLevel={3}
          className="p-2 mb-4 bg-white rounded"
        />

        <div className="bg-white col-12 p-0 pr-2 py-1 rounded">
          <div className="pl-2 my-3 d-flex justify-content-between align-items-center">
            <span>Mot de passe Administrateur</span>

            <a
              className="ff-bold"
              onClick={() => handlePasswordModals(true, false)}>
              modifier
            </a>
          </div>

          <hr className="col-3 opacity-50" />

          <div className="pl-2 my-3 d-flex justify-content-between align-items-center">
            <span>Mot de passe Utilisateur</span>

            <a
              className="ff-bold"
              onClick={() => handlePasswordModals(false, true)}>
              modifier
            </a>
          </div>
        </div>
      </div>

      <ModalCustom
        onHide={handlePasswordModals}
        id="modal-admin-password"
        show={state.adminModalShow}
        content={
          <>
            <h3>Modifier mot de passe administrateur</h3>
            <PasswordFormAlert state={state} />
            <PasswordForm userRole={ADMIN_ROLE} onSubmit={handleSubmit} />
          </>
        }
      />

      <ModalCustom
        onHide={handlePasswordModals}
        id="modal-admin-password"
        show={state.userModalShow}
        content={
          <>
            <h3>Modifier mot de passe utilisateur</h3>
            <PasswordFormAlert state={state} />
            <PasswordForm userRole={USER_ROLE} onSubmit={handleSubmit} />
          </>
        }
      />
    </>
  )
}

Utilisateurs.propTypes = {}

export default Utilisateurs
