import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../components/ui/loader/Loader";
import {SimpleButton} from "./Actions/SimpleButton";

export function ActionSimpleButtons({item, buttons}) {
    return (
        <>
            {buttons.map((button, i) => (
                <SimpleButton key={i} button={button} item={item} />
            ))}
        </>
    );
}

ActionSimpleButtons.propTypes = {
    item: PropTypes.object,
    buttons: PropTypes.array.isRequired,
}