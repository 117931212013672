import PropTypes from 'prop-types'

export const Dirty = ({formik}) => {
  return (
    <></>
    // <Prompt
    //     when={!!formik.dirty && !formik.isValidating}
    //     message={() =>
    //         `Les modifications que vous avez apportées ne seront peut-être pas enregistrées.`
    //     }
    // />
  )
}

Dirty.propTypes = {
  formik: PropTypes.object.isRequired,
}
