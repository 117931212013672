import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useContext, useRef} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {StateCreator, create as _create} from "zustand";
import AuthAPI from '../../../services/AuthAPI'
import AuthContext from '../../../contexts/AuthContext'
import FleuristesAPI from '../../../services/FleuristesAPI'
import Select from '../form/Select'
import Text from '../../../helpers/Text'
import {useStoreExecutions} from "../../../store/useStoreExecutions";

const SideMenuUser = () => {
  const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
  const {user, setUser} = useContext(AuthContext)
  const userRef = useRef(useContext(AuthContext))
  const setFlorist = useStoreExecutions(state => state.setFlorist);

  // Gestion de la déconnexion
  const handleLogout = () => {
    AuthAPI.logout()
    setIsAuthenticated(false)
  }

  // Gestion du changement de magasin
  const handleMagasin = (e) => {
    FleuristesAPI.getFleuristeByCode(e.target.value)
      .then((result) => {
        setUser((prevState) => ({
          ...prevState,
          Adresse: {
            Adresse1: result.data[0].adresse1,
            Adresse2: result.data[0].adresse2,
            Adresse3: result.data[0].adresse3,
          },
          Catalogue: {
            Catalogue: result.data[0].catalogue,
            CatalogueCompensation: result.data[0].cataloguecompensation,
            CatalogueGratuit: result.data[0].cataloguegratuit,
          },
          Civilite: result.data[0].civilite,
          CodeFl: result.data[0].codefl,
          CodeTf: result.data[0].codetf,
          Codepostal: result.data[0].codpostal,
          Contrat: result.data[0].contrat,
          Email: result.data[0].email,
          Fax: result.data[0].fax,
          Fax_1: result.data[0].fax1,
          Faxreleves: result.data[0].faxreleves,
          IdFleuriste: result.data[0].idsfleuristes,
          Localite: result.data[0].localite,
          Magasin: result.data[0].magasin,
          Nom: result.data[0].nom,
          Prenom: result.data[0].prenom,
          Siren: result.data[0].siren,
          Siret: result.data[0].siret,
          Telephone: result.data[0].telephone,
          Telephone1: result.data[0].telephone1,
          TelephoneDom: result.data[0].telephoneDom,
          username: result.data[0].codefl,
        }))
        setFlorist(user);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    isAuthenticated &&
    user && (
      <>
        <Col className={'bg-white mt-3 py-2 px-3'}>
          <Row>
            <Col lg={10}>
              <span className={'font-weight-bold'}>
                <FontAwesomeIcon
                    icon={
                      user.roles.find((item) => item === 'ROLE_ADMIN')
                          ? ['fas', 'key']
                          : ['fas', 'user']
                    }
                    color="var(--black-light)"
                    className="mr-1"
                />
                    {`${Text.CapitalizeAll(user.Prenom)} ${Text.CapitalizeAll(
                        user.Nom,
                    )}
               - ${user.CodeFl} (${user.Contrat})`}
              </span>
            </Col>
            <Col lg={2}>
              <FontAwesomeIcon
                  icon={['fas', 'power-off']}
                  size="lg"
                  color="var(--black-light)"
                  onClick={handleLogout}
              />
            </Col>
          </Row>
          {user.roles.find((item) => item === 'ROLE_ADMIN') && (
              <Row>
                <Col>
                  <Select
                      controlId="magasinsSelect"
                      classNamesGroup="col p-0"
                      classNames="m-0 bg-white multi"
                      classNamesForm="mt-1 d-flex justify-content-center w-100"
                      onChange={(e) => handleMagasin(e)}
                      options={
                        <>
                          <option value={userRef.current.user.CodeFl}>
                            {userRef.current.user.CodeFl} - {userRef.current.user.Magasin}
                          </option>
                          {user.Multimagasins.length > 0 &&
                              user.Multimagasins.filter(
                                  (item) => item.codefl !== userRef.current.user.CodeFl,
                              ).map((item, index) => (
                                  <option key={index} value={item.codefl}>
                                    {item.codefl} - {item.magasin}
                                  </option>
                              ))}
                        </>
                      }
                  />
                </Col>
              </Row>
          )}
        </Col>


      </>
    )
  )
}

export default SideMenuUser
