import PropTypes from 'prop-types'
import React from 'react'
import Card from "react-bootstrap/Card";
import uuid from 'react-uuid'

import Loader from "../loader/Loader";

const CardCustom = ({
    isLoading = false,
    isError = false,
    key,
    cardClassName,
    header,
    headerClassName,
    body,
    bodyClassName,
    footer,
    footerClassName,
}) => {
    return (
        <>
            {!isLoading && !isError && (
                <Card key={key || uuid()} className={cardClassName}>
                    <div className={`card-horizontal`}>
                        <Card.Header className={headerClassName}>
                            {header}
                        </Card.Header>
                        <Card.Body className={bodyClassName}>
                            {((typeof body === typeof (()=>{})) ? body() : body)}
                        </Card.Body>
                    </div>
                    <Card.Footer className={footerClassName}>
                        {((typeof body === typeof (()=>{})) ? footer() : footer)}
                    </Card.Footer>
                </Card>
            )}

            {isLoading && <Loader/>}
        </>
    )
}

CardCustom.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cardClassName: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    headerClassName: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
    bodyClassName: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
    footerClassName: PropTypes.string,
}

export default CardCustom
