import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, {registerLocale} from "react-datepicker";
import React, {useContext, useEffect, useState} from "react";
import {roundToNearestMinutes, setHours, setMinutes} from "date-fns";
import fr from "date-fns/locale/fr";
import ModalCustom from "../../../ui/modal/ModalCustom";
import OuverturesAPI from "../../../../services/OuverturesAPI";
import {format} from "../../../../Tools/Date";
import AuthContext from "../../../../contexts/AuthContext";
import AlertCustom from "../../../ui/alert/AlertCustom";
import FermeturesAPI from "../../../../services/FermeturesAPI";

registerLocale('fr', fr)

export const ExceptionalOpeningsForm = ({show, onHide, fetchData}) => {
    const {user} = useContext(AuthContext);
    const [date, setDate] = useState(new Date());
    const [timeStart, setTimeStart] = useState(roundToNearestMinutes(new Date(), { nearestTo: 15 }));
    const [timeEnd, setTimeEnd] = useState(setHours(setMinutes(new Date(), 0), 19));
    const [showExceptionalOpeningsModal, setShowExceptionalOpeningsModal] = useState(false);
    const [hasErrorExceptionalOpenings, setErrorExceptionalOpenings] = useState(false);
    const [showHasHolidays, setShowHasHolidays] = useState(false);

    async function hasHolidays() {
        let hasHolidays = false;

        await FermeturesAPI.countHolidaysByDate(format(date, 'yyyy-MM-dd')).then((result) => {
            hasHolidays = result > 0;
        });

        return hasHolidays;
    }

    function checkHasHolidaysByDate() {
        hasHolidays()
            .then((response) => {
                if (response) {
                    setShowHasHolidays(true);
                    onHide();
                } else {
                    addExceptionalOpenings();
                }
            })
    }

    async function addExceptionalOpenings() {
        setErrorExceptionalOpenings(false);
        setShowHasHolidays(false);

        await OuverturesAPI.postOuvertures(user.CodeFl, format(date), format(timeStart), format(timeEnd))
            .then(() => {
                fetchData(OuverturesAPI.getOuvertures(user.CodeFl));
            })
            .catch(({response}) => {
                setErrorExceptionalOpenings({
                    'error': true,
                    'errorMessage': response.data['hydra:description'],
                });

            });
        setShowExceptionalOpeningsModal(true);
        onHide();
    }

    const filterPassedTimeStart = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        const isInHoursInterval = selectedDate.getHours() >= 7 && selectedDate.getHours() <= 22;

        return format(currentDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
            ? currentDate.getTime() <= selectedDate.getTime()
            : isInHoursInterval
        ;
    };

    const filterPassedTimeEnd = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        const isInHoursInterval = selectedDate > timeStart;

        return format(timeStart, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
            ? timeStart.getTime() <= selectedDate.getTime()
            : isInHoursInterval
        ;
    };

    useEffect(() => {
        if (timeStart > timeEnd) {
            setTimeEnd(timeStart)
        }
    }, [timeStart]);

    // Si on sélectionne une autre date qu'aujourd'hui, on met par défaut la plage horaire 9h-19h
    useEffect(() => {
        if (format(date, 'yyyy-MM-dd') > format(new Date(), 'yyyy-MM-dd')) {
            setTimeStart(setHours(setMinutes(new Date(), 0), 9));
        } else {
            setTimeStart(roundToNearestMinutes(new Date(), { nearestTo: 15 }));
        }

        setTimeEnd(setHours(setMinutes(new Date(), 0), 19))
    }, [date])

    return (
        <>
            <ModalCustom
                id={'modal-exceptional-openings'}
                show={show}
                onHide={onHide}
                header={true}
                size={'md'}
                headerTitle={'Ajouter une nouvelle ouverture'}
                modalClassname={'date-picker-container'}
                content={
                    <Form className={'mt-2 date-picker-control'}>
                        <Row className={'justify-content-md-center'}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date :</Form.Label>
                                    <DatePicker
                                        name={'exceptional-openings-date'}
                                        locale={'fr'}
                                        required={true}
                                        openToDate={new Date()}
                                        minDate={new Date()}
                                        dateFormat={'dd/MM/yyyy'}
                                        placeholderText={'--/--/----'}
                                        showLeadingZeros={true}
                                        selected={date ?? new Date()}
                                        className={'form-control ml-1'}
                                        disabledKeyboardNavigation
                                        onChange={(value) => {
                                            setDate(value);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={'justify-content-md-center mt-3'}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Heure de début :</Form.Label>
                                    <DatePicker
                                        name={'exceptional-openings-date-start'}
                                        locale={'fr'}
                                        required={true}
                                        dateFormat={'HH:mm'}
                                        placeholderText={'--/--/----'}
                                        showLeadingZeros={true}
                                        selected={timeStart}
                                        className={'form-control ml-1'}
                                        disabledKeyboardNavigation
                                        onChange={(value) => {
                                            setTimeStart(roundToNearestMinutes(value, { nearestTo: 15 }));
                                        }}
                                        showTimeSelect
                                        filterTime={filterPassedTimeStart}
                                        timeIntervals={15}
                                        showTimeSelectOnly
                                        timeCaption={"Heure"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Heure de fin :</Form.Label>
                                    <DatePicker
                                        name={'exceptional-openings-date-end'}
                                        locale={'fr'}
                                        required={true}
                                        dateFormat={'HH:mm'}
                                        placeholderText={'--/--/----'}
                                        showLeadingZeros={true}
                                        selected={timeEnd}
                                        className={'form-control ml-1'}
                                        disabledKeyboardNavigation
                                        onChange={(value) => {
                                            setTimeEnd(roundToNearestMinutes(value, { nearestTo: 15 }));
                                        }}
                                        showTimeSelect
                                        filterTime={filterPassedTimeEnd}
                                        timeIntervals={15}
                                        showTimeSelectOnly
                                        timeCaption={"Heure"}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Annuler',
                        classNames: 'mx-2',
                    },
                    {
                        text: 'Confirmer',
                        classNames: 'mx-2',
                        func: () => checkHasHolidaysByDate(),
                    }
                ]}
            />

            <ModalCustom
                show={showExceptionalOpeningsModal}
                id={'modal-exceptional-openings-confirmation'}
                onHide={() => setShowExceptionalOpeningsModal(false)}
                content={
                    <AlertCustom
                        classname={"text-left"}
                        variant={hasErrorExceptionalOpenings === false ? "success" : "danger"}
                        heading={true}
                        headingTitle={"Information"}
                        headingIconName={"info-circle"}
                        bodyContent={
                            hasErrorExceptionalOpenings === false
                                ?
                                    <h3>
                                        Enregistrement effectué ! <br />
                                        Prise en compte dans quelques instants.
                                    </h3>
                                :
                                    hasErrorExceptionalOpenings.errorMessage
                        }
                        transition={true}
                    />
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Fermer',
                        classNames: 'mx-auto',
                    }
                ]}
            />

            <ModalCustom
                id={'modal-has-holidays'}
                onHide={() => setShowHasHolidays(false)}
                show={showHasHolidays}
                content={
                    <AlertCustom
                        classname="text-left"
                        variant="warning"
                        heading={true}
                        headingTitle="Information"
                        headingIconName="info-circle"
                        bodyContent={'La dateStart exceptionnelle d\'ouverture que vous avez choisi se situe sur une ou plusieurs plages de congés.'}
                        transition={true}
                    />
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Annuler',
                        classNames: 'ml-auto',
                        func: () => setShowHasHolidays(false),
                    },
                    {
                        text: 'J\'ai compris',
                        classNames: 'mr-auto',
                        func: () => addExceptionalOpenings(),
                    }
                ]}
            />
        </>
    );
}

ExceptionalOpeningsForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func
}