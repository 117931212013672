import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import Image from "react-bootstrap/Image";

const SideMenuHeader = (props) => {
  return (
      <div className="mb-4 text-center">
          <Image
              src="/images/logo-monflorajet.svg"
              alt="Mon Florajet"
              className="img-fluid mt-1"
              style={{ maxWidth: '80%' }}
          />
      </div>
      // <Button {...props} className="d-lg-none btn-primary closed">
      //   <FontAwesomeIcon
      //     icon="chevron-left"
      //     color="white"
      //     size="sm"
      //     className="mr-1"
      //   />
      //   <span>MENU</span>
      // </Button>
  )
}

export default SideMenuHeader
