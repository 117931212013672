import PropTypes from "prop-types";
import React from "react";
import Image from "react-bootstrap/Image";
import {Heading} from "../../page/Title";

/**
 * @param headingText
 * @param logoSrc
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const ShopperFormHeading = ({headingText, logoSrc}) => {
  if (!headingText && logoSrc) {
    throw new Error('One of headingText or logoSrc variable at least must defined for ShopperFormHeading component')
  }

  return (
    <>
      {
        ((logoSrc) && <Image src={logoSrc} className="align-self-start mb-3" />) ||
        ((headingText) && <Heading title={headingText} headingLevel={3} className="h5" />)
      }
    </>
  )
}

ShopperFormHeading.propTypes = {
  headingText: PropTypes.string,
  logoSrc: PropTypes.string
}

export default ShopperFormHeading