import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import Box from "../../ui/box/Box";

export const ExecutionsInformations = () => {
  return (
      <>
          <Box
              boxName="executions-infos"
              boxClassNames="mb-3"
              boxContentPadding="p-3"
              boxContentClassNames="fs-14 rounded"
              boxContentBg="grey-light"
              content={
                  <>
                      <div>
                          <FontAwesomeIcon
                              icon="info-circle"
                              color="var(--primary)"
                              size="lg"
                              className="mr-2"
                          />
                          <span className="ff-bold">Information :</span> Pour une visibilité
                          optimale, les commandes acceptées automatiquement apparaîtront
                          dorénavant <span className="bg-blue-light ff-bold">en bleu</span>{' '}
                          dans l'onglet EN COURS.
                      </div>
                  </>
              }
          />
      </>
  );
}