import React, {useContext} from 'react'
import {Navigate} from 'react-router-dom/dist'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AuthContext from '../../../contexts/AuthContext'
import LoginTabs from './LoginTabs'
import Logo from '../../ui/logos/Logo'
import {useFlowerFeast} from '../../../hooks/useFlowerFeast'

const Login = () => {
  const {isAuthenticated} = useContext(AuthContext)
  const {hasFlowerFeast} = useFlowerFeast()

  return isAuthenticated ? (
    <Navigate to={hasFlowerFeast > 0 ? '/executions' : '/tableau-de-bord'} />
  ) : (
    <div className="login bg-white-dark">
      <Container className="pt-5 pb-sm-5 ">
        <div className="text-center mb-5">
          <Logo />
        </div>
        <Row>
          <Col
            md={{span: 10, offset: 1}}
            lg={{span: 8, offset: 2}}
            xl={{span: 6, offset: 3}}>
            <LoginTabs />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
