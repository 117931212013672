const DocumentationReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_DOCUMENTS_WITH_CATEGS':
      return {
        ...state,
        documentsWithCategs: action.documentsWithCategs,
      }
    case 'HANDLE_PAGE_CHANGE':
      return {
        ...state,
        currentPage: action.currentPage,
        reload: action.reload,
      }
    case 'HANDLE_FILTER_CHANGE':
      return {
        ...state,
        currentPage: action.currentPage,
        filter: action.filter,
        reload: action.reload,
      }
    case 'HANDLE_SORT_CHANGE':
      return {
        ...state,
        currentPage: action.currentPage,
        sortOrder: action.sortOrder,
        sortType: action.sortType,
        reload: action.reload,
      }
    case 'HANDLE_MODAL':
      return {
        ...state,
        modalShow: action.modalShow,
        url: action.url,
        mimetype: action.mimetype,
      }
    default:
      throw new Error()
  }
}

export default DocumentationReducer
