import $ from "jquery";
import Col from "react-bootstrap/Col";
import {BrowserView, MobileView} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import React, {useEffect, useState} from "react";
import useFetch from "../../../hooks/useFetch";
import MessagesAPI from "../../../services/MessagesAPI";
import Loader from "../loader/Loader";
import {INTERVAL_RELOAD_BANNERS} from "../../../constants/Constants";
import {useMercureSubscriber} from "../../../hooks/useMercureSubscriber";

export const Message = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [title, setTitle] = useState();
    const [titleIcon, setTitleIcon] = useState();
    const [titleIconColor, setTitleIconColor] = useState();
    const [titleTextColor, setTitleTextColor] = useState();
    const [border, setBorder] = useState();
    const {result: newMessages} = useMercureSubscriber(['/messages/news'], false)

    useEffect(() => {
        setMessageInSession();

        if (newMessages.data) {
            console.log(newMessages)
            setMessageInSession(true)
        }

    }, [newMessages]);

    async function setMessageInSession(forceReload = false) {
        if (sessionStorage.getItem('messages') === null || forceReload) {
            sessionStorage.setItem('messages', '{}');
            setMessage(sessionStorage.getItem('messages'));
            const informations = await MessagesAPI.getMsgInfos();

            if (informations.data instanceof Object && Object.keys(informations.data).length > 0) {
                sessionStorage.setItem('messages', JSON.stringify(informations.data));
                setMessage(informations.data);
                setTitle('Informations');
                setTitleIcon('info-circle');
                setTitleIconColor('info');
                setTitleTextColor('text-info');
                setBorder('border border-info border-top-0');
            }

            const alerts = await MessagesAPI.getMsgAlertes();

            if (alerts.data instanceof Object && Object.keys(alerts.data).length > 0) {
                sessionStorage.setItem('messages', JSON.stringify(alerts.data));
                setMessage(alerts.data);
                setTitle('Alertes');
                setTitleIcon('exclamation-triangle');
                setTitleIconColor('red');
                setTitleTextColor('text-danger');
                setBorder('border border-danger border-top-0');
            }
        }
    }

    return (
        <>
            {message && message.id && (
                <Col md={{span: 12}} lg={{span: 6}} className={`info bg-grey p-3 order-sm-first order-last ${border}`}>
                    <BrowserView renderWithFragment={true}>
                        <div className="title d-flex align-items-center mb-2">
                            <FontAwesomeIcon
                                icon={['fas', titleIcon]}
                                color={`var(--${titleIconColor})`}
                                className="mr-1"
                                size="lg"
                            />
                            <h2 className={`mb-0 ${titleTextColor}`}>{title}</h2>
                        </div>
                        {/* {!isLoading && message && ( */}
                        {message && (
                            <div
                                className="content mb-0 text-wrap"
                                dangerouslySetInnerHTML={{__html: message.content}}>
                            </div>
                        )}
                        {/* {isLoading && <Loader/>} */}
                    </BrowserView>

                    <MobileView renderWithFragment={true}>
                        <div className="title d-flex align-items-center align-content-center">
                            <FontAwesomeIcon
                                icon={['fas', titleIcon]}
                                color={`var(--${titleIconColor})`}
                                className="mr-1"
                                size="lg"
                            />
                            <h2 className="d-flex justify-content-between mb-0 w-100">
                                <span>{title}</span>
                                <Button
                                    onClick={() => setOpen(!open)}
                                    aria-controls="info-collapse-text"
                                    aria-expanded={open}
                                    className={"btn-sm float-end"}
                                >
                                    Voir
                                </Button>
                            </h2>
                        </div>

                        <Collapse in={open}>
                            <div id="info-collapse-text">
                                {/* {!isLoading && message && ( */}
                                {message && (
                                    <div
                                        className="content mb-0 text-wrap"
                                        dangerouslySetInnerHTML={{__html: message.content}}></div>
                                )}
                                {/* {isLoading && <Loader/>} */}
                            </div>
                        </Collapse>
                    </MobileView>
                </Col>
            )}
        </>
    );
}