import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Numbers from "../../../helpers/Numbers";
import HelperDate from "../../../helpers/Date";
import ActionButtons from "../../../helpers/ActionButtons";
import {useOrder} from "../../../hooks/useOrder";
import Picto from "../picto/Picto";

export const OrderCard = ({item, index, actionButtons = [], user = {}}) => {
    const {downloadOrder} = useOrder(user);
    const [orderDocumentLink, setOrderDocumentLink] = useState(false);

    return (
        <>
            <Card key={index} className={`mb-2${item.etatextranet === '40' ? ' bg-yellow-light' : ''}`}>
                <div className={"card-horizontal"}>
                    {item.etatextranet && (
                        <Card.Header>
                            <Picto id={item.etatextranet}/>
                        </Card.Header>
                    )}

                    <Card.Body>
                        <div className={"row"}>
                            <div className={"col-8"}>
                                {item.numcde
                                    ? (
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip-${item.numcde}`}>
                                                    Télécharger
                                                </Tooltip>
                                            }>
                                            <a
                                                href={orderDocumentLink}
                                                download
                                                className="btn-link">
                                                {item.numcde}
                                            </a>
                                        </OverlayTrigger>
                                    )
                                    : '-'}
                            </div>
                            <div className={"col-4 text-right"}>
                                {Numbers.ConvertToEuro(item.amountvat)}
                            </div>
                        </div>
                        {item.nomdes !== '' && (<>{item.nomdes}<br/></>)}
                        <div>
                            <FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2"/>
                            {item.codpostal}{' - '}{item.localite}
                        </div>
                        <div>
                            <FontAwesomeIcon icon="calendar" color="black" className="mr-2"/>
                            {HelperDate.format(item.datliv)}{' - '}{
                            item.momentjour === '1' || item.momentjour === 1
                                ? 'MATIN'
                                : item.momentjour === '2' || item.momentjour === 2
                                    ? 'APRES-MIDI'
                                    : item.momentjour === '3' || item.momentjour === 3
                                        ? 'JOURNEE'
                                        : item.momentjour === '4' || item.momentjour === 4
                                            ? 'APRES 18H'
                                            : item.momentjour
                        }
                        </div>
                    </Card.Body>
                </div>
                {item.numcde && item.numcde !== '' && (
                    <Card.Footer className={"d-flex justify-content-center"}>
                        <ActionButtons buttons={actionButtons} item={item}/>
                    </Card.Footer>
                )}
            </Card>
        </>
    );
}

OrderCard.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    actionButtons: PropTypes.array,
    user: PropTypes.object,
}