import React from 'react'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {updatePassword} from '../../../../hooks/usePassword'

const PasswordForm = ({onSubmit, userRole, className}) => {
  const {state, validated, updateInput, checkEquality} = updatePassword()

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={onSubmit}
        className={className}>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="formNewPassword" className="mb-3">
              <Form.Label>
                Nouveau mot de passe
                <span className="text-red"> *</span>
              </Form.Label>
              <Form.Control
                type="password"
                name={'newPassword'}
                value={state.credentials.newPassword}
                onChange={(e) => {
                  updateInput(e.target)
                }}
                placeholder="Nouveau mot de passe"
                required
                error={state.errorField.newPassword}
                isValid={validated}
                isInvalid={!validated && !state.isValid && state.isError}
              />
              <Form.Control.Feedback type="invalid">
                {state.errorField.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group controlId="formConfirmPassword" className="mb-3">
              <Form.Label>
                Confirmer votre nouveau mot de passe
                <span className="text-red"> *</span>
              </Form.Label>
              <Form.Control
                type="password"
                name={'confirmPassword'}
                value={state.credentials.confirmPassword}
                onChange={(e) => {
                  updateInput(e.target)
                  checkEquality(e.target)
                }}
                placeholder="Confirmation du mot de passe"
                required
                error={state.errorField.confirmPassword}
                isValid={validated}
                isInvalid={!validated && !state.isValid && state.isError}
              />
              {userRole && (
                <Form.Control
                  name={'userRole'}
                  type="hidden"
                  value={userRole}
                />
              )}
              <Form.Control.Feedback type="invalid">
                {state.errorField.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" className="btn-primary" disabled={!validated}>
          Valider
        </Button>
      </Form>
    </>
  )
}

PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  className: PropTypes.string,
}

export default PasswordForm
