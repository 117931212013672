import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import Loader from "../../components/ui/loader/Loader";

export function SimpleButton({item, button}) {
    const [isLoading, setIsLoading] = useState(false);

    async function handleClick() {
        if (button.func) {
            setIsLoading(true);
            await button.func(
                button.funcParam
                    ? button.funcParam instanceof Array
                        ? button.funcParam.map((param) => item[param])
                        : item[button.funcParam]
                    : {item, id: button.filterParam},
            );
        }
    }

    return (
        <Button
            className={`btn-${button.color} ${button.classnames}`}
            onClick={handleClick}
            disabled={isLoading}
        >
            {isLoading
                ? (<Loader variant={'white'} size={'sm'} classname={'my-auto mx-auto'} />)
                : <FontAwesomeIcon icon={button.icon} className={button.iconClassNames} variant={button.iconColor} />
            }
        </Button>
    );
}

SimpleButton.propTypes = {
    item: PropTypes.object,
    button: PropTypes.object.isRequired,
}
