import PropTypes from 'prop-types'
import moment from 'moment/moment'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {FormCheck} from 'react-bootstrap'
import {format} from "date-fns";
import Badge from "react-bootstrap/Badge";
import ActionButtons from '../../../helpers/ActionButtons'
import {PopoverOrder} from '../popover/PopoverOrder'
import Numbers from '../../../helpers/Numbers'
import Picto from '../picto/Picto'
import OverlayedHyperlink from '../hyperlink/OverlayedHyperlink'
import Text from '../../../helpers/Text'
import {retrieveValue} from '../../../hooks/useCustomTable'
import {ActionSimpleButtons} from '../../../helpers/ActionSimpleButtons'
import {ButtonsWithConfirmation} from '../../../helpers/Actions/ButtonsWithConfirmation'
import {isFunction} from "../../../Tools/TypeOf";

const TableData = ({conf, value, item, dataKey, children}) => {
  /**
   * Format une date en fonction d'un format
   *
   * @param {string} strDate
   * @param {string} nullValuePrefix
   * @param {string} format
   *
   * @returns {string|string}
   */
  const dateFormat = (strDate, nullValuePrefix = '', format = 'DD/MM/YYYY') => {
    const value =
      strDate !== '0000-00-00' && typeof strDate !== typeof undefined
        ? moment(strDate).format(format)
        : (nullValuePrefix !== '' && `${nullValuePrefix}: -`) || '-'

    return Text.CapitalizeFirst(value)
  }

  return (
    (conf.type === 'text' && (
      <td
        key={dataKey}
        onClick={() => conf.func && conf.func(value)}
        colSpan={conf.colSpan || 1}>
        {value}
        {children}
      </td>
    )) ||
    (conf.type === 'currency' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        {Numbers.ConvertToEuro(value)}
        {children}
      </td>
    )) ||
    (conf.type === 'date' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        {dateFormat(value, conf.type)}
        {children}
      </td>
    )) ||
    (conf.type === 'month' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        {moment(value).format('MM/YYYY')}
        {children}
      </td>
    )) ||
    (conf.type === 'time' && (
        <td key={dataKey} colSpan={conf.colSpan || 1}>
            {value ? format(value, 'HH:mm') : '-'}
            {children}
        </td>
    )) ||
    (value !== '0' && conf.type === 'moment' && (
      <div key={dataKey}>
        {item.momentjourDesc && item.momentjourDesc !== ''
          ? item.momentjourDesc
          : value === '1'
          ? 'MATIN'
          : value === '2'
          ? 'APRES-MIDI'
          : value === '3'
          ? 'JOURNEE'
          : item[object.key] === '4'
          ? 'APRES 18H'
          : value}
        {children}
      </div>
    )) ||
    (conf.type === 'phone' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        Tél. :{' '}
        {value
          .split('')
          .reduce((a, e, i) => a + e + (i % 2 === 1 ? '.' : ''), '')}
        {children}
      </td>
    )) ||
    (conf.type === 'link' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${value}`}>Télécharger</Tooltip>}>
          <a href={`documents/${value}`} download className="btn-link">
            {value}
          </a>
        </OverlayTrigger>
        {children}
      </td>
    )) ||
    (conf.type === 'link_modal' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-${value}`}>Visualiser {value}</Tooltip>
          }>
          <a
            onClick={() =>
              conf.func &&
              conf.func(
                conf.funcParam
                  ? conf.funcParam instanceof Array
                    ? conf.funcParam.map((param) => item[param])
                    : item[conf.funcParam]
                  : {item, id: conf.funcParam},
              )
            }
            className="btn-link">
            {value}
          </a>
        </OverlayTrigger>
        {children}
      </td>
    )) ||
    (conf.type === 'link-download' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        {conf.key.map((link, iLink) => (
          <OverlayedHyperlink
            key={iLink}
            placement="bottom"
            overlayTxt="Télécharger"
            href={item[link.href]}
            linkClassName="btn-link"
            linkTxt={item[link.text]}
            target={'_blank'}
            download={typeof link.download === typeof true && link.download}
          />
        ))}
        {children}
      </td>
    )) ||
    (conf.type === 'checkbox' &&
      (<td key={dataKey} colSpan={conf.colSpan || 1}>
        <FormCheck
          onChange={conf.func}
          type="checkbox"
          className={"form-check"}
          defaultChecked={[1, '1', true].includes(value)}
          id={item[conf.id]}
          aria-label={`option ${item[conf.id]}`}
          readOnly={conf.readOnly}
        />
        {children}
      </td>
    )) ||
    (conf.type === 'bulk'
      && (
        conf.isSelectable === undefined
        || (isFunction(conf.isSelectable) && conf.isSelectable(item))
      ) && (<td key={dataKey} colSpan={conf.colSpan || 1}>
        <FormCheck
          type="checkbox"
          className={"form-check"}
          onChange={(evt) => conf.func(item, evt)}
          checked={isFunction(conf.value) && conf.value(item) || false}
        />
        {children}
      </td>
    )) ||
    (conf.type === 'picto' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        <Picto id={value} sorting={conf.sorting} />
        {children}
      </td>
    )) ||
    ((conf.type === 'multi' || conf.type === 'multilines') && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        {conf.key.map((object, i) => {
          let valueMulti = retrieveValue(object, item)

          if (object.array) {
            valueMulti = retrieveValue(object, item[object.array])
          }

          return (
            (conf.type === 'multi' &&
              ((object.type === 'text' && (
                <span key={dataKey}>{valueMulti} </span>
              )) ||
                (object.type === 'currency' && (
                  <span key={i}>{Numbers.ConvertToEuro(valueMulti)} </span>
                )) ||
                (object.type === 'date' && (
                  <span key={i}>{dateFormat(valueMulti, object.type)}</span>
                )) ||
                (object.type === 'month' && (
                  <span key={i}>{moment(valueMulti).format('MM/YYYY')} </span>
                )) ||
                  (object.type === 'time' && (
                      <span key={dataKey}>{valueMulti ? format(valueMulti, 'HH:mm') : '-'} </span>
                  )) ||
                (valueMulti !== '0' && object.type === 'moment' && (
                  <div key={i}>
                    {item.momentjourDesc && item.momentjourDesc !== ''
                      ? item.momentjourDesc
                      : valueMulti === '1'
                      ? 'MATIN'
                      : valueMulti === '2'
                      ? 'APRES-MIDI'
                      : valueMulti === '3'
                      ? 'JOURNEE'
                      : valueMulti === '4'
                      ? 'APRES 18H'
                      : valueMulti}
                  </div>
                )) ||
                (object.type === 'phone' && valueMulti && (
                  <span key={i}>
                    Tél. :{' '}
                    {valueMulti &&
                      valueMulti
                        .split('')
                        .reduce(
                          (a, e, i) => a + e + (i % 2 === 1 ? '.' : ''),
                          '',
                        )}{' '}
                  </span>
                )) ||
                (object.type === 'link_modal' && (
                  <span key={i}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${valueMulti}`}>
                          Visualiser {valueMulti}
                        </Tooltip>
                      }>
                      <a
                        onClick={() =>
                          object.func && object.func(item[object.funcParam])
                        }
                        className="btn-link">
                        {valueMulti}
                      </a>
                    </OverlayTrigger>
                  </span>
                )) ||
                (object.type === 'link' && (
                  <span key={i}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${valueMulti}`}>
                          Télécharger
                        </Tooltip>
                      }>
                      <a
                        href={`documents/${valueMulti}`}
                        download
                        className="btn-link">
                        {valueMulti}
                      </a>
                    </OverlayTrigger>{' '}
                  </span>
                )))) ||
            (conf.type === 'multilines' &&
              ((object.type === 'text' && <div key={i}>{valueMulti}</div>) ||
                (object.type === 'currency' && (
                  <div key={i}>{Numbers.ConvertToEuro(valueMulti)}</div>
                )) ||
                (object.type === 'date' && (
                  <div key={i}>{dateFormat(valueMulti, object.type)}</div>
                )) ||
                (object.type === 'month' && (
                  <div key={i}>{moment(valueMulti).format('MM/YYYY')}</div>
                )) ||
                  (object.type === 'time' && (
                      <div key={dataKey}>{valueMulti ? format(valueMulti, 'HH:mm') : '-'}</div>
                  )) ||
                ((valueMulti !== '0' || valueMulti !== 0) &&
                  object.type === 'moment' && (
                    <div key={i}>
                      {item.momentjourDesc && item.momentjourDesc !== ''
                        ? item.momentjourDesc
                        : valueMulti === '1' || valueMulti === 1
                        ? 'MATIN'
                        : valueMulti === '2' || valueMulti === 2
                        ? 'APRES-MIDI'
                        : valueMulti === '3' || valueMulti === 3
                        ? 'JOURNEE'
                        : valueMulti === '4' || valueMulti === 4
                        ? 'APRES 18H'
                        : 'Moment: -'}
                    </div>
                  )) ||
                (object.type === 'phone' && valueMulti && (
                  <span key={i}>
                    Tél. :{' '}
                    {valueMulti &&
                      valueMulti
                        .split('')
                        .reduce(
                          (a, e, i) => a + e + (i % 2 === 1 ? '.' : ''),
                          '',
                        )}{' '}
                  </span>
                )) ||
                (object.type === 'link_modal' && (
                  <div key={i}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${valueMulti}`}>
                          Visualiser {valueMulti}
                        </Tooltip>
                      }>
                      <a
                        onClick={() =>
                          object.func &&
                          object.func(
                            object.funcParam
                              ? object.funcParam instanceof Array
                                ? object.funcParam.map((param) => item[param])
                                : item[object.funcParam]
                              : {item, id: object.funcParam},
                          )
                        }
                        className="btn-link">
                        {valueMulti}
                      </a>
                    </OverlayTrigger>
                  </div>
                )) ||
                (object.type === 'link_modal_order' && (
                  <div key={i}>
                    {item.product && item.product.idcompo !== 0 ? (
                      <PopoverOrder
                        product={item.product}
                        composition={item.libchxfl}
                        content={
                          <a
                            onClick={() =>
                              object.func &&
                              object.func(
                                object.funcParam
                                  ? object.funcParam instanceof Array
                                    ? object.funcParam.map(
                                        (param) => item[param],
                                      )
                                    : item[object.funcParam]
                                  : {item, id: object.funcParam},
                              )
                            }
                            className="btn-link">
                            {valueMulti}
                          </a>
                        }
                      />
                    ) : (
                      <a
                          className={'font-weight-bold'}
                          onClick={() =>
                              object.func &&
                              object.func(
                                  object.funcParam
                                      ? object.funcParam instanceof Array
                                          ? object.funcParam.map(
                                              (param) => item[param],
                                          )
                                          : item[object.funcParam]
                                      : {item, id: object.funcParam},
                              )
                          }
                      >{valueMulti}</a>
                    )}
                  </div>
                )) ||
                (object.type === 'link' && (
                  <div key={i}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${valueMulti}`}>
                          Télécharger
                        </Tooltip>
                      }>
                      <a
                        href={`documents/${valueMulti}`}
                        download
                        className="btn-link">
                        {valueMulti}
                      </a>
                    </OverlayTrigger>
                  </div>
                ))))
          )
        })}
        {children}
      </td>
    )) ||
    (((item.numcde && item.numcde !== '') ||
      conf.view ||
      (item.idsgribouil && item.idsgribouil)) &&
      conf.type === 'buttons' && (
        <td key={dataKey} colSpan={conf.colSpan || 1}>
          <ActionButtons buttons={conf.key} item={item} />
          {children}
        </td>
      )) ||
    (conf.type === 'buttons_sav' && (
      <td key={dataKey} colSpan={conf.colSpan || 1}>
        <ActionButtons buttons={conf.key} item={item} />
        {children}
      </td>
    )) ||
    (conf.type === 'simple_buttons' && (
      <td key={dataKey} colSpan={conf.colSpan || 1} className={conf.colClass}>
        <ActionSimpleButtons buttons={conf.key} item={item} />
      </td>
    )) ||
    (conf.type === 'buttons_with_confirmation' && (
      <td key={dataKey} colSpan={conf.colSpan || 1} className={conf.colClass}>
        <ButtonsWithConfirmation buttons={conf.key} item={item} />
      </td>
    ))
    ||
    (conf.type === 'list' && (
        <td key={dataKey} colSpan={conf.colSpan || 1} className={conf.colClass}>
            {item[conf.key] && item[conf.key].length > 0
                ? (
                    <>
                        <ul className={'list-group list-group-flush'}>
                            {item[conf.key].map((element, i) => {
                                return (
                                    <li key={i} className={`list-group-item ${conf.withQuantity && 'd-flex justify-content-between align-items-center bg-color-parent'} ${conf.compactList && 'pt-1 pb-1'}`}>
                                        {element[conf.labelField]}
                                        {conf.withQuantity && (
                                            <Badge bg={'secondary'}>
                                                {element[conf.quantityField]}
                                            </Badge>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </>

                )
                : '-'
            }
        </td>
    ))
  )
}

TableData.propTypes = {
  conf: PropTypes.object.isRequired,
  value: PropTypes.any,
  item: PropTypes.any.isRequired,
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.element,
}

export default TableData
