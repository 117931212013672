import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useContext, useEffect, useState} from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import Box from '../../ui/box/Box'
import HorairesAPI from '../../../services/HorairesAPI'
import useFetch from '../../../hooks/useFetch'
import Loader from '../../ui/loader/Loader'
import MagasinMenu from './MagasinMenu'
import ModalCustom from '../../ui/modal/ModalCustom'
import Title from '../../ui/page/Title'

const Magasin = () => {
  const {user} = useContext(AuthContext)
  const [modalShowHoraires, setModalShowHoraires] = useState(false)
  const [modalShowEdit, setModalShowEdit] = useState(false)
  const [currentTab, setCurrentTab] = useState('magasin');

  const [{data: horaires, isLoading, isError}, fetchData] = useFetch(
    HorairesAPI.getHoraires(user.CodeFl),
  )

  // Gestion du changement de magasin
  useEffect(() => {
    fetchData(HorairesAPI.getHoraires(user.CodeFl))
  }, [user.CodeFl])

  const [
    {data: horairesIframe, isLoading: isLoadingIframe, isError: isErrorIframe},
  ] = useFetch(HorairesAPI.getIframe(user.CodeFl))

  return (
    <>
      <Title title="Mon magasin" />

      <Box
        boxXxl="7"
        boxName="magasin"
        boxClassNames="flex-column mb-3"
        header={true}
        headerContent={
          <>
            <MagasinMenu currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </>
        }
        boxContentClassNames="rounded-bottom"
        boxContentPadding="px-0 px-sm-3 py-3"
        content={
          <>
            <Row>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Code fleuriste</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.CodeFl}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Contrat</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Contrat}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mx-3 mb-3">
              <div className="form-label mb-2">Enseigne</div>
              <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                {user.Magasin}
              </div>
            </div>

            <Row>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Nom</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Nom}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Prénom</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Prenom}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mx-3 mb-3">
              <div className="form-label mb-2">Adresse</div>
              <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                {user.Adresse.Adresse1}
              </div>
            </div>

            <div className="mx-3 mb-3">
              <div className="form-label mb-2">Complément d'adresse 1</div>
              <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                {user.Adresse.Adresse2}
              </div>
            </div>

            <div className="mx-3 mb-3">
              <div className="form-label mb-2">Complément d'adresse 2</div>
              <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                {user.Adresse.Adresse3}
              </div>
            </div>

            <Row>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Code postal</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Codepostal}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Ville</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Localite}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Téléphone magasin</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Telephone || user.Telephone1}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Téléphone domicile</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.TelephoneDom}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Fax relevés</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Faxreleves || user.Fax || user.Fax_1}
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="mx-3 mb-3">
                  <div className="form-label mb-2">Email pro</div>
                  <div className="mh bg-white rounded text-grey-darker py-1 px-3 mb-3">
                    {user.Email}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      />

      <Box
        boxXxl="5"
        boxName="side-box horaires"
        boxClassNames="flex-column flex-grow-1 mb-3"
        header={true}
        headerClassNames="rounded-top"
        headerContent={
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-between px-1">
            <h3 className="mb-0 px-1">Horaires d'ouverture</h3>
            <Button
              onClick={() => { setModalShowHoraires(true) }}
              className="btn-rounded ff-bold">
              Modifier
            </Button>
          </div>
        }
        boxContentPadding="p-0"
        boxContentClassNames="flex-column rounded-bottom"
        content={
          <>
            <Table responsive size="sm" className="mb-0">
              <thead>
                <tr>
                  <th>
                    <FontAwesomeIcon
                      size="lg"
                      icon="clock"
                      color="var(--grey-dark)"
                    />
                  </th>
                  <th colSpan={2}>MATIN</th>
                  <th colSpan={2}>APRES-MIDI</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={5} className="table-alert">
                      <Loader />
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !isError &&
                  horaires &&
                  horaires.map((item, index) => (
                    <tr key={index}>
                      <td>{item.jourouvertureDate}</td>
                      <td>
                        {item.matinHeureouverture}
                      </td>
                      <td>
                        {item.matinHeurefermeture}
                      </td>
                      <td>
                        {item.amHeureouverture}
                      </td>
                      <td>
                        {item.amHeurefermeture}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <AlertCustom
              heading={isError.heading}
              variant={isError.variant}
              bodyIcon={isError.icon}
              bodyContent={isError.content}
              bodyContentBold={isError.message}
              bodyContentItalic={isError.detail}
              transition={!!isError}
            />
          </>
        }
      />

      <ModalCustom
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
        id="modal-edit"
        bodyClassname="p-5"
        content={
          <>
            <h3>
              Modification envoyée. <br />
              Nos équipes s'occupent de votre commande.
            </h3>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: 'Fermer',
          },
        ]}
      />

      <ModalCustom
        show={modalShowHoraires}
        onHide={() => setModalShowHoraires(false)}
        id="modal-horaires"
        bodyClassname="p-0"
        content={
          <>
            {isLoadingIframe && <Loader />}

            <AlertCustom
              classname="mx-5 my-3 text-left"
              heading={isErrorIframe.heading}
              variant={isErrorIframe.variant}
              bodyIcon={isErrorIframe.icon}
              bodyContent={isErrorIframe.content}
              bodyContentBold={isErrorIframe.message}
              bodyContentItalic={isErrorIframe.detail}
              transition={!!isErrorIframe}
            />

            {!isLoading && !isError && horairesIframe && (
              <div
                className="embed-responsive embed-responsive-1by1"
                dangerouslySetInnerHTML={{
                  __html: `<iframe
                src="${horairesIframe.url}"
                class="embed-responsive-item"
                allowtransparency="true"></iframe>`,
                }}></div>
            )}
          </>
        }
      />
    </>
  )
}

export default Magasin
