import API from './API'
import {
  EXECUTIONS_ACCEPTED,
  EXECUTIONS_API,
  EXECUTIONS_POST_API,
  EXECUTIONS_REFUS_API,
  NOTIFICATIONS_API,
  UPLOAD_PHOTO_API,
} from '../constants/Constants'
import APIv2 from "./APIv2";

function getExecutions(
  codefl,
  type,
  sortType,
  sortOrder,
  currentPage,
  searchText,
  dateDebut,
  dateFin,
  itemsPerPage,
) {
  return API({
    name: 'exécutions',
    method: 'get',
    url: `${EXECUTIONS_API}?codefl=${codefl}&type=${type}${
      type === 'by-last-days' ? '&days=30' : ''
    }${
      type === 'search'
        ? `&numcdelocalite=${searchText || ''}&date_debut=${
            dateDebut || ''
          }&date_fin=${dateFin || ''}`
        : ''
    }&orderBy=${sortType}&way=${
      sortOrder || 'desc'
    }`,
    // }&page=${currentPage}&itemsPerPage=${itemsPerPage}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  });
}

function getExecutionsCount(
  codefl,
  type,
  sortType,
  sortOrder,
  currentPage,
  searchText,
  dateDebut,
  dateFin,
  itemsPerPage,
) {
  return {
    name: 'exécutions',
    method: 'get',
    url: `${EXECUTIONS_API}?codefl=${codefl}&type=${type}${
      type === 'by-last-days' ? '&days=30' : ''
    }${
      type === 'search'
        ? `&numcdelocalite=${searchText || ''}&date_debut=${
            dateDebut || ''
          }&date_fin=${dateFin || ''}`
        : ''
    }&orderBy=${sortType}&way=${
      sortOrder || 'desc'
    }&count=true`,
    // }&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=true`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

async function postExecutions(codefl, data) {
  return API({
    name: 'post_executions',
    method: 'post',
    url: `${EXECUTIONS_POST_API}?codefl=${codefl}`,
    data,
  })
}

async function uploadPicture(codefl, numcde, data) {
  return API({
    name: 'upload_photo',
    method: 'post',
    url: `${UPLOAD_PHOTO_API}?codefl=${codefl}&numcde=${numcde}`,
    data,
  })
}

function getRefus() {
  return API({
    name: 'refus',
    method: 'get',
    url: EXECUTIONS_REFUS_API,
    single: true,
  })
}

function getNotifications(codefl) {
  return {
    name: 'notifications',
    method: 'get',
    url: `${NOTIFICATIONS_API}?codefl=${codefl}`,
    female: true,
  }
}

function getAcceptedExecutions(floristCode, async = false) {
  const data = {
    codefl: floristCode
  };

  const request = {
    name: 'accepted_execution',
    method: 'get',
    url: EXECUTIONS_ACCEPTED,
    data
  };

  return async ? API(request) : request;
}

function getAllAcceptedExecutions(dateStart, dateEnd) {
  return API({
    name: 'accepted_execution',
    method: 'get',
    url: `${EXECUTIONS_ACCEPTED}/all?date_start=${dateStart}&date_end=${dateEnd}`
  });
}

export default {
  getExecutions,
  getExecutionsCount,
  postExecutions,
  getRefus,
  uploadPicture,
  getNotifications,
  getAcceptedExecutions,
  getAllAcceptedExecutions,
}
