import {CP_VILLE_API} from '../constants/Constants'
import APIv2 from './APIv2'

function getAllMinial() {
  return APIv2.requestData({
    name: 'scommune-all-minimal',
    method: 'GET',
    url: `${CP_VILLE_API}/all-minimal`,
    headers: {
      accept: 'application/ld+json',
    },
  })
}

export default {
  getAllMinial,
}
