import API from './API'
import {
  ITEMS_PER_PAGE,
  SAV_API,
  SAV_GET_API,
  SAV_MOTIFS_API,
  SAV_POST_API,
  SAV_POST_COMMENT_API,
  SAV_RECLAMANTS_API,
} from '../constants/Constants'

function getSavs(
  codefl,
  sortReclamant,
  sortState,
  sortStatus,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
  numberOfDaysToSee = ''
) {
  return API({
    name: 'dossiers',
    method: 'get',
    url: `${SAV_API}?pagination=true&codefl=${codefl}&recla_plaignant=${sortReclamant}&recla_cloture=${sortState}&recla_fondee=${sortStatus}&orderBy=${sortType}&numberOfDaysToSee=${numberOfDaysToSee}&way=${
      sortOrder || 'desc'
    }&page=${currentPage}&itemsPerPage=${itemsPerPage || ITEMS_PER_PAGE}`,
    headers: {
      accept: 'application/ld+json',
    },
  })
}

function getSavsCount(
  codefl,
  sortReclamant,
  sortState,
  sortStatus,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
) {
  return {
    name: 'sav',
    method: 'get',
    url: `${SAV_API}?pagination=true&codefl=${codefl}&recla_plaignant=${sortReclamant}&recla_cloture=${sortState}&recla_fondee=${sortStatus}&orderBy=${sortType}&way=${
      sortOrder || 'desc'
    }&page=${currentPage}&itemsPerPage=${
      itemsPerPage || ITEMS_PER_PAGE
    }&count=true`,
    headers: {
      accept: 'application/ld+json',
    },
    single: true,
  }
}

function getSavReclamants() {
  return API({
    name: 'sav_réclamants',
    method: 'get',
    url: SAV_RECLAMANTS_API,
  })
}

function getSavMotifs() {
  return API({
    name: 'sav_motifs',
    method: 'get',
    url: SAV_MOTIFS_API,
  })
}

async function getSav(id) {
  return API({
    name: 'dossier SAV',
    method: 'get',
    url: `${SAV_GET_API}?id=${id}`,
  })
}

async function postSav(codefl, type, data) {
  return API({
    name: 'post_sav',
    method: 'post',
    url: `${SAV_POST_API}?codefl=${codefl}&type=${type}`,
    data,
  })
}

async function postSavComment(codefl, data) {
  return API({
    name: 'post_sav',
    method: 'post',
    url: `${SAV_POST_COMMENT_API}?codefl=${codefl}&type=comment`,
    data,
  })
}

export default {
  getSavs,
  getSavsCount,
  getSavReclamants,
  getSavMotifs,
  getSav,
  postSav,
  postSavComment,
}
