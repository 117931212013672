import API from './API'
import {
  COMPTA_CHEQUES_API,
  COMPTA_FACTURES_API,
  COMPTA_FACTURES_PDF_API,
  COMPTA_PRELEV_API,
  COMPTA_RELEV_PDF_API,
  COMPTA_VIREMENTS_API,
} from '../constants/Constants'

function getReleves(
  codefl,
  type,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
) {
  return {
    name: 'relevés',
    method: 'get',
    url: `florajet/${
      type === 'ex'
        ? `relex-dats?relexCodefl=${codefl}&relexDate[after]=2012-06-01`
        : `reltr-dats?reltrCodefl=${codefl}&reltrDate[after]=2012-06-01`
    }&order[${sortType}]=${
      sortOrder || 'desc'
    }&page=${currentPage}&itemsPerPage=${itemsPerPage}&pagination=true`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getRelevesPDF(codefl, type, file) {
  return API({
    name: 'relevés',
    method: 'get',
    url: `${COMPTA_RELEV_PDF_API}?codefl=${codefl}&type=${type}&fichier=${file}`,
  })
}

function getPrelevements(
  codefl,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
  count,
) {
  return {
    name: 'prélèvements',
    method: 'get',
    url: `${COMPTA_PRELEV_API}?codefl=${codefl}&orderBy=${sortType}&way=${sortOrder}&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=${count}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getVirements(
  codefl,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
  count,
) {
  return {
    name: 'virements',
    method: 'get',
    url: `${COMPTA_VIREMENTS_API}?codefl=${codefl}&orderBy=${sortType}&way=${sortOrder}&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=${count}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getCheques(
  codefl,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
  count,
) {
  return {
    name: 'chèques',
    method: 'get',
    url: `${COMPTA_CHEQUES_API}?codefl=${codefl}&orderBy=${sortType}&way=${sortOrder}&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=${count}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getFactures(
  codefl,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
  count,
) {
  return {
    name: 'factures',
    method: 'get',
    url: `${COMPTA_FACTURES_API}?codefl=${codefl}&orderBy=${sortType}&way=${sortOrder}&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=${count}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

function getFacturesPDF(numerofacture) {
  return API({
    name: 'factures',
    method: 'get',
    url: `${COMPTA_FACTURES_PDF_API}?numerofacture=${numerofacture}`,
  })
}

export default {
  getReleves,
  getRelevesPDF,
  getPrelevements,
  getVirements,
  getCheques,
  getFactures,
  getFacturesPDF,
}
