import {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";
import AuthAPI from "../services/AuthAPI";
import {
    SIDE_MENU_MES_COMMANDES,
    SIDE_MENU_MON_FLORAJET
} from "../constants/SideMenuConstants";
import {Store} from "../providers/MenuProvider";

export function sideMenuManager() {
    const {user} = useContext(AuthContext);
    const [isAdmin] = useState(AuthAPI.isAdministrator(user))

    const {state, dispatch, toggleMenu} = useContext(Store);

    const handleModalShow = (showModal) => {
        toggleMenu()
        dispatch({
            type: 'HANDLE_MODAL',
            modalShow: showModal,
        });
    }

    const handleNavItem = () => {
        toggleMenu()
        // handleAccordion()
    }

    // Préremplie le champ du code fleuriste
    // du formulaire de contact.
    function contactFormOnMount(idModal) {
        const targetNode = document.getElementById(idModal);
        // childList permet de détecter les modifications dans les enfants immédiats
        // subtree permet de suivre toutes les modifications dans les sous-éléments
        const config = { childList: true, subtree: true };

        const callback = function(mutationsList, observer) {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const memberCodeField = document.getElementById("fld_CS_MemberCode");

                    if (memberCodeField !== null) {
                        memberCodeField.value = user.CodeFl;
                        // On arrête l'observation après l'insertion
                        observer.disconnect();
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }


    function handleDefaultKeys(
      accordionDefaultKey = SIDE_MENU_MON_FLORAJET.eventKey,
      subAccordionDefaultKey = SIDE_MENU_MES_COMMANDES.eventKey
    ) {
        dispatch({
            type: 'HANDLE_DEFAULT_KEYS',
            accordionDefaultKey,
            subAccordionDefaultKey,
        })
    }


    return {
        state,
        dispatch,
        toggleMenu,
        isAdmin,
        handleNavItem,
        handleModalShow,
        handleDefaultKeys,
        contactFormOnMount,
        user
    }
}
