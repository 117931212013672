import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import React, {useState} from "react";
import ModalCustom from "../../ui/modal/ModalCustom";
import Text from "../../../helpers/Text";

export const CatalogueItemModal = ({product, show, onHide}) => {
    return (
      <>
          <ModalCustom
              show={show}
              onHide={onHide}
              id={`modal-catalogue-${product.reference}`}
              size="lg"
              modalClassname="text-left"
              bodyClassname="px-3 pb-4"
              header={true}
              headerTitle={
                <>
                    {Text.CapitalizeAll(product.libcompo)} ({product.reference})
                </>
              }
              content={
                  <>

                      <div className="d-flex flex-column flex-sm-row">
                          <Col
                              xs={12}
                              sm={6}
                              className="d-flex align-items-center justify-content-center px-3">
                              <Image
                                  onError={(e) => {
                                      e.target.src =
                                          'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                                  }}
                                  src={`https://cdn.florajet.com/produits/300/${product.idcompo}.jpg`}
                                  alt={product.libcompo}
                                  className="rounded img-fluid"
                              />
                          </Col>
                          <Col
                              xs={12}
                              sm={6}
                              className="d-flex flex-column justify-content-between px-3 mt-3 mt-sm-0">
                              {product.description && (
                                  <div className="">
                                      <h2 className="text-primary">Description</h2>
                                      <p
                                          dangerouslySetInnerHTML={{
                                              __html: product.description,
                                          }}></p>
                                  </div>
                              )}
                              {product.descriptionTech && (
                                  <div className="">
                                      <h2 className="text-primary">Composition</h2>
                                      <p
                                          dangerouslySetInnerHTML={{
                                              __html: product.descriptionTech,
                                          }}></p>
                                  </div>
                              )}
                              <div className="ff-bold">
                                  {product.categorie[0] &&
                                      `Catégorie : ${product.categorie[0].categDesc} (${product.categorie[0].categCode})`}
                              </div>
                          </Col>
                      </div>
                  </>
              }
          />
      </>
    );
}

CatalogueItemModal.propTypes = {
    product: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}