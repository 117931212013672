import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useFetch from "../../../../hooks/useFetch";
import FermeturesAPI from "../../../../services/FermeturesAPI";
import AuthContext from "../../../../contexts/AuthContext";
import Count from "../../../ui/page/Count";
import {HolidaysContentTab} from "./HolidaysContentTab";
import {HolidaysForm} from "./HolidaysForm";
import AlertCustom from "../../../ui/alert/AlertCustom";

export const HolidaysContent = () => {
    const {user} = useContext(AuthContext);
    const [{data: holidays, totalItems, isLoading, isError}, fetchData] = useFetch(FermeturesAPI.getFermetures(user.CodeFl));
    const [showAddHolidaysModal, setShowAddHolidaysModal] = useState(false);

    return (
        <>
            <Container fluid>
                <Row className={'row bg-light p-3'}>
                    <Col className={'my-auto'}>
                        <h3>Dates de congés/fermetures</h3>

                    </Col>
                    <Col className={'my-auto d-lg-flex justify-content-end'}>
                        <Button
                            onClick={() => setShowAddHolidaysModal(true)}
                            variant={'primary'}
                            className={'mr-3'}
                        >
                            <FontAwesomeIcon
                                icon="plus-circle"
                                color="white"
                                className="mr-2"
                            />
                            Ajouter
                        </Button>
                        <Count totalItems={totalItems} name={'congé'} classNames={'my-auto'}/>
                    </Col>
                </Row>
            </Container>
            <hr className="m-0" />
            <HolidaysContentTab holidays={holidays} isLoading={isLoading} fetchData={fetchData} isError={isError}/>

            <HolidaysForm
                show={showAddHolidaysModal}
                onHide={() => setShowAddHolidaysModal(false)}
            />
        </>
    );
}