import {Badge, Nav} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import {isBool, isFunction} from '../Tools/TypeOf'

const NavItemCustom = ({
  badge,
  eventKey,
  classnameItem,
  classnamelink,
  disabled,
  iconName,
  iconSize,
  isActive,
  title,
  toPathname,
  toState,
  onClick,
}) => {
  const handleNotActive = (isActive) =>
    (((isFunction(isActive) && !isActive()) ||
      (isBool(isActive) && !isActive)) &&
      'not-active') ||
    ''

  const handleLinkClassname = (classnamelink, isActive) => {
    return `${classnamelink} ${
      isActive !== undefined && handleNotActive(isActive)
    }`.trim()
  }

  return (
    <Nav.Item className={classnameItem}>
      <Nav.Link
        as={NavLink}
        key={eventKey}
        to={{
          pathname: `/${toPathname}`,
          state: toState,
        }}
        onClick={(evt) => {
          if (!isActive) {
            evt.preventDefault()
          }

          if (onClick) {
            onClick(evt)
          }
        }}
        disabled={disabled}
        active={false}
        className={(() => handleLinkClassname(classnamelink, isActive))()}>
        {iconName && (
          <FontAwesomeIcon
            icon={iconName}
            color="white"
            className="mr-1"
            size={iconSize}
          />
        )}
        {badge ? (
          <span className="d-flex flex-row align-items-center">
            {title}
            <Badge bg="danger" className="ml-1 text-decoration-none">
              {badge}
            </Badge>
          </span>
        ) : (
          title
        )}
      </Nav.Link>
    </Nav.Item>
  )
}

NavItemCustom.defaultProps = {
  isActive: true,
}

NavItemCustom.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  classnameItem: PropTypes.string,
  classnamelink: PropTypes.string,
  disabled: PropTypes.bool,
  eventKey: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconSize: PropTypes.string,
  isActive: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  toPathname: PropTypes.string.isRequired,
  toState: PropTypes.object,
}

export default NavItemCustom
