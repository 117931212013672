import API from './API'
import {
  CATALOGUE_API, FLORIST_CATALOGS_API,
  ITEMS_PER_PAGE_CATALOGUE
} from '../constants/Constants'

function getCatalogue(
  search,
  searchType,
  sortCatalog,
  sortType,
  sortOrder,
  currentPage,
) {
  return {
    name: 'produits',
    method: 'get',
    url: `${CATALOGUE_API}?pagination=true${
      search ? `&${searchType}=${search}` : ''
    }${
      sortCatalog ? `&nomcat=${sortCatalog}` : ''
    }&order%5B${sortType}%5D=${sortOrder}&actif=1&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE_CATALOGUE}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getProductBySearch(search, country, floristCode) {
  return API({
    name: 'références',
    method: 'get',
    url: `${CATALOGUE_API}/search-by-reference-or-libcompo?keyword=${search}&orderBy=libcompo&way=asc&country=${country}&floristCode=${floristCode}`,
  })
}

function getNotMourningCatalogue(
    floristCode,
    search,
    searchType,
    sortCatalog,
    sortType,
    sortOrder,
    currentPage,
    data
) {
  return {
    name: 'produits',
    method: 'get',
    url: `${CATALOGUE_API}/not-mournings/all?pagination=true${
        search ? `&${searchType}=${search}` : ''
    }${
        sortCatalog ? `&category=${sortCatalog}` : ''
    }&order%5B${sortType}%5D=${sortOrder}&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE_CATALOGUE}&floristCode=${floristCode}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getCatalogsByFlorist(floristCode) {
  return {
    name: 'get_florist_catalogs',
    method: 'get',
    url: `${FLORIST_CATALOGS_API}`
  }
}

function getAvailableDatesByProduct(productId, country) {
  return API({
    name: 'available_dates',
    method: 'get',
    url: `${CATALOGUE_API}/available/${productId}/${country}`
  })
}

export default {
  getCatalogue,
  getProductBySearch,
  getNotMourningCatalogue,
  getCatalogsByFlorist,
  getAvailableDatesByProduct
}
