import $ from 'jquery'

/**
 * Vérifie si une variable est une fonction.
 *
 * @param variable
 *
 * @returns {boolean}
 */
export function isFunction(variable) {
  return typeof variable === typeof (() => {})
}

/**
 * Vérifie si un variable est un boolean.
 * Ne vérifie pas si la valeur existe ou pas.
 *
 * @param variable
 *
 * @returns {boolean}
 */
export function isBool(variable) {
  return typeof variable === typeof true
}

/**
 * Vérifie si un variable est un object.
 * Ne vérifie pas si c'est une instance d'un objet.
 * N'équivaux pas à instanceof.
 *
 * @param item
 *
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

/**
 * Test si une variable est un number
 *
 * @param variable
 * @returns {boolean}
 */
export function isNumber(variable) {
  return typeof variable === typeof 1
}

/**
 * Test si une variable est un array
 *
 * @param variable
 *
 * @returns {boolean}
 */
export function isArray(variable) {
  return $.isArray(variable)
}
