import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

import AlertCustom from '../../ui/alert/AlertCustom'
import CompteursGraphique from './CompteursGraphique'
import CompteursTable from './CompteursTable'
import Loader from '../../ui/loader/Loader'

const CompteursTabs = ({years, isLoading, isError}) => {
  const [selectedTab, setSelectedTab] = useState()

  // Gestion du tab active
  const handleSelectedTab = (tab) => {
    setSelectedTab(tab)
  }

  useEffect(() => {
    if (years && years.length) setSelectedTab(`graph${years[0]}`)
  }, [years])

  return (
    <>
      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
        classname="mt-4 mx-3 mb-3"
      />

      {!isLoading && !isError && years.length && (
        <Tab.Container
          activeKey={selectedTab}
          onSelect={(k) => handleSelectedTab(k)}
          id="compteurs-tabs">
          <Nav className="d-flex flex-row justify-content-sm-between bg-grey px-3 py-2">
            <div className="d-flex flex-row flex-wrap">
              {years.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    eventKey={`table${item}`}
                    className="menu-sub-item my-1 my-sm-2 mr-2">
                    Année {item}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </div>

            <div className="d-flex flex-row flex-wrap">
              {years.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    eventKey={`graph${item}`}
                    className="menu-sub-item my-1 my-sm-2 mr-2 mr-md-0 ml-0 ml-md-2">
                    Graphique {item}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </div>
          </Nav>
          <hr className="m-0" />
          <Tab.Content>
            {years.map((item, index) => (
              <Tab.Pane key={index} eventKey={`table${item}`}>
                <CompteursTable year={item} />
              </Tab.Pane>
            ))}

            {years.map((item, index) => (
              <Tab.Pane key={index} eventKey={`graph${item}`}>
                <CompteursGraphique year={item} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  )
}

CompteursTabs.propTypes = {
  isError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool,
  years: PropTypes.array,
}

export default CompteursTabs
