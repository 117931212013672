import PropTypes from 'prop-types'
import Popover from 'react-bootstrap/Popover'
import {Overlay, OverlayTrigger} from 'react-bootstrap'
import React from 'react'
import Image from 'react-bootstrap/Image'

export function PopoverOrder({product, composition, content}) {
  return (
    <>
      <OverlayTrigger
        placement={'auto'}
        overlay={
          <Popover id={`popover-${product.idcompo}`}>
            <Popover.Header as="h3">{product.libcompo}</Popover.Header>
            <Popover.Body>
              <Image
                src={`https://cdn.florajet.com/produits/300/${product.idcompo}.jpg`}
                className={'img-fluid'}
                onError={(e) => {
                  e.target.src =
                    'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                }}
              />
              {composition}
            </Popover.Body>
          </Popover>
        }>
        {content}
      </OverlayTrigger>
    </>
  )
}

PopoverOrder.propTypes = {
  product: PropTypes.object.isRequired,
  composition: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
}
