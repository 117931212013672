import {Link} from "react-router-dom";
import Box from "../../ui/box/Box";
import {SavsTab} from "../sav/SavsTab";
import {useStoreSavs} from "../../../store/useStoreSavs";

export const DashboardSavs = () => {
    const savs = useStoreSavs(state => state.savs);

    return (
        <>
            <Box
                boxName="sav dashboard-sav"
                boxClassNames="flex-column mb-4"
                header={true}
                headerClassNames="rounded-top"
                headerTextColor="white"
                headerContent={
                    <>
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-sm-between">
                            <h3 className="mr-3 mb-2 mb-sm-0">Mes derniers SAV</h3>
                            <hr />
                            <Link
                                to="/sav"
                                role="button"
                                className="btn d-flex align-items-center btn-sm ff-bold">
                                Voir tout
                            </Link>
                        </div>
                    </>
                }
                boxContentClassNames="rounded-bottom"
                content={
                    <>
                        <SavsTab savs={savs.slice(0, 4)}/>
                    </>
                }
            />

        </>
    );
}