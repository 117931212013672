import PropTypes from 'prop-types'
import {ListGroup} from 'react-bootstrap'
import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Heading} from '../page/Title'
import ActionButtons from '../../../helpers/ActionButtons'
import Loader from '../loader/Loader'
import Numbers from '../../../helpers/Numbers'
import AlertCustom from '../alert/AlertCustom'
import {
  EXTRANET_STATE_WAITING_RESPONSE,
} from '../../../hooks/useExtranetState'
import AuthContext from '../../../contexts/AuthContext'
import {
  asyncValidatePrebook,
  fetchPrebooks,
  selectShoppers,
} from '../../../slice/shoppersSlice'
import {isObject} from '../../../Tools/TypeOf'
import {
  addChosenPrebook,
  configureShoppersModals,
  selectShoppersModals,
} from '../../../slice/shoppersModalsSlice'
import {useStoreExecutions} from "../../../store/useStoreExecutions";

/**
 * Affiche la liste de Shoppers disponible pour une commande.
 *
 * @param {$ObjMap}  shopperModalState
 * @param {function} handleShopperPrebookOnHide
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const ShoppersListGroup = ({numcde, execution, handleShopperPrebookOnHide}) => {
  const dispatch = useDispatch()
  const {prebooks, error: prebookRequestError} = useSelector(selectShoppers)
  const {user} = useContext(AuthContext)
  const getExecutions = useStoreExecutions((state) => state.getExecutions)
  const {
    loading,
    prebookListShow,
    deliveryDate,
    currentPrebookNumcde,
    error,
    errorText
  } = useSelector(selectShoppersModals)

  const [prebookValidationRequested, setPrebookValidationRequested] = useState(
    false,
  )
  const [closeModalCountdown, setCloseModalCountdown] = useState({seconds: 5})
  const [intervalId, setIntervalId] = useState()
  const [acceptExec, setAcceptExec] = useState(false)

  const [resultVariant, setResultVariant] = useState('success')
  const [successText] = useState('La livraison a été validée.')
  const [resultText, setResultText] = useState()

  useEffect(() => {
    if (deliveryDate && currentPrebookNumcde) {
      setPrebookValidationRequested(false)

      // Demande des prebook aux différents shoppers disponible.
      dispatch(fetchPrebooks({numcde, deliveryDate}))
      dispatch(configureShoppersModals({loading: false}))
    }
  }, [currentPrebookNumcde, deliveryDate])

  useEffect(() => {
    if (closeModalCountdown.seconds === 0) {
      handleShopperPrebookOnHide()
      setPrebookValidationRequested(false)

      clearInterval(intervalId)
    }
  }, [closeModalCountdown.seconds])

  // Accept la commande si un prebook est validé
  // et que la commande n'est pas encore acceptée.
  useEffect(() => {
      if (acceptExec) {
        getExecutions(user, true, false)
      }
  }, [acceptExec])

  useEffect(() => {
    if (prebookValidationRequested && (error || prebookRequestError)) {
      setResultVariant('danger')
      setResultText(errorText || prebookRequestError)

      clearInterval(intervalId)
    }
  }, [error, prebookRequestError, prebookValidationRequested])

  /**
   * Envoie le prebook choisi pour la commande
   * et ajoute le prebook dans le State qui contient
   * les prebooks séléctionnés pour les commandes.
   *
   * @param {object} prebook
   *
   * @returns {Promise<void>}
   */
  const handleChoose = async (prebook) => {
    dispatch(configureShoppersModals({loading: true}))
    await dispatch(asyncValidatePrebook({prebook}))

    if (!prebookRequestError) {
      dispatch(addChosenPrebook({prebook}))
      getExecutions(user, true, false)
    }

    if (
      Number(String(execution.etatextranet)[0]) <=
      EXTRANET_STATE_WAITING_RESPONSE
    ) {
      setAcceptExec(true)
    }

    dispatch(configureShoppersModals({loading: false}))

    let {seconds} = closeModalCountdown

    setIntervalId(
      setInterval(() => {
        seconds -= 1
        setCloseModalCountdown({seconds})
      }, 1000),
    )

    setPrebookValidationRequested(true)
  }

  return (
    (prebookListShow && isObject(prebooks) &&
      Object.values(prebooks) &&
      Object.values(prebooks).length > 0 &&
      !loading &&
      !prebookValidationRequested && (
        <ListGroup className="bg-grey-light">
          {Object.values(prebooks).map((prebook, i) => (
            <div key={i}>
              <ListGroup.Item className="d-flex justify-content-around align-items-center p-0 m-2">
                 {(prebook.shopper.config && (
                  <img
                    src={prebook.shopper.config.logoSrc || ''}
                    width="64px"
                    height="64px"
                  />
                 )) ||
                  (prebook.shopper.reference && (
                    <Heading
                      title={prebook.shopper.reference}
                      headingLevel={3}
                      className="h5 mx-0 my-3"
                    />
                  ))}
                {(!prebook.error && (
                  <>
                    <p className="m-0">
                      {prebook.price && (() => Numbers.ConvertToEuro(prebook.price))() || '--------'}
                    </p>
                    <ActionButtons
                      buttons={[
                        {
                          name: 'Choisir',
                          icon: 'check',
                          color: 'primary-dark',
                          func: handleChoose,
                        },
                      ]}
                      item={prebook}
                    />
                  </>
                ))}
                {prebook.error && (
                  <AlertCustom
                    variant={'warning'}
                    bodyContentHtml={`<strong>${prebook.error}</strong>`}
                    transition={true}
                    bodyIcon={true}
                    col={6}
                  />
                )}
              </ListGroup.Item>
            </div>
          ))}
        </ListGroup>
      )) ||
    (prebookValidationRequested && (
      <>
        <AlertCustom
          variant={resultVariant}
          classname={'w-50 mt-3'}
          bodyContent={<strong>{resultText || successText}</strong>}
          transition={true}
          bodyIcon={true}
        />

        {!error && !prebookRequestError && (
          <p className={'text-muted text-right m-2'}>
            La fenêtre se fermera dans {closeModalCountdown.seconds}s
          </p>
        )}
      </>
    )) || <Loader />
  )
}

ShoppersListGroup.propTypes = {
  numcde: PropTypes.string.isRequired,
  execution: PropTypes.object.isRequired,
  handleShopperPrebookOnHide: PropTypes.func.isRequired,
}

export default ShoppersListGroup
