import axios from 'axios'
import jwtDecode from 'jwt-decode'

import API from './API'
import {
  ADMIN_ROLE,
  FLORIST_FORGOT_PASSWORD,
  FLORIST_RESET_PASSWORD,
  FLORIST_RESET_PASSWORD_TOKEN,
  LOGIN_API,
} from '../constants/Constants'

/**
 * @param credentials
 * @return {AxiosPromise<any>}
 */
async function authenticate(credentials) {
  return API({
    method: 'post',
    url: LOGIN_API,
    data: credentials,
  })
}


/**
 *
 */
function logout() {
  window.localStorage.removeItem('authToken')
  delete axios.defaults.headers.Authorization
  sessionStorage.clear()
}

/**
 * @return {boolean}
 */
function isAuthenticated() {
  const token = window.localStorage.getItem('authToken')

  if (token) {
    const {exp: expiration} = jwtDecode(token)

    if (expiration * 1000 > new Date().getTime()) {
      return true
    }
    return false
  }
  return false
}

/**
 * isAdministrator
 *
 * @param user
 * @return {boolean}
 */
function isAdministrator(user) {
  return Boolean(user.roles.find((item) => item === ADMIN_ROLE))
}

/**
 * @return {boolean|unknown}
 */
function getUser() {
  const token = window.localStorage.getItem('authToken')

  if (token) {
    const {exp: expiration} = jwtDecode(token)
    if (expiration * 1000 > new Date().getTime()) {
      const user = jwtDecode(token)
      return user
    }
    return false
  }
  return false
}

/**
 * @param credentials
 * @returns {AxiosPromise}
 */
function forgotPassword(credentials) {
  return API({
    method: 'post',
    url: FLORIST_FORGOT_PASSWORD,
    data: credentials,
  })
}

/**
 * @param credentials
 * @returns {AxiosPromise}
 */
function resetPassword(credentials) {
  return API({
    method: 'post',
    url: FLORIST_RESET_PASSWORD,
    data: credentials,
  })
}

/**
 * @return {AxiosPromise}
 */
function resetPasswordToken() {
  return API({
    method: 'get',
    url: FLORIST_RESET_PASSWORD_TOKEN,
  })
}

function sendErrorLogin(error) {
  return API({
    method: 'get',
    url: '/florajet/error-login/send',
    data: error,
  })
}

export default {
  authenticate,
  logout,
  isAuthenticated,
  isAdministrator,
  getUser,
  forgotPassword,
  resetPassword,
  resetPasswordToken,
  sendErrorLogin,
}
