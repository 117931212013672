import {number, object, string} from 'yup'
import uuid from 'react-uuid'

export default class Sperimetr {
  constructor(data) {
    this.idsperimetr = data.idsperimetr
    this.idscommunes = `${data.idscommunes}`
    this.codefl = data.codefl
    this.dep = data.dep
    this.cpostal = data.cpostal
    this.localite = data.localite
    this.fraislive = data.fraislive
    this.montantminie = Number(data.montantminie)
    this.depannage = data.depannage
    this.veillefaf = data.veillefaf
    this.isSurvey = data.isSurvey
    this.formattedAddress = data.formattedAddress

    this.updated = false
  }

  static initialeValues() {
    return {
      idsperimetr: uuid(),
      idscommunes: undefined,
      montantminie: '0.00',
      depannage: false,
    }
  }

  static validationSchemas() {
    return object().shape({
      idscommunes: string().required('Ce champs requis'),
      montantminie: number().moreThan(-1).required('Le montant minimum est requis'),
    })
  }

  initializedValues() {
    return {
      idsperimetr: this.idsperimetr || uuid(),
      idscommunes: this.idscommunes,
      montantminie: this.montantminie,
      depannage: this.depannage,
    }
  }

  buildCommune() {
    return {
      codpostal: this.cpostal,
      localite: this.localite,
      idscommunes: this.idscommunes,
    }
  }

  buildFormattedAddress({localite, codpostal}) {
    this.formattedAddress = `${localite} (${codpostal})`
  }
}
