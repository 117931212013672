import {SCHEDULES_API, SCHEDULES_IFRAME_API} from '../constants/Constants'

function getHoraires(codefl) {
  return {
    name: 'horaires',
    method: 'get',
    url: `${SCHEDULES_API}?codefl=${codefl}`,
  }
}

function getIframe(codefl) {
  return {
    name: 'horaires',
    method: 'get',
    url: `${SCHEDULES_IFRAME_API}/${codefl}`,
  }
}

export default {
  getHoraires,
  getIframe,
}
