import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import CompteursAPI from '../../../services/CompteursAPI'
import Loader from '../../ui/loader/Loader'
import useFetch from '../../../hooks/useFetch'

const CompteursGraphique = ({year}) => {
  const [graphDatas, setGraphDatas] = useState({})
  const {user} = useContext(AuthContext)

  const [{data: compteurs, isLoading, isError}, fetchData] = useFetch(
    CompteursAPI.getCompteursGraphique(year, user.CodeFl),
  )

  // Gestion du changement de magasin
  useEffect(() => {
    fetchData(CompteursAPI.getCompteursGraphique(year, user.CodeFl))
  }, [user.CodeFl])

  const labels = ['Nb. Trans', 'Nb. Exec', 'Nb. Refus']
  const datasIndexes = ['nbtrans', 'nbexec', 'nbrefus']
  const colors = ['#EFBC4B', '#528FE3', '#E35252']

  const options = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 14,
            fontColor: '#2c2c2c',
            fontStyle: 'bold',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 14,
            fontColor: '#2c2c2c',
            fontStyle: 'bold',
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      align: 'start',
      labels: {
        boxWidth: 15,
        fontColor: '#2c2c2c',
        fontSize: 14,
        padding: 30,
      },
    },
  }

  // Gestion de la config et des datas du graphique
  const handleGraphique = (compteurs, labels, datasIndexes) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
    )

    setGraphDatas({
      labels: compteurs.map(
        ({mois}) => `${mois.substring(4)}/${mois.substring(0, 4)}`,
      ),
      datasets: labels.map((label, index) => {
        return {
          label: labels[index],
          data: compteurs.map((stat) => stat[datasIndexes[index]]),
          backgroundColor: colors[index],
        }
      }),
    })
  }

  useEffect(() => {
    if (compteurs) {
      handleGraphique(compteurs, labels, datasIndexes)
    }
  }, [compteurs])

  return (
    <>
      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
        classname="mt-4 mx-3 mb-3"
      />

      {!isLoading &&
        !isError &&
        compteurs &&
        Object.values(graphDatas).length &&
        Object.values(options).length && (
          <div className="charts">
            <Bar data={graphDatas} options={options} />
          </div>
        )}
    </>
  )
}

CompteursGraphique.propTypes = {
  year: PropTypes.string,
}

export default CompteursGraphique
