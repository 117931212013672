import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'
import Col from "react-bootstrap/Col";

import Bandeaux from './Bandeaux'
import NavbarTop from './NavbarTop'
import {Message} from "../messages/Message";
import {FlowerFeastHeader} from "../flower_feast/FlowerFeastHeader";

const Header = ({toggleMenu}) => {
  return (
    <>
      <NavbarTop toggleMenu={toggleMenu} />

      <Container id="layout-header" fluid className="header">
        <Row className="no-gutters d-print-none">
          <Message />
          <Bandeaux />
        </Row>
      </Container>
        <Container fluid>
            <Row>
                <Col>
                    <FlowerFeastHeader />
                </Col>
            </Row>
        </Container>
    </>
  )
}

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
}

export default Header
