import React, {useState, useContext} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import AuthContext from '../../../contexts/AuthContext';
import {forgotLoginPassword} from "../../../hooks/usePassword";
import Field from "../../ui/form/Field";
import Loader from "../../ui/loader/Loader";
import AlertCustom from "../../ui/alert/AlertCustom";

const LoginFormForgotPassword = ({location}, history) => {
  const {isAuthenticated} = useContext(AuthContext)
  const {state, dispatch, handleChange, handleSubmit} = forgotLoginPassword(location, history);

  return (
    <div className="px-3 py-4">
      <Row>
        <Col
          md={{span: 10, offset: 1}}
          xl={{span: 8, offset: 2}}
          className="col-hd-6 offset-hd-3">
          <h1>Mot de passe oublié</h1>

          {!state.isError && state.isValid ?
            <AlertCustom
              heading={false}
              variant="success"
              bodyContent={state.message}
              transition={!state.isError}
              dismissible={false}
              classname={"text-center"}
            /> :
              <AlertCustom
                heading={false}
                variant={"danger"}
                bodyContent={state.errorMessage}
                transition={state.isError}
                dismissible={false}
                classname={"text-center"}
              />
          }

          <Form onSubmit={handleSubmit}>
            <Field
              label={"Identifiant"}
              labelRequired={true}
              name={"floristCode"}
              classNames={"mb-3"}
              value={state.credentials.floristCode}
              onChange={handleChange}
              onFocus={() => {
                dispatch({
                  type: 'HANDLE_FOCUS',
                })
              }}
              error={state.errorField.floristCode}
            />

            <Button
              type="submit"
              disabled={isAuthenticated}
              className="btn-primary mb-3">
              {state.isLoading ? (
                  <Loader variant="white" size="sm" classname="my-1 mx-3" /> // Indique le loading du dispatch 'FETCH_INIT'
              ) : (
                  'Valider'
              )}
            </Button>
          </Form>

          <Link
            to="/login"
            className="btn-link text-grey-darker d-flex flex-row align-items-center">
            <FontAwesomeIcon
              icon="chevron-left"
              color="var(--grey-darker)"
              className="mr-1"
            />
            <span>Retour</span>
          </Link>
        </Col>
      </Row>
    </div>
  )
}

LoginFormForgotPassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
}

export default LoginFormForgotPassword
