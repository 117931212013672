import React, {useState, useEffect, useContext} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AlertCustom from '../../ui/alert/AlertCustom'
import CatalogueAPI from '../../../services/CatalogueAPI'
import CatalogueFilters from './CatalogueFilters'
import {ITEMS_PER_PAGE_CATALOGUE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import PaginationSelect from '../../ui/pagination/PaginationSelect'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'
import {CatalogueItems} from "./CatalogueItems";
import AuthContext from "../../../contexts/AuthContext";

const Catalogue = () => {
  const [searchInput, setSearchInput] = useState({reference: '', libcompo: ''})
  const [searchText, setSearchText] = useState()
  const [searchType, setSearchType] = useState()
  const [sortCatalog, setSortCatalog] = useState('')
  const [sortType, setSortType] = useState('reference')
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [showTFCatalog, setShowTFCatalog] = useState(false);
  const {user} = useContext(AuthContext);

  const [
    {data: catalogue, totalItems, isLoading, isError},
    fetchData,
  ] = useFetch(
    CatalogueAPI.getNotMourningCatalogue(user.CodeFl,'', '', '2022', sortType, sortOrder, currentPage),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (reload)
      fetchData(
        CatalogueAPI.getNotMourningCatalogue(
          user.CodeFl,
          searchText,
          searchType,
          sortCatalog,
          sortType,
          sortOrder,
          currentPage
        ),
      )
  }, [searchText, sortCatalog, sortType, sortOrder, currentPage])

  // Gestion des champs recherche
  const handleSearchInput = (event) => {
    setSearchInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
    setSearchType(event.target.name)
  }

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setSortOrder('asc')
    setReload(true)
    setSearchText(searchInput[searchType])

    const keyToClear = Object.keys(searchInput).filter(
      (key) => key !== searchType,
    )
    setSearchInput((prevState) => ({
      ...prevState,
      [keyToClear]: '',
    }))
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setReload(true)
    setSearchInput({reference: '', libcompo: ''})
    setSearchText()
    setSearchType()
  }

  // Gestion du changement de filtre
  const handleSortType = (event) => {
    const {selectedIndex} = event.target.options
    setCurrentPage(1)
    setSortOrder('asc')
    setReload(true)
    setSortType(event.target.options[selectedIndex].getAttribute('id'))
  }

  // Gestion du changement de catalogue
  const handleSortCatalog = (event) => {
    const {selectedIndex} = event.target.options
    const catalogName = event.target.options[selectedIndex].getAttribute('id');
    setCurrentPage(1)
    setSortOrder('asc')
    setReload(true)
    setSortCatalog(catalogName)

    if (catalogName === 'TF') {
      setShowTFCatalog(true);
    } else {
      setShowTFCatalog(false);
    }
  }

  // Gestion du changement de tri
  const handleSortOrder = () => {
    const sort = sortOrder === 'asc' ? 'desc' : 'asc'
    setCurrentPage(1)
    setSortOrder(sort)
    setReload(true)
  }

  // Gestion du changement de page
  const handlePage = (event) => {
    const {selectedIndex} = event.target.options
    setCurrentPage(event.target.options[selectedIndex].getAttribute('id'))
    setReload(true)
  }

  return (
    <>
      <Title title="Catalogue" />

      <CatalogueFilters
        handleSearch={handleSearch}
        searchInput={searchInput}
        searchType={searchType}
        searchText={searchText}
        handleSearchInput={handleSearchInput}
        handleCancel={handleCancel}
        handleSortCatalog={handleSortCatalog}
        sortCatalog={sortCatalog}
        handleSortType={handleSortType}
        sortOrder={sortOrder}
        handleSortOrder={handleSortOrder}
        totalItems={totalItems}
      />

      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
      />

      {!isLoading && !isError && (
        <Col xs={12}>
          <Row>
            <CatalogueItems catalogue={catalogue} showTFCatalog={showTFCatalog}/>
          </Row>
        </Col>
      )}

      {!isLoading && ITEMS_PER_PAGE_CATALOGUE < totalItems && (
        <PaginationSelect
          currentPage={currentPage || 1}
          itemsPerPage={ITEMS_PER_PAGE_CATALOGUE}
          totalPages={totalItems}
          handlePage={handlePage}
        />
      )}
    </>
  )
}

export default Catalogue
