import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {BrowserView, MobileView} from 'react-device-detect';

import Box from '../../ui/box/Box'
import {CartContext} from '../../../contexts/CartContext'
import ModalCart from '../cart/ModalCart'
import ModalCustom from '../../ui/modal/ModalCustom'
import Numbers from '../../../helpers/Numbers'
import Select from '../../ui/form/Select'
import Text from '../../../helpers/Text'
import CardCustom from "../../ui/card/CardCustom";

const AchatsItem = ({article}) => {
    const [quantity, setQuantity] = useState(1)
    const [isRemovable, setIsRemovable] = useState(false)
    const [size, setSize] = useState()
    const [sizeId, setSizeId] = useState()
    const [priceUnit, setPriceUnit] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [modalCartShow, setModalCartShow] = useState(false)

    const {addToCart, removeFromCart, cartItems, handlePriceTotal} = useContext(
        CartContext,
    )

    // Gestion du prix et de la taille au chargement de l'article
    useEffect(() => {
        if (article.articleTypes && article.articleTypes.length) {
            setSize(article.articleTypes[0].articlesExtranetTypeLibelle)
            setSizeId(article.articleTypes[0].articlesExtranetTypeId)
            setPriceUnit(article.articleTypes[0].articlesExtranetTypePrix)
        }
    }, [article])

    // Gestion de la synchro dynamique avec le contexte panier
    useEffect(() => {
        if (cartItems) {
            const index = cartItems.findIndex(
                (item) => item.idSarticles === article.idSarticles,
            )

            // Si l'article existe dans le panier
            if (index !== -1) {
                const indexType = cartItems[index].articleTypes.findIndex(
                    (item) => item.articlesExtranetTypeId === cartItems[index].cartSizeId,
                )

                if (indexType !== -1) {
                    setQuantity(cartItems[index].cartQuantity)
                    setSize(cartItems[index].cartSize)
                    setSizeId(cartItems[index].cartSizeId)
                    setPriceUnit(cartItems[index].cartPriceUnit)
                    setIsRemovable(true)
                }
            }
        }

        if (!cartItems.length) {
            setQuantity(1)
        }
    }, [cartItems])

    // Gestion du retrait de quantités
    const removeQuantity = () => {
        setQuantity(quantity - 1)
    }

    // Gestion de l'ajout de quantités
    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    // Gestion de l'id de la taille et de la quantité selon le choix de la taille
    const handleSize = (event) => {
        setSize(event.target.value)

        for (let i = 0; i < article.articleTypes.length; i += 1) {
            if (
                article.articleTypes[i].articlesExtranetTypeLibelle ===
                event.target.value
            ) {
                setSizeId(article.articleTypes[i].articlesExtranetTypeId)
                setPriceUnit(article.articleTypes[i].articlesExtranetTypePrix)
            }
        }

        if (cartItems) {
            const index = cartItems.findIndex(
                (item) =>
                    item.idSarticles === article.idSarticles &&
                    item.cartSize === event.target.value,
            )

            // Si l'article existe dans le panier
            // A VOIR SI ON GARDE LA QUANTITE DE L'ARTICLE AJOUTE AVANT OU ON RESET A 0
            if (index !== -1) {
                setQuantity(cartItems[index].cartQuantity)
                setIsRemovable(true)
            } else {
                setQuantity(0)
                setIsRemovable(false)
            }
        }
    }

    // Gestion de l'ajout au panier
    const handleAddToCart = (article, quantity, size, sizeId, priceUnit) => {
        if (isRemovable && quantity === 0) {
            removeFromCart(article, sizeId)
            setIsRemovable(false)
        } else {
            addToCart(article, quantity, size, sizeId, priceUnit)
            setIsRemovable(true)
        }
        setModalCartShow(true)
    }

    const itemDescription = () => {
        return (
            <>
                <h5 className="mb-2" onClick={() => setModalShow(true)}>
                    {Text.CapitalizeAll(article.articlesExtranetLibelle)}
                </h5>
                <div
                    className="description flex-grow-1"
                    onClick={() => setModalShow(true)}>
                    {Text.CapitalizeAll(article.articlesExtranetDescription)}
                </div>
            </>
        )
    }

    const itemPrice = () => {
        return (
            <>
                <BrowserView renderWithFragment={true}>
                    <p className="price mb-0" onClick={() => setModalShow(true)}>
                        {!priceUnit
                            ? 'NC'
                            : handlePriceTotal(priceUnit, quantity)
                                ? Numbers.ConvertToEuro(
                                    Number(handlePriceTotal(priceUnit, quantity)),
                                )
                                : priceUnit === '0'
                                    ? 'GRATUIT'
                                    : Numbers.ConvertToEuro(Number(priceUnit))}
                    </p>
                     <div className="d-none flex-row align-items-center my-1">
                        <Button
                            className="btn-trans p-0"
                            onClick={removeQuantity}
                            disabled={quantity < 1 && true}>
                            <FontAwesomeIcon
                                size="lg"
                                icon="minus-circle"
                                color="var(--black-light)"
                            />
                        </Button>
                        <div className="quantity mx-3 unselectable">{quantity}</div>
                        <Button
                            className="btn-trans p-0 mr-2"
                            onClick={addQuantity}
                            disabled={
                                quantity > article.articlesExtranetMaxi - 1 && true
                            }>
                            <FontAwesomeIcon
                                size="lg"
                                icon="plus-circle"
                                color="var(--black-light)"
                            />
                        </Button>
                     </div>

                    {size &&
                        article.articleTypes &&
                        article.articleTypes.length &&
                        article.articleTypes.length > 1 && (
                            <div className="size">
                                <Select
                                    controlId="sizeSelect"
                                    classNames="m-0"
                                    classNamesForm="m-0"
                                    value={size}
                                    onChange={handleSize}
                                    options={
                                        <>
                                            {article.articleTypes.map((articleType, index) => (
                                                <option key={index}>
                                                    {articleType.articlesExtranetTypeLibelle}
                                                </option>
                                            ))}
                                        </>
                                    }
                                />
                            </div>
                        )
                    }

                    <Button
                        className="btn-rounded mt-2 btn-block"
                        disabled={
                            (quantity > article.articlesExtranetMaxi || quantity < 1) &&
                            !isRemovable &&
                            true
                        }
                        onClick={() =>
                            handleAddToCart(article, quantity, size, sizeId, priceUnit)
                        }>
                        <FontAwesomeIcon
                            icon="shopping-cart"
                            color="white"
                            className="mr-2"
                        />
                        Ajouter au panier
                    </Button>
                    <p className="quantity-max mt-2 mb-0 unselectable">
                        Max : {article.articlesExtranetMaxi}{' '}
                    </p>
                </BrowserView>

                <MobileView renderWithFragment={true}>
                    <div className={'flex-grow-1 p-1'}>
                        <p className="quantity-max mb-2 unselectable w-100 text-center">
                            Max : {article.articlesExtranetMaxi}{' '}
                        </p>
                        <div className={'d-flex flex-wrap justify-content-around align-items-center'}>
                            <p className="price mb-0" onClick={() => setModalShow(true)}>
                                {!priceUnit
                                    ? 'NC'
                                    : handlePriceTotal(priceUnit, quantity)
                                        ? Numbers.ConvertToEuro(
                                            Number(handlePriceTotal(priceUnit, quantity)),
                                        )
                                        : priceUnit === '0'
                                            ? 'GRATUIT'
                                            : Numbers.ConvertToEuro(Number(priceUnit))}
                            </p>
                            <div className="d-none flex-row align-items-center my-1">
                                <Button
                                    className="btn-trans p-0"
                                    onClick={removeQuantity}
                                    disabled={quantity < 1 && true}>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon="minus-circle"
                                        color="var(--black-light)"
                                    />
                                </Button>
                                <div className="quantity mx-3 unselectable">{quantity}</div>
                                <Button
                                    className="btn-trans p-0 mr-2"
                                    onClick={addQuantity}
                                    disabled={
                                        quantity > article.articlesExtranetMaxi - 1 && true
                                    }>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon="plus-circle"
                                        color="var(--black-light)"
                                    />
                                </Button>
                            </div>

                                {size &&
                                    article.articleTypes &&
                                    article.articleTypes.length &&
                                    article.articleTypes.length > 1 && (
                                        <div className="size">
                                            <Select
                                                controlId="sizeSelect"
                                                classNames="m-0"
                                                classNamesForm="m-0"
                                                value={size}
                                                onChange={handleSize}
                                                options={
                                                    <>
                                                        {article.articleTypes.map((articleType, index) => (
                                                            <option key={index}>
                                                                {articleType.articlesExtranetTypeLibelle}
                                                            </option>
                                                        ))}
                                                    </>
                                                }
                                            />
                                        </div>
                                    )
                                }
                        </div>
                    </div>

                    <Button
                        className="mobile-cart-btn"
                        disabled={
                            (quantity > article.articlesExtranetMaxi || quantity < 1) &&
                            !isRemovable &&
                            true
                        }
                        onClick={() =>
                            handleAddToCart(article, quantity, size, sizeId, priceUnit)
                        }>
                        <FontAwesomeIcon icon={'cart-plus'} size={'lg'}/>
                    </Button>
                </MobileView>
            </>
        )
    }

    return (
        <>
            <BrowserView renderWithFragment={true}>
                <Box
                    boxMd={6}
                    boxXxl="4"
                    boxHd="3"
                    boxName="achats-item"
                    boxContentClassNames="d-flex flex-row"
                    boxContentPadding="p-1"
                    boxClassNames={'rounded mb-3'}
                    content={
                        <>
                            <div className="p-0 align-self-center col-4">
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        height: '300px',
                                        background: `center / cover no-repeat url(https://www.monflorajet.com/ftp-extranet/OrderShop/Product/${article.idSarticles}/01.jpg)`,
                                    }}
                                    onClick={() => setModalShow(true)}
                                    alt="Mon Florajet"
                                    className="rounded unselectable"></div>
                            </div>
                            <div className="d-flex flex-column col-8 p-3">
                                {itemDescription()}
                                <div>
                                    <hr className="mt-0 mb-1"/>
                                    {itemPrice()}
                                </div>
                            </div>
                        </>
                    }
                />
            </BrowserView>

            <MobileView renderWithFragment={true}>
                <CardCustom
                    cardClassName={'mb-3 achats-item'}
                    headerClassName={'w-50'}
                    header={
                        <><img className={'img-fluid rounded'}
                               src={`https://www.monflorajet.com/ftp-extranet/OrderShop/Product/${article.idSarticles}/01.jpg`}/></>
                    }
                    body={itemDescription}
                    footer={itemPrice}
                    footerClassName={'pr-0 py-0'}
                />
            </MobileView>

            <ModalCustom
                show={modalShow}
                onHide={() => setModalShow(false)}
                id="modal-achat"
                size="lg"
                modalClassname="text-left"
                bodyClassname="p-0"
                content={
                    <Box
                        boxSm={12}
                        boxName="achats-item"
                        boxContentBg="white"
                        boxContentClassNames="d-flex flex-row"
                        boxContentRounded="xl"
                        content={
                            <>
                                <div className="col-4 p-0 align-self-center">
                                    <img
                                        src={`https://www.monflorajet.com/ftp-extranet/OrderShop/Product/${article.idSarticles}/01.jpg`}
                                        alt="Mon Florajet"
                                        className="rounded unselectable img-fluid"
                                    />
                                </div>
                                <div className="col-8 d-flex flex-column px-3 pt-3 pb-1">
                                    <h5 className="mb-2" onClick={() => setModalShow(true)}>
                                        {Text.CapitalizeAll(article.articlesExtranetLibelle)}
                                    </h5>
                                    <div
                                        className="description flex-grow-1"
                                        onClick={() => setModalShow(true)}>
                                        {Text.CapitalizeAll(article.articlesExtranetDescription)}
                                    </div>
                                    <div>
                                        <hr className="mt-0 mb-1"/>
                                        <p
                                            className="price mb-0"
                                            onClick={() => setModalShow(true)}>
                                            {!priceUnit
                                                ? 'NC'
                                                : handlePriceTotal(priceUnit, quantity)
                                                    ? Numbers.ConvertToEuro(
                                                        Number(handlePriceTotal(priceUnit, quantity)),
                                                    )
                                                    : priceUnit === '0'
                                                        ? 'GRATUIT'
                                                        : Numbers.ConvertToEuro(Number(priceUnit))}
                                        </p>
                                        <div className="d-none flex-row align-items-center my-1">
                                            <Button
                                                className="btn-trans p-0"
                                                onClick={removeQuantity}
                                                disabled={quantity < 1 && true}>
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon="minus-circle"
                                                    color="var(--black-light)"
                                                />
                                            </Button>
                                            <div className="quantity mx-3 unselectable">
                                                {quantity}
                                            </div>
                                            <Button
                                                className="btn-trans p-0 mr-2"
                                                onClick={addQuantity}
                                                disabled={
                                                    quantity > article.articlesExtranetMaxi - 1 && true
                                                }>
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon="plus-circle"
                                                    color="var(--black-light)"
                                                />
                                            </Button>
                                        </div>

                                        <div className="size">
                                            {size &&
                                                article.articleTypes &&
                                                article.articleTypes.length &&
                                                article.articleTypes.length > 1 && (
                                                    <Select
                                                        controlId="sizeSelect"
                                                        classNames="m-0"
                                                        classNamesForm="m-0"
                                                        value={size}
                                                        onChange={handleSize}
                                                        options={
                                                            <>
                                                                {article.articleTypes.map(
                                                                    (articleType, index) => (
                                                                        <option key={index}>
                                                                            {articleType.articlesExtranetTypeLibelle}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                )}
                                        </div>

                                        <Button
                                            className="btn-rounded mt-2"
                                            disabled={
                                                (quantity > article.articlesExtranetMaxi ||
                                                    quantity < 1) &&
                                                !isRemovable &&
                                                true
                                            }
                                            onClick={() =>
                                                handleAddToCart(
                                                    article,
                                                    quantity,
                                                    size,
                                                    sizeId,
                                                    priceUnit,
                                                )
                                            }>
                                            Ajouter au panier
                                        </Button>
                                        <p className="quantity-max mt-2 mb-0 unselectable">
                                            Max : {article.articlesExtranetMaxi}
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />

            <ModalCart
                modalCartShow={modalCartShow}
                setModalCartShow={setModalCartShow}
            />
        </>
    )
}

AchatsItem.propTypes = {
    article: PropTypes.object,
}

export default AchatsItem
