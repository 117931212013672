import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";
import CommandeAPI from "../../../services/CommandeAPI";
import ModalCustom from "../../ui/modal/ModalCustom";
import AlertCustom from "../../ui/alert/AlertCustom";

export const SavOrderModal = ({orderNumber, onHideModal, showModal}) => {
    const {user} = useContext(AuthContext);
    const [pdf, setPdf] = useState();

    useEffect(async () => {
        await CommandeAPI.getPDF(user.CodeFl, orderNumber)
            .then((response) => {
                if (response.data !== '') {
                    setPdf(response.data);
                }
            })
        ;
    }, [orderNumber]);

    return (
        <ModalCustom
            show={showModal}
            id={'modal-sav-order'}
            onHide={() => onHideModal()}
            title={`Visualiser la commande ${orderNumber}`}
            titleClassname={'text-center mb3'}
            bodyClassname={'text-left'}
            content={
                <>
                    {pdf !== ''
                        ? (
                            <div
                                className={'embed-responsive embed-responsive-1by1 mb-3'}
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe src="${pdf}" class="embed-responsive-item" allowtransparency="true"></iframe>`
                                }}>
                            </div>
                        ) :
                        (
                            <AlertCustom
                                variant={'danger'}
                                heading={true}
                                bodyContent={'Votre commande n\'a pas pu être récupérée, veuillez réessayer ultérieurement.'}
                                transition={true}
                            />
                        )
                    }
                </>
            }
            footer={true}
            buttonsFooter={[
                {
                    text: 'Fermer',
                    func: () => onHideModal()
                }
            ]}
        />
    );

}

SavOrderModal.propTypes = {
    orderNumber: PropTypes.string.isRequired,
    onHideModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
}