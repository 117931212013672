import React from 'react'
import PropTypes from 'prop-types'
import ModalCustom from '../../modal/ModalCustom'

export const EASIWARE_ID_MODAL = 'modal-contact-easiware'

const EasiwareForm = ({
  state,
  handleModalShow,
  formId,
  onMount,
  headerTitle,
  headerTitleClassname = 'col-11 mx-auto',
  titleClassname = 'row mb-3',
  bodyClassname = 'text-left',
  header = true,
}) => {
  function MountFormEasiware() {
    setTimeout(() => {
      const easiwareFormParams = {
        solutionId: '214d5d92123f02436f14b0faa3a8624f479fc521',
        formId: `${formId}`,
        language: 'fr',
        buildinelement: EASIWARE_ID_MODAL,
      }

      esw.forms.create(easiwareFormParams)
    }, 1)
  }

  const easiwareOnMount = () => {
    MountFormEasiware()

    if (typeof onMount === typeof (() => {})) {
      onMount()
    }
  }

  return (
    <ModalCustom
      id={'modal-contact'}
      onHide={() => handleModalShow(false)}
      onMount={easiwareOnMount}
      show={state.modalShow}
      modalBodyId={EASIWARE_ID_MODAL}
      headerTitle={headerTitle}
      headerTitleClassname={headerTitleClassname}
      titleClassname={titleClassname}
      bodyClassname={bodyClassname}
      header={true}
    />
  )
}

EasiwareForm.propTypes = {
  state: PropTypes.object.isRequired,
  handleModalShow: PropTypes.func.isRequired,
  formId: PropTypes.number.isRequired,
  onMount: PropTypes.func,
  modalBodyId: PropTypes.string,
  headerTitle: PropTypes.string,
  headerTitleClassname: PropTypes.string,
  titleClassname: PropTypes.string,
  bodyClassname: PropTypes.string,
  header: PropTypes.bool,
}

export default EasiwareForm
