import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import React, {useState} from 'react'
import {RouterProvider, createBrowserRouter,Navigate} from 'react-router-dom'
import 'moment/locale/fr'

import {NeedHelp} from './components/ui/chat/NeedHelp'
import AuthAPI from './services/AuthAPI'
import AuthContext from './contexts/AuthContext'
import CartContextProvider from './contexts/CartContext'
import LoginFormEditPassword from './components/pages/login/LoginFormEditPassword'
import {MenuProvider} from './providers/MenuProvider'
import {PRIVATES_ROUTES} from './router/routes/privateRoutes'
import {ADMIN_ROUTES} from './router/routes/adminRoutes'

import './App.scss'
import {Matomo} from "./components/ui/matomo/Matomo";
import Login from "./components/pages/login/Login";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthAPI.isAuthenticated(),
  )
  const [user, setUser] = useState(AuthAPI.getUser())

  moment.locale('fr')

  // Font Awesome 5 React Library
  library.add(fab, fas, far)

  return (
    <div className="App bg-white-dark">
      <MenuProvider>
        <AuthContext.Provider
          value={{
            isAuthenticated,
            setIsAuthenticated,
            user,
            setUser,
          }}>
          <NeedHelp />
          <Matomo />
          <CartContextProvider>
            <RouterProvider
              router={createBrowserRouter([
                PRIVATES_ROUTES.addRoutes(ADMIN_ROUTES).serialize(),
                {
                  path: '/nouveau-mot-de-passe',
                  element: <LoginFormEditPassword />,
                },
                  {
                      path: '/v2*',
                      element: <Navigate to={'/'} />,
                  },
                {
                  path: '*',
                  element: <Navigate to={'/'} />,
                },
              ])}
            />
          </CartContextProvider>
        </AuthContext.Provider>
      </MenuProvider>
    </div>
  )
}

export default App
