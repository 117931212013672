import {useReducer, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {PasswordFormReducer} from '../reducers/PasswordFormReducer'
import AuthAPI from '../services/AuthAPI'
import {ROOT_PATH} from "../constants/RoutesPaths";

export function updatePassword() {
  const navigate = useNavigate()
  const urlSearch = new URLSearchParams(window.location.search)
  const [validated, setValidated] = useState(false)
  const [state, dispatch] = useReducer(PasswordFormReducer, {
    isError: false,
    isLoading: false,
    message: false,
    errorField: false,
    isValid: false,
    credentials: {
      floristCode: urlSearch.get('hash'),
      newPassword: '',
      confirmPassword: '',
      passwordResetToken: urlSearch.get('token'),
      isAuthenticated: AuthAPI.isAuthenticated(),
      userRole: '',
    },
    adminModalShow: false,
    userModalShow: false,
    alertTransition: false,
  })

  function updateInput(input) {
    dispatch({
      type: 'HANDLE_CHANGE',
      credentials: {...state.credentials, [input.name]: input.value},
    })
  }

  function checkEquality(input) {
    if (state.credentials.newPassword.length <= input.value.length) {
      if (state.credentials.newPassword !== input.value) {
        dispatch({
          type: 'FETCH_ERROR_PASSWORDS_NOT_EQUAL',
        })
        setValidated(false)
      } else {
        dispatch({
          type: 'FETCH_PASSWORDS_EQUAL',
        })
        setValidated(true)
      }
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      for (const credentialsKey in state.credentials) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.credentials,
            credentialsKey,
          ) &&
          event.target[credentialsKey]
        ) {
          state.credentials[credentialsKey] = event.target[credentialsKey].value
        }
      }

      dispatch({
        type: 'HANDLE_CHANGE',
        credentials: state.credentials,
      })

      if (state.credentials.newPassword === state.credentials.confirmPassword) {
        if (AuthAPI.isAuthenticated()) {
          await AuthAPI.resetPasswordToken()
        }

        await AuthAPI.resetPassword(state.credentials)

        dispatch({
          type: 'FETCH_SUCCESS',
        })

        if (!AuthAPI.isAuthenticated()) {
          navigate(ROOT_PATH) // Redirection;
        }
      } else {
        event.stopPropagation()
        setValidated(false)
        dispatch({
          type: 'FETCH_ERROR_PASSWORDS_NOT_EQUAL',
        })
      }
    } catch (e) {
      console.log(e)
      dispatch({
        type: 'FETCH_ERROR',
      })
    }
  }

  const handlePasswordModals = (admin = false, user = false) => {
    dispatch({
      type: 'FETCH_MODAL',
      adminModalShow: admin,
      userModalShow: user,
    })
  }

  return {
    state,
    dispatch,
    validated,
    handleSubmit,
    updateInput,
    checkEquality,
    handlePasswordModals,
  }
}

export function forgotLoginPassword() {
  // Etat par défaut
  const [state, dispatch] = useReducer(PasswordFormReducer, {
    isError: false,
    isLoading: false,
    errorMessage: false,
    errorField: false,
    credentials: {
      floristCode: '',
    },
  })

  // Gestion du changement des inputs textes
  function handleChange({currentTarget}) {
    const {value, name} = currentTarget
    dispatch({
      type: 'HANDLE_CHANGE',
      credentials: {...state.credentials, [name]: value}, // La clé name dans crédentials ancien état change par value
    })
  }

  // Gestion du submit
  async function handleSubmit(event) {
    event.preventDefault()
    forgotPassword()
  }

  async function forgotPassword() {
    dispatch({
      type: 'FETCH_INIT',
    })

    try {
      const results = await AuthAPI.forgotPassword(state.credentials)

      dispatch({
        type: `FETCH_${
          results.data.errorMessage && results.data.errorMessage !== ''
            ? results.data.errorMessage
            : 'FORGOT_PASSWORD_SUCCESS'
        }`,
      })
    } catch (e) {
      console.log(e)
      dispatch({
        type: 'FETCH_ERROR',
      })
    }
  }

  return {state, dispatch, handleChange, handleSubmit}
}
