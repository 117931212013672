import PrivateRoute from '../PrivateRoute'
import Dashboard from '../../components/pages/dashboard/Dashboard'
import Documentation from '../../components/pages/documentation/Documentation'
import {Partners} from '../../components/pages/partners/Partners'
import Videos from '../../components/pages/videos/Videos'
import Achats from '../../components/pages/achats/Achats'
import Cart from '../../components/pages/cart/Cart'
import Catalogue from '../../components/pages/catalogue/Catalogue'
import Magasin from '../../components/pages/magasin/Magasin'
import {HolidaysAndExceptionalOpenings} from '../../components/pages/magasin/HolidaysAndExceptionalOpenings'
import Perimetre from '../../components/pages/magasin/Perimetre'
import Compteurs from '../../components/pages/magasin/Compteurs'
import Historique from '../../components/pages/historique/Historique'
import Virements from '../../components/pages/comptabilite/Virements'
import Cheques from '../../components/pages/comptabilite/Cheques'
import Pointages from '../../components/pages/pointages/Pointages'
import Clients from '../../components/pages/clients/Clients'
import {OrderEntry} from '../../components/pages/commande/OrderEntry'
import Transmissions from '../../components/pages/transmissions/Transmissions'
import Executions from '../../components/pages/executions/Executions'
import {CustomerAdd} from '../../components/pages/clients/CustomerAdd'
import Top10 from '../../components/pages/top10/Top10'
import Route from '../../class/Route'
import Sav from '../../components/pages/sav/Sav'
import {
  ACCESSORIES_STOCK_PATH,
  BUYINGS_PATH,
  CART_PATH,
  CATALOG_PATH,
  CHECKS_PATH,
  CLIENTS_PATH,
  COUNTERS_PATH,
  CUSTOMER_ADD_PATH,
  DASHBOARD_PATH,
  DOCUMENTATION_PATH,
  EXECUTIONS_PATH,
  LOG_PATH,
  MOVIES_PATH,
  ORDER_ENTRY_PATH,
  PARTNERS_PATH,
  PERIMETER_PATH,
  POINTINGS_PATH,
  ROOT_PATH,
  SAV_PATH,
  SHOP_PATH,
  TOP_10_PATH,
  TRANSFERT_PATH,
  TRANSMISSIONS_PATH,
  VACATIONS_PATH,
} from '../../constants/RoutesPaths'
import {ExecutionsNew} from "../../components/pages/executions/ExecutionsNew";
import {SavNew} from "../../components/pages/sav/SavNew";
import {StockAccessories} from "../../components/pages/stock/accessory/StockAccessories";

export const PRIVATES_ROUTES = new Route(ROOT_PATH, <PrivateRoute />, [
  new Route(DASHBOARD_PATH, <Dashboard />),
  new Route(DOCUMENTATION_PATH, <Documentation />),
  new Route(PARTNERS_PATH, <Partners />),
  new Route(MOVIES_PATH, <Videos />),
  new Route(BUYINGS_PATH, <Achats />),
  new Route(CART_PATH, <Cart />),
  new Route(CATALOG_PATH, <Catalogue />),
  new Route(SHOP_PATH, <Magasin />),
  new Route(VACATIONS_PATH, <HolidaysAndExceptionalOpenings />),
  new Route(PERIMETER_PATH, <Perimetre />),
  new Route(COUNTERS_PATH, <Compteurs />),
  new Route(LOG_PATH, <Historique />),
  new Route(TRANSFERT_PATH, <Virements />),
  new Route(CHECKS_PATH, <Cheques />),
  new Route(POINTINGS_PATH, <Pointages />),
  new Route(CLIENTS_PATH, <Clients />),
  new Route(SAV_PATH, <SavNew />),
  new Route(ORDER_ENTRY_PATH, <OrderEntry />),
  new Route(TRANSMISSIONS_PATH, <Transmissions />),
  new Route(EXECUTIONS_PATH, <ExecutionsNew />),
  new Route(CUSTOMER_ADD_PATH, <CustomerAdd />),
  new Route(TOP_10_PATH, <Top10 />),
  new Route(ACCESSORIES_STOCK_PATH, <StockAccessories />),
])
