import jwtDecode from "jwt-decode";

export function TokenManager() {
    return {
        getCurrentToken,
        setToken,
        isExpired,
    }
}

function getExpirationDate(token) {
    const {exp: expiration} = jwtDecode(token);

    return expiration * 1000;
}

/**
 * Return the current token
 *
 * @returns {string}
 */
function getCurrentToken() {
    return window.localStorage.getItem('authToken');
}

/**
 * @param token
 * @returns {string}
 */
function setToken(token) {
    window.localStorage.setItem('authToken', token)

    return token;
}

/**
 * Return if current token has expired
 *
 * @returns {boolean}
 */
function isExpired() {
    let expired = true;
    const token = getCurrentToken();

    if (token && getExpirationDate(token) > new Date().getTime()) {
        expired = false;
    }

    return expired;
}