import React from 'react'

import LoginFormPasswordLayout from '../../ui/layout/LoginFormPasswordLayout'
import PasswordForm from '../../ui/form/login/PasswordForm'
import {updatePassword} from '../../../hooks/usePassword'
import PasswordFormAlert from '../../ui/form/login/PasswordFormAlert'

const LoginFormEditPassword = () => {
  const {state, handleSubmit} = updatePassword()

  return (
    <LoginFormPasswordLayout
      alert={<PasswordFormAlert state={state} />}
      form={
        <PasswordForm
          onSubmit={handleSubmit}
          className="col-12 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2"
        />
      }
    />
  )
}

LoginFormEditPassword.propTypes = {}

export default LoginFormEditPassword
