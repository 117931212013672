import {useContext, useEffect, useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import AuthContext from '../../../contexts/AuthContext'
import FermeturesAPI from '../../../services/FermeturesAPI'

export function FlowerFeastHeader() {
  const {user} = useContext(AuthContext)
  const [hasHoliday, setHasHoliday] = useState(
    sessionStorage.getItem('holiday_current') === null
      ? false
      : sessionStorage.getItem('holiday_current'),
  )

  useEffect(() => {
    if (sessionStorage.getItem('holiday_current') === null) {
      FermeturesAPI.getCurrentHoliday(user.CodeFl).then((result) => {
        sessionStorage.setItem('holiday_current', result.data)
        setHasHoliday(result.data)
      })
    }
  }, [sessionStorage])

  return (
    <>
      {hasHoliday === 'true' || hasHoliday === true && (
        <>
          <Alert variant={'warning'} className={'mt-2 text-center'}>
            Attention ! Vous êtes normalement fermé ce jour-ci. Merci d'appeler
            le 0826 107 707 pour modification.
          </Alert>
        </>
      )}
    </>
  )
}
