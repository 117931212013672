import React, {useLayoutEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import {isFunction} from '../../../Tools/TypeOf'

const ModalCustom = ({
  show,
  contentClassname,
  bodyClassname,
  buttons,
  buttonsFooter,
  content,
  footer,
  header,
  headerClassname,
  headerTitle,
  headerTitleClassname,
  icon,
  id,
  modalClassname,
  onHide,
  size,
  title,
  titleClassname,
  onMount,
  backdrop = true,
  modalBodyId,
}) => {
  useLayoutEffect(() => {
    if (onMount && show) {
      onMount()
    }
  })

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size || 'lg'}
      aria-labelledby={id}
      centered
      id={id}
      contentClassName={contentClassname}
      className={modalClassname || 'text-center'}
      backdrop={backdrop}>
      {header && (
        <Modal.Header closeButton className={headerClassname || ''}>
          <Modal.Title className={headerTitleClassname || ''}>
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
      )}
      {!header && backdrop !== 'static' && (
        <div className="modal-no-header">
          <button
            type="button"
            onClick={onHide}
            className="close text-right px-3 py-2">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      )}
      <Modal.Body
        className={bodyClassname || 'px-5 pt-0 pb-4'}
        id={modalBodyId}>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            color="var(--primary)"
            className="mb-1"
            size="2x"
          />
        )}
        {title && <h2 className={titleClassname || ''}>{title}</h2>}
        {content}

        {buttons &&
          buttons.map((item, index) => (
            <Button
              key={index}
              type={item.type}
              disabled={item.disabled}
              onClick={
                isFunction(item.onClick)
                  ? () => {
                      item.onClick(item.onClickParams)
                    }
                  : isFunction(item.func)
                  ? () => item.func()
                  : onHide
              }
              className={`${item.classNames ? ` ${item.classNames}` : ''}`}>
              {item.text}
            </Button>
          ))}
      </Modal.Body>
      {footer && (
        <Modal.Footer className="justify-content-center">
          {buttonsFooter &&
            buttonsFooter.map((item, index) => (
              <Button
                key={index}
                type={item.type}
                variant={item.variant}
                disabled={item.disabled}
                form={item.formId}
                onClick={
                  isFunction(item.onClick)
                    ? () => item.onClick(item.onClickParams)
                    : isFunction(item.func)
                    ? () => item.func()
                    : onHide
                }
                className={`${item.classNames ? ` ${item.classNames}` : ''}`}>
                {item.text}
              </Button>
            ))}
        </Modal.Footer>
      )}
    </Modal>
  )
}

ModalCustom.propTypes = {
  bodyClassname: PropTypes.string,
  contentClassname: PropTypes.string,
  buttons: PropTypes.array,
  buttonsFooter: PropTypes.array,
  content: PropTypes.object,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  headerClassname: PropTypes.string,
  headerTitle: PropTypes.string,
  headerTitleClassname: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  modalClassname: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
  titleClassname: PropTypes.string,
  onMount: PropTypes.func,
  modalBodyId: PropTypes.string,
  backdrop: PropTypes.string,
}

export default ModalCustom
