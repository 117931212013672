import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Numbers from "../../../helpers/Numbers";
import {CatalogueItemModal} from "./CatalogueItemModal";
import CardCustom from "../../ui/card/CardCustom";

export const CatalogueItem = ({product, index, show}) => {
    const [modalShow, setModalShow] = useState(false);

    const handleDetails = () => {
        setModalShow(true);
    }

    const body = (product) => {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <strong>Référence :</strong> {product.reference}
                        <br/>
                        <strong>Libellé :</strong> {product.libcompo}
                        <br/>
                        <strong>Catégorie :</strong> {product.categorie[0] && product.categorie[0].categDesc}
                        <br/>
                        <strong>TVA :</strong> {product.tauxtva}%
                        <br/>
                        <strong>Catalogue :</strong> {product.nomcat}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'d-flex justify-content-center mt-1'}>
                        <Button
                            aria-modal={true}
                            className="btn-rounded"
                            onClick={handleDetails}>
                            Détails
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    const footer = (product) => {
        return (
            <>
                <Row>
                    <Col
                        xs={12}
                        className="prices d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center justify-content-center flex-wrap px-1">
                            <div>Passionnément :</div>
                            <div className="price ml-xs-0 ml-sm-1 ml-md-0 ml-hd-1">
                                {product.montanthaut > 0 &&
                                    Numbers.ConvertToEuro(Number(product.montanthaut))}
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center flex-wrap px-1">
                            <div>Énormément :</div>
                            <div className="price ml-xs-0 ml-sm-1 ml-md-0 ml-hd-1">
                                {product.montantjuste > 0 &&
                                    Numbers.ConvertToEuro(Number(product.montantjuste))}
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-center flex-wrap px-1">
                            <div>Beaucoup :</div>
                            <div className="price ml-xs-0 ml-sm-1 ml-md-0 ml-hd-1">
                                {product.montantbas > 0 &&
                                    Numbers.ConvertToEuro(Number(product.montantbas))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    return (
      <>
          <Col key={index} className={`mb-2 ${!show && 'hidden'}`}>
              <CardCustom
                  key={index}
                  cardClassName={`rounded catalogue-item h-100`}
                  headerClassName={'w-50'}
                  header={
                      <Image
                          onError={(e) => {
                              e.target.src =
                                  'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                          }}
                          src={`https://cdn.florajet.com/produits/300/${product.idcompo}.jpg`}
                          onClick={handleDetails}
                          alt={product.libcompo}
                          className="rounded"
                          fluid={true}
                      />
                  }
                  body={body(product)}
                  bodyClassName={"d-flex flex-column justify-content-between"}
                  footer={footer(product)}
              />
          </Col>
          <CatalogueItemModal product={product} show={modalShow} onHide={() => setModalShow(false)} />
      </>
    );
}

CatalogueItem.propTypes = {
    product: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    show: PropTypes.bool,
}
