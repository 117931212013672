import {createSlice} from '@reduxjs/toolkit'
import {
  SIDE_MENU_AIDE,
  SIDE_MENU_BOUTIQUE,
  SIDE_MENU_MES_COMMANDES,
  SIDE_MENU_MES_OUTILS,
  SIDE_MENU_MON_COMPTE,
  SIDE_MENU_MON_FLORAJET,
  SIDE_MENU_PARAMETRES,
  SIDE_MENU_SOFTWARE,
} from '../constants/SideMenuConstants'

const initialState = {
  activeMenu: false,
  modalShow: false,
  accordionDefaultKey: SIDE_MENU_MON_FLORAJET.eventKey,
  accordionActiveKey: SIDE_MENU_MON_FLORAJET.eventKey,
  subAccordionActiveKey: SIDE_MENU_MES_COMMANDES.eventKey,
}

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    configureSideMenuActiveKey: (state, {payload}) => {
      state.accordionActiveKey = payload
    },
    configureSideSubMenuActiveKey: (state, {payload}) => {
      state.subAccordionActiveKey = payload
    },
  },
})

export const {
  configureSideMenuActiveKey,
  configureSideSubMenuActiveKey,
} = sideMenuSlice.actions

export const selectSideMenu = (state) => state.sideMenu

export default sideMenuSlice.reducer
