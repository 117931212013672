import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'

import Box from '../../ui/box/Box'
import {CartContext} from '../../../contexts/CartContext'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import Numbers from '../../../helpers/Numbers'
import Text from '../../../helpers/Text'

const CartItem = ({article}) => {
  const [quantity, setQuantity] = useState(article.cartQuantity)
  const [modalShowRemove, setModalShowRemove] = useState(false)
  const [isLoadingModalRemove, setIsLoadingModalRemove] = useState()

  const {addToCart, removeFromCart} = useContext(CartContext)

  // Gestion de l'ajout au panier si changement de quantités
  useEffect(() => {
    addToCart(
      article,
      quantity,
      article.cartSize,
      article.cartSizeId,
      article.cartPriceUnit,
    )
  }, [quantity])

  // Gestion du retrait de quantités
  const removeQuantity = () => {
    setQuantity(quantity - 1)
  }

  // Gestion de l'ajout de quantités
  const addQuantity = () => {
    setQuantity(quantity + 1)
  }

  // Gestion de la suppression d'un article
  const handleRemoveFromCart = (article) => {
    removeFromCart(article)
    setModalShowRemove(false)
  }

  return (
    <>
      <Box
        boxXs={12}
        boxName="cart-item"
        boxContentClassNames="d-flex flex-row flex-wrap align-items-xl-center"
        boxContentPadding="p-3"
        boxContentRounded="xl"
        content={
          <>
            <Col xs={4} className="col-xxl-2 p-0">
              <div
                style={{
                  height: '150px',
                  background: `center / cover no-repeat url(https://www.monflorajet.com/ftp-extranet/OrderShop/Product/${article.idSarticles}/01.jpg)`,
                }}
                alt="Mon Florajet"
                className="rounded-xl unselectable mr-3"></div>
            </Col>

            <Col xs={8} sm={8} className="col-xxl-4 p-0">
              <div className="d-flex flex-row flex-wrap">
                <h3 className="mb-2">
                  {Text.CapitalizeAll(article.articlesExtranetLibelle)}
                </h3>

                <div className="description fs-14">
                  {Text.CapitalizeAll(article.articlesExtranetDescription)}
                </div>

                {article.articleTypes.length > 1 && (
                  <div className="text-primary ff-bold mt-2">
                    {article.cartSize}
                  </div>
                )}
              </div>
            </Col>

            <Col
              xs={12}
              sm={12}
              className="col-xxl-6 d-flex flex-row flex-wrap align-items-center justify-content-between w-100 p-0">
              <div className="d-flex flex-column justify-content-center mt-3 mt-xxl-0 mx-2">
                <h5 className="text-center mb-0">Prix unitaire</h5>
                <p className="price ff-bold text-primary text-center fs-20 mb-0">
                  {!article.cartPriceUnit
                    ? 'NC'
                    : article.cartPriceUnit === '0'
                    ? 'GRATUIT'
                    : Numbers.ConvertToEuro(Number(article.cartPriceUnit))}
                </p>
              </div>

              <div className="d-flex flex-column justify-content-center mt-3 mt-xxl-0 mx-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <Button
                    className="btn-trans p-0"
                    onClick={removeQuantity}
                    disabled={quantity < 2 && true}>
                    <FontAwesomeIcon
                      size="lg"
                      icon="minus-circle"
                      color="var(--black-light)"
                    />
                  </Button>
                  <div className="quantity mx-3 unselectable">{quantity}</div>
                  <Button
                    className="btn-trans p-0"
                    onClick={addQuantity}
                    disabled={
                      quantity > article.articlesExtranetMaxi - 1 && true
                    }>
                    <FontAwesomeIcon
                      size="lg"
                      icon="plus-circle"
                      color="var(--black-light)"
                    />
                  </Button>
                </div>

                <p className="d-flex justify-content-center quantity-max fs-14 mt-2 mb-0 unselectable">
                  Max : {article.articlesExtranetMaxi}
                </p>
              </div>

              <div className="d-flex flex-column justify-content-center mt-3 mt-xxl-0 mx-2">
                <h5 className="text-center mb-0">Prix total</h5>
                <p className="price ff-bold text-center text-primary fs-20 mb-0">
                  {Numbers.ConvertToEuro(Number(article.cartPriceTotal))}
                </p>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-center mt-3 mt-xxl-0 mx-2">
                <Button
                  className="btn-trans btn-remove text-grey-darker fs-14 p-0"
                  onClick={() => setModalShowRemove(true)}>
                  <FontAwesomeIcon
                    icon="trash-alt"
                    color="grey-darker"
                    className="mr-2"
                  />
                  <span>Supprimer</span>
                </Button>
              </div>
            </Col>
          </>
        }
      />

      <ModalCustom
        show={modalShowRemove}
        onHide={() => setModalShowRemove(false)}
        id="modal-remove"
        bodyClassname="p-5"
        content={
          <>
            <h3>Etes-vous sûr de vouloir supprimer cet article ?</h3>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: isLoadingModalRemove ? (
              <Loader variant="white" size="sm" classname="my-1 mx-2" />
            ) : (
              'Confirmer'
            ),
            func: () => handleRemoveFromCart(article),
            classNames: 'mx-2',
          },
          {
            text: 'Annuler',
            classNames: 'mx-2',
          },
        ]}
      />
    </>
  )
}

CartItem.propTypes = {
  article: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default CartItem
