import {Link} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import Box from "../../ui/box/Box";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import {ExecutionsTab} from "../executions/ExecutionsTab";

export const DashboardExecutions = () => {
    const executionsCount = useStoreExecutions(state => state.executionsCount);
    const type = useStoreExecutions(state => state.type);
    const setType = useStoreExecutions(state => state.setType);

    useEffect(() => {
        if (type !== 'pending') {
            setType('pending'); // Permet d'afficher les commandes en cours sur le dashboard
        }
    }, []);

    return (
        <>
            <Box
                boxName="executions dashboard-executions"
                boxClassNames={`${executionsCount > 3 ? 'scroll' : ''} flex-column mb-4`}
                header={true}
                headerClassNames="rounded-top"
                headerContent={
                    <>
                        <div className="d-flex flex-row flex-wrap align-items-center justify-content-sm-between">
                            <h5 className="mr-3 my-auto">Mes dernières exécutions</h5>
                            <Link
                                to="/executions"
                                role="button"
                                className="btn d-flex align-items-center btn-sm ff-bold">
                                Voir tout
                            </Link>
                        </div>
                    </>
                }
                boxContentClassNames="rounded-bottom"
                content={
                    <>
                        <ExecutionsTab />
                    </>
                }
            />
        </>
    );
}