import {getIn, useFormik} from 'formik'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {object, string} from 'yup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {useLocation} from 'react-router-dom/dist'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Field from '../../ui/form/Field'
import Title from '../../ui/page/Title'
import ClientsAPI from '../../../services/ClientsAPI'
import Box from '../../ui/box/Box'
import AuthContext from '../../../contexts/AuthContext'
import useFetch from '../../../hooks/useFetch'
import AlertCustom from '../../ui/alert/AlertCustom'

export function CustomerAdd() {
  const {user} = useContext(AuthContext)
  const [customer, setCustomer] = useState()
  const [isLoadingZipcode, setIsLoadingZipcode] = useState(false)
  const [selectedZipcode, setSelectedZipcode] = useState([])
  const [optionsZipcode, setOptionsZipcode] = useState([])
  const [isLoadingCity, setIsLoadingCity] = useState(false)
  const [selectedCity, setSelectedCity] = useState([])
  const [optionsCity, setOptionsCity] = useState([])
  const [isLoadingCountry, setIsLoadingCountry] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState([
    {
      countryLabel: 'FRANCE',
      countryCode: 'F',
    },
  ])
  const [optionsCountry, setOptionsCountry] = useState([])
  const isForeignCountry = useMemo(
    () =>
      selectedCountry[0] &&
      (selectedCountry[0].countryLabel !== 'FRANCE' ||
        selectedCountry[0].countryCode !== 'F'),
    [setSelectedCountry],
  )
  const [enableValidation, setEnableValidation] = useState(false)
  const [{data: customers}] = useFetch(ClientsAPI.getClients(user.CodeFl))
  const [isCutomerExists, setIsCutomerExists] = useState(false)
  const history = useLocation()
  const [showAlertCatchError, setShowAlertCatchError] = useState(false)
  const [
    showAlertErrorCustomerExists,
    setShowAlertErrorCustomerExists,
  ] = useState(false)

  async function handleSearchZipcode(query) {
    setIsLoadingZipcode(true)

    if (isForeignCountry) {
      const options = [
        {
          zipcode: query,
          city: selectedCity[0] && selectedCity[0].city,
        },
      ]
      setOptionsZipcode(options)
    } else {
      await ClientsAPI.getCodePostal(query).then((result) => {
        const options = result.data.map((option) => ({
          zipcode: option.codpostal,
          city: option.localite,
        }))

        setOptionsZipcode(options)
      })
    }

    setIsLoadingZipcode(false)
  }

  async function handleSearchCity(query) {
    setIsLoadingCity(true)

    if (isForeignCountry) {
      const options = [
        {
          zipcode: selectedZipcode[0] && selectedZipcode[0].zipcode,
          city: query,
        },
      ]
    } else {
      await ClientsAPI.getLocalite(query).then((result) => {
        const options = result.data.map((option) => ({
          zipcode: option.codpostal,
          city: option.localite,
        }))

        setOptionsCity(options)
      })
    }

    setIsLoadingCity(false)
  }

  async function handleSearchCountry(query) {
    setIsLoadingCountry(true)

    await ClientsAPI.getPays(query).then((result) => {
      const options = result.data.map((option) => ({
        countryLabel: option.libelle,
        countryCode: option.codiso,
      }))

      setOptionsCountry(options)
    })

    setIsLoadingCountry(false)
  }

  const validationSchema = object().shape({
    lastname: string()
      .min(2, 'Ce champ doit contenir au minimum 2 caractères')
      .required('Ce champ est requis'),
    firstname: string()
      .min(2, 'Ce champ doit contenir au minimum 2 caractères')
      .required('Ce champ est requis'),
    phone: string()
      .matches(
        /^(0|\+\d{0,2}|00\d{0,2})?([0-9][\s.-]?){5,9}$/,
        'Ce champ doit contenir un téléphone valide.',
      )
      .required('Ce champ est requis.'),
  })

  const formik = useFormik({
    initialValues: {
      companyname: (customer && customer.companyname) || '',
      lastname: (customer && customer.lastname) || '',
      firstname: (customer && customer.firstname) || '',
      address1: (customer && customer.address1) || '',
      address2: (customer && customer.address2) || '',
      zipcode: (customer && customer.zipcode) || '',
      city: (customer && customer.city) || '',
      countryLabel: (customer && customer.countryLabel) || 'FRANCE',
      phone: (customer && customer.phone) || '',
      mobilephone: (customer && customer.mobilephone) || '',
      email: (customer && customer.email) || '',
    },
    validationSchema,
    validateOnChange: enableValidation,
    onSubmit: (values, {setSubmitting}) => {
      const newClient = {
        idclient: null,
        raisonsoc: values.companyname,
        nom: values.lastname,
        prenom: values.firstname,
        adresse1: values.address1,
        adresse2: values.address2,
        codep: values.zipcode,
        ville: values.city,
        pays: values.countryLabel,
        tel: values.phone,
        tel2: values.mobilephone,
        email: values.email,
      }
      addCustomer(newClient)
    },
  })

  async function addCustomer(values) {
    await ClientsAPI.postClient({
      ...values,
      codfl: user.CodeFl,
    })
      .then((result) => {
        if (result.data) {
          history.push({
            pathname: '/clients',
            state: {
              create_success: true,
            },
          })
        }

        setShowAlertErrorCustomerExists(true)
      })
      .catch((error) => {
        setShowAlertCatchError(true)
      })
  }

  useEffect(() => {
    setIsCutomerExists(false)

    customers.every(function (customer) {
      // Every permet de faire comme un break;. La boucle contine tant que return true;
      if (
        customer.nom.trim().toLowerCase() ===
          formik.values.lastname.trim().toLowerCase() &&
        customer.prenom.trim().toLowerCase() ===
          formik.values.firstname.trim().toLowerCase()
      ) {
        setIsCutomerExists(true)
        return false
      }

      return true
    })
  }, [formik.values.lastname, formik.values.firstname])

  return (
    <>
      <Title title={'Ajouter un nouveau client'} />

      {showAlertCatchError && (
        <AlertCustom
          variant={'danger'}
          heading={false}
          bodyContent={'Une erreur est survenue lors de la création du client.'}
          bodyIcon={true}
          bodyIconName={'exclamation-triangle'}
          transition={true}
        />
      )}

      {showAlertErrorCustomerExists && (
        <AlertCustom
          variant={'danger'}
          heading={false}
          bodyContent={'Le client existe déjà.'}
          bodyIcon={true}
          bodyIconName={'exclamation-triangle'}
          transition={true}
        />
      )}

      <Box
        boxXs={12}
        boxSm={6}
        boxName={'customer-add'}
        header={false}
        boxContentPadding={'p-3'}
        content={
          <>
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                setEnableValidation(true)
                formik.handleSubmit()
              }}>
              <Row>
                <Col xs={12}>
                  <Field
                    name={'companyname'}
                    label={'Raison sociale'}
                    value={formik.values.companyname}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    name={'lastname'}
                    labelRequired={true}
                    label={'Nom'}
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={getIn(formik.errors, 'lastname')}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    name={'firstname'}
                    labelRequired={true}
                    label={'Prénom'}
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    error={getIn(formik.errors, 'firstname')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    name={'address1'}
                    label={'Adresse'}
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Field
                    name={'address2'}
                    label={'Adresse (suite)'}
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group controlId={'zipcode'}>
                    <Form.Label>Code postal</Form.Label>
                    <AsyncTypeahead
                      id={'zipcode'}
                      name={'zipcode'}
                      minLength={2}
                      maxResults={10}
                      filterBy={() => true}
                      promptText={'Entrez votre recherche'}
                      searchText={'Chargement...'}
                      emptyLabel={'Aucun résultat'}
                      paginationText={'Plus de résultats'}
                      placeholder={'Code postal'}
                      labelKey={'zipcode'}
                      isLoading={isLoadingZipcode}
                      selected={selectedZipcode}
                      options={optionsZipcode}
                      onSearch={handleSearchZipcode}
                      renderMenuItemChildren={(option) => (
                        <>
                          <span>{option.zipcode}</span>
                          <span>
                            {' '}
                            {option.city !== '' && `(${option.city})`}
                          </span>
                        </>
                      )}
                      onChange={(value) => {
                        setSelectedZipcode(value)

                        if (!isForeignCountry) {
                          setSelectedCity(value)
                        }

                        if (value.length > 0) {
                          formik.setFieldValue('zipcode', value[0].codep)
                          formik.setFieldValue('city', value[0].ville)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group controlId={'city'}>
                    <Form.Label>Ville</Form.Label>
                    <AsyncTypeahead
                      id={'city'}
                      name={'city'}
                      minLength={2}
                      maxResults={10}
                      filterBy={() => true}
                      promptText={'Entrez votre recherche'}
                      searchText={'Chargement...'}
                      emptyLabel={'Aucun résultat'}
                      paginationText={'Plus de résultats'}
                      placeholder={'Ville'}
                      labelKey={'city'}
                      isLoading={isLoadingCity}
                      selected={selectedCity}
                      options={optionsCity}
                      onSearch={handleSearchCity}
                      renderMenuItemChildren={(option) => (
                        <>
                          <span>{option.city}</span>
                          <span>
                            {' '}
                            {option.zipcode !== '' && `(${option.zipcode})`}
                          </span>
                        </>
                      )}
                      onChange={(value) => {
                        setSelectedCity(value)

                        if (!isForeignCountry) {
                          setSelectedZipcode(value)
                        }

                        if (value.length > 0) {
                          formik.setFieldValue('zipcode', value[0].codep)
                          formik.setFieldValue('city', value[0].ville)
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId={'countryLabel'}>
                    <Form.Label>Pays</Form.Label>
                    <AsyncTypeahead
                      id={'countryLabel'}
                      name={'countryLabel'}
                      minLength={2}
                      maxResults={10}
                      filterBy={() => true}
                      promptText={'Entrez votre recherche'}
                      searchText={'Chargement...'}
                      emptyLabel={'Aucun résultat'}
                      paginationText={'Plus de résultats'}
                      placeholder={'Pays'}
                      labelKey={'countryLabel'}
                      isLoading={isLoadingCountry}
                      selected={selectedCountry}
                      options={optionsCountry}
                      onSearch={handleSearchCountry}
                      renderMenuItemChildren={(option) => (
                        <>
                          <span>{option.countryLabel}</span>
                          <span>
                            {' '}
                            {option.countryCode !== '' &&
                              `(${option.countryCode})`}
                          </span>
                        </>
                      )}
                      onChange={(value) => {
                        setSelectedCountry(value)
                        setSelectedZipcode('')
                        setSelectedCity('')
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    name={'phone'}
                    labelRequired={true}
                    label={'Téléphone'}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={getIn(formik.errors, 'phone')}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    name={'mobilephone'}
                    label={'Téléphone mobile'}
                    value={formik.values.mobilephone}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name={'email'}
                    label={'Email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                {isCutomerExists && (
                  <Col xs={12} sm={10} className={'d-flex justify-content-end'}>
                    <Alert
                      show={isCutomerExists}
                      variant={'danger'}
                      className={'my-auto'}>
                      Le client existe déjà.
                    </Alert>
                  </Col>
                )}

                <Col
                  xs={12}
                  sm={isCutomerExists ? 2 : 12}
                  className={'d-flex justify-content-end'}>
                  <Button
                    type={'submit'}
                    className={'btn btn-primary'}
                    disabled={isCutomerExists}>
                    Enregister
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        }
      />
    </>
  )
}
