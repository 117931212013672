export function PasswordFormReducer(state, action) {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isError: false,
                isLoading: true,
                errorMessage: false,
                errorField: false,
            }
        case 'FETCH_FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                isError: false,
                isLoading: false,
                isValid: true,
                message: 'Vous allez recevoir un mail afin de réinitialiser votre mot de passe.'
            }
        case 'FETCH_ERROR_PASSWORDS_NOT_EQUAL':
            return {
                ...state,
                isError: true,
                isLoading: false,
                isValid: false,
                message: 'Les champs ne sont pas identiques',
                errorField: {
                    newPassword: 'Les champs ne sont pas identiques',
                    confirmPassword: 'Les champs ne sont pas identiques'
                }
            }
        case 'FETCH_PASSWORDS_EQUAL':
            return {
                ...state,
                isError: false,
                isLoading: false,
                isValid: false,
                message: 'Les champs sont identiques',
                errorField: {
                    newPassword: 'Les champs sont identiques',
                    confirmPassword: 'Les champs sont identiques'
                }
            }
        case 'HANDLE_CHANGE':
            return {
                ...state,
                credentials: action.credentials,
            }
        case 'FETCH_ERROR_FLORIST_CODE_NOT_FOUND':
            return {
                ...state,
                isError: true,
                isLoading: false,
                errorMessage: '',
                errorField: {
                    floristCode: 'L\'identifiant saisit n\'existe pas'
                }
            }
        case 'FETCH_ERROR':
            return {
                ...state,
                isError: true,
                isLoading: false,
                message: null,
                errorMessage: 'Échec de la modification du mot de passe',
                errorField: {
                    username: ' ',
                    password: ' ',
                }
            }
        case 'HANDLE_FOCUS':
            return {
                ...state,
                isError: false,
                errorField: false,
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isError: false,
                isLoading: false,
                adminModalShow: false,
                userModalShow: false,
                message: 'Votre mot de passe a été modifié',
            }
        case 'FETCH_ERROR_FLORIST_RESET_PASSWORD_EXIST':
            return {
                ...state,
                isError: true,
                isLoading: false,
                isValid: true,
                errorMessage: 'Une demande de réinitialisation de mot de passe est déjà en cours pour ce compte'
            }
        case 'FETCH_MODAL':
            return {
                ...state,
                adminModalShow: action.adminModalShow,
                userModalShow: action.userModalShow,
            }
        case 'FETCH_ALERT_TRANSITION':
            return {
                ...state,
                alertTransition: action.alertTransition,
            }
        default:
            throw new Error();
    }
}
