import {isObject} from './TypeOf'

/**
 * Retourne la base64 d'un objet ou d'une string.
 * Si {variable} est un objet, celui-ci stringifier en json.
 *
 * @param {string|object} variable
 *
 * @returns {string}
 */
export function base64(variable) {
  return btoa(JSON.stringify(variable))
}
