import React from 'react'
import PropTypes from 'prop-types'
import Field from "../Field";

export const CodeflField = ({classNames, value, onChange, onFocus, error, fieldClassNames, readonly = false}) => {
  return (
    <>
      <Field
        label="Identifiant"
        placeholder="Identifiant"
        labelRequired={true}
        name="codefl"
        classNames={classNames}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        error={error}
        fieldClassNames={fieldClassNames}
        readonly={readonly}
      />
    </>
  )
}

export const PwdField = ({label, placeholder, name, isRequired, value, classNames, onChange, onFocus, error}) => {
  return (
    <>
      <Field
        label={label}
        placeholder={placeholder}
        labelRequired={isRequired}
        name={name}
        classNames={classNames}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        error={error}
        type="password"
      />
    </>
  )
}

CodeflField.propTypes = {
  classNames: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.any,
  fieldClassNames: PropTypes.string,
  readonly: PropTypes.bool,
}

PwdField.propTypes = {
  label:PropTypes.string.isRequired,
  placeholder:PropTypes.string.isRequired,
  isRequired:PropTypes.bool.isRequired,
  name:PropTypes.string.isRequired,
  classNames: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.any,
  fieldClassNames: PropTypes.string,
}
