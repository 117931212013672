import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import AuthContext from "../../../contexts/AuthContext";
import TableCustom from "../../ui/table/TableCustom";
import ModalCustom from "../../ui/modal/ModalCustom";
import {useActionsButtons} from "../../../hooks/executions/useActionsButtons";
import ShopperModals from "../../ui/modal/ShopperModals";
import Box from "../../ui/box/Box";
import Loader from "../../ui/loader/Loader";

export const ExecutionsTab = () => {
    const {user} = useContext(AuthContext);
    const storeExecutions = useStoreExecutions();
    const getExecutions = useStoreExecutions((state) => state.getExecutions);
    const executions = useStoreExecutions(state => state.executions);
    const isLoading = useStoreExecutions(state => state.isLoading);
    const isError = useStoreExecutions(state => state.isError);
    const sortType = useStoreExecutions(state => state.sortType);
    const sortOrder = useStoreExecutions(state => state.sortOrder);
    const type = useStoreExecutions(state => state.type);
    const setType = useStoreExecutions(state => state.setType);

    const {
        actionButtons,
        handleShowOrder,
        showModalInformation,
        modalSize,
        modalTitle,
        modalContent,
        buttons,
        setShowModalInformation,
        handleCancellationModal
    } = useActionsButtons();

    // Permet de charger une première fois les données avec Zustand
    useEffect(() => {
        getExecutions(user);
    }, [getExecutions]);

    const handleSortOrder = (newSortType) => {
        let sort = sortOrder === 'asc' ? 'desc' : 'asc';

        if (newSortType !== sortType) {
            sort = 'asc';
        }
        storeExecutions.sortOrder = sort;
        storeExecutions.sortType = newSortType;
        getExecutions(user, true);
    }

    return (
        <>
            <TableCustom
                id={'executionsList'}
                isLoading={isLoading}
                isError={isError}
                headers={[
                    'Etat',
                    'Numéro',
                    'Date et moment de livraison',
                    'Code postal ville',
                    'Accessoire',
                    'Montant',
                    'Actions',
                ]}
                sorting={[
                    'etatextranet',
                    'numcde',
                    'datliv',
                    'localite',
                    'occasion',
                    'montantcdee',
                ]}
                handleSortOrder={handleSortOrder}
                data={executions}
                sortType={sortType}
                sortOrder={sortOrder}
                configLine={
                    type === 'pending' && {
                        key: 'etatextranet',
                        filter: 40,
                        color: 'blue-light',
                        text_class: 'font-weight-bold',
                    }
                }
                config={[
                    {
                        type: 'picto',
                        key: 'etatextranet',
                    },
                    {
                        type: 'multilines',
                        key: [
                            {
                                type: 'link_modal_order',
                                key: 'numcde',
                                func: handleShowOrder,
                                funcParam: 'numcde',
                            },
                            {type: 'text', key: 'nomdes'},
                        ],
                    },
                    {
                        type: 'multilines',
                        key: [
                            {type: 'date', key: 'datliv'},
                            {type: 'text', key: 'momentjourvalue'},
                        ],
                    },
                    {
                        type: 'multilines',
                        key: [
                            {type: 'text', key: 'codpostal'},
                            {type: 'text', key: 'localite'},
                        ],
                    },
                    {
                        type: 'list',
                        key: 'accessories',
                        labelField: 'accessory_label',
                        quantityField: 'accessory_number',
                        compactList: true,
                        withQuantity: true,
                    },
                    {type: 'currency', key: 'amountvat'},
                    {
                        type: 'buttons',
                        key: actionButtons,
                    },
                ]}
            />

            {isLoading && <Loader />}

            <ModalCustom
                show={showModalInformation}
                id={'executions-modal'}
                onHide={() => setShowModalInformation(false)}
                size={modalSize}
                title={modalTitle}
                titleClassname={!modalTitle && 'text-center mb-3'}
                header={false}
                bodyClassname={'text-left'}
                content={
                    <>
                        {modalContent}
                    </>
                }
                footer={true}
                buttonsFooter={[
                    ...buttons,
                    ...[
                        {
                            text: 'Fermer',
                        },
                    ]
                ]}
            />

            <ShopperModals
                handleCancellationModal={handleCancellationModal}
                executions={executions}
            />
        </>
    );
}
