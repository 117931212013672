import {
  CATEGORIES_DOCUMENTS_API,
  DOCUMENTS_LIST_API,
  ITEMS_PER_PAGE,
} from '../constants/Constants'

function getDocuments(filter, sortType, sortOrder, currentPage) {
  return {
    name: 'documents',
    method: 'get',
    url: `${DOCUMENTS_LIST_API}&idCategorie=${filter}&order%5B${sortType}%5D=${
      sortOrder || 'desc'
    }&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getCategoriesDocuments() {
  return {
    name: 'catégories',
    method: 'get',
    url: CATEGORIES_DOCUMENTS_API,
  }
}

export default {
  getDocuments,
  getCategoriesDocuments,
}
