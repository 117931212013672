import React, {useState} from 'react'

import Box from '../../ui/box/Box'
import CompteursAPI from '../../../services/CompteursAPI'
import CompteursTabs from './CompteursTabs'
import MagasinMenu from './MagasinMenu'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'

const Compteurs = () => {
  const [{data: years, isLoading, isError}] = useFetch(
    CompteursAPI.getYearsCompteurs(),
  );
  const [currentTab, setCurrentTab] = useState('compteurs');

  return (
    <>
      <Title title="Mon magasin" />

      <Box
        boxName="magasin"
        boxClassNames="flex-column counters"
        boxContentBg="white"
        header={true}
        headerClassNames="rounded-top"
        headerContent={
          <>
            <MagasinMenu currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </>
        }
        content={
          <CompteursTabs
            years={years}
            isLoading={isLoading}
            isError={isError}
          />
        }
      />
    </>
  )
}

export default Compteurs
