import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useContext, useRef} from 'react'

import AuthAPI from '../../../services/AuthAPI'
import AuthContext from '../../../contexts/AuthContext'
import Text from '../../../helpers/Text'

const NavbarTop = ({toggleMenu}) => {
  const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
  const {user, setUser} = useContext(AuthContext)
  const userRef = useRef(useContext(AuthContext))

  // Gestion de la déconnexion
  const handleLogout = () => {
    AuthAPI.logout()
    setIsAuthenticated(false)
  }

  return (
    <>
      <div className="d-block d-lg-none mb-3">
        <Navbar
          bg="primary"
          variant="dark"
          expand="lg"
          sticky="top"
          className="navbar-fixed-top flex-wrap"
          collapseOnSelect>

          <div className="d-flex flex-row mb-md-4">
            <Button className="d-lg-none mr-1 btn-white" onClick={toggleMenu}>
              <FontAwesomeIcon icon={"bars"} />
            </Button>
          </div>
          <Navbar.Text className="d-flex flex-row align-items-center p-0 mb-0">
            <span className="d-flex flex-row flex-wrap align-items-center ff-bold mr-2">
              <FontAwesomeIcon
                icon={
                  user.roles.find((item) => item === 'ROLE_ADMIN')
                    ? ['fas', 'key']
                    : ['fas', 'user']
                }
                color="white"
                className="mr-1"
              />
              {isAuthenticated &&
                user &&
                `${Text.CapitalizeAll(user.Prenom)} ${Text.CapitalizeAll(
                  user.Nom,
                )}`}
              <span>
                {isAuthenticated &&
                  user &&
                  ` - ${user.CodeFl} (${user.Contrat})`}
              </span>
            </span>

            <FontAwesomeIcon
              icon={['fas', 'power-off']}
              size="lg"
              color="white"
              onClick={handleLogout}
            />
          </Navbar.Text>
        </Navbar>
      </div>
    </>
  )
}
NavbarTop.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
}

export default NavbarTop
