/**
 * Renvoie la valeur de conf.key si définie ou defaultKey sinon.
 * Si le deux indexes ne sont pas definis renvoie undefined
 *
 * @param conf
 * @param item
 *
 * @returns {*}
 */
const retrieveValue = (conf, item) => {
  const fromItem =
    item[
      (conf.key && !['', null].includes(item[conf.key]) && conf.key) ||
        conf.defaultKey
    ]

  return ![undefined, null].includes(fromItem)
    ? fromItem
    : conf.type && conf[conf.type]
}

export {retrieveValue}
