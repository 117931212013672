import React, {useState, useEffect, useRef} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import LoginFooter from './LoginFooter'
import LoginForm from './LoginForm'
import LoginPresentation from './LoginPresentation'
import LoginFormForgotPassword from './LoginFormForgotPassword'
import LoginPresentationFooter from './LoginPresentationFooter'

const LoginTabs = () => {
  const [selectedTab, setSelectedTab] = useState('login')
  const isUnmounted = useRef(false)

  const handleSelectedTab = (tab) => {
    if (!isUnmounted.current) {
      setSelectedTab(tab)
    }
  }

  return (
    <div className="bg-primary">
      <Tabs
        activeKey={selectedTab}
        onSelect={(k) => handleSelectedTab(k)}
        id="login-tabs"
        className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 col-hd-6 offset-hd-3">
        <Tab
          eventKey="login"
          title={
            <span className="d-flex align-items-center">
              <FontAwesomeIcon icon="key" color="white" className="mr-1" />
              S'identifier
            </span>
          }>
          <LoginForm handleSelectedTab={handleSelectedTab} />
        </Tab>

        <Tab
          eventKey="presentation"
          title={
            <span className="text-decoration-none d-flex align-items-center">
              <FontAwesomeIcon
                icon={['fab', 'youtube']}
                color="white"
                className="mr-1"
              />
              Présentation
            </span>
          }>
          <LoginPresentation />
          <LoginPresentationFooter />
        </Tab>

        <Tab eventKey="password">
          <LoginFormForgotPassword />
        </Tab>
      </Tabs>
    </div>
  )
}

export default LoginTabs
