import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import {CSSTransition} from 'react-transition-group'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const AlertCustom = ({
  bodyContent,
  bodyContentHtml,
  bodyContentBold,
  bodyContentItalic,
  bodyIcon,
  bodyIconName,
  bodyIconType,
  classname,
  dismissible,
  heading,
  headingIconName,
  headingIconType,
  headingTitle,
  onClose,
  transition,
  variant,
  col = 12,
}) => {
  return (
    <>
      <CSSTransition
        in={transition}
        timeout={500}
        classNames="alert"
        unmountOnExit>
        <Col xs={col} className="d-flex justify-content-center p-0">
          <Alert
            transition={false}
            onClose={transition && onClose}
            dismissible={transition && (dismissible || false)}
            variant={variant}
            className={classname || 'm-3'}>
            {heading && (
              <Alert.Heading>
                <FontAwesomeIcon
                  icon={[
                    headingIconType || 'fas',
                    headingIconName || 'exclamation-triangle',
                  ]}
                  className="mr-1"
                />
                {headingTitle || 'Oups ! Une erreur est survenue.'}
              </Alert.Heading>
            )}
            {bodyIcon && (
              <FontAwesomeIcon
                icon={[bodyIconType || 'fas', bodyIconName || 'info-circle']}
                className="mr-1"
              />
            )}
            {bodyContent}
            {bodyContentHtml && (
              <span
                dangerouslySetInnerHTML={{
                  __html: bodyContentHtml,
                }}></span>
            )}
            {bodyContentBold && (
              <div className="fs-14 font-weight-bold mt-2">
                {bodyContentBold}
              </div>
            )}
            {bodyContentItalic && (
              <div className="fs-14 font-italic">{bodyContentItalic}</div>
            )}
          </Alert>
        </Col>
      </CSSTransition>
    </>
  )
}

AlertCustom.propTypes = {
  bodyContent: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  bodyContentHtml: PropTypes.string,
  bodyContentBold: PropTypes.string,
  bodyContentItalic: PropTypes.string,
  bodyIcon: PropTypes.bool,
  bodyIconName: PropTypes.string,
  bodyIconType: PropTypes.string,
  classname: PropTypes.string,
  dismissible: PropTypes.bool,
  heading: PropTypes.bool,
  headingIconName: PropTypes.string,
  headingIconType: PropTypes.string,
  headingTitle: PropTypes.string,
  onClose: PropTypes.func,
  transition: PropTypes.bool,
  variant: PropTypes.string,
  col: PropTypes.number,
}

export default AlertCustom
