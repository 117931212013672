import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterLegende from "../footer/FooterLegende";
import FooterSocial from "../footer/FooterSocial";
import FooterInfos from "../footer/FooterInfos";

const SideMenuFooter = () => {
    return (
        <>
            <Row className={'mt-3 text-center'}>
                <Col>
                    <FooterLegende/>
                </Col>
                <Col>
                    <FooterSocial/>
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Col className={'text-center'}>
                    <FooterInfos/>
                </Col>
            </Row>
        </>
    )
}

export default SideMenuFooter
