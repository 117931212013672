import PropTypes from 'prop-types'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import ModalCustom from '../../ui/modal/ModalCustom'
import AlertCustom from '../../ui/alert/AlertCustom'
import {TRANSMISSIONS_PATH} from "../../../constants/RoutesPaths";

export function ConfirmationModal({show, onHide, error = false}) {
  const navigate = useNavigate()
  const [buttonsFooter, setButtonsFooter] = useState([
    {
      text: 'Voir mes transmissions',
      func: () => navigate(TRANSMISSIONS_PATH),
      classNames: 'mx-2',
    },
  ])
  const [isError, setIsError] = useState({
    variant: 'success',
    content: 'Votre transmission a été enregistrée avec succès.',
  })

  if (error) {
    setIsError({
      variant: 'danger',
      content: "Erreur lors de l'enregistrement de la transmission.",
    })
    setButtonsFooter([
      {
        text: 'Revenir à la saisie',
        func: onHide,
      },
      {
        text: 'Voir mes transmissions',
        func: () => navigate(TRANSMISSIONS_PATH),
        classNames: 'mx-2',
      },
    ])
  }

  return (
    <ModalCustom
      show={show}
      id={'summaryConfirmation'}
      onHide={onHide}
      header={false}
      footer={true}
      backdrop={'static'}
      buttonsFooter={buttonsFooter}
      bodyClassname={'pt-5'}
      content={
        <>
          <AlertCustom
            classname="text-left"
            variant={isError.variant}
            heading={false}
            bodyContent={isError.content}
            transition={true}
          />
        </>
      }
    />
  )
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  error: PropTypes.bool,
}
