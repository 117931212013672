import {Image} from 'react-bootstrap'
import PropTypes from 'prop-types'
import React, {useState} from 'react'

import Box from '../../ui/box/Box'
import ModalCustom from '../../ui/modal/ModalCustom'

const Top10Item = ({item}) => {
  const [modalImage, setModalImage] = useState(false)

  return (
    <>
      <Box
        boxName="top10"
        boxContentBg="primary"
        boxXs={6}
        boxSm={4}
        boxXl={3}
        boxCol={'xxl'}
        boxClassNames="d-flex mb-3"
        boxContentClassNames="d-flex flex-column text-center text-white rounded"
        boxContentPadding="p-1"
        style={{cursor: 'pointer'}}
        onClick={() => setModalImage(true)}
        content={
          <>
            <Image
              onError={(e) => {
                e.target.src =
                  'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
              }}
              src={`https://cdn.florajet.com/produits/300/${item.photonet}.jpg`}
              alt={item.libcompo}
              className="img-fluid rounded-top mb-1"
            />
            <div className="d-flex flex-column flex-fill">
              <h5 className="d-flex flex-column flex-fill mb-0">
                {item.libcompo}
              </h5>
              <div className="fs-14">Référence : {item.reference}</div>
            </div>
          </>
        }
      />

      {item && (
        <ModalCustom
          show={modalImage}
          onHide={() => setModalImage(false)}
          id="modal-image"
          size="sm"
          modalClassname="text-left"
          bodyClassname="px-3 pb-4"
          content={
            <div className="d-flex align-items-center justify-content-center">
              <Image
                onError={(e) => {
                  e.target.src =
                    'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                }}
                src={`https://cdn.florajet.com/produits/300/${item.photonet}.jpg`}
                alt={item.photonet}
                className="rounded img-fluid"
              />
            </div>
          }
        />
      )}
    </>
  )
}

Top10Item.propTypes = {
  item: PropTypes.shape({
    libcompo: PropTypes.string,
    photonet: PropTypes.string,
    reference: PropTypes.string,
  }),
}

export default Top10Item
