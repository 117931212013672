import {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";

/**
 *
 * @param uris
 * @param privateSubscribe
 * @param withCredentials
 * @returns {{result: {data: null, errorMessage: null, error: boolean}}}
 */
export function useMercureSubscriber(uris: array, privateSubscribe = true, withCredentials = false) {
    const {user} = useContext(AuthContext);
    const [result, setResult] = useState({
        error: false,
        errorMessage: null,
        data: null,
    });

    useEffect(() => {
        const mercureUrl = new URL(process.env.REACT_APP_MERCURE_PUBLIC_URL);

        let frontHost = process.env.REACT_APP_FRONT_HOST;

        if (frontHost.endsWith('/')) {
            frontHost = frontHost.substring(0, frontHost.length - 1)
        }

        uris.forEach((uri) => {
            if (uri.startsWith('/')) {
                uri = uri.substring(1);
            }

            if (privateSubscribe) {
                mercureUrl.searchParams.append('topic', `${frontHost}/${user.CodeFl}/${uri}`);
            } else {
                mercureUrl.searchParams.append('topic', `${frontHost}/${uri}`);
            }
        })

        const eventSource = new EventSource(mercureUrl, {withCredentials});

        eventSource.onmessage = (event) => {
            setResult({
                error: false,
                errorMessage: null,
                data: event.data !== '' ? JSON.parse(event.data) : '',
            });
        }

        eventSource.onerror = (error) => {
            setResult({
                error: true,
                errorMessage: error.message,
                data: null,
            });
        }

        // On ferme la connexion lorsque le composant est démonté
        return () => {
            eventSource.close();
        }
    }, []);

    return {result};
}