import API from './API'
import {
  MSG_ALERTES_API,
  MSG_INFOS_API,
  TRACE_MSG_ALERTES_API,
} from '../constants/Constants'

function getMsgInfos() {
  return API({
    name: 'informations',
    method: 'get',
    url: MSG_INFOS_API,
  })
}

function getMsgAlertes() {
  return API({
    name: 'messages_alertes',
    method: 'get',
    url: MSG_ALERTES_API,
  })
}

async function postMsgAlertes(idMessage, codefl) {
  return API({
    name: 'trace_messages_alertes',
    method: 'post',
    url: TRACE_MSG_ALERTES_API,
    data: {
      messagesAlertsId: idMessage,
      codefl,
    },
  })
}

export default {
  getMsgInfos,
  getMsgAlertes,
  postMsgAlertes,
}
