import PropTypes from 'prop-types'
import React, {useCallback, useContext, useState} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {getIn} from 'formik'
import Box from '../../ui/box/Box'
import Field from '../../ui/form/Field'
import ClientsAPI from '../../../services/ClientsAPI'
import AuthContext from '../../../contexts/AuthContext'
import Checkbox from '../../ui/form/Checkbox'

export function SenderForm({formik, colXs, colSm}) {
  const {user} = useContext(AuthContext)
  const [isLoadingName, setIsLoadingName] = useState(false)
  const [selectedName, setSelectedName] = useState([
    {nom: formik.values.sender.lastname},
  ])
  const [optionsNames, setOptionsNames] = useState()
  const [isLoadingTel, setIsLoadingTel] = useState(false)
  const [selectedTel, setSelectedTel] = useState([
    {tel: formik.values.sender.tel},
  ])
  const [optionsTel, setOptionsTel] = useState()

  const handleSearchName = useCallback((query) => {
    setIsLoadingName(true)

    ClientsAPI.getClientByName(user.CodeFl, query).then((result) => {
      if (result.data) {
        setOptionsNames(result.data)
      }

      setIsLoadingName(false)
    })
  }, [])

  const handleSearchTel = useCallback((query) => {
    setIsLoadingTel(true)

    ClientsAPI.getClientByTel(user.CodeFl, query)
    .then((result) => {
      if (result.data) {
        setOptionsTel(result.data)
      }

      setIsLoadingTel(false)
    })
  }, [])

  function handleChangeSender(value) {
    setSelectedName(value)
    setSelectedTel(value)

    if (value.length > 0) {
      formik.setFieldValue('sender.lastname', value[0].nom)
      formik.setFieldValue('sender.firstname', value[0].prenom)
      formik.setFieldValue('sender.tel', value[0].tel)
      formik.setFieldValue('sender.phone', value[0].phone)
      formik.setFieldValue('sender.email', value[0].email)
    }
  }

  return (
    <>
      <Box
        boxXs={colXs}
        boxSm={colSm}
        boxName={'sender'}
        headerClassNames={'rounded-top'}
        header={true}
        headerTextColor={'white'}
        headerContent={<h3 className="my-auto">Client expéditeur</h3>}
        headerPadding={'p-3'}
        boxContentPadding={'p-3'}
        content={
          <>
            <Checkbox
              id={'sender.add_client_exp'}
              name={'sender.add_client_exp'}
              type={'checkbox'}
              label={'Ajouter ce client'}
              className={'mb-2'}
              checked={formik.values.sender.add_client_exp}
              onChange={formik.handleChange}
              />
            <Row>
              <Col xs={12}>
                <Field
                  id={'sender.companyname'}
                  name={'sender.companyname'}
                  label={'Raison sociale'}
                  value={formik.values.sender.companyname}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'sender.lastname'}>
                  <Form.Label>
                    Nom <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'sender.lastname'}
                    name={'sender.lastname'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'nom'}
                    placeholder={'Nom'}
                    isLoading={isLoadingName}
                    selected={selectedName}
                    options={optionsNames}
                    onSearch={handleSearchName}
                    renderMenuItemChildren={(option) => (
                      <span>
                        {option.nom} {option.prenom} ({option.tel})
                      </span>
                    )}
                    onInputChange={(value) => {
                      formik.setFieldValue('sender.lastname', value)
                    }}
                    onChange={(value) => {
                      handleChangeSender(value)
                    }}
                    className={
                      getIn(formik.touched, 'sender.lastname') &&
                      getIn(formik.errors, 'sender.lastname') &&
                      'is-invalid'
                    }
                  />
                  {getIn(formik.touched, 'sender.lastname') &&
                    getIn(formik.errors, 'sender.lastname') && (
                      <Form.Control.Feedback
                        type={
                          (getIn(formik.errors, 'sender.lastname') &&
                            'invalid') ||
                          'valid'
                        }>
                        {getIn(formik.errors, 'sender.lastname')}
                      </Form.Control.Feedback>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  id={'sender.firstname'}
                  name={'sender.firstname'}
                  label={'Prénom'}
                  value={formik.values.sender.firstname}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'sender.firstname') &&
                    getIn(formik.errors, 'sender.firstname')
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'sender.tel'}>
                  <Form.Label>
                    Téléphone <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'sender.tel'}
                    name={'sender.tel'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'tel'}
                    placeholder={'Téléphone'}
                    isLoading={isLoadingTel}
                    selected={selectedTel}
                    options={optionsTel}
                    onSearch={handleSearchTel}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.tel}</span>
                        <span> - {option.nom}</span>
                      </>
                    )}
                    onInputChange={(value) => {
                      formik.setFieldValue('sender.tel', value)
                    }}
                    onChange={(value) => {
                      handleChangeSender(value)
                    }}
                    className={
                      getIn(formik.touched, 'sender.tel') &&
                      getIn(formik.errors, 'sender.tel') &&
                      'is-invalid'
                    }
                  />
                  {getIn(formik.touched, 'sender.tel') &&
                    getIn(formik.errors, 'sender.tel') && (
                      <Form.Control.Feedback
                        type={
                          (getIn(formik.errors, 'sender.tel') && 'invalid') ||
                          'valid'
                        }>
                        {getIn(formik.errors, 'sender.tel')}
                      </Form.Control.Feedback>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  id={'sender.phone'}
                  name={'sender.phone'}
                  label={'Portable'}
                  value={formik.values.sender.phone}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  id={'sender.email'}
                  name={'sender.email'}
                  label={'Email'}
                  value={formik.values.sender.email}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  id={'sender.anonymous'}
                  name={'sender.anonymous'}
                  type={'checkbox'}
                  aria-label={'Client anonyme'}
                  label={'Client anonyme'}
                  className={'mb-2'}
                  checked={formik.values.sender.anonymous}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
}

SenderForm.propTypes = {
  formik: PropTypes.object.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
}
