import {useEffect} from "react";

export const Matomo = () => {
    useEffect(() => {
        const _mtm = window._mtm || [];
        window._mtm = _mtm;
        _mtm.push({
            'mtm.startTime': (new Date().getTime()),
            'event': 'mtm.Start'
        });
        const d = document;
        const g = d.createElement('script');
        const s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = process.env.REACT_APP_MATOMO_URL;
        s.parentNode.insertBefore(g,s);
    }, []);

    return (<></>);
}