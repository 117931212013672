import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {format, formatISO} from "date-fns";
import TableCustom from "../../../ui/table/TableCustom";
import OuverturesAPI from "../../../../services/OuverturesAPI";
import AlertCustom from "../../../ui/alert/AlertCustom";
import ModalCustom from "../../../ui/modal/ModalCustom";
import AuthContext from "../../../../contexts/AuthContext";
import ExecutionsAPI from "../../../../services/ExecutionsAPI";

export const ExceptionalOpeningsContentTab = ({exceptionalOpenings, isLoading, fetchData, isError}) => {
    const {user} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState({});

    async function deleteExceptionalOpenings(exceptionalOpeningsId) {
        OuverturesAPI.deleteHoliday(exceptionalOpeningsId)
            .then((response) => {
                setIsSuccess({
                    variant: 'success',
                    content: response,
                });
                fetchData(OuverturesAPI.getOuvertures(user.CodeFl));
            })
            .catch(({response}) => {

                setIsSuccess({
                    variant: 'danger',
                    content: response.data['hydra:description'],
                });
            })
            .finally(() => {
                setShowModal(true);
            })
        ;
    }

    async function checkHasAcceptedOrder(exceptionalOpeningsDate) {
        let hasExecutionsAccepted = false;

        await ExecutionsAPI.getAllAcceptedExecutions(format(new Date(exceptionalOpeningsDate), 'yyyy-MM-dd'), format(new Date(exceptionalOpeningsDate), 'yyyy-MM-dd')).then((result) => {
            hasExecutionsAccepted = result.data > 0;
        });

        return hasExecutionsAccepted;
    }

    return (
        <>
            <TableCustom
                headers={['Date', 'Heure début', 'Heure fin', '']}
                data={exceptionalOpenings}
                isLoading={isLoading}
                isError={isError}
                config={[
                    {type: 'date', key: 'datesaufles'},
                    {type: 'time', key: 'timeStart'},
                    {type: 'time', key: 'timeEnd'},
                    {
                        type: 'buttons_with_confirmation',
                        colClass: 'd-flex justify-content-end',
                        key: [
                            {
                                name: 'Supprimer',
                                color: 'red',
                                icon: 'trash',
                                iconColor: 'white',
                                classnames: 'm-1',
                                func: deleteExceptionalOpenings,
                                funcParam: 'idsfleuristesSaufles',
                                messageInModal: 'Attention, vous avez une ou plusieurs commandes acceptées sur votre extranet à livrer à cette date. Si vous souhaitez les réaffecter, vous pouvez nous contacter par tchat ou par téléphone au 0826.107.707. choix 3.',
                                funcToCheckBeforeConfirmation: checkHasAcceptedOrder,
                                funcToCheckBeforeConfirmationParam: 'datesaufles',
                            }
                        ],
                    },
                ]}
            />

            <ModalCustom
                show={showModal}
                id={'deleteModal'}
                onHide={() => setShowModal(false)}
                content={(
                    <AlertCustom
                        variant={isSuccess.variant}
                        heading={true}
                        headingTitle={"Information"}
                        headingIconName={"info-circle"}
                        bodyContent={isSuccess.content}
                        transition={true}
                    />
                )}
            />
        </>
    );
}

ExceptionalOpeningsContentTab.propTypes = {
    exceptionalOpenings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isLoading: PropTypes.bool.isRequired,
    fetchData: PropTypes.func,
    isError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};