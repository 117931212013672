import React from "react";
import Box from "../../../ui/box/Box";
import {ExceptionalOpeningsContent} from "./ExceptionalOpeningsContent";

export const ExceptionalOpenings = () => {
    return (
        <>
            <Box
                boxXxl={'4'}
                boxHd={'4'}
                boxName={'ouvertures'}
                header={true}
                headerContent={
                    <h3 className={'my-auto'}>Dates des ouvertures exceptionnelles</h3>
                }
                headerClassNames={'rounded-top p-3'}
                content={<ExceptionalOpeningsContent />}
            />
        </>
    );
}