import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import Button from 'react-bootstrap/Button'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {setHours, setMinutes} from 'date-fns'

import {SHOPPERS_PICKUP_INTERVAL} from '../../../../hooks/useShoppers'
import {aroundHourDate} from '../../../../Tools/Date'
import {
  EXTRANET_CLOSING_HOUR,
  EXTRANET_OPEN_HOUR,
} from '../../../../hooks/useExtranetState'
import {configureShoppersModals} from '../../../../slice/shoppersModalsSlice'
import Checkbox from "../Checkbox";

const DeliveryDateForm = ({execution}) => {
  const dispatch = useDispatch()
  const [allowOtherDeliveryDate, setAllowOtherDeliveryDate] = useState(false)
  const [now] = useState(new Date())

  /**
   * Mets à jour la valeur de la date de livraison.
   *
   * @param {Date} value
   */
  const handleChangeDate = (value) => {
    formik.setFieldValue('shopperDatetimeControl', value)
  }

  const handleAllowOtherDeliveryDate = () => {
    setAllowOtherDeliveryDate(!allowOtherDeliveryDate)
  }

  const getDeliveryInitialDateValue = (executionStartDeliveryDate) => {
    return aroundHourDate(
      executionStartDeliveryDate > Date.now()
        ? new Date(executionStartDeliveryDate)
        : setHours(new Date(), now.getHours() + 2),
    )
  }

  const getMinDeliveryDate = (minTimestamp, allowDeliveryDate) => {
    let deliveryDate = new Date(minTimestamp)
    deliveryDate.setHours(deliveryDate.getHours() + 2)

    if (
      minTimestamp < Date.now() &&
      (allowDeliveryDate || !allowDeliveryDate)
    ) {
      deliveryDate = aroundHourDate()
      deliveryDate.setHours(deliveryDate.getHours() + 2)
    } else if (!allowDeliveryDate) {
      deliveryDate = setHours(setMinutes(new Date(), 0), EXTRANET_OPEN_HOUR - 1)
    }

    return deliveryDate
  }

  const formik = useFormik({
    initialValues: {
      shopperDatetimeControl: (() =>
        getDeliveryInitialDateValue(execution.momentjourlimits.from))(),
    },
    onSubmit(values) {
      dispatch(
        configureShoppersModals({
          deliveryDate: new Date(Date.parse(values.shopperDatetimeControl)),
          execution,
        }),
      )
    },
  })

  return (
    <>
      <Form onSubmit={formik && formik.handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor={'shopperDatetimeControl'}>
            Date d'enlèvement du bouquet{' '}
            <small>
              <em>(+2 heures)</em>
            </small>{' '}
            : <sup>*</sup>
          </Form.Label>
          <div className={'d-flex flex-column flex-md-row align-items-center'}>
            <DatePicker
              name={'shopperDatetimeControl'}
              locale={'fr'}
              placeholderText={'--/--/---- --:--'}
              openToDate={(() =>
                getDeliveryInitialDateValue(execution.momentjourlimits.from))()}
              minDate={
                execution.momentjourlimits.from > Date.now()
                  ? new Date(execution.momentjourlimits.from)
                  : setHours(now, now.getHours() + 2)
              }
              maxDate={!allowOtherDeliveryDate ?? new Date(execution.datliv)}
              showTimeSelect
              showTimeSelectOnly={!allowOtherDeliveryDate}
              minTime={
                !allowOtherDeliveryDate
                  ? (() =>
                      getMinDeliveryDate(
                        execution.momentjourlimits.from,
                        allowOtherDeliveryDate,
                      ))()
                  : setHours(new Date(), EXTRANET_OPEN_HOUR - 1)
              }
              maxTime={
                !allowOtherDeliveryDate
                  ? new Date(execution.momentjourlimits.to)
                  : setHours(
                      setMinutes(new Date(), 0),
                      EXTRANET_CLOSING_HOUR + 3,
                    )
              }
              timeCaption={'Heure :'}
              timeIntervals={SHOPPERS_PICKUP_INTERVAL}
              className="form-control bg-white"
              selected={formik && formik.values.shopperDatetimeControl}
              onChange={(value) => handleChangeDate(value)}
              onBlur={formik && formik.handleBlur}
              dateFormat={'dd/MM/yyyy HH:mm'}
            />

            <Button type={'submit'} className={'btn-primary mt-2 ml-md-4'}>
              Sélectionner
            </Button>
          </div>
        </Form.Group>
      </Form>

      <Form.Group className={'d-flex align-items-center mt-3'}>
        <Checkbox
          id={'allowOtherDeliveryDate'}
          label={'Choisir une autre date de livraison'}
          type={'checkbox'}
          onChange={handleAllowOtherDeliveryDate}
          checked={allowOtherDeliveryDate}
        />
      </Form.Group>
    </>
  )
}

DeliveryDateForm.propTypes = {
  execution: PropTypes.object.isRequired,
}

export default DeliveryDateForm
