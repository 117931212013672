import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {selectSideMenu} from '../../../slice/sideMenuSlice'

const SideMenuIcon = ({eventKey, lvl, size}) => {
  const {accordionActiveKey, subAccordionActiveKey} = useSelector(
    selectSideMenu,
  )

  const handleIcon = (eventKey, lvl) => {
    const icon = 'chevron-'
    let up

    if (lvl === 1) {
      up = eventKey === accordionActiveKey
    } else {
      up = eventKey === subAccordionActiveKey
    }

    return `${icon}${(up && 'up') || 'down'}`
  }

  const handleColor = (lvl) => {
    return lvl === 1 ? 'white' : 'black-light'
  }

  return (
    <FontAwesomeIcon
      icon={(() => handleIcon(eventKey, lvl))()}
      color={(() => handleColor(lvl))()}
      size={size}
      className="mr-1"
    />
  )
}

SideMenuIcon.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  lvl: PropTypes.number,
  size: PropTypes.oneOf([
    '2xs',
    'xs',
    'sm',
    'lg',
    'xl',
    '2xl',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x',
  ]),
}

SideMenuIcon.defaultProps = {
  lvl: 1,
  size: '1x',
}

export default SideMenuIcon
