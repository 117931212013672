import Form from 'react-bootstrap/Form'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {getIn} from 'formik'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useLocation} from 'react-router-dom'
import Box from '../../ui/box/Box'
import Field from '../../ui/form/Field'
import ClientsAPI from '../../../services/ClientsAPI'
import AuthContext from '../../../contexts/AuthContext'
import Checkbox from '../../ui/form/Checkbox'

export function ReceiverForm({formik, colXs, colSm}) {
  const {user} = useContext(AuthContext)
  const location = useLocation()
  const [isLoadingName, setIsLoadingName] = useState(false)
  const [selectedName, setSelectedName] = useState([
    {nom: formik.values.receiver.lastname},
  ])
  const [optionsNames, setOptionsNames] = useState()
  const [isLoadingZipcode, setIsLoadingZipcode] = useState(false)
  const [selectedZipcode, setSelectedZipcode] = useState([
    {codep: formik.values.receiver.zipcode},
  ])
  const [optionsZipcode, setOptionsZipcode] = useState()
  const [isLoadingCity, setIsLoadingCity] = useState(false)
  const [selectedCity, setSelectedCity] = useState([
    {city: formik.values.receiver.city},
  ])
  const [optionsCity, setOptionsCity] = useState([])
  const [isLoadingCountry, setIsLoadingCountry] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState([
    {
      libelle: 'FRANCE',
      codiso: 'F',
      idspays: 72,
    },
  ])
  const [optionsCountry, setOptionsCountry] = useState([])
  const [isLoadingTel, setIsLoadingTel] = useState(false)
  const [selectedTel, setSelectedTel] = useState([
    {tel: formik.values.receiver.tel},
  ])
  const [optionsTel, setOptionsTel] = useState([])
  const isForeignCountry = useMemo(
    () =>
      selectedCountry[0] &&
      (selectedCountry[0].libelle !== 'FRANCE' ||
        selectedCountry[0].codiso !== 'F'),
    [selectedCountry],
  )

  const handleSearchName = useCallback((query) => {
    setIsLoadingName(true)

    ClientsAPI.getClientByName(user.CodeFl, query).then((result) => {
      if (result.data) {
        setOptionsNames(result.data)
      }

      setIsLoadingName(false)
    })
  }, [])

  const handleSearchZipcode = useCallback((query) => {
    setIsLoadingZipcode(true)

    if (isForeignCountry) {
      const options = [
        {
          codep: query,
          city: selectedCity[0] && selectedCity[0].localite,
        },
      ]
      setOptionsZipcode(options)
    } else {
      ClientsAPI.getCodePostal(query).then((result) => {
        const options = result.data.map((option) => ({
          codep: option.codpostal,
          city: option.localite,
        }))

        setOptionsZipcode(options)
        setIsLoadingZipcode(false)
      })
    }
  }, [])

  const handleSearchCity = useCallback((query) => {
    setIsLoadingCity(true)

    if (isForeignCountry) {
      const options = [
        {
          codep: (selectedZipcode[0] && selectedZipcode[0].codep) || '',
          city: query,
        },
      ]
      setOptionsCity(options)
    } else {
      ClientsAPI.getLocalite(query).then((result) => {
        const options = result.data.map((option) => ({
          codep: option.codpostal,
          city: option.localite,
        }))

        setOptionsCity(options)
        setIsLoadingCity(false)
      })
    }
  }, [])

  const handleSearchCountry = useCallback((query) => {
    setIsLoadingCountry(true)

    ClientsAPI.getPays(query).then((result) => {
      if (result.data) {
        setOptionsCountry(result.data)
      }
      setIsLoadingCountry(false)
    })
  }, [])

  const handleSearchTel = useCallback((query) => {
    setIsLoadingTel(true)

    ClientsAPI.getClientByTel(user.CodeFl, query).then((result) => {
      if (result.data) {
        setOptionsTel(result.data)
      }

      setIsLoadingTel(false)
    })
  }, [])

  // Mets à jour tous les champs du destinataire en fonction de celui sélectionné
  function handleChangeReceiver(value) {
    setSelectedName(value)
    setSelectedTel(value)
    setSelectedZipcode(value)

    if (value.length > 0) {
      value[0].city = value[0].ville
    }

    setSelectedCity(value)

    if (value.length > 0) {
      formik.setFieldValue('receiver.lastname', value[0].nom)
      formik.setFieldValue('receiver.firstname', value[0].prenom)
      formik.setFieldValue('receiver.idclient', value[0].idclient)
      formik.setFieldValue('receiver.address1', value[0].adresse1)
      formik.setFieldValue('receiver.address2', value[0].adresse2)
      formik.setFieldValue('receiver.address3', value[0].adresse3)
      formik.setFieldValue('receiver.tel', value[0].tel)
      formik.setFieldValue('receiver.phone', value[0].phone)
      formik.setFieldValue('receiver.companyname', value[0].raisonsoc)
      formik.setFieldValue('receiver.zipcode', value[0].codep)
      formik.setFieldValue('receiver.city', value[0].ville)
    }
  }

  useEffect(() => {
    if (location.state && location.state.order) {
      ClientsAPI.getPaysByCode(location.state.order.receiver.paysex).then(
        (result) => {
          if (result.data) {
            setSelectedCountry([result.data[0]])
          }
        },
      )
    }
  }, [])

  return (
    <>
      <Box
        boxXs={colXs}
        boxSm={colSm}
        boxName={'receiver'}
        headerClassNames={'rounded-top'}
        header={true}
        headerTextColor={'white'}
        headerContent={<h3 className="my-auto">Client destinataire</h3>}
        headerPadding={'p-3'}
        boxContentPadding={'p-3'}
        content={
          <>
            <Checkbox
              id={'receiver.add_client_dest'}
              name={'receiver.add_client_dest'}
              type={'checkbox'}
              label={'Ajouter ce client'}
              className={'mb-2'}
              checked={formik.values.receiver.add_client_dest}
              onChange={formik.handleChange}
            />
            <Row>
              <Col xs={12}>
                <Field
                  id={'receiver.companyname'}
                  name={'receiver.companyname'}
                  label={'Raison sociale'}
                  value={formik.values.receiver.companyname}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'receiver.lastname'}>
                  <Form.Label>
                    Nom{' '}
                    {formik.values.occasion === '5' ||
                    formik.values.occasion === 5
                      ? ' du défunt'
                      : ''}
                    <span className="text-red"> *</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'receiver.lastname'}
                    name={'receiver.lastname'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'nom'}
                    placeholder={`Nom ${
                      formik.values.occasion === '5' ||
                      formik.values.occasion === 5
                        ? ' du défunt'
                        : ''
                    }`}
                    isLoading={isLoadingName}
                    selected={selectedName}
                    options={optionsNames}
                    onSearch={handleSearchName}
                    renderMenuItemChildren={(option) => (
                      <span>
                        {option.nom} {option.prenom} ({option.tel})
                      </span>
                    )}
                    onInputChange={(text) => {
                      formik.setFieldValue('receiver.lastname', text)
                    }}
                    onChange={(value) => {
                      handleChangeReceiver(value)
                      formik.setFieldTouched('receiver.lastname', true)
                    }}
                    className={
                      getIn(formik.errors, 'receiver.lastname') &&
                      getIn(formik.touched, 'receiver.lastname') &&
                      'is-invalid'
                    }
                  />
                  {getIn(formik.touched, 'receiver.lastname') &&
                    getIn(formik.errors, 'receiver.lastname') && (
                      <Form.Control.Feedback
                        type={
                          (getIn(formik.errors, 'receiver.lastname') &&
                            'invalid') ||
                          'valid'
                        }>
                        {getIn(formik.errors, 'receiver.lastname')}
                      </Form.Control.Feedback>
                    )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  id={'receiver.firstname'}
                  name={'receiver.firstname'}
                  label={'Prénom'}
                  value={formik.values.receiver.firstname}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'receiver.firstname') &&
                    getIn(formik.errors, 'receiver.firstname')
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  labelRequired={true}
                  id={'receiver.address1'}
                  name={'receiver.address1'}
                  label={'Adresse'}
                  value={formik.values.receiver.address1}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'receiver.address1') &&
                    getIn(formik.errors, 'receiver.address1')
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  labelRequired={false}
                  id={'receiver.address2'}
                  name={'receiver.address2'}
                  label={"Complément d'adresse"}
                  value={formik.values.receiver.address2}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  labelRequired={false}
                  id={'receiver.address3'}
                  name={'receiver.address3'}
                  label={"Complément d'adresse 2"}
                  value={formik.values.receiver.address3}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'receiver.zipcode'}>
                  <Form.Label>
                    Code postal <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'receiver.zipcode'}
                    name={'receiver.zipcode'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'codep'}
                    placeholder={'Code postal'}
                    isLoading={isLoadingZipcode}
                    selected={selectedZipcode}
                    options={optionsZipcode}
                    onSearch={handleSearchZipcode}
                    useCache={false}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.codep}</span>
                        <span> {option.city !== '' && `(${option.city})`}</span>
                      </>
                    )}
                    onInputChange={(text) => {
                      // Utiliser useCallback si cette option est ajoutée
                      formik.setFieldValue('receiver.zipcode', text)
                    }}
                    onChange={(value) => {
                      setSelectedZipcode(value)

                      if (!isForeignCountry) {
                        setSelectedCity(value)
                      }

                      if (value.length > 0) {
                        formik.setFieldValue('receiver.zipcode', value[0].codep)

                        if (!isForeignCountry) {
                          formik.setFieldValue('receiver.city', value[0].city)
                        }
                      }
                    }}
                    className={
                      getIn(formik.touched, 'receiver.zipcode') &&
                      getIn(formik.errors, 'receiver.zipcode') &&
                      'is-invalid'
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'receiver.city'}>
                  <Form.Label>
                    Ville <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'receiver.city'}
                    name={'receiver.city'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'city'}
                    placeholder={'Ville'}
                    isLoading={isLoadingCity}
                    selected={selectedCity}
                    options={optionsCity}
                    onSearch={handleSearchCity}
                    useCache={false}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.codep}</span>
                        <span> {option.city !== '' && `(${option.city})`}</span>
                      </>
                    )}
                    onInputChange={(value) => {
                      formik.setFieldValue('receiver.city', value)
                    }}
                    onChange={(value) => {
                      setSelectedCity(value)

                      if (!isForeignCountry) {
                        setSelectedZipcode(value)
                      }

                      if (value.length > 0) {
                        formik.setFieldValue('receiver.city', value[0].city)

                        if (!isForeignCountry) {
                          formik.setFieldValue(
                            'receiver.zipcode',
                            value[0].codep,
                          )
                        }
                      }
                    }}
                    className={
                      getIn(formik.touched, 'receiver.city') &&
                      getIn(formik.errors, 'receiver.city') &&
                      'is-invalid'
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId={'receiver.country'}>
                  <Form.Label>
                    Pays <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'receiver.country'}
                    name={'receiver.country'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'libelle'}
                    placeholder={'Pays'}
                    isLoading={isLoadingCountry}
                    selected={selectedCountry}
                    options={optionsCountry}
                    onSearch={handleSearchCountry}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.libelle}</span>
                        <span> ({option.codiso})</span>
                      </>
                    )}
                    onChange={(value) => {
                      setSelectedCountry(value)
                      setSelectedZipcode([])
                      setSelectedCity([])

                      if (value.length > 0) {
                        formik.setFieldValue(
                          'receiver.country',
                          value[0].codiso,
                        )
                        formik.setFieldValue(
                          'receiver.countryLabel',
                          value[0].libelle,
                        )
                        formik.setFieldValue(
                          'receiver.countryId',
                          value[0].idspays,
                        )
                      }
                    }}
                    className={
                      getIn(formik.touched, 'receiver.country') &&
                      getIn(formik.errors, 'receiver.country') &&
                      'is-invalid'
                    }
                  />
                  {isForeignCountry && (
                    <>
                      <div className={'mb-2'}>
                        <FontAwesomeIcon
                          icon="info-circle"
                          color="var(--primary)"
                          size="lg"
                          className="mr-1"
                        />
                        <small>
                          <b>
                            Attention, aucune livraison à l'international ne
                            sera assurée le dimanche. Merci de nous contacter
                            pour plus de précision.
                          </b>
                        </small>
                      </div>
                      </>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'receiver.tel'}>
                  <Form.Label>
                    Téléphone <span className="text-red">*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id={'receiver.tel'}
                    name={'receiver.tel'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'tel'}
                    placeholder={'Téléphone'}
                    isLoading={isLoadingTel}
                    selected={selectedTel}
                    options={optionsTel}
                    onSearch={handleSearchTel}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.tel}</span>
                        <span> - {option.nom}</span>
                      </>
                    )}
                    onInputChange={(value) => {
                      formik.setFieldValue('receiver.tel', value)
                    }}
                    onChange={(value) => {
                      handleChangeReceiver(value)
                    }}
                    className={
                      getIn(formik.touched, 'receiver.tel') &&
                      getIn(formik.errors, 'receiver.tel') &&
                      'is-invalid'
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  id={'receiver.phone'}
                  name={'receiver.phone'}
                  label={'Portable'}
                  value={formik.values.receiver.phone}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'receiver.phone') &&
                    getIn(formik.errors, 'receiver.phone')
                  }
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
}

ReceiverForm.propTypes = {
  formik: PropTypes.object.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
}
