import React, {useState, useEffect} from 'react'
import Col from 'react-bootstrap/Col'

import AlertCustom from '../../ui/alert/AlertCustom'
import Count from '../../ui/page/Count'
import {ITEMS_PER_PAGE_VIDEOS} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import useFetch from '../../../hooks/useFetch'
import VideosAPI from '../../../services/VideosAPI'
import VideosItem from './VideosItem'

const Videos = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [{data: videos, totalItems, isLoading, isError}, fetchData] = useFetch(
    VideosAPI.getVideos(1),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) fetchData(VideosAPI.getVideos(currentPage))
  }, [currentPage])

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  return (
    <>
      <Col
        xs={12}
        className="d-flex flex-row align-items-center justify-content-between mt-3 mb-4">
        <h1 className="mb-0">Vidéos</h1>
        <Count classNames="text-right" totalItems={totalItems} name="vidéo" />
      </Col>

      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
      />

      {!isLoading &&
        !isError &&
        videos.map((item) => (
          <VideosItem
            key={item.id}
            src={`.${process.env.REACT_APP_FTP_BASE_URI}/Videos/v3/${item.fileName}`}
            title={item.title}
            alt={item.description}
          />
      ))}

      {!isLoading && ITEMS_PER_PAGE_VIDEOS < totalItems && (
        <Col xs={12} className="d-flex justify-content-end">
          <PaginationCustom
            currentPage={currentPage || 1}
            itemsPerPage={ITEMS_PER_PAGE_VIDEOS}
            totalPages={totalItems}
            handlePage={handlePage}
          />
        </Col>
      )}
    </>
  )
}

export default Videos
