import {format, sub} from "date-fns";
import {
  EXCEPTIONAL_OPENINGS_API_DELETE, EXCEPTIONAL_OPENINGS_API_NEW,
  ITEMS_PER_PAGE,
  OPENINGS_API,
  OPENINGS_POST_API,
} from '../constants/Constants'
import APIv2 from "./APIv2";

function getOuvertures(codefl, sortType, sortOrder = 'asc', currentPage = 1) {
  const dateStart = format(sub(new Date(), {
    years: 2,
  }), 'yyyy-01-01');

  return {
    name: 'ouvertures',
    method: 'get',
    url: `${OPENINGS_API}&codefl=${codefl}&order%5B${sortType}%5D=${
      sortOrder || 'asc'
    }&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE}&datesaufles[after]=${dateStart}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

async function postOuvertures(codefl, sauflesDate, timeStart, timeEnd) {
  return APIv2.requestData({
    name: 'post_ouvertures',
    method: 'post',
    url: EXCEPTIONAL_OPENINGS_API_NEW,
    data: {
      codefl,
      sauflesDate,
      timeStart,
      timeEnd
    },
  })
}

function deleteHoliday(id) {
  return APIv2.requestData({
    name: 'delete_exceptional_openings',
    method: 'delete',
    url: `${EXCEPTIONAL_OPENINGS_API_DELETE}/${id}`,
    headers: {
      accept: 'application/json',
    }
  })
}

export default {
  getOuvertures,
  postOuvertures,
  deleteHoliday,
}
