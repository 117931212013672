import {PRESENTATION_API} from '../constants/Constants'

function getPresentation() {
  return {
    name: 'présentation',
    method: 'get',
    url: PRESENTATION_API,
    single: true,
    female: true,
  }
}

export default {
  getPresentation,
}
