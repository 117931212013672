import React, {useState, useContext} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {CSSTransition} from 'react-transition-group'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'

import AlertCustom from '../../ui/alert/AlertCustom'
import Box from '../../ui/box/Box'
import {CartContext} from '../../../contexts/CartContext'
import CartItem from './CartItem'
import Count from '../../ui/page/Count'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import Numbers from '../../../helpers/Numbers'

const Cart = () => {
  const {
    cartItems,
    handleCheckout,
    handleClear,
    cartMontantHT,
    cartDeliveries,
    cartTotalHT,
    cartTVA,
    cartTotalTTC,
    isLoading,
    setIsError,
    isError,
  } = useContext(CartContext)
  const [modalShowClear, setModalShowClear] = useState(false)
  const [modalShowCheckout, setModalShowCheckout] = useState(false)

  // Gestion de la suppression du panier
  const handleClearCart = () => {
    handleClear()
    setModalShowClear(false)
  }

  // Gestion du message de validation du panier
  const handleCheckoutMessage = () => {
    setModalShowCheckout(false)
    setTimeout(() => {
      setIsError(false)
    }, 1000)
  }

  return (
    <>
      <Col
        xs={12}
        className="d-flex flex-row justify-content-between mt-3 mb-4">
        <h1>Mon panier</h1>
        <Link
          to="/mes-achats"
          role="button"
          className="btn btn-primary d-flex align-items-center btn-rounded ff-bold">
          <FontAwesomeIcon icon="chevron-left" color="white" className="mr-2" />
          <span>Retour</span>
        </Link>
      </Col>

      <AlertCustom
        variant="warning"
        bodyIcon={true}
        bodyContent="Votre panier est vide."
        transition={!cartItems.length}
      />

      {cartItems && cartItems.length > 0 && (
        <>
          <CSSTransition
            in={true}
            appear={true}
            timeout={500}
            unmountOnExit
            classNames="fade">
            <Col xs={12} md={8}>
              <Row className="d-flex flex-column">
                {cartItems.map((item, index) => (
                  <CartItem key={index} article={item} />
                ))}
              </Row>

              <div className="d-flex align-items-center justify-content-between">
                <Button
                  className="btn-rounded btn-grey-darker ff-bold"
                  onClick={() => setModalShowClear(true)}>
                  Vider le panier
                </Button>

                <Count totalItems={cartItems.length} name="article" />
              </div>
            </Col>
          </CSSTransition>

          <CSSTransition
            in={true}
            appear={true}
            timeout={500}
            unmountOnExit
            classNames="fade">
            <Box
              boxXs={12}
              boxMd={4}
              boxName="cart-side"
              boxContentBg="grey-light"
              boxContentClassNames="d-flex flex-column mt-3 mt-md-0"
              boxContentPadding="py-3 px-4"
              boxContentRounded="xl"
              content={
                <>
                  <h1>Résumé</h1>
                  <p className="mb-5">
                    Livraison sous 10 jours (dans la limite des stocks
                    disponibles)
                  </p>

                  <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div className="fs-20">Montant HT :</div>
                    <div className="ff-bold">
                      {Numbers.ConvertToEuro(Number(cartMontantHT))}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between mb-5">
                    <div className="ml-3">
                      + Estimation frais logistique et Port (*) :
                    </div>
                    <div className="ff-bold">
                      {Numbers.ConvertToEuro(Number(cartDeliveries))}
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div className="fs-20">Total HT :</div>
                    <div className="ff-bold">
                      {Numbers.ConvertToEuro(Number(cartTotalHT))}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="ml-3">
                      + TVA (
                      {cartItems
                        // 1- MAPPING : On renvoie la tva
                        // 2- FILTER : On filtre pour enlever les doublons
                        // 3- MAPPING : On affiche le tableau filtré et on applique le "%," si ce n'est pas le dernier item

                        // (On est obligé de faire deux mappings pour afficher les "%;" sinon on ne peut pas filtrer :
                        // ex: si on applique le "%;" dans le 1er mapping on peut avoir "20%," et "20"
                        // donc le filtre ne s'appliquera pas pour enlever les doublons de "20")
                        .map((item) => item.articlesExtranetTva)
                        .filter(
                          (item, index, array) => array.indexOf(item) === index,
                        )
                        .map(
                          (item, index, array) =>
                            `${item}%${
                              !(Object.keys(array).length === index + 1)
                                ? ', '
                                : ''
                            }`,
                        )}
                      ) :
                    </div>
                    <div className="ff-bold">
                      {Numbers.ConvertToEuro(Number(cartTVA))}
                    </div>
                  </div>

                  <div>
                    <hr />
                  </div>

                  <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div className="fs-20 ff-bold">Total TTC :</div>
                    <div className="ff-bold">
                      {Numbers.ConvertToEuro(Number(cartTotalTTC))}
                    </div>
                  </div>

                  <div>
                    <Button
                      className="btn-rounded ff-bold mt-2"
                      onClick={() => setModalShowCheckout(true)}>
                      Valider le panier
                    </Button>
                  </div>

                  <p className="fs-14 mt-5">
                    (*) Ce montant sera éventuellement ajusté à la baisse en
                    fonction du colisage.
                  </p>
                </>
              }
            />
          </CSSTransition>
        </>
      )}

      <ModalCustom
        show={modalShowClear}
        onHide={() => setModalShowClear(false)}
        id="modal-clear"
        bodyClassname="p-5"
        content={
          <>
            <h3>Etes-vous sûr de vouloir vider votre panier ?</h3>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: 'Confirmer',
            func: () => handleClearCart(),
            classNames: 'mx-2',
          },
          {
            text: 'Annuler',
            classNames: 'mx-2',
          },
        ]}
      />

      <ModalCustom
        show={modalShowCheckout}
        onHide={() => setModalShowCheckout(false)}
        id="modal-checkout"
        bodyClassname="p-5"
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant={isError.variant}
              heading={isError.heading}
              headingTitle={isError.headingTitle}
              headingIconName={isError.headingIconName}
              bodyContent={isError.content}
              transition={!!isError}
            />

            {!isError && !isLoading && (
              <h3>Etes-vous sûr de vouloir valider votre panier ?</h3>
            )}
          </>
        }
        footer={true}
        buttonsFooter={
          isError
            ? [
                {
                  text: 'Fermer',
                  func: () => handleCheckoutMessage(),
                },
              ]
            : [
                {
                  text: isLoading ? (
                    <Loader variant="white" size="sm" classname="my-1 mx-2" />
                  ) : (
                    'Confirmer'
                  ),
                  func: () => handleCheckout(),
                  classNames: 'mx-2',
                },
                {
                  text: 'Annuler',
                  classNames: 'mx-2',
                },
              ]
        }
      />
    </>
  )
}

export default Cart
