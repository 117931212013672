import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {dataTransformer} from "../Tools/TransformerAxios";

const API = () => {
  // Set the default options
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    transformRequest: [dataTransformer].concat(axios.defaults.transformRequest)
  }

  // Create instance
  const instance = axios.create(defaultOptions)

  // Update the instance config for any request
  instance.interceptors.request.use(function (config) {
    // Set the current AUTH token for any request or delete it in axios headers if expired
    const token = window.localStorage.getItem('authToken')

    if (token) {
      const {exp: expiration} = jwtDecode(token)
      if (expiration * 1000 > new Date().getTime()) {
        config.headers.Authorization = `Bearer ${token}`
      } else delete config.headers.Authorization
    } else delete config.headers.Authorization

    config.cancelTokenSource = axios.CancelToken.source()

    return config
  })

  return instance
}

export default API()
