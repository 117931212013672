import PropTypes from 'prop-types'
import React from 'react'
import uuid from 'react-uuid'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {isArray, isFunction} from '../../../Tools/TypeOf'
import TableData from './TableData'
import {retrieveValue} from '../../../hooks/useCustomTable'
import {BOOTSTRAP_VARIANTS} from '../../../constants/Constants'

const TableBody = ({
                       data,
                       config,
                       configLine,
                       lineVariant,
                       totals,
                       rowDeletable,
                       handleDeletableRow,
                       deletableRowOnClick,
                   }) => {
    const handleConfig = (config, i) => {
        return (config[i] && isArray(config[i]) && config[i]) || config
    }

    const handleClassname = (configLine, item) => {
        if (isFunction(configLine)) {
            return configLine(item)
        }

        if (
            configLine &&
            configLine.key &&
            configLine.filter &&
            item[configLine.key] === configLine.filter
        ) {
            return `bg-${configLine.color} ${configLine.text_class}`
        }

        return ''
    }

    const handleLineVariant = (lineVariant, item) => {
        if (lineVariant) {
            const variant =
                (isFunction(lineVariant) && lineVariant(item)) || lineVariant

            if (!BOOTSTRAP_VARIANTS.includes(variant)) {
                throw new Error(
                    `lineVariant function must be return one of these values: ${BOOTSTRAP_VARIANTS.join(
                        ', ',
                    )}, but ${variant} is returned`,
                )
            }

            return `bg-${variant}`
        }

        return ''
    }

    return (
        data && (
            <tbody>
            {data.map((item, i) => {
                const configuration = handleConfig(config, i)

                return (
                    <tr
                        key={i}
                        className={`${handleClassname(
                            configLine,
                            item,
                        )} ${handleLineVariant(lineVariant, item)}`}>
                        {configuration.map((conf, tableDataIndex) => {
                            const value = retrieveValue(conf, item)

                            return (
                                (rowDeletable &&
                                    configuration &&
                                    configuration.length - 1 === tableDataIndex && (
                                        <TableData
                                            key={uuid()}
                                            dataKey={uuid()}
                                            conf={conf}
                                            value={value}
                                            item={item}>
                                            {rowDeletable &&
                                                ((isFunction(handleDeletableRow) &&
                                                        handleDeletableRow(item)) ||
                                                    handleDeletableRow === undefined) && (
                                                    <Button
                                                        variant={'danger'}
                                                        className={'row-delete-control z-1000 mr-1'}
                                                        title={'Annuler'}
                                                        size={'sm'}
                                                        onClick={(evt) => {
                                                            deletableRowOnClick(item, evt)
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={'times'}
                                                            style={{verticalAlign: 'middle'}}
                                                        />
                                                    </Button>
                                                )}
                                        </TableData>
                                    )) || (
                                    <TableData
                                        key={uuid()}
                                        dataKey={uuid()}
                                        conf={conf}
                                        value={value}
                                        item={item}
                                    />
                                )
                            )
                        })}
                    </tr>
                )
            })}
            {totals && (
                <tr className="ff-bold">
                    <td>{totals.title}</td>
                    {totals.values.map((item, index) => (
                        <td key={index}>{item}</td>
                    ))}
                </tr>
            )}
            </tbody>
        )
    )
}

TableBody.propTypes = {
    data: PropTypes.array,
    config: PropTypes.array,
    configLine: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
        PropTypes.func,
    ]),
    lineVariant: PropTypes.oneOfType([
        PropTypes.oneOf(BOOTSTRAP_VARIANTS),
        PropTypes.func,
    ]),
    totals: PropTypes.object,
    rowDeletable: PropTypes.bool,
    handleDeletableRow: PropTypes.func,
    deletableRowOnClick: PropTypes.func,
}

export default TableBody;
