import {
  ITEMS_PER_PAGE,
  PERIMETERS_API,
  PERIMETERS_ON_PENDING_API,
  PERIMETERS_PATCH_API,
  PERIMETERS_POST_API,
} from '../constants/Constants'
import APIv2 from './APIv2'

function getPerimetres(sortType, sortOrder, currentPage, codefl) {
  return APIv2.requestData({
    name: 'perimetres',
    method: 'GET',
    url: PERIMETERS_API,
    params: {
      [`order[${sortType}]`]: sortOrder,
      page: currentPage,
      itemsPerPage: ITEMS_PER_PAGE,
      pagination: true,
      codefl,
    },
  })
}

function getOnPending(sortType, sortOrder, currentPage, codefl) {
  return APIv2.requestData({
    name: 'perimetres_validation',
    method: 'GET',
    url: PERIMETERS_ON_PENDING_API,
    params: {
      [`order[${sortType}]`]: sortOrder,
      page: currentPage,
      pagination: true,
      itemsPerPage: 9999,
      validationApplied: false,
      codefl,
    },
  })
}

async function patchPerimetre(perimetre) {
  return APIv2.requestData({
    name: 'patch_perimetre_item',
    method: 'PATCH',
    url: `${PERIMETERS_PATCH_API}/${perimetre.idsperimetr}`,
    data: perimetre,
    headers: {
      accept: 'application/ld+json',
      'Content-Type': 'application/merge-patch+json',
    },
  })
}

async function newPerimetresValidation(perimetres) {
  return APIv2.requestData({
    name: 'post_perimetres_items',
    method: 'POST',
    url: `${PERIMETERS_POST_API}/submit`,
    data: perimetres,
    headers: {
      accept: 'application/ld+json',
    },
  })
}

function deletePerimetre(perimetre) {
  return APIv2.requestData({
    name: 'delete_perimetre',
    method: 'GET',
    url: `${PERIMETERS_API}/delete/${perimetre.idsperimetr}`,
  })
}

export default {
  getPerimetres,
  getOnPending,
  patchPerimetre,
  newPerimetresValidation,
  deletePerimetre,
}
