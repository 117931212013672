import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

const PaginationCustom = ({
  classNames,
  currentPage,
  itemsPerPage,
  totalPages,
  handlePage,
}) => {
  const nbPages = Math.ceil(totalPages / itemsPerPage)
  const pages = []

  for (let page = 1; page <= nbPages; page += 1) {
    pages.push(
      <Pagination.Item
        key={page}
        onClick={() => handlePage(page)}
        active={currentPage === page}>
        {page}
      </Pagination.Item>,
    )
  }

  return (
    <Pagination
      size="sm"
      className={`d-flex flex-row align-items-center justify-content-end ${
        classNames || 'mb-3'
      }`}>
      <Pagination.Prev
        onClick={() => handlePage(currentPage - 1)}
        disabled={currentPage === 1}>
        <FontAwesomeIcon icon="chevron-left" color="black-light" />
      </Pagination.Prev>
      {currentPage > 5 && (
        <Pagination.Ellipsis
          onClick={() => handlePage(currentPage - 5)}
          disabled={currentPage < 6}
        />
      )}

      <div className="btn-rounded-sm bg-grey d-flex flex-row flex-wrap">
        {pages.filter(
          (item) =>
            Number(item.key) === currentPage ||
            Number(item.key) === currentPage - 4 ||
            Number(item.key) === currentPage + 4 ||
            Number(item.key) === currentPage - 3 ||
            Number(item.key) === currentPage + 3 ||
            Number(item.key) === currentPage - 2 ||
            Number(item.key) === currentPage + 2 ||
            Number(item.key) === currentPage - 1 ||
            Number(item.key) === currentPage + 1,
        )}
      </div>

      {currentPage < nbPages - 4 && (
        <Pagination.Ellipsis
          onClick={() => handlePage(currentPage + 5)}
          disabled={currentPage > nbPages - 5}
        />
      )}
      <Pagination.Next
        onClick={() => handlePage(currentPage + 1)}
        disabled={currentPage === nbPages}>
        <FontAwesomeIcon icon="chevron-right" color="black-light" />
      </Pagination.Next>
    </Pagination>
  )
}

PaginationCustom.getData = (items, currentPage, itemsPerPage) => {
  const start = currentPage * itemsPerPage - itemsPerPage
  return items.slice(start, start + itemsPerPage)
}

PaginationCustom.propTypes = {
  classNames: PropTypes.string,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
}

export default PaginationCustom
