import React from 'react'
import PropTypes from "prop-types";

import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Logo from "../logos/Logo";
import PasswordFormAlert from "../form/login/PasswordFormAlert";

const LoginFormPasswordLayout = ({form, alert}) => {
  return (
    <div className="login bg-white-dark">
      <Container className="pt-5 pb-sm-5 ">
        <div className="text-center mb-5">
          <Logo />
        </div>
        <Row>
          <Col
            md={{span: 10, offset: 1}}
            xl={{span: 8, offset: 2}}
            className="col-hd-6 offset-hd-3 login">
            <div className="bg-primary" style={{height: '50px'}}>
            </div>
            <div className="px-3 pb-4 bg-white pt-4">
              <Row className="d-flex flex-column">
                <Col
                  lg={{span: 10, offset: 1}}
                  md={{span: 10, offset: 1}}
                  xs={{span: 12}}
                >
                  <h1 className="text-center" style={{fontSize: '1.9rem'}}>
                    Modifier mon mot de passe
                  </h1>
                  {alert}
                  {form}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

LoginFormPasswordLayout.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  alert: PropTypes.instanceOf(PasswordFormAlert),
}

export default LoginFormPasswordLayout
