import ExtranetStateApi from "../services/ExtranetStateApi";

export const EXTRANET_STATE_WAITING_RESPONSE = 2;
export const EXTRANET_STATE_WAITING_WITHOUT_RINGING = 20;
export const EXTRANET_STATE_EXEC_REFUSED = 3;
export const EXTRANET_STATE_EXEC_ACCEPTED = 4;
export const EXTRANET_STATE_DELIVERING = 5;
export const EXTRANET_STATE_DELIVERED = 6;
export const STATE_REFUSED_BY_CUSTOMER_AFTER_DELIVERY_NOTIFICATION = 80;

export const EXTRANET_OPEN_HOUR = 9;
export const EXTRANET_CLOSING_HOUR = 18;

/**
 * @returns {
 *  {
 *     extranetStateIsDeliverable: (function(string): boolean),
 *     getExtranetStateById: ((function(string): Promise<*|null>)|*)
 *  }
 * }
 */
export function useExtranetState() {

    /**
     * @param {string} ident
     *
     * @returns {Promise<*|null>}
     */
    const getExtranetStateById =  async (ident) => {
        try {
            const {data: {actions}} = await ExtranetStateApi.getExtranetStates()

            return actions[`statut_${ident}`]
        } catch (e) {
            console.log(new Error(e))
        }

        return null
    }

    /**
     * @param {string} extranetStateId
     *
     * @returns {boolean}
     */
    const extranetStateIsDeliverable = (extranetStateId) => {
        const parentId = parseInt(extranetStateId.toString()[0], 10);

        return ![
            EXTRANET_STATE_EXEC_REFUSED,
            STATE_REFUSED_BY_CUSTOMER_AFTER_DELIVERY_NOTIFICATION,
            EXTRANET_STATE_DELIVERED,
            EXTRANET_STATE_DELIVERING
        ].includes(parentId);
    }


    return {getExtranetStateById, extranetStateIsDeliverable}
}