import React, {useContext, useEffect, useState} from "react";
import Image from "react-bootstrap/Image";
import {useDispatch} from "react-redux";
import {isDesktop, isIOS, isMacOs} from "react-device-detect";
import CommandeAPI from "../../services/CommandeAPI";
import AuthContext from "../../contexts/AuthContext";
import AlertCustom from "../../components/ui/alert/AlertCustom";
import ExecutionsAPI from "../../services/ExecutionsAPI";
import {RefusalForm} from "../../components/pages/executions/RefusalForm";
import {useStoreExecutions} from "../../store/useStoreExecutions";
import {base64} from "../../Tools/Tools";
import {configureShoppersModals} from "../../slice/shoppersModalsSlice";
import {useStoreExecutionsAccepted} from "../../store/useStoreExecutionsAccepted";

export const useExecutions = () => {
    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [modalContent, setModalContent] = useState(false);
    const [showModalInformation, setShowModalInformation] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const [modalSize, setModalSize] = useState('sm');
    const [currentOrder, setCurrentOrder] = useState(false);
    const [buttons, setButtons] = useState([]);
    const getExecutions = useStoreExecutions((state) => state.getExecutions);
    const executions = useStoreExecutions((state) => state.executions);
    const dispatch = useDispatch();
    const getExecutionsAccepted = useStoreExecutionsAccepted(state => state.getExecutionsAccepted);

    const handleShowOrder = async (id) => {
        setIsLoading(true);
        await CommandeAPI.getPDF(user.CodeFl, id)
            .then(({data}) => {
                switch (data) {
                    case null:
                        setShowModalInformation(true);

                        setModalContent((
                            <AlertCustom
                                variant={'warning'}
                                heading={false}
                                bodyContent={`Il n'y a actuellement aucun élément à afficher.`}
                                transition={true}
                            />
                        ))
                        break;
                    case false:
                        setShowModalInformation(true);
                        setModalContent((
                            <AlertCustom
                                variant={'danger'}
                                heading={data !== null}
                                bodyContent={`Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`}
                                transition={true}
                            />
                        ))
                        break;
                    default:
                        if (isMacOs || isIOS) {
                            window.open(`${process.env.REACT_APP_FRONT_HOST}${data}`, '_blank');
                        } else if (isDesktop) {
                            window.open().location = `${process.env.REACT_APP_FRONT_HOST}${data}`;
                        } else {
                            const link = document.createElement('a');
                            const url = `${process.env.REACT_APP_FRONT_HOST}/${data}`
                            const urlInformations = url.split('/');
                            const fileName = urlInformations[urlInformations.length - 1];
                            link.href = url;
                            link.setAttribute('download', fileName);
                            link.click();
                        }
                        break;
                }
            })

        setIsLoading(false);
    }

    const handleRefusal = async (order) => {
        await ExecutionsAPI.getRefus().then(function(response) {
            if (response.data) {
                setModalTitle(`Motif de refus de la commande ${((order.item && order.item.numcde) || order.id)}`);
                setModalContent(<RefusalForm refus={response.data} order={order} setShowModal={setShowModalInformation} setModalContent={setModalContent} />);
                setModalSize('md');
                setShowModalInformation(true);
                setButtons([
                    {
                        text: 'Enregistrer',
                        formId: 'refusalForm',
                        type: 'submit',
                    }
                ])
            }
        })
    }

    const handleState = async (order) => {
        try {
            await ExecutionsAPI.postExecutions(user.CodeFl, {
                etatextranet: parseInt(order.item.actions[order.id].id, 10),
                etatextranetlibelle: order.item.actions[order.id].label,
                idsgribouil: order.item.idsgribouil,
                numcde: order.item.numcde,
            }).then(() => {
                getExecutions(user, true, false);

                // Si la commande est acceptée, on recharge le tableau des commandes acceptées
                if (parseInt(order.item.actions[order.id].id, 10) === 4) {
                    getExecutionsAccepted(user, true);
                }
            });
        } catch (error) {
            setModalContent((
                <AlertCustom
                    variant={'danger'}
                    heading={true}
                    bodyContent={error.response.data.detail}
                    transition={true}
                />
            ));
        }

    }

    const handleViewPic = async (picture) => {
        setModalContent((
            <div className="mb-3">
                <Image
                    onError={(e) => {
                        e.target.src =
                            'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                    }}
                    src={picture}
                    className="rounded-xl img-fluid mx-auto"
                />
            </div>
        ))
    }

    useEffect(() => {
        if (executions.length) {
            const chosenPrebooks = {}

            executions.forEach((execution) => {
                if (execution.prebook && execution.prebook.mission) {
                    chosenPrebooks[base64(execution.prebook.id)] = execution.prebook
                }
            })

            dispatch(configureShoppersModals({chosenPrebooks}))
        }
    }, [executions])

    return {
        handleShowOrder,
        handleRefusal,
        handleState,
        handleViewPic,
        isLoading,
        modalContent,
        showModalInformation,
        setShowModalInformation,
        modalTitle,
        modalSize,
        buttons
    }
}