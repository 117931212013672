import React, {useEffect, useState} from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Col from "react-bootstrap/Col";
import $ from "jquery"

import BandeauxAPI from '../../../services/BandeauxAPI'
import {BannerContent} from "./BannerContent";
import {useMercureSubscriber} from "../../../hooks/useMercureSubscriber";

const Bandeaux = () => {
  const [banners, setBanners] = useState([]);
  const {result: newBanners} = useMercureSubscriber(['/banners/news'], false);

  useEffect(() => {
    setBannersInSession();

    if (newBanners.data) {
      setBannersInSession(true);
    }
  }, [newBanners])

  async function setBannersInSession(forceReload = false) {
    if (sessionStorage.getItem('banners') === null || forceReload) {
      const result = await BandeauxAPI.getBandeaux();
      sessionStorage.setItem('banners', '{}');

      if (result.data && result.data.length > 0) {
        sessionStorage.setItem('banners', JSON.stringify(result.data));
        setBanners(JSON.parse(sessionStorage.getItem('banners')));
      }
    }
  }

  // TODO : A supprimer après mise en place de Varnish (début)
  useEffect(() => {
    if (sessionStorage.getItem('holiday_current') !== null || !!sessionStorage.getItem('holiday_current') !== false) {
      setBanners(JSON.parse(sessionStorage.getItem('banners')));
    }
  }, [sessionStorage]);
  // TODO : A supprimer après mise en place de Varnish (fin)

  function changeWidthMessages() {
    const messages = $('#layout-header .info');
    $(messages).addClass('col-lg-12').removeClass('col-lg-7');
  }

  return (
    <>
      {/* {isLoading && <Loader />}

      {!isLoading && banners && banners.length === 0 && (changeWidthMessages())} */}

      {banners && banners.length > 0 && (
        <>
          <Col lg={6} className="advise d-lg-block d-md-none order-sm-last order-first my-auto">
            {banners.length > 1
              ? (
                    <Carousel fade controls={false} slide={false} indicators={false}>
                      {banners.map((banner) => (
                          <Carousel.Item key={banner.id}>
                            <BannerContent banner={banner} />
                          </Carousel.Item>
                      ))}
                    </Carousel>
                )
              :
                banners.map((banner) => (
                    <div key={banner.id}>
                      <BannerContent banner={banner} />
                    </div>
                ))
            }
          </Col>
        </>
      )}
    </>
  )
}

export default Bandeaux
