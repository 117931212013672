import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {isBool} from '../Tools/TypeOf'
import {base64} from '../Tools/Tools'
import ShopperApi from '../services/ShopperApi'

const initialState = {
  loading: false,
  currentPrebookNumcde: '',
  execution: null,
  prebookListShow: false,
  cancellationShow: false,
  cancelled: false,
  error: false,
  errorText: '',
  cancelBtnClass: 'bg-red',
  chosenPrebooks: {},
  cancelledPrebooks: [],
  minimumDeliveryTimestamp: null,
  deliveryDate: null,
  prebookSteps: {
    false: 'choseDeliveryDate',
    true: 'chosePrebookTab',
  },
}

export const fetchGlobalConfig = createAsyncThunk(
  'fetchGlobalConfig',
  async () => {
    return ShopperApi.getGlobalConfig()
  },
)

export const shoppersModalsSlice = createSlice({
  name: 'shoppersModals',
  initialState,
  reducers: {
    resetShoppersModalState: (state, {payload: {preserveChosenPrebooks}}) =>
      (preserveChosenPrebooks)
        ? {...initialState, ...{chosenPrebooks: state.chosenPrebooks}}
        : initialState
    ,
    showShoppersModal: (state, {payload: {show, currentPrebookNumcde}}) => {
      state.prebookListShow = show
      state.currentPrebookNumcde = currentPrebookNumcde
    },
    showCancellationShoppersModal: (
      state,
      {payload: {show, currentPrebookNumcde}},
    ) => {
      return {
        ...state,
        ...{
          cancellationShow: show,
          currentPrebookNumcde:
            currentPrebookNumcde || state.currentPrebookNumcde,
        },
      }
    },
    addChosenPrebook: (state, {payload: {prebook}}) => {
      state.chosenPrebooks[base64(prebook.id)] = prebook
    },
    removeChosenPrebook: (state, {payload: {prebook}}) => {
      delete state.chosenPrebooks[base64(prebook.id)]
    },
    configureShoppersModals: (state, {payload}) => {

      return {
        ...state,
        ...payload,
        ...{
          show: isBool(payload.show) ? payload.show : state.show,
          cancellationShow: isBool(payload.cancellationShow)
            ? payload.cancellationShow
            : state.cancellationShow,
          loading: isBool(payload.loading) ? payload.loading : state.loading,
          error: isBool(payload.error) ? payload.error : state.error,
          deliveryDate: Object.prototype.hasOwnProperty.call(
            payload,
            'deliveryDate',
          )
            ? payload.deliveryDate
            : state.deliveryDate,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGlobalConfig.fulfilled,
      (state, {payload: {deliveryDatetimeDelay}}) => {
        state.minimumDeliveryTimestamp = deliveryDatetimeDelay
      },
    )
  },
})

export const {
  showShoppersModal,
  showCancellationShoppersModal,
  resetShoppersModalState,
  addChosenPrebook,
  removeChosenPrebook,
  configureShoppersModals,
} = shoppersModalsSlice.actions

export const selectShoppersModals = (state) => state.shoppersModals

export default shoppersModalsSlice.reducer
