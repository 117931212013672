class Text {
  static CapitalizeAll = (text) => {
    return text && text.toUpperCase()
  }

  static CapitalizeFirst = (text) => {
    return text && text.charAt(0).toUpperCase() + text.slice(1)
  }

  static RemoveLastChar = (text) => {
    return text && text.slice(0, -1)
  }
}

export default Text
