import React from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'

import Select from '../form/Select'

const PaginationSelect = ({
  currentPage,
  itemsPerPage,
  totalPages,
  handlePage,
}) => {
  const nbPages = Math.ceil(totalPages / itemsPerPage)
  const pages = []
  for (let page = 1; page <= nbPages; page += 1) {
    pages.push(page)
  }

  return (
    <Col xs={12} className="d-flex justify-content-end mb-3">
      <Select
        controlId="paginationSelect"
        label="Aller à la page"
        classNamesForm="mb-0"
        defaultValue={currentPage || 1}
        onChange={handlePage}
        options={
          pages &&
          pages.map((page, index) => (
            <option key={index} id={page}>
              {page}
            </option>
          ))
        }
      />
    </Col>
  )
}

PaginationSelect.propTypes = {
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemsPerPage: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default PaginationSelect
