import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AlertCustom from '../../ui/alert/AlertCustom'
import Loader from '../../ui/loader/Loader'
import PresentationAPI from '../../../services/PresentationAPI'
import useFetch from '../../../hooks/useFetch'

const LoginPresentation = () => {
  const [{data: presentation, isLoading, isError}] = useFetch(
    PresentationAPI.getPresentation(),
  )

  return (
    <div className="px-3 py-4">
      <Row>
        <Col md={{span: 10, offset: 1}}>
          <Row className="no-gutters">
            <h2>Présentation</h2>

            <Col xs={12}>
              {isLoading && <Loader />}

              <AlertCustom
                heading={isError.heading}
                variant={isError.variant}
                bodyIcon={isError.icon}
                bodyContent={isError.content}
                bodyContentBold={isError.message}
                bodyContentItalic={isError.detail}
                transition={!!isError}
              />

              {!isLoading && !isError && presentation && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: presentation.content,
                  }}></div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LoginPresentation
