import React from 'react'
import PropTypes from 'prop-types'
import {Tabs} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import {useDispatch, useSelector} from 'react-redux'

import ModalCustom from './ModalCustom'
import ShoppersListGroup from '../ListGroup/ShoppersListGroup'
import {useOrderSimple} from '../../../hooks/useOrder'
import {Heading} from '../page/Title'
import CancellationModal from '../../pages/shoppers/CancellationModal'
import DeliveryDateForm from '../form/shopper/DeliveryDateForm'
import {format} from '../../../Tools/Date'
import {
  configureShoppersModals,
  selectShoppersModals,
  showShoppersModal,
} from '../../../slice/shoppersModalsSlice'

const ShopperModals = ({handleCancellationModal, executions}) => {
  const dispatch = useDispatch()
  const {
    currentPrebookNumcde,
    prebookListShow: show,
    prebookSteps,
    deliveryDate,
  } = useSelector(selectShoppersModals)

  /** Ferme la modale permettant de choisir un prebook */
  const handleShopperPrebookOnHide = () => {
    dispatch(
      showShoppersModal({
        show: false,
        currentPrebookNumcde,
      }),
    )
  }

  /**
   * Affiche les étapes pour sélectionner un prebook:
   *  - 1 : séléction de la date de livraison
   *  - 2 : choix du shopper
   *
   * @returns {JSX.Element}
   */
  const prebooksModalContent = () => {
    const {getOrder} = useOrderSimple(executions)
    const exec = getOrder(currentPrebookNumcde)

    /**
     * Vide la propriete deliveryDate de l'etat shopperModalState
     *
     * @param eventKey
     */
    const tabsOnSelect = (eventKey) => {
      if (eventKey === prebookSteps.false) {
        dispatch(
          configureShoppersModals({
            deliveryDate: null,
            loading: true,
          }),
        )
      }
    }

    return (
      <Tabs
        id={'chosePrebookTabs'}
        defaultActiveKey={prebookSteps.false}
        activeKey={prebookSteps[Boolean(deliveryDate).toString()]}
        onSelect={(eventKey) => {
          tabsOnSelect(eventKey)
        }}
        fill>
        {exec && (
          <Tab
            eventKey={prebookSteps.false}
            title={
              <Heading
                title="1: Sélectionner une date"
                headingLevel={5}
                className="m-0"
              />
            }
            tabClassName={`${(!deliveryDate && 'bg-primary') || ''} border-0 w-100`}>
            <div className="bg-grey-light p-4 rounded col col-md-6 mt-3 mx-auto">
              <DeliveryDateForm execution={exec} />
            </div>
          </Tab>
        )}

        {exec && deliveryDate && (
          <Tab
            eventKey={prebookSteps.true}
            title={
              <Heading
                title="2: Sélectionner un livreur"
                headingLevel={5}
                className="m-0"
              />
            }
            disabled={!deliveryDate}
            tabClassName={`${deliveryDate && 'bg-primary'} border-0 w-100`}>
            <h5 className="alert alert-warning text-center my-2 mx-auto py-1 col col-md-6">
              Commande: {currentPrebookNumcde}
              <br />
              Date de livraison:&nbsp;
              {deliveryDate && format(deliveryDate, 'dd/MM/yyyy HH:mm')}
            </h5>

            <ShoppersListGroup
              numcde={currentPrebookNumcde}
              execution={exec}
              deliveryDate={deliveryDate}
              handleShopperPrebookOnHide={handleShopperPrebookOnHide}
            />
          </Tab>
        )}
      </Tabs>
    )
  }

  return (
    <>
      <ModalCustom
        id={'shopperPrebookModal'}
        title="Choisir un livreur"
        modalClassname={'overflow visible'}
        show={show}
        titleClassname="text-center mb-3"
        bodyClassname="text-left"
        footer={true}
        buttonsFooter={[{text: 'Fermer'}]}
        onHide={handleShopperPrebookOnHide}
        content={(() => prebooksModalContent())()}
      />

      <CancellationModal
        handleCancellationModal={handleCancellationModal}
        executions={executions}
      />
    </>
  )
}

ShopperModals.propTypes = {
  handleCancellationModal: PropTypes.func.isRequired,
  executions: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired,
  ]),
}

export default ShopperModals
