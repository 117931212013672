import {base64} from './Tools'

export const REQUEST_STATUS_PENDING = 'pending'
export const REQUEST_STATUS_FULFILLED = 'fulfilled'
export const REQUEST_STATUS_REJECTED = 'rejected'

export function builderAddMatchers(builder, asyncThunks) {
  for (const asyncThunkProp in asyncThunks) {
    if (Object.prototype.hasOwnProperty.call(asyncThunks, asyncThunkProp)) {
      builder.addMatcher(asyncThunks[asyncThunkProp], (state, {type}) => {
        const requestStatusRegex = new RegExp(
          `/(${REQUEST_STATUS_PENDING}|${REQUEST_STATUS_REJECTED}|${REQUEST_STATUS_FULFILLED})`,
        )

        if (requestStatusRegex.test(type)) {
          const [, requestStatus] = type.match(requestStatusRegex)

          state.requestStatus = requestStatus
          state.isLoading = requestStatus === REQUEST_STATUS_PENDING
        }
      })
    }
  }
}

export function convertObjectKeysToBase64(toConvert, identKey) {
  const data = {}

  toConvert.forEach((value) => {
    data[base64(value[identKey])] = value
  })

  return data
}
