import {configureStore} from '@reduxjs/toolkit'
import perimetreReducer from './slice/perimetreSlice'
import perimetrePageReducer from './slice/perimetrePageSlice'
import communeSearchReducer from './slice/communeSearchSlice'
import shoppersReducer from './slice/shoppersSlice'
import shoppersModalsReducer from './slice/shoppersModalsSlice'
import sideMenuReducer from './slice/sideMenuSlice'
import executionReducer from './slice/executionSlice'

export default configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    perimetre: perimetreReducer,
    perimetrePage: perimetrePageReducer,
    communeSearch: communeSearchReducer,
    shoppers: shoppersReducer,
    shoppersModals: shoppersModalsReducer,
    executions: executionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}),
})
