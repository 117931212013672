export const SIDE_MENU_MON_FLORAJET = {
  stateName: 'activeAccordion1',
  eventKey: '1',
  defaultActiveKey: '1',
}

export const SIDE_MENU_MES_COMMANDES = {
  stateName: 'activeSubAccordion1',
  eventKey: '1',
  defaultActiveKey: SIDE_MENU_MON_FLORAJET.eventKey,
  subAccordionDefaultKey: '1',
}

export const SIDE_MENU_MON_COMPTE = {
  stateName: 'activeSubAccordion2',
  eventKey: '5',
  defaultActiveKey: SIDE_MENU_MON_FLORAJET.eventKey,
  subAccordionDefaultKey: '5',
}

export const SIDE_MENU_BOUTIQUE = {
  stateName: 'activeSubAccordion3',
  eventKey: '12',
  defaultActiveKey: SIDE_MENU_MON_FLORAJET.eventKey,
  subAccordionDefaultKey: '12',
}

export const SIDE_MENU_MES_OUTILS = {
  stateName: 'activeAccordion2',
  eventKey: '15',
  defaultActiveKey: '2',
}

export const SIDE_MENU_AIDE = {
  stateName: 'activeAccordion3',
  eventKey: '19',
  defaultActiveKey: '3',
}

export const SIDE_MENU_SOFTWARE = {
  stateName: 'activeSubAccordionSoftware',
  eventKey: '25',
  defaultActiveKey: SIDE_MENU_AIDE.eventKey,
  subAccordionDefaultKey: '1',
}

export const SIDE_MENU_PARAMETRES = {
  stateName: 'activeAccordion4',
  eventKey: '23',
  defaultActiveKey: '4',
}

export const getAccordionStateNames = () => {
  return [
    SIDE_MENU_MON_FLORAJET,
    SIDE_MENU_MES_OUTILS,
    SIDE_MENU_AIDE,
    SIDE_MENU_PARAMETRES,
  ].map((constant) => {
    return constant.stateName
  })
}

export const getSubAccordionStateNames = () => {
  return [
    SIDE_MENU_MES_COMMANDES,
    SIDE_MENU_MON_COMPTE,
    SIDE_MENU_BOUTIQUE,
    SIDE_MENU_SOFTWARE,
  ].map((constant) => {
    return constant.stateName
  })
}

export const getConstantByStateName = (stateName) => {
  let constant = null

  const constants = [
    SIDE_MENU_MON_FLORAJET,
    SIDE_MENU_MES_OUTILS,
    SIDE_MENU_AIDE,
    SIDE_MENU_PARAMETRES,
    SIDE_MENU_MES_COMMANDES,
    SIDE_MENU_MON_COMPTE,
    SIDE_MENU_BOUTIQUE,
    SIDE_MENU_SOFTWARE,
  ]

  constants.forEach((sideMenuConst) => {
    if (sideMenuConst.stateName === stateName) {
      constant = sideMenuConst
    }
  })

  return constant
}
