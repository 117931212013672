import React, {useEffect, useReducer} from 'react'
import Col from 'react-bootstrap/Col'
import {isMobile} from "react-device-detect";

import AlertCustom from '../../ui/alert/AlertCustom'
import Count from '../../ui/page/Count'
import DocumentationAPI from '../../../services/DocumentationAPI'
import DocumentationReducer from '../../../reducers/DocumentationReducer'
import {ITEMS_PER_PAGE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import Select from '../../ui/form/Select'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'

const Documentation = () => {
  const [state, dispatch] = useReducer(DocumentationReducer, {
    documentsWithCategs: [],
    currentPage: 1,
    filter: '',
    sortType: 'dateCreation',
    sortOrder: 'desc',
    modalShow: false,
    url: '',
    mimetype: '',
    reload: false,
  })

  const [
    {data: documents, totalItems, isLoading, isError},
    fetchData,
  ] = useFetch(
    DocumentationAPI.getDocuments(
      state.filter,
      state.sortType,
      state.sortOrder,
      state.currentPage,
    ),
  )

  const [{data: categories}] = useFetch(
    DocumentationAPI.getCategoriesDocuments(),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (state.reload)
      fetchData(
        DocumentationAPI.getDocuments(
          state.filter,
          state.sortType,
          state.sortOrder,
          state.currentPage,
        ),
      )
  }, [state.filter, state.sortType, state.sortOrder, state.currentPage])

  // Gestion de la jointure catégorieDocuments
  // (libellé catégorie à ajouter dans les documents)
  useEffect(() => {
    if (documents && documents.length && categories) {
      const joinedDocs = documents.map((d) => ({
        ...categories.find((c) => c.id === d.idCategorie && c),
        ...d,
      }))

      dispatch({
        type: 'HANDLE_DOCUMENTS_WITH_CATEGS',
        documentsWithCategs: joinedDocs,
      })
    } else {
      dispatch({
        type: 'HANDLE_DOCUMENTS_WITH_CATEGS',
        documentsWithCategs: [],
      })
    }
  }, [documents, categories])

  // Gestion du changement de page
  const handlePage = (page) => {
    dispatch({
      type: 'HANDLE_PAGE_CHANGE',
      currentPage: page,
      reload: true,
    })
  }

  // Gestion du changement de filtre
  const handleSortType = (event) => {
    const {selectedIndex} = event.target.options
    dispatch({
      type: 'HANDLE_FILTER_CHANGE',
      currentPage: 1,
      filter: event.target.options[selectedIndex].getAttribute('id'),
      reload: true,
    })
  }

  // Gestion du changement de tri
  const handleSortOrder = (order) => {
    let sort
    if (state.sortType !== order) {
      sort = 'asc'
    } else {
      sort = state.sortOrder === 'asc' ? 'desc' : 'asc'
    }
    dispatch({
      type: 'HANDLE_SORT_CHANGE',
      currentPage: 1,
      sortOrder: sort,
      sortType: order,
      reload: true,
    })
  }

  // Gestion du changement de pdf à afficher en modal
  const handleModalFile = ([url, mime]) => {
    dispatch({
      type: 'HANDLE_MODAL',
      modalShow: true,
      url: `${process.env.REACT_APP_FRONT_HOST}${url}`,
      mimetype: mime,
    })
  }

  return (
    <>
      <Title title="Liste des documents" />

      <Col xs={12}>
        <Col className="p-0">
          <div className="d-flex flex-row flex-wrap align-items-end align-items-sm-center justify-content-between">
            <Select
              controlId="filterSelect"
              label="Filtrer par groupe"
              classNamesForm="mr-2 mb-3"
              defaultValue={
                state.filter &&
                categories &&
                categories.filter((c) => c.id === parseInt(state.filter, 10))
              }
              onChange={handleSortType}
              options={
                <>
                  <option value="">Aucun filtre</option>
                  {categories &&
                    categories.map((item, index) => (
                      <option key={index} id={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                </>
              }
            />
            <Count totalItems={totalItems} name="document" classNames="mb-3" />
          </div>

          <TableCustom
            isLoading={isLoading}
            isError={isError}
            full={!isMobile}
            headers={[
              'GROUPE',
              'TITRE',
              'DATE',
              'NOM',
              'DESCRIPTION',
              'ACTION',
            ]}
            sorting={[
              'idCategorie',
              'titre',
              'dateCreation',
              'fichier',
              'description',
            ]}
            handleSortOrder={handleSortOrder}
            sortType={state.sortType}
            sortOrder={state.sortOrder}
            data={state.documentsWithCategs}
            config={[
              {type: 'text', key: 'libelle'},
              {type: 'text', key: 'titre', required: true},
              {type: 'date', key: 'dateCreation', required: true},
              {type: 'link-download', key: [{text: 'fichier', href: 'url', download: true, name: 'fichier'}]},
              {type: 'text', key: 'description'},
              {
                type: 'buttons',
                required: true,
                key: [
                  {
                    name: 'Visualiser',
                    color: 'primary',
                    icon: 'eye',
                    iconColor: 'white',
                    func: handleModalFile,
                    funcParam: ['url', 'mimeType'],
                  },
                ],
                view: true,
              },
            ]}
          />

          {isLoading && <Loader />}

          <AlertCustom
            heading={isError.heading}
            variant={isError.variant}
            bodyIcon={isError.icon}
            bodyContent={isError.content}
            bodyContentBold={isError.message}
            bodyContentItalic={isError.detail}
            transition={!!isError}
          />

          {!isLoading && ITEMS_PER_PAGE < totalItems && (
            <PaginationCustom
              currentPage={state.currentPage || 1}
              itemsPerPage={ITEMS_PER_PAGE}
              totalPages={totalItems}
              handlePage={handlePage}
            />
          )}

          <ModalCustom
            show={state.modalShow}
            onHide={() =>
              dispatch({
                type: 'HANDLE_MODAL',
                modalShow: false,
                url: '',
                mimetype: ''
              })
            }
            id="modal-pdf"
            bodyClassname="p-3"
            content={
              (state.mimetype && state.mimetype.match(/jpeg|png|gif|svg|webp/)) && (
                  <img src={state.url} className="mb-3" />
              ) || (
                <div className="embed-responsive embed-responsive-1by1 mb-3">
                  <embed src={state.url} type={state.mimetype} />
                </div>
              )
            }
            buttons={[
              {
                text: 'Fermer',
              },
            ]}
          />
        </Col>
      </Col>
    </>
  )
}

export default Documentation
