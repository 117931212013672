import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {BrowserView, MobileView} from "react-device-detect";
import React, {useState} from "react";
import Title from "../../ui/page/Title";
import {ExecutionsTab} from "./ExecutionsTab";
import {ExecutionsFilters} from "./ExecutionsFilters";
import Box from "../../ui/box/Box";
import {TabMenu} from "../../ui/menu/TabMenu";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import Count from "../../ui/page/Count";
import {ExecutionsCards} from "./ExecutionsCards";
import {ExecutionsInformations} from "./ExecutionsInformations";

export const ExecutionsNew = () => {
    const type = useStoreExecutions(state => state.type);
    const setType = useStoreExecutions(state => state.setType);
    const isLoading = useStoreExecutions(state => state.isLoading);
    const executionsCount = useStoreExecutions(state => state.executionsCount);
    const [types] = useState([
        {type: 'pending', title: 'En cours', pathname: 'executions'},
        {type: 'today', title: `Aujourd'hui`, pathname: 'executions'},
        {type: 'yesterday', title: 'Hier', pathname: 'executions'},
        {type: 'last-week', title: 'Semaine dernière', pathname: 'executions'},
        {type: 'last-month', title: 'Mois dernier', pathname: 'executions'},
        {type: 'by-last-days', title: '30 derniers jours', pathname: 'executions'},
        {type: 'search', title: 'Recherche', pathname: 'executions'},
    ]);

    return (
        <>
            <Title title={'Mes exécutions'} />
            <Col xs={12}>
                <ExecutionsFilters />
                <Row>
                    <Col xs={12}>
                        <ExecutionsInformations />
                        <Box
                            boxName={'executions'}
                            boxContentBg={'white'}
                            content={
                                <>
                                    <BrowserView renderWithFragment={true}>
                                        <TabMenu
                                            currentTab={type}
                                            tabs={types}
                                            setCurrentTabFunction={setType}
                                            isLoading={isLoading}
                                            numberOfElements={executionsCount}
                                        />
                                        <ExecutionsTab />
                                    </BrowserView>
                                    <MobileView renderWithFragment={true}>
                                        <TabMenu
                                            tabs={types}
                                            numberTabsToShow={3}
                                            setCurrentTabFunction={setType}
                                            currentTab={type}
                                        />

                                        {executionsCount > 0 && !isLoading && (
                                            <Count
                                                classNames="text-right p-2 bg-grey rounded mb-2"
                                                totalItems={executionsCount}
                                                name="exécution"
                                            />
                                        )}
                                        <ExecutionsCards />
                                    </MobileView>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </>
    );
}