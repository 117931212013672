import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PartnersAPI from "../../../services/PartnersAPI";
import useFetch from "../../../hooks/useFetch";
import AlertCustom from "../../ui/alert/AlertCustom";
import Title from "../../ui/page/Title";
import {Partner} from "./Partner";

export const Partners = () => {
  const [{data: partners, isLoading, isError}] = useFetch(PartnersAPI.getPartners());

  return (
      <>
          <div className={'container-fluid'}>
              <Row>
                  <Col>
                      <Title title={'Partenaires'} />
                  </Col>
              </Row>
              <Row>
                  <Col xs={12} className="d-flex justify-content-center p-0">
                      <AlertCustom
                          heading={isError.heading}
                          variant={isError.variant}
                          bodyIcon={isError.icon}
                          bodyContent={isError.content}
                          bodyContentBold={isError.message}
                          bodyContentItalic={isError.detail}
                          transition={!!isError}
                      />
                  </Col>
                  {!isLoading && !isError && partners.length > 0 && partners.map((item, index) => <Partner key={index} partner={item} />)}
              </Row>
          </div>
      </>
  );
}