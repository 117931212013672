import PropTypes from "prop-types";
import React from "react";
import {ButtonWithConfirmation} from "./ButtonWithConfirmation";

export function ButtonsWithConfirmation({item, buttons}) {
    return (
        <>
            {buttons.map((button, i) => {
                if (button.func) {
                    const func = () => button.func(
                        button.funcParam
                            ? button.funcParam instanceof Array
                                ? button.funcParam.map((param) => item[param])
                                : item[button.funcParam]
                            : {item, id: button.filterParam},
                    );

                    const funcToCheckBeforeConfirmation = () => button.funcToCheckBeforeConfirmation(
                        button.funcToCheckBeforeConfirmationParam
                            ? button.funcToCheckBeforeConfirmationParam instanceof Array
                                ? button.funcToCheckBeforeConfirmationParam.map((param) => item[param])
                                : item[button.funcToCheckBeforeConfirmationParam]
                            : {item, id: button.filterParam}
                    );

                    return (
                        <ButtonWithConfirmation
                            key={i}
                            button={button}
                            func={button.func && func}
                            funcToCheckBeforeConfirmation={button.funcToCheckBeforeConfirmation && funcToCheckBeforeConfirmation}
                        />
                    );
                }

                return (<></>);
            })}
        </>
    );
}

ButtonsWithConfirmation.propTypes = {
    item: PropTypes.object.isRequired,
    buttons: PropTypes.array.isRequired,
}