import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import TableCustom from "../../ui/table/TableCustom";
import {useStoreSavs} from "../../../store/useStoreSavs";
import AuthContext from "../../../contexts/AuthContext";
import {SavOrderModal} from "./SavOrderModal";
import {SavModal} from "./SavModal";
import {useActionsButtons} from "../../../hooks/savs/useActionsButtons";
import Loader from "../../ui/loader/Loader";

export const SavsTab = ({savs}) => {
    const {user} = useContext(AuthContext);
    const savsFromStore = useStoreSavs(state => state.savsFiltered);
    const sortType = useStoreSavs(state => state.sortType);
    const setSortType = useStoreSavs(state => state.setSortType);
    const sortOrder = useStoreSavs(state => state.sortOrder);
    const setSortOrder = useStoreSavs(state => state.setSortOrder);
    const isLoading = useStoreSavs(state => state.isLoading);
    const isError = useStoreSavs(state => state.isError);
    const getSavs = useStoreSavs(state => state.getSavs);
    // const [savsToDisplay, setSavsToDisplay] = useState(savsFromStore);
    const savsToDisplay = savs || savsFromStore;
    const {
        actionsButtons,
        orderNumber,
        setShowModalOrder,
        sav,
        setShowModalSav,
        showModalOrder,
        showModalSav,
        handleShowOrder
    } = useActionsButtons();

    const handleSortOrder = (newSortType) => {
        let sort = sortOrder === 'asc' ? 'desc' : 'asc';

        if (newSortType !== sortType) {
            sort = 'asc';
        }

        setSortOrder(sort);
        setSortType(newSortType);
        getSavs(user, true);
    }

    return (
        <>
            <TableCustom
                id={'savsList'}
                isLoading={isLoading}
                isError={isError}
                headers={[
                    'Statut',
                    'Etat',
                    'N° commande',
                    `Date d'ouverture`,
                    'Réclamant',
                    'Motif',
                    'Fautif',
                    'Actions',
                ]}
                sorting={[
                    '',
                    '',
                    'reclaNumcde',
                    'reclaDteopen',
                    'reclaPlaignant',
                    'reclaMotif',
                    'reclaQuifaute',
                ]}
                handleSortOrder={handleSortOrder}
                sortType={sortType}
                sortOrder={sortOrder}
                data={savs || savsFromStore}
                config={[
                    {type: 'picto', key: 'reclaFondee', sorting: 'statut'},
                    {type: 'picto', key: 'reclaCloture', sorting: 'etat'},
                    {
                        type: 'link_modal',
                        key: 'reclaNumcde',
                        func: handleShowOrder,
                        funcParam: 'reclaNumcde',
                    },
                    {type: 'date', key: 'reclaDteopen'},
                    {type: 'text', key: 'complainantLabel'},
                    {type: 'text', key: 'reclaMotif'},
                    {type: 'text', key: 'whoFault'},
                    {
                        type: 'buttons_sav',
                        key: actionsButtons,
                    },
                ]}
            />

            {isLoading && <Loader />}
            {orderNumber && (<SavOrderModal onHideModal={() => setShowModalOrder(false)} showModal={showModalOrder} orderNumber={orderNumber} />)}
            {sav && (<SavModal onHideModal={() => setShowModalSav(false)} sav={sav} showModal={showModalSav} />)}
        </>
    );
}

SavsTab.propTypes = {
    savs: PropTypes.array,
};