import React from 'react'

const FooterInfos = () => {
  return (
    <span className="fs-10">
      FLORAJET S.A.S - Réseau Fleuri - 26 chemin de l'Esqueiras 84240 Cabrières-d'Aigues | Téléphone : 0826 107 707 (0.15€ TTC/min) - Fax : 0821 237 247
      (0.12€ TTC/min)
    </span>
  )
}

export default FooterInfos
