import uuid from 'react-uuid'
import Shopper from './Shopper'

export default class Prebook {
  constructor(data) {
    this.id = data.id || uuid()
    this.idCreatedByShopper = data.idCreatedByShopper
    this.numcde = data.numcde
    this.price = data.price
    this.mission = data.mission
    this.aborted = data.aborted
    this.cancellable = data.cancellable
    this.expired = data.expired

    this.error = data.error || false
    this.expectedDeliveryDate = data.expectedDeliveryDate
    this.shopper = new Shopper(data.shopper)
  }

  hasMission() {
    return this.mission && !this.aborted
  }
}
