export default class Route {
  /**
   * Route class constructor.
   *
   * @param {string} path
   * @param {JSX.Element} element
   * @param {array|null} children
   */
  constructor(path: string, element: JSX.Element, children: [] = []) {
    this.path = path
    this.element = element
    this.children = children
  }

  /**
   * hasChildren
   *
   * @returns {boolean}
   */
  hasChildren() {
    return this.children.length > 0
  }

  /**
   * addRoutes
   *
   * @param {array} routes
   */
  addRoutes(routes: []) {
    this.children = [...this.children, ...routes]

    return this
  }

  serialize() {
    return {
      ...this,
      ...{
        children:
          (this.hasChildren() &&
            this.children.map((child) => child.serialize())) ||
          this.children,
      },
    }
  }
}
