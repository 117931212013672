import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import {SavModalBody} from "./SavModalBody";
import ModalCustom from "../../ui/modal/ModalCustom";

export const SavModal = ({showModal, onHideModal, sav}) => {
    return (
        <>
            <ModalCustom
                show={showModal}
                onHide={onHideModal}
                id={'modal-sav'}
                title={`Détails du SAV de la commande ${sav.reclaNumcde}`}
                titleClassname={'text-center mb-3'}
                bodyClassname={'text-left'}
                content={
                    <>
                        <Col>
                            <SavModalBody sav={sav} />
                        </Col>
                    </>
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Fermer',
                        func: () => onHideModal()
                    }
                ]}
            />
        </>
    );
}

SavModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHideModal: PropTypes.func.isRequired,
    sav: PropTypes.object.isRequired,
}
