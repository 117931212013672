import PropTypes from 'prop-types'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {getIn} from 'formik'
import {useLocation} from 'react-router-dom'
import Box from '../../ui/box/Box'
import Field from '../../ui/form/Field'
import Select from '../../ui/form/Select'
import FleuristesAPI from '../../../services/FleuristesAPI'
import AuthContext from '../../../contexts/AuthContext'
import Numbers from '../../../helpers/Numbers'
import {parseFloatCustom} from '../../../Tools/Numbers'
import ModalCustom from '../../ui/modal/ModalCustom'
import AlertCustom from '../../ui/alert/AlertCustom'

import 'react-datepicker/dist/react-datepicker.css'

export function PriceForm({formik, colXs, colSm}) {
  const {user} = useContext(AuthContext)
  const location = useLocation()
  const [isLoadingFlorist, setIsLoadingFlorist] = useState(false)
  const [selectedFlorist, setSelectedFlorist] = useState([
    {codefl: formik.values.codex},
  ])
  const [optionsFlorist, setOptionsFlorist] = useState([])
  const [totalAmout, setTotalAmout] = useState(
    parseFloat(formik.values.amountvat) +
      parseFloat(formik.values.transmissioncosts),
  )
  const defaultDeliveryFee = parseFloat(
    user.deliveryFees.max === '0.00'
      ? user.deliveryFees.min
      : user.deliveryFees.max,
  ).toFixed(2)
  const isForeignCountry = useMemo(
    () =>
      formik.values.receiver.countryLabel !== 'FRANCE' ||
      formik.values.receiver.country !== 'F',
    [formik.values.receiver.country, formik.values.receiver.countryLabel],
  )
  const [accessories, setAccessories] = useState([])
  const [showModalCodex, setShowModalCodex] = useState(false)

  useEffect(() => {
    if (location.state) {
      if (location.state.order.bubble) {
        setAccessories(accessories.concat(['bubble']))
      }

      if (location.state.order.ruban.length > 0) {
        setAccessories(accessories.concat(['ribbon']))
      }
    }
  }, [location])

  const handleSearchFlorist = useCallback((query) => {
    setIsLoadingFlorist(true)

    if (query.length > 2) {
      FleuristesAPI.getFloristsByFloristCodeAndExcludedContracts(query).then(
        (result) => {
          if (result.data) {
            setOptionsFlorist(result.data)
          }

          setIsLoadingFlorist(false)
        },
      )
    }
  }, [])

  function handleChangeComposition(event) {
    const newAmountVat = (
      parseFloat(event.target.value) +
      parseFloat(formik.values.hasRibbon ? 10 : 0) +
      parseFloat(formik.values.bubble ? 4 : 0)
    ).toFixed(2)
    formik.setFieldValue('amountvat', newAmountVat)
  }

  function enableDeliveryFees() {
    return user.deliveryFees.min === user.deliveryFees.max || isForeignCountry
  }

  function checkCodexIsValid(codex) {
    let isValid = false

    if (optionsFlorist) {
      for (let i = 0; i < optionsFlorist.length; i += 1) {
        if (
          optionsFlorist[i].codefl === codex ||
          optionsFlorist[i].magasin === codex
        ) {
          isValid = true
          break
        }
      }

      if (!isValid) {
        setShowModalCodex(true)
        setSelectedFlorist([{codefl: ''}])
      }
    }
  }
  
  // Changement des frais de transmissions lorsque le pays change
  useEffect(() => {
    formik.setFieldValue(
      'transmissioncosts',
      isForeignCountry
        ? parseFloat(user.deliveryFees.international).toFixed(2)
        : defaultDeliveryFee,
    )
  }, [isForeignCountry])

  // Changement du prix total
  useEffect(() => {
    const amountVat = formik.values.amountvat === '' ? 0.0 : formik.values.amountvat;
    const totalAmount = parseFloat(amountVat) + parseFloat(formik.values.transmissioncosts) + parseFloat(formik.values.totalAmountAccessories);
    setTotalAmout(totalAmount)
  }, [formik.values.amountvat, formik.values.transmissioncosts, formik.values.totalAmountAccessories])

  // Changement du prix des fleurs et de la formule
  useEffect(() => {
    if (formik.values.scompo.reference === '') {
      formik.setFieldValue('scompo.amountmini', 0)
      formik.setFieldValue('scompo.amountmax', '')
    }

    formik.setFieldValue('composition', formik.values.scompo.amountmini)
  }, [formik.values.scompo.reference])

  return (
    <>
      <Box
        boxXs={colXs}
        boxSm={colSm}
        boxName={'price'}
        headerClassNames={'rounded-top'}
        header={true}
        headerTextColor={'white'}
        headerContent={<h3 className="my-auto">Informations prix</h3>}
        headerPadding={'p-3'}
        boxContentPadding={'p-3'}
        content={
          <>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  id={'amountvat'}
                  name={'amountvat'}
                  label={`Montant fleurs`}
                  labelRequired={true}
                  value={formik.values.amountvat}
                  onChange={formik.handleChange}
                  onBlur={(event) => {
                    let {value} = event.target

                    if (value.length > 0 && value.includes(',')) {
                      value = parseFloatCustom(value, 2)
                    }

                    formik.setFieldValue('amountvat', value)
                  }}
                  prepend={'€'}
                  error={
                    getIn(formik.touched, 'amountvat') &&
                    getIn(formik.errors, 'amountvat')
                  }
                />
              </Col>
              <Col xs={12} sm={3}>
                <Field
                  id={'transmissioncosts'}
                  name={'transmissioncosts'}
                  label={'Frais de transmission'}
                  value={formik.values.transmissioncosts}
                  onChange={formik.handleChange}
                  prepend={'€'}
                  disabled={enableDeliveryFees()}
                  readOnly={enableDeliveryFees()}
                  min={user.deliveryFees.min}
                  max={user.deliveryFees.max}
                />
              </Col>
              <Col xs={12} sm={3}>
                <Field
                    id={'totalAmountAccessories'}
                    name={'totalAmountAccessories'}
                    label={'Montant accessoires'}
                    value={formik.values.totalAmountAccessories}
                    onChange={formik.handleChange}
                    prepend={'€'}
                    disabled={true}
                    readOnly={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Select
                  controlId={'vat'}
                  label={'TVA'}
                  name={'vat'}
                  value={formik.values.vat}
                  onChange={formik.handleChange}
                  options={
                    <>
                      <option value="">Sélectionner un taux</option>
                      <option value="10">10 %</option>
                      <option value="20">20 %</option>
					</>
                  }
                />
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId={'codex'}>
                  <Form.Label>Fleuriste pré-choisi</Form.Label>
                  <AsyncTypeahead
                    id={'codex'}
                    name={'codex'}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText={'Entrez votre recherche'}
                    searchText={'Chargement...'}
                    emptyLabel={'Aucun résultat'}
                    paginationText={'Afficher plus de résultats'}
                    labelKey={'codefl'}
                    placeholder={'Fleuriste pré-choisi'}
                    isLoading={isLoadingFlorist}
                    selected={selectedFlorist}
                    options={optionsFlorist}
                    onSearch={handleSearchFlorist}
                    useCache={false}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.codefl}</span>
                        <span> - {option.magasin}</span>
                      </>
                    )}
                    onBlur={(event) => checkCodexIsValid(event.target.value)}
                    onChange={(value) => {
                      setSelectedFlorist(value)
                      if (value.length > 0) {
                        formik.setFieldValue('codex', value[0].codefl)
                        formik.setFieldValue('codexLabel', value[0].magasin)
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {(formik.values.scompo.amountmini !== '' ||
                  formik.values.scompo.amountmax !== '') &&
                  formik.values.scompo.amountmini !==
                    formik.values.scompo.amountmax &&
                  formik.values.scompo.amountmini !== 0 && (
                  <>
                      <Form.Check
                        id={'composition-1'}
                        name={'composition'}
                        type={'radio'}
                        label={`${Numbers.ConvertToEuro(
                          formik.values.scompo.amountmini,
                        )} Formule Beaucoup`}
                        value={formik.values.scompo.amountmini}
                        defaultChecked={true}
                        onChange={(event) => {
                          handleChangeComposition(event)
                        }}
                      />
                      <Form.Check
                        id={'composition-2'}
                        name={'composition'}
                        type={'radio'}
                        label={`${Numbers.ConvertToEuro(
                          formik.values.scompo.amountmax,
                        )} Formule Énormément`}
                        value={formik.values.scompo.amountmax}
                        onChange={(event) => {
                          handleChangeComposition(event)
                        }}
                      />
                    </>
                  )}
              </Col>
            </Row>
            <Row>
              <Col className={'d-flex align-self-end justify-content-end'}>
                <h3 className={'font-weight-bold'}>
                  Total TTC : {Numbers.ConvertToEuro(totalAmout)}
                </h3>
              </Col>
            </Row>
            {formik.values.ruban !== '' && (
              <Row>
                <Col className={'d-flex align-self-end justify-content-end'}>
                  - Inclus ruban : {Numbers.ConvertToEuro(10)}
                </Col>
              </Row>
            )}
            {formik.values.bubble && (
              <Row>
                <Col className={'d-flex align-self-end justify-content-end'}>
                  - Inclus bulle d'eau : {Numbers.ConvertToEuro(4)}
                </Col>
              </Row>
            )}
            {[...formik.values.accessoriesMap.values()].map((value, index) => (
                <Row key={index}>
                  <Col className={'d-flex align-self-end justify-content-end'}>
                    - Inclus {value.item.label} {value.quantity > 1 ? `(x${value.quantity}) ` : ''}: {Numbers.ConvertToEuro(value.unitAmountVat * value.quantity)}
                  </Col>
                </Row>
            ))}
          </>
        }
      />
      <ModalCustom
        show={showModalCodex}
        id={'modal-codex'}
        onHide={() => setShowModalCodex(false)}
        bodyClassname={'p-3'}
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant="warning"
              heading={false}
              headingTitle="Information"
              headingIconName="info-circle"
              bodyContent={
                'Veuillez saisir le code adhérent du fleuriste préchoisi.'
              }
              transition={true}
              />
          </>
        }
        backdrop={'static'}
        footer={true}
        buttonsFooter={[
          {
            text: 'Fermer',
            func: () => setShowModalCodex(false),
          },
        ]}
      />
    </>
  )
}

PriceForm.propTypes = {
  formik: PropTypes.object.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
}
