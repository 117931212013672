import PropTypes from 'prop-types'
import React, {useState, useEffect, useContext} from 'react'
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import {CSSTransition} from 'react-transition-group'

import AlertCustom from '../../ui/alert/AlertCustom'
import {CartContext} from '../../../contexts/CartContext'
import Count from '../../ui/page/Count'
import Loader from '../../ui/loader/Loader'
import ModalCartItem from './ModalCartItem'
import ModalCustom from '../../ui/modal/ModalCustom'
import Numbers from '../../../helpers/Numbers'

const ModalCart = ({modalCartShow, setModalCartShow}) => {
  const {
    cartItems,
    handleCheckout,
    handleClear,
    handlePaymentMethod,
    cartTotalTTC,
    cartMontantHT,
    cartTVA,
    isLoading,
    setIsError,
    isError,
    cartDeliveries
  } = useContext(CartContext)
  const [modalShowClear, setModalShowClear] = useState(false)
  const [modalShowCheckout, setModalShowCheckout] = useState(false)

  // Gestion de la fermeture de la modal si panier vide
  useEffect(() => {
    if (!cartItems.length) {
      setModalCartShow(false)
    }
  }, [cartItems])

  // Gestion de la suppression du panier
  const handleClearCart = () => {
    handleClear()
    setModalShowClear(false)
    setModalCartShow(false)
  }

  // Gestion du message de validation du panier
  const handleCheckoutMessage = () => {
    setModalShowCheckout(false)

    if (!cartItems.length) {
      setModalCartShow(false)
    }

    setTimeout(() => {
      setIsError(false)
    }, 1000)
  }

  return (
    <>
      <ModalCustom
        show={modalCartShow}
        onHide={() => setModalCartShow(false)}
        id="modal-cart"
        size="md"
        bodyClassname="py-5 px-sm-5 text-left"
        content={
          <>
            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              unmountOnExit
              classNames="fade">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h1>Mon panier</h1>
                <Count totalItems={cartItems.length} name="article" />
                <Button
                    className="btn-rounded btn-grey-darker mr-3 mb-3"
                    onClick={() => setModalShowClear(true)}>
                  Vider le panier
                </Button>
              </div>
            </CSSTransition>

            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              unmountOnExit
              classNames="fade">
              <Row className="d-flex flex-column">
                {cartItems.map((item, index) => (
                  <ModalCartItem key={index} article={item} />
                ))}
              </Row>
            </CSSTransition>

            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              unmountOnExit
              classNames="fade">
              <div>
                <div className="d-flex flex-row align-items-center ml-auto mb-3">
                  <div className="fs-14 ff-bold mr-2">Montant total HT :</div>
                  <div className="fs-14 ff-bold">
                    {Numbers.ConvertToEuro(Number(cartMontantHT))}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center ml-auto mb-3">
                  <div className="fs-14 ff-bold mr-2">Estimation frais de port :</div>
                  <div className="fs-14 ff-bold">
                    {Numbers.ConvertToEuro(Number(cartDeliveries))}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center ml-auto mb-3">
                  <div className="fs-14 ff-bold mr-2">TVA :</div>
                  <div className="fs-14 ff-bold">
                    {Numbers.ConvertToEuro(Number(cartTVA))}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-end ml-auto mb-3">
                  <div className="fs-20 ff-bold mr-2">Total :</div>
                  <div className="fs-20 ff-bold">
                    {Numbers.ConvertToEuro(Number(cartTotalTTC))}
                  </div>
                </div>
              </div>
            </CSSTransition>

            <div key={'inline-radio'} className={'text-center'}>
              <Form.Check
                  inline
                  defaultChecked
                  label="Prélèvement"
                  name="group1"
                  type={'radio'}
                  id={`inline-radio-sampling`}
                  onClick={() => handlePaymentMethod('prélèvement')}
              />
              <Form.Check
                  inline
                  disabled={cartTotalTTC < 100}
                  label="Compensation"
                  name="group1"
                  type={'radio'}
                  id={`inline-radio-compensation`}
                  onClick={() => handlePaymentMethod('compensation')}
              />
            </div>


            <CSSTransition
              in={true}
              appear={true}
              timeout={500}
              unmountOnExit
              classNames="fade">
              <div className="d-flex justify-content-center">
                <Button
                    className="btn-rounded btn-grey-darker px-2 py-2 fs-15 my-5 mr-1"
                    onClick={() => setModalCartShow(false)}>
                  Continuer mes achats
                </Button>
                <Button
                  className="btn-rounded px-2 py-2 fs-1 my-5"
                  onClick={() => setModalShowCheckout(true)}>
                  Commander
                </Button>
              </div>
            </CSSTransition>
          </>
        }
      />

      <ModalCustom
        show={modalShowClear}
        onHide={() => setModalShowClear(false)}
        id="modal-clear"
        bodyClassname="p-5"
        content={
          <>
            <h3>Etes-vous sûr de vouloir vider votre panier ?</h3>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: 'Confirmer',
            func: () => handleClearCart(),
            classNames: 'mx-2',
          },
          {
            text: 'Annuler',
            classNames: 'mx-2',
          },
        ]}
      />

      <ModalCustom
        show={modalShowCheckout}
        onHide={() => setModalShowCheckout(false)}
        id="modal-checkout"
        bodyClassname="p-5"
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant={isError.variant}
              heading={isError.heading}
              headingTitle={isError.headingTitle}
              headingIconName={isError.headingIconName}
              bodyContent={isError.content}
              transition={!!isError}
            />

            {!isError && !isLoading && (
              <h3>Etes-vous sûr de vouloir valider votre panier ?</h3>
            )}
          </>
        }
        footer={true}
        buttonsFooter={
          isError
            ? [
                {
                  text: 'Fermer',
                  func: () => handleCheckoutMessage(),
                },
              ]
            : [
                {
                  text: isLoading ? (
                    <Loader variant="white" size="sm" classname="my-1 mx-2" />
                  ) : (
                    'Confirmer'
                  ),
                  func: () => handleCheckout(),
                  classNames: 'mx-2',
                },
                {
                  text: 'Annuler',
                  classNames: 'mx-2',
                },
              ]
        }
      />
    </>
  )
}

ModalCart.propTypes = {
  modalCartShow: PropTypes.bool,
  setModalCartShow: PropTypes.func,
}

export default ModalCart
