import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalCustom from "../../components/ui/modal/ModalCustom";
import AlertCustom from "../../components/ui/alert/AlertCustom";
import Loader from "../../components/ui/loader/Loader";

/**
 *
 * @param button
 * @param func Fonction à lancer après la confirmation
 * @param funcToCheckBeforeConfirmation
 * @returns {JSX.Element}
 * @constructor
 */
export function ButtonWithConfirmation({button, func, funcToCheckBeforeConfirmation}) {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleShowModal() {
        if (funcToCheckBeforeConfirmation instanceof Function) {
            funcToCheckBeforeConfirmation().then((response) => {
                // Si la condition est respectée, on affiche la demande de confirmation à l'utilisateur
                if (response) {
                    setShowModal(true);
                } else {
                    handleConfirmation();
                }
            })
        } else {
            setShowModal(true);
        }

    }
    async function handleConfirmation() {
        setShowModal(false);
        setIsLoading(true);

        await func().finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <Button
                className={`btn-${button.color} ${button.classnames}`}
                onClick={handleShowModal}
                disabled={isLoading}
            >
                {isLoading
                    ? (<Loader variant={'white'} size={'sm'} classname={'my-auto mx-auto'} />)
                    : <>
                        <FontAwesomeIcon icon={button.icon} className={button.iconClassNames} color={button.iconColor} /> {button.title ? button.title : ''}
                      </>
                }
            </Button>

            <ModalCustom
                show={showModal}
                id={'confirmationModal'}
                onHide={() => setShowModal(false)}
                content={
                    <AlertCustom
                        classname={'text-left'}
                        variant={'warning'}
                        heading={true}
                        headingTitle={'Information'}
                        headingIconName={'info-circle'}
                        bodyContent={button.messageInModal}
                        transition={true}
                    />
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Annuler',
                        classNames: 'ml-auto',
                        func: () => setShowModal(false),
                    },
                    {
                        text: 'Ok',
                        classNames: 'mr-auto',
                        func: () => handleConfirmation(),
                    }
                ]}
            />
        </>
    );
}

ButtonWithConfirmation.propTypes = {
    button: PropTypes.shape({
        color: PropTypes.string,
        classnames: PropTypes.string,
        icon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        iconClassNames: PropTypes.string,
        iconColor: PropTypes.string,
        messageInModal: PropTypes.string,
        title: PropTypes.string
    }),
    func: PropTypes.func.isRequired,
    funcToCheckBeforeConfirmation: PropTypes.func,
}