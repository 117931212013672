import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {Button, ButtonGroup, Col, Form, Row} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {BrowserView, MobileView} from 'react-device-detect'
import Dropdown from 'react-bootstrap/Dropdown'
import {Link, useLocation} from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import ClientsAPI from '../../../services/ClientsAPI'
import ClientsFilters from './ClientsFilters'
import Field from '../../ui/form/Field'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'

const Clients = () => {
  const {user} = useContext(AuthContext)
  const isUnmounted = useRef(false)
  const [customer, setCustomer] = useState({})
  const [reload, setReload] = useState(false)
  const [searchInput, setSearchInput] = useState({nom: '', tel: ''})
  const [searchText, setSearchText] = useState()
  const [searchType, setSearchType] = useState()
  const [modalView, setModalView] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalDeleteMessage, setModalDeleteMessage] = useState(false)
  const [isLoadingEdit, setIsLoadingEdit] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isErrorEdit, setIsErrorEdit] = useState(false)
  const [isErrorDelete, setIsErrorDelete] = useState(false)
  const [transition, setTransition] = useState(false)
  const [isLoadingTypeCp, setIsLoadingTypeCp] = useState(false)
  const [isLoadingTypeVille, setIsLoadingTypeVille] = useState(false)
  const [isLoadingTypePays, setIsLoadingTypePays] = useState(false)
  const [optionsTypeCp, setOptionsTypeCp] = useState()
  const [optionsTypeVille, setOptionsTypeVille] = useState()
  const [optionsTypePays, setOptionsTypePays] = useState()
  const [selectedTypeCp, setSelectedTypeCp] = useState([])
  const [selectedTypeVille, setSelectedTypeVille] = useState([])
  const [selectedTypePays, setSelectedTypePays] = useState([])
  const refCp = React.createRef()
  const refVille = React.createRef()
  const refPays = React.createRef()
  const [filteredCustomers, setFilteredCustomers] = useState()
  const location = useLocation()
  const [showAlertCreateCustomer, setShowAlertCreateCustomer] = useState(
    [undefined, null, ''].includes(location.state)
      ? false
      : location.state.create_success,
  )

  const validationSchema = Yup.object().shape({
    nom: Yup.string()
      .required('* Ce champ est requis.')
      .min(2, 'Ce champ doit contenir au minimum 2 caractères.'),
    prenom: Yup.string()
      .required('* Ce champ est requis.')
      .min(2, 'Ce champ doit contenir au minimum 2 caractères.'),
    tel: Yup.string()
      .matches(
        /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
        'Ce champ doit contenir un téléphone valide.',
      )
      .required('* Ce champ est requis.'),
    tel2: Yup.string().matches(
      /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
      'Ce champ doit contenir un téléphone valide.',
    ),
    email: Yup.string().email('Ce champ doit contenir un email valide.'),
  })

  const formik = useFormik({
    initialValues: {
      raisonsoc: (customer && customer.raisonsoc) || '',
      nom: (customer && customer.nom) || '',
      idclient: (customer && customer.idclient) || '',
      prenom: (customer && customer.prenom) || '',
      adresse1: (customer && customer.adresse1) || '',
      adresse2: (customer && customer.adresse2) || '',
      adresse3: (customer && customer.adresse3) || '',
      codep: (customer && customer.codep) || '',
      ville: (customer && customer.ville) || '',
      pays: (customer && customer.pays) || 'FRANCE',
      idpays: (customer && customer.idpays) || '72',
      tel: (customer && customer.tel) || '',
      tel2: (customer && customer.tel2) || '',
      email: (customer && customer.email) || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, {setSubmitting}) => {
      postCustomer(values, setSubmitting)
    },
  })

  const [
    {data: customers, totalItems, isLoading, isError},
    fetchData,
  ] = useFetch(ClientsAPI.getClients(user.CodeFl))

  // Gestion de l'annuaire
  useEffect(() => {
    if (customers && totalItems > 0) {
      if (customers.length > 1) {
        customers.sort((a, b) =>
          a.nom.localeCompare(b.nom, 'fr', {sensitivity: 'base'}),
        )
      }

      const data = customers.reduce((r, e) => {
        const letter = e.nom[0].toUpperCase()
        if (!r[letter]) r[letter] = {letter, customer: [e]}
        else r[letter].customer.push(e)
        return r
      }, {})

      setFilteredCustomers(Object.values(data))
    }
  }, [customers])

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) fetchData(ClientsAPI.getClients(user.CodeFl))
  }, [user.CodeFl])

  // Gestion du changement de magasin
  useEffect(() => {
    setReload(true)
  }, [user.CodeFl])

  // Gestion du changement de la recherche
  useEffect(() => {
    if (reload)
      fetchData(
        ClientsAPI.getClientBySearch(user.CodeFl, searchType, searchText),
      )
  }, [searchText])

  // Gestion des champs recherche
  const handleSearchInput = (event) => {
    setSearchInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
    setSearchType(event.target.name)
  }

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setReload(true)
    setSearchText(searchInput[searchType])

    const keyToClear = Object.keys(searchInput).filter(
      (key) => key !== searchType,
    )
    setSearchInput((prevState) => ({
      ...prevState,
      [keyToClear]: '',
    }))
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setReload(true)
    setSearchInput({nom: '', tel: ''})
    setSearchText()
    setSearchType()
  }

  // Gestion du scroll des lettres de l'annuaire
  const scrollTo = (letter) => {
    const letterElement = document.querySelector(`.letter-${letter}`)
    if (letterElement) {
      letterElement.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    if (customer) {
      if (customer.codep && customer.ville) {
        setSelectedTypeCp([
          {
            codep: customer.codep,
            ville: customer.ville,
          },
        ])
        setSelectedTypeVille([
          {
            codep: customer.codep,
            ville: customer.ville,
          },
        ])
      }

      if (customer.pays) {
        setSelectedTypePays([
          {
            pays: customer.pays,
            idpays: customer.idpays,
          },
        ])
      }
    }
  }, [customer])

  // Gestion de l'autocomplétion du code postal
  const handleSearchCp = useCallback((query) => {
    setIsLoadingTypeCp(true)

    ClientsAPI.getCodePostal(query)
      .then((result) => {
        const options = result.data.map((option) => ({
          codep: option.codpostal,
          ville: option.localite,
        }))

        setOptionsTypeCp(options)
        setIsLoadingTypeCp(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoadingTypeCp(false)
      })
  }, [])

  // Gestion de l'autocomplétion de la ville
  const handleSearchVille = useCallback((query) => {
    setIsLoadingTypeVille(true)

    ClientsAPI.getLocalite(query)
      .then((result) => {
        const options = result.data.map((option) => ({
          codep: option.codpostal,
          ville: option.localite,
        }))

        setOptionsTypeVille(options)
        setIsLoadingTypeVille(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoadingTypeVille(false)
      })
  }, [])

  // Gestion de l'autocomplétion du pays
  const handleSearchPays = useCallback((query) => {
    setIsLoadingTypePays(true)

    ClientsAPI.getPays(query)
      .then((result) => {
        const options = result.data.map((option) => ({
          pays: option.libelle,
          idpays: option.codiso,
        }))

        setOptionsTypePays(options)
        setIsLoadingTypePays(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoadingTypePays(false)
      })
  }, [])

  // Gestion du changement de code postal
  const handleChangeCp = (value) => {
    setSelectedTypeCp(value)
    setSelectedTypeVille(value)

    if (value.length > 0) {
      formik.setFieldValue('codep', value[0] && value[0].codep)
      formik.setFieldValue('ville', value[0] && value[0].ville)
    } else {
      formik.setFieldValue('codep', '')
      formik.setFieldValue('ville', '')
    }

    formik.setFieldTouched('codep', true)
    formik.setFieldTouched('ville', true)
  }

  // Gestion du changement de ville
  const handleChangeVille = (value) => {
    setSelectedTypeCp(value)
    setSelectedTypeVille(value)

    if (value.length > 0) {
      formik.setFieldValue('codep', value[0] && value[0].codep)
      formik.setFieldValue('ville', value[0] && value[0].ville)
    } else {
      formik.setFieldValue('codep', '')
      formik.setFieldValue('ville', '')
    }
    formik.setFieldTouched('codep', true)
    formik.setFieldTouched('ville', true)
  }

  // Gestion du changement de pays destinataire
  const handleChangePays = (value) => {
    setSelectedTypePays(value)

    if (value.length > 0) {
      formik.setFieldValue('idpays', (value[0] && value[0].idpays) || '')
      formik.setFieldValue('pays', (value[0] && value[0].pays) || '')
      formik.setFieldTouched('pays', true)
    } else {
      formik.setFieldValue('idpays', '')
      formik.setFieldValue('pays', '')
      formik.setFieldTouched('pays', true)
    }
  }

  // Gestion du bouton Visualiser un client
  const handleView = (customer) => {
    setModalView(true)
    setCustomer(customer)
  }

  // Gestion du bouton Modifier un client depuis Visualiser
  const handleViewEdit = (customer) => {
    setModalView(false)
    setModalEdit(true)
    setCustomer(customer)
    setIsErrorEdit(false)
    setTransition(false)
  }

  // Gestion du bouton Supprimer un client depuis Visualiser
  const handleViewDelete = (customer) => {
    setModalView(false)
    setModalDelete(true)
    setCustomer(customer)
  }

  // Gestion du bouton Modifier un client
  const handleEdit = (customer) => {
    setModalEdit(true)
    setCustomer(customer)
    setIsErrorEdit(false)
    setTransition(false)
    setSelectedTypeCp([
      {
        codep: customer.codep,
        ville: customer.ville,
      },
    ])
    setSelectedTypeVille([
      {
        codep: customer.codep,
        ville: customer.ville,
      },
    ])
    setSelectedTypePays([
      {
        pays: customer.pays || 'FRANCE',
        idpays: customer.idpays || '72',
      },
    ])
  }

  // Gestion du bouton Supprimer un client
  const handleModalDelete = (customer) => {
    setModalDelete(true)
    setCustomer(customer)
  }

  // Gestion de la suppression d'un client
  const handleDelete = async (customer) => {
    setIsLoadingDelete(true)
    setTransition(false)
    setIsErrorDelete(false)

    try {
      if (!isUnmounted.current) {
        await ClientsAPI.updateClient(customer.idclient, {actif: 0})
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo !',
          headingIconName: 'info-circle',
          content: 'Votre client a bien été supprimé.',
        })
        setCustomer()
        fetchData(ClientsAPI.getClients(user.CodeFl))
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'danger',
          heading: true,
          content: `Votre client n'a pas pu être supprimé, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setModalDelete(false)
    setModalDeleteMessage(true)
  }

  // Gestion de la validation d'un client
  const postCustomer = async (values, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingEdit(true)
      setTransition(false)
      setIsErrorEdit(false)
    }
    try {
      if (!isUnmounted.current) {
        if (customer && customer.idclient) {
          const result = await ClientsAPI.updateClient(
            customer.idclient,
            values,
          )
          setCustomer(result.data)
        } else {
          await ClientsAPI.postClient({
            ...values,
            codfl: user.CodeFl,
          })
        }
        setIsErrorEdit({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo !',
          headingIconName: 'info-circle',
          content: `Votre client a bien été ${
            customer && customer.idclient ? 'modifié' : 'ajouté'
          }.`,
        })
        setIsLoadingEdit(false)
        setTransition(true)
        formik.resetForm()

        fetchData(ClientsAPI.getClients(user.CodeFl))
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingEdit(false)
        setTransition(true)
        setIsErrorEdit({
          variant: 'danger',
          heading: true,
          content: `Votre client n'a pas pu être ${
            customer && customer.idclient ? 'modifié' : 'ajouté'
          }, veuillez réessayer.`,
        })
      }
    }
    setSubmitting(false)
  }

  return (
    <>
      <Title title="Mes clients" />

      {showAlertCreateCustomer && (
        <Col xs={12} className={'d-flex justify-content-center'}>
          <Alert
            variant={'success'}
            show={showAlertCreateCustomer}
            dismissible={true}
            onClose={() => setShowAlertCreateCustomer(false)}>
            Le client a bien été enregistré.
          </Alert>
        </Col>
      )}

      <ClientsFilters
        handleSearch={handleSearch}
        searchInput={searchInput}
        searchType={searchType}
        searchText={searchText}
        handleSearchInput={handleSearchInput}
        handleCancel={handleCancel}
        totalItems={totalItems}
        handleEdit={handleEdit}
      />

      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
      />

      {!isLoading && !isError && (
        <Col xs={12}>
          <Link
            to={'/customers/add'}
            role={'button'}
            className={'btn btn-primary mb-2'}>
            Ajouter un client
          </Link>
          <div className="d-flex flex-row flex-nowrap mb-3">
            <div className="d-flex flex-column flex-fill">
              {filteredCustomers &&
                filteredCustomers.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={`letter-${item.letter} border-bottom border-success py-2 px-3`}>
                        <h3>{item.letter}</h3>
                      </div>
                      {item.customer.map((customer, index) => (
                        <div
                          key={index}
                          className="customer d-flex flex-row flex-wrap align-items-center justify-content-between py-2 px-3">
                          <div className="d-flex flex-row flex-wrap flex-fill align-items-center justify-content-between">
                            <div className="w-50">
                              {customer.nom} {customer.prenom}
                              <BrowserView renderWithFragment={true}>
                                {` - ${customer.tel}`}
                              </BrowserView>
                            </div>

                            <div className="d-flex flex-row flex-wrap align-items-center">
                              <Dropdown as={ButtonGroup}>
                                <Button
                                  variant="success"
                                  onClick={() => {
                                    handleView(customer)
                                  }}>
                                  Voir
                                </Button>
                                <Dropdown.Toggle
                                  split={true}
                                  variant={'success'}
                                  className={'border-left'}
                                />
                                <Dropdown.Menu align={'right'}>
                                  <MobileView renderWithFragment={true}>
                                    <Dropdown.Item href={`tel:${customer.tel}`}>
                                      <Button>
                                        <FontAwesomeIcon
                                          icon="phone"
                                          color="white"
                                        />
                                      </Button>
                                    </Dropdown.Item>
                                  </MobileView>
                                  <Dropdown.Item>
                                    <Button
                                      className="btn-primary"
                                      onClick={() => {
                                        handleEdit(customer)
                                      }}>
                                      <FontAwesomeIcon
                                        icon="pen"
                                        color="white"
                                      />
                                    </Button>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Button
                                      className="btn-red"
                                      onClick={() => {
                                        handleModalDelete(customer)
                                      }}>
                                      <FontAwesomeIcon
                                        icon="trash"
                                        color="white"
                                      />
                                    </Button>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                })}
            </div>
            <div className={'bg-grey'}>
              <div className="d-flex flex-column align-items-center sticky-top ff-bold">
                {filteredCustomers &&
                  filteredCustomers.length > 0 &&
                  filteredCustomers.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => scrollTo(item.letter)}
                        className="letter py-2 px-3 d-flex justify-content-center w-100">
                        {item.letter}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </Col>
      )}

      {customer && (
        <ModalCustom
          show={modalView}
          onHide={() => setModalView(false)}
          id="modal-view"
          title={`Visualiser le client ${customer.nom} ${customer.prenom}`}
          titleClassname="text-center mb-3"
          bodyClassname="text-left"
          content={
            <Col md={{span: 10, offset: 1}} className="text-left">
              <div className="form-label mb-2">Raison sociale</div>
              <div className="mh bg-grey py-1 px-3 mb-3">
                {customer.raisonsoc}
              </div>

              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Nom</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.nom}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Prénom</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.prenom}
                  </div>
                </Col>
              </Row>

              <div className="form-label mb-2">Adresse</div>
              <div className="mh bg-grey py-1 px-3 mb-3">
                {customer.adresse1}
              </div>

              <div className="form-label mb-2">Complément d'adresse 1</div>
              <div className="mh bg-grey py-1 px-3 mb-3">
                {customer.adresse2}
              </div>

              <div className="form-label mb-2">Complément d'adresse 2</div>
              <div className="mh bg-grey py-1 px-3 mb-3">
                {customer.adresse3}
              </div>

              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Code postal</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.codep}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Ville</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.ville}
                  </div>
                </Col>
              </Row>

              <div className="form-label mb-2">Pays</div>
              <div className="mh bg-grey py-1 px-3 mb-3">{customer.pays}</div>

              <Row>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Téléphone</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.tel}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="form-label mb-2">Portable</div>
                  <div className="mh bg-grey py-1 px-3 mb-3">
                    {customer.tel2}
                  </div>
                </Col>
              </Row>

              <div className="form-label mb-2">Email</div>
              <div className="mh bg-grey py-1 px-3 mb-3">{customer.email}</div>

              <Button
                onClick={() => {
                  handleViewEdit(customer)
                }}
                className="btn-primary mr-2 mb-3">
                Modifier
              </Button>
              <Button
                onClick={() => {
                  handleViewDelete(customer)
                }}
                className="btn-red mb-3">
                Supprimer
              </Button>
            </Col>
          }
          footer={true}
          buttonsFooter={[
            {
              text: 'Fermer',
            },
          ]}
        />
      )}

      <ModalCustom
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        id="modal-edit"
        title={`${
          customer && customer.nom ? 'Modifier le' : 'Ajouter un'
        } client ${customer && customer.nom ? customer.nom : ''} ${
          customer && customer.prenom ? customer.prenom : ''
        }`}
        titleClassname="text-center mb-3"
        bodyClassname="text-left"
        content={
          <>
            <Col md={{span: 10, offset: 1}} className="text-left">
              <AlertCustom
                variant={isErrorEdit.variant}
                heading={isErrorEdit.heading}
                headingTitle={isErrorEdit.headingTitle}
                headingIconName={isErrorEdit.headingIconName}
                bodyContentHtml={isErrorEdit.content}
                transition={transition}
                dismissible={true}
                onClose={() => setTransition(false)}
                classname="m-0 mb-3"
              />

              <Form onSubmit={formik.handleSubmit}>
                <Field
                  label="Raison sociale"
                  name="raisonsoc"
                  value={formik.values.raisonsoc}
                  placeholder="Raison sociale"
                  classNames="mb-3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.raisonsoc && formik.errors.raisonsoc}
                  success={
                    formik.values.raisonsoc !== '' &&
                    formik.touched.raisonsoc &&
                    !formik.errors.raisonsoc
                  }
                />

                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Nom"
                      labelRequired={true}
                      name="nom"
                      value={formik.values.nom}
                      placeholder="Nom"
                      classNames="mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.nom && formik.errors.nom}
                      success={formik.touched.nom && !formik.errors.nom}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Prénom"
                      labelRequired={true}
                      name="prenom"
                      value={formik.values.prenom}
                      placeholder="Prénom"
                      classNames="mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.prenom && formik.errors.prenom}
                      success={formik.touched.prenom && !formik.errors.prenom}
                    />
                  </Col>
                </Row>

                <Field
                  label="Adresse"
                  name="adresse1"
                  value={formik.values.adresse1}
                  placeholder="Adresse"
                  classNames="mb-3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.adresse1 && formik.errors.adresse1}
                  success={
                    formik.values.adresse1 !== '' &&
                    formik.touched.adresse1 &&
                    !formik.errors.adresse1
                  }
                />

                <Field
                  label="Complément d'adresse 1"
                  name="adresse2"
                  value={formik.values.adresse2}
                  placeholder="Complément d'adresse 1"
                  classNames="mb-3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.adresse2 && formik.errors.adresse2}
                  success={
                    formik.values.adresse2 !== '' &&
                    formik.touched.adresse2 &&
                    !formik.errors.adresse2
                  }
                />

                <Field
                  label="Complément d'adresse 2"
                  name="adresse3"
                  value={formik.values.adresse3}
                  placeholder="Complément d'adresse 2"
                  classNames="mb-3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.adresse3 && formik.errors.adresse3}
                  success={
                    formik.values.adresse3 !== '' &&
                    formik.touched.adresse3 &&
                    !formik.errors.adresse3
                  }
                />

                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="codep">
                      <Form.Label>Code postal</Form.Label>
                      <AsyncTypeahead
                        id="codep"
                        name="codep"
                        isLoading={isLoadingTypeCp}
                        minLength={2}
                        maxResults={10}
                        filterBy={() => true}
                        promptText="Entrez votre recherche"
                        searchText="Chargement..."
                        emptyLabel="Aucun résultat"
                        paginationText="Plus de résultats"
                        placeholder="Code postal"
                        labelKey="codep"
                        selected={selectedTypeCp || []}
                        options={optionsTypeCp}
                        onSearch={handleSearchCp}
                        ref={refCp}
                        onInputChange={(text) => {
                          formik.setFieldTouched('codep', true)
                          formik.setFieldTouched('ville', true)
                        }}
                        onChange={(value) => {
                          handleChangeCp(value)
                        }}
                        onBlur={() => {
                          formik.setFieldTouched('codep', true)
                          formik.setFieldTouched('ville', true)

                          if (selectedTypeCp.length === 0) {
                            formik.setFieldValue('codep', '')
                            formik.setFieldValue('ville', '')
                            refCp.current.clear()
                            refVille.current.clear()
                          }
                        }}
                        renderMenuItemChildren={(option) => (
                          <>
                            <span>{option.codep}</span>
                            <span> ({option.ville})</span>
                          </>
                        )}
                        className={
                          (formik.touched.codep &&
                            formik.errors.codep &&
                            ' is-invalid') ||
                          (formik.values.codep !== '' &&
                            formik.touched.codep &&
                            !formik.errors.codep &&
                            ' is-valid')
                        }
                      />
                      {formik.errors.codep && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.codep}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="ville">
                      <Form.Label>Ville</Form.Label>
                      <AsyncTypeahead
                        id="ville"
                        name="ville"
                        isLoading={isLoadingTypeVille}
                        minLength={2}
                        maxResults={10}
                        filterBy={() => true}
                        promptText="Entrez votre recherche"
                        searchText="Chargement..."
                        emptyLabel="Aucun résultat"
                        paginationText="Plus de résultats"
                        placeholder="Ville"
                        labelKey="ville"
                        selected={selectedTypeVille || []}
                        options={optionsTypeVille}
                        onSearch={handleSearchVille}
                        ref={refVille}
                        onInputChange={(text) => {
                          formik.setFieldTouched('codep', true)
                          formik.setFieldTouched('ville', true)
                        }}
                        onChange={(value) => {
                          handleChangeVille(value)
                        }}
                        onBlur={() => {
                          formik.setFieldTouched('codep', true)
                          formik.setFieldTouched('ville', true)
                          if (selectedTypeVille.length === 0) {
                            formik.setFieldValue('codep', '')
                            formik.setFieldValue('ville', '')
                            refCp.current.clear()
                            refVille.current.clear()
                          }
                        }}
                        renderMenuItemChildren={(option) => (
                          <>
                            <span>{option.ville}</span>
                            <span> ({option.codep})</span>
                          </>
                        )}
                        className={
                          (formik.touched.ville &&
                            formik.errors.ville &&
                            ' is-invalid') ||
                          (formik.values.ville !== '' &&
                            formik.touched.ville &&
                            !formik.errors.ville &&
                            ' is-valid')
                        }
                      />
                      {formik.errors.ville && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ville}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="pays" className="mb-3">
                  <Form.Label>Pays</Form.Label>
                  <AsyncTypeahead
                    id="pays"
                    name="pays"
                    clearButton
                    isLoading={isLoadingTypePays}
                    minLength={2}
                    maxResults={10}
                    filterBy={() => true}
                    promptText="Entrez votre recherche"
                    searchText="Chargement..."
                    emptyLabel="Aucun résultat"
                    paginationText="Plus de résultats"
                    placeholder="Pays"
                    labelKey="pays"
                    selected={selectedTypePays || []}
                    options={optionsTypePays}
                    onSearch={handleSearchPays}
                    ref={refPays}
                    onInputChange={(text) => {
                      formik.setFieldTouched('pays', true)
                    }}
                    onChange={(value) => {
                      handleChangePays(value)
                    }}
                    onBlur={() => {
                      formik.setFieldTouched('pays', true)
                      if (selectedTypePays.length === 0) {
                        refPays.current.clear()
                        setSelectedTypePays([
                          {
                            pays: 'FRANCE',
                            idpays: '72',
                          },
                        ])
                        formik.setFieldValue('pays', 'FRANCE')
                        formik.setFieldValue('idpays', '72')
                      }
                    }}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.pays}</span>
                      </>
                    )}
                    className={
                      (formik.touched.pays &&
                        formik.errors.pays &&
                        ' is-invalid') ||
                      (formik.touched.pays &&
                        !formik.errors.pays &&
                        ' is-valid')
                    }
                  />
                  {formik.errors.pays && (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.pays}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Row>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Téléphone"
                      labelRequired={true}
                      name="tel"
                      value={formik.values.tel}
                      placeholder="Téléphone"
                      classNames="mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tel && formik.errors.tel}
                      success={formik.touched.tel && !formik.errors.tel}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Field
                      label="Portable"
                      name="tel2"
                      value={formik.values.tel2}
                      placeholder="Portable"
                      classNames="mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tel2 && formik.errors.tel2}
                      success={formik.touched.tel2 && !formik.errors.tel2}
                    />
                  </Col>
                </Row>

                <Field
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  placeholder="Email"
                  classNames="mb-3"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  success={formik.touched.email && !formik.errors.email}
                />

                <Button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn-primary">
                  {isLoadingEdit ? (
                    <Loader
                      variant="white"
                      size="sm"
                      classname="my-1"
                      style={{
                        marginLeft: '22px',
                        marginRight: '22px',
                      }}
                    />
                  ) : (
                    'Enregistrer'
                  )}
                </Button>
              </Form>
            </Col>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: 'Fermer',
          },
        ]}
      />

      {customer && (
        <ModalCustom
          show={modalDelete}
          onHide={() => setModalDelete(false)}
          id="modal-delete"
          bodyClassname="p-5"
          content={
            <>
              <h3>
                Etes-vous sûr de vouloir supprimer le client
                {customer.nom ? ` ${customer.nom}` : ''}
                {customer.prenom ? ` ${customer.prenom}` : ''} ?
              </h3>
            </>
          }
          footer={true}
          buttonsFooter={[
            {
              text: isLoadingDelete ? (
                <Loader variant="white" size="sm" classname="my-1 mx-2" />
              ) : (
                'Confirmer'
              ),
              func: () => handleDelete(customer),
              classNames: 'mx-2',
            },
            {
              text: 'Annuler',
              classNames: 'btn-grey-darker mx-2',
            },
          ]}
        />
      )}

      <ModalCustom
        show={modalDeleteMessage}
        onHide={() => setModalDeleteMessage(false)}
        id="modal-delete-message"
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant={isErrorDelete.variant}
              heading={isErrorDelete.heading}
              headingTitle={isErrorDelete.headingTitle}
              headingIconName={isErrorDelete.headingIconName}
              bodyContent={isErrorDelete.content}
              bodyContentItalic={isErrorDelete.detail}
              transition={transition}
            />
          </>
        }
        buttons={[
          {
            text: 'Fermer',
            classNames: 'mx-2',
          },
        ]}
      />
    </>
  )
}

export default Clients
