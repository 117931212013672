import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createSelector} from '@reduxjs/toolkit'
import {Row} from 'react-bootstrap'

import Title from '../../ui/page/Title'
import ShopperFormGroup from '../../ui/form/shopper/ShopperFormGroup'
import {fetchShoppers, selectShoppers} from '../../../slice/shoppersSlice'
import {isArray} from '../../../Tools/TypeOf'
import Shopper from '../../../class/Shopper'

/**
 * Shoppers
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Shoppers = () => {
  const dispatch = useDispatch()
  const {data: sfloristShoppers, isError, isLoading, totalItems} = useSelector(
    selectShoppers,
  )

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchShoppers())

      setInitialized(true)
    }
  }, [initialized])

  return (
    <>
      <Row>
        <Title title="Gestion des livreurs" />
      </Row>

      <div className="container d-flex flex-wrap mx-0">
        {!isError &&
          !isLoading &&
          totalItems > 0 &&
          Object.values(sfloristShoppers).map(({shopper: {reference}}, i) => (
            <div key={i} className="mb-3 col-6">
              <ShopperFormGroup reference={reference} />
            </div>
          ))}
      </div>
    </>
  )
}

export default Shoppers
