import PropTypes from 'prop-types'
import {Button, useAccordionButton} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {
  configureSideMenuActiveKey,
  configureSideSubMenuActiveKey,
  selectSideMenu,
} from '../../../slice/sideMenuSlice'

const SideMenuToggle = ({children, eventKey, lvl}) => {
  const dispatch = useDispatch()
  const {accordionActiveKey, subAccordionState} = useSelector(selectSideMenu)

  const handleClassname = (lvl) => {
    return lvl === 1
      ? `btn-block bg-primary${
          accordionActiveKey === eventKey ? '-dark' : ''
        } text-left mt-1 px-3 py-1`
      : 'accordion-link text-left btn-block btn-trans text-black-light'
  }

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    if (lvl === 1) {
      dispatch(configureSideMenuActiveKey(eventKey))
    } else {
      dispatch(configureSideSubMenuActiveKey(eventKey))
    }
  })

  return (
    <Button
      type="button"
      className={(() => handleClassname(lvl))()}
      onClick={decoratedOnClick}
      size={(lvl === 1 && 'lg') || 'sm'}>
      {children}
    </Button>
  )
}

SideMenuToggle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  lvl: PropTypes.number,
}

SideMenuToggle.defaultProps = {
  lvl: 1,
}

export default SideMenuToggle
