import Image from 'react-bootstrap/Image'
import React from 'react'

const Logo = () => {
  return (
    <Image
      src="/images/logo-florajet.svg"
      alt="Mon Florajet"
      className="img-fluid"
    />
  )
}

export default Logo
