import React, {useContext, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import {useFormik} from "formik";
import PropTypes from "prop-types";
import Field from "../form/Field";
import ModalCustom from "../modal/ModalCustom";
import Loader from "../loader/Loader";
import AlertCustom from "../alert/AlertCustom";
import ExecutionsAPI from "../../../services/ExecutionsAPI";
import AuthContext from "../../../contexts/AuthContext";

export const TakePicture = ({showModalTakePicture, order, onHide}) => {
    const {user} = useContext(AuthContext)
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [uploadState, setUploadState] = useState(false);
    const [preview, setPreview] = useState();
    const previewRef = useRef();
    const formik = useFormik({
        initialValues: {
            file: '',
        },
        enableReinitialize: true,
        onSubmit: (values, {setSubmitting}) => {
            uploadFile(preview, setSubmitting);
        },
        onReset: () => {
            previewRef.current.value = null;
        },
    })

    function handleUploadChange(e) {
        const reader = new FileReader();
        formik.handleChange(e);

        // Si on veut choisir une autre image après avoir enregistré une première image
        if (isUploaded) {
            setUploadState(false);
            setIsUploaded(false);
            setIsLoadingUpload(false);
        }

        reader.onloadend = () => {
            setPreview(reader.result);
        }

        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    async function uploadFile(preview, setSubmitting) {
        setIsLoadingUpload(true);

        try {
            // const formData = new FormData();
            // formData.append("file", preview);

            await ExecutionsAPI.uploadPicture(user.CodeFl, order.numcde, {
                content: preview,
            });
            // await ExecutionsAPI.uploadImage(user.CodeFl, order.numcde, formData);

            setIsLoadingUpload(false);
            setIsUploaded(true);
            formik.resetForm();
            setUploadState({
                variant: 'success',
                heading: true,
                headingTitle: 'Bravo ! Votre photo a bien été envoyée.',
                headingIconName: 'info-circle',
                content: `Vous pouvez consulter votre photo avec l'action "Voir la photo"`,
            });
        } catch (error) {
            setIsLoadingUpload(false);
            setIsUploaded(false);
            setUploadState({
                variant: 'danger',
                heading: true,
                content: `Votre photo n'a pas pu être envoyée, veuillez réessayer ultérieurement.`,
            });
        }
        setSubmitting(false);
    }


    return (
        <>
            <ModalCustom
                onHide={() => onHide(false)}
                id={"modal-take-picture"}
                show={showModalTakePicture}
                bodyClassname="p-3"
                content={
                    <>
                        <h3 className="mb-3">Ajouter une nouvelle photo {order && order.numcde && (`pour la commande ${order.numcde}`)}</h3>
                        <div className="d-flex justify-content-center">
                            <Container fluid>
                                <Row>
                                    {uploadState && <AlertCustom
                                        variant={uploadState.variant}
                                        heading={uploadState.heading}
                                        headingTitle={uploadState.headingTitle}
                                        headingIconName={uploadState.headingIconName}
                                        bodyContentHtml={uploadState.content}
                                    />}

                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label
                                            htmlFor={'file'}
                                            className={'btn btn-primary'}
                                        >Choisir / prendre une photo</Form.Label>
                                        <Field
                                            type="file"
                                            name="file"
                                            value={undefined}
                                            onChange={handleUploadChange}
                                            classNames="text-left hidden"
                                            fieldRef={previewRef}
                                        />
                                    </Col>
                                </Row>
                                {preview && (
                                    <Row>
                                        <Col>
                                            <Image src={preview} className="rounded-xl mb-3 mx-auto" fluid />
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                        </div>
                    </>
                }
                footer={true}
                buttonsFooter={
                    isUploaded
                    ? [
                            {
                                text: 'Fermer',
                                classNames: 'mx-2',
                            },
                        ]
                    : [
                            {
                                text: isLoadingUpload ? (
                                    <Loader variant="white" size="sm" classname="my-1 mx-3" />
                                ) : (
                                    'Enregistrer'
                                ),
                                func: formik.handleSubmit,
                                disabled: formik.isSubmitting || !preview,
                                type: 'submit',
                                classNames: 'mx-2',
                                variant: uploadState.variant,
                            },
                            {
                                text: 'Annuler',
                                classNames: 'mx-2',
                            },
                        ]
                }
            />
        </>
    );
}

TakePicture.propTypes = {
    showModalTakePicture: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    order: PropTypes.object,
}