import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Title from "../../../ui/page/Title";
import useFetch from "../../../../hooks/useFetch";
import {StockAccessory} from "./StockAccessory";
import AccessoryAPI from "../../../../services/AccessoryAPI";
import Loader from "../../../ui/loader/Loader";

export const StockAccessories = () => {
    const [{data: accessories, isLoading}, fetchData] = useFetch(AccessoryAPI.getAccessories());

    return (
        <>
            <Title title="Mon stock d'accessoires" />
            <Container fluid={true}>
                <Row xs={1} md={5}>
                    {!isLoading && accessories.map((accessory, index) => (
                        <StockAccessory key={index} accessory={accessory} fetchData={fetchData} />
                    ))}
                    {isLoading && <Loader />}
                </Row>
            </Container>
        </>
    );
}