import {ITEMS_PER_PAGE_VIDEOS, VIDEOS_API} from '../constants/Constants'

function getVideos(currentPage) {
  return {
    name: 'vidéos',
    method: 'get',
    url: `${VIDEOS_API}&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE_VIDEOS}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

export default {
  getVideos,
}
