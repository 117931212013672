import {useContext, useState} from "react";
import Col from "react-bootstrap/Col";
import {useFormik} from "formik";
import {object, string} from "yup";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Field from "../../ui/form/Field";
import ExecutionsAPI from "../../../services/ExecutionsAPI";
import AuthContext from "../../../contexts/AuthContext";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import AlertCustom from "../../ui/alert/AlertCustom";

export const RefusalForm = ({refus, order, setShowModal, setModalContent}) => {
    const {user} = useContext(AuthContext);
    const [enableValidation, setEnableValidation] = useState(true);
    const getExecutions = useStoreExecutions((state) => state.getExecutions);

    const validationSchemaRefus = object().shape({
        refusmessage: string()
            .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
            .required('* Ce champ est requis.'),
    });

    const postRefus = async (values, order, setSubmitting) => {
        try {
            await ExecutionsAPI.postExecutions(user.CodeFl, {
                etatextranet: parseInt(order.item.actions[order.id].id, 10),
                etatextranetlibelle: order.item.actions[order.id].label,
                idsgribouil: order.item.idsgribouil,
                numcde: order.item.numcde,
                motif: formikRefus.values.refus,
                motifdesc: formikRefus.values.refusmessage,
            });

            getExecutions(user, true, false);
            setShowModal(false);
        } catch (err) {
            setModalContent((
                <AlertCustom
                    variant={'danger'}
                    heading={true}
                    bodyContent={error.response.data.detail}
                    transition={true}
                />
            ));
        }
    }

    const formikRefus = useFormik({
        initialValues: {
            refus: '1',
            refusmessage: '',
        },
        enableReinitialize: true,
        validationSchema: enableValidation ? validationSchemaRefus : false,
        validateOnChange: true,
        onSubmit: (values, {setSubmitting}) => {
            postRefus(values, order, setSubmitting);
        },
    })

    const handleChangeRefusal = (event) => {
        formikRefus.setFieldValue('refus', event.target.value)

        if (event.target.value === '1') {
            setEnableValidation(true)
        } else {
            setEnableValidation(false)
        }
    }

    return (
        <Col className={'text-left'}>
            <Form
                onSubmit={(event) => {
                    setShowModal(true)
                    event.preventDefault();
                    formikRefus.handleSubmit();
                }}
                className={'mb-3'}
                id={'refusalForm'}
            >
                {refus && refus.map((item, index) => (
                    <Form.Check
                        key={index}
                        id={`refus-${item.refusIdx}`}
                        name={'refus'}
                        type={'radio'}
                        label={item.refusDesc}
                        value={item.refusIdx}
                        className={'mb-2'}
                        defaultChecked={
                            formikRefus.values.refus === item.refusIdx
                        }
                        onChange={(event) => handleChangeRefusal(event)}
                    />
                ))}
                {formikRefus.values.refus === '1' && (
                    <Field
                        as={'textarea'}
                        rows={8}
                        name={'refusmessage'}
                        value={formikRefus.values.refusmessage}
                        onChange={formikRefus.handleChange}
                        placeholder={'Merci de préciser le choix floral demandé *'}
                        fieldClassNames={'bg-white'}
                        classNames={'mt-3'}
                        error={formikRefus.touched.refusmessage && formikRefus.errors.refusmessage}
                    />
                )}
            </Form>
        </Col>
    )
}

RefusalForm.propTypes = {
    refus: PropTypes.array.isRequired,
    order: PropTypes.object.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setModalContent: PropTypes.func.isRequired,
}