import PropTypes from "prop-types";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import React, {useState} from "react";
import uuid from "react-uuid";

const OverlayedHyperlink = ({placement, overlayTxt, href, linkTxt, linkClassName, target, download}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
          <Tooltip id={`tooltip-${uuid()}`}>
            {overlayTxt}
          </Tooltip>
      }
    >
      <a
        {... {
          href,
          className: linkClassName,
          download,
          target
      }}>
        {linkTxt}
      </a>
    </OverlayTrigger>
  )
}

OverlayedHyperlink.propTypes = {
  placement:     PropTypes.string,
  overlayTxt:    PropTypes.string,
  href:          PropTypes.string,
  linkTxt:       PropTypes.string,
  linkClassName: PropTypes.string,
  target:        PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  download:      PropTypes.bool,
}

export default OverlayedHyperlink;
