import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'
import Layout from '../components/ui/layout/Layout'
import Login from '../components/pages/login/Login'

const PrivateRoute = () => {
  const {isAuthenticated} = useContext(AuthContext)
  const {pathname} = useLocation()

  const Authenticated = ({pathname}) => {
    return (
      (pathname === '/' && <Navigate to={'/tableau-de-bord'} />) || (
        <Layout>
          <Outlet />
        </Layout>
      )
    )
  }

  Authenticated.propTypes = {
    pathname: PropTypes.string.isRequired,
  }

  return (
    (isAuthenticated && <Authenticated pathname={pathname} />) ||
    (pathname === '/' && <Login />) || <Navigate to={'/'} replace={true} />
  )
}

export default PrivateRoute
