import Shopper from './Shopper'

export default class SfleuristeShopper {
  constructor({ident, active, credentials, badCredentialsError, ...props}) {
    this.ident = ident
    this.credentials = credentials || {}
    this.active = active
    this.badCredentialsError = badCredentialsError || false

    this.shopper = new Shopper(props.shopper)
  }
}
