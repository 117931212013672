import { create } from 'zustand';
import {format} from "date-fns";
import ExecutionsAPI from "../services/ExecutionsAPI";

export const useStoreExecutions = create((set, get) => ({
    executionsCount: 0,
    executionsInPendingCount: 0,
    executions: [],
    isLoading: false,
    isError: false,
    florist: null,
    type: 'pending',
    sortType: 'etatextranet',
    sortOrder: 'asc',
    currentPage: 1,
    searchText: '',
    dateStart: '',
    dateEnd: '',
    activeRing: true,
    executionsAutoAcceptedCount: 0,
    setType: (newType) => {
        if (newType !== get().type) {
            set({type: newType});

            if (newType !== 'search') {
                get().setSearchText('');
                get().setDateStart('');
                get().setDateEnd('');
            }

            get().getExecutions(get().florist, true);
        }
    },
    setSearchText: (newSearchText) => {
        set({searchText: newSearchText});
    },
    setDateStart: (newDateStart) => {
        set({dateStart: newDateStart});
    },
    setDateEnd: (newDateEnd) => {
        set({dateEnd: newDateEnd});
    },
    setFlorist: (florist) => {
        set({
            florist,
            executions: [],
        })
    },
    getExecutions: async (florist, forceReload = false, activeRing = true) => {
        set({
            isLoading: true,
            executionsCount: 0,
            isError: false
        });

        if (get().florist === null || get().florist.CodeFl !== florist.CodeFl) {
            set({florist});
        }

        if (get().florist !== null && get().executions.length === 0 || forceReload) {
            await ExecutionsAPI.getExecutions(
                get().florist.CodeFl,
                get().type,
                get().sortType,
                get().sortOrder,
                get().currentPage,
                get().searchText,
                get().dateStart !== '' ? format(get().dateStart, 'dd-MM-yyyy HH:mm:ss') : '',
                get().dateEnd !== '' ? format(get().dateEnd, 'dd-MM-yyyy HH:mm:ss') : '',
                'ALL'
            ).then(({data}) => {
                if (data['hydra:member']) { // TODO: A supprimer POST-FDM
                    data = data['hydra:member'];
                }

                set({executions: data});
                set({executionsCount: data.length});

                if (data.length === 0) {
                    set({isError: {
                            variant: 'warning',
                            icon: true,
                            content: 'Il n\'y a actuellement aucune exécution'
                        }}
                    );
                }

                // Permet de mettre à jour le nombre d'exécutions en attente dans le menu lors d'une action sur une commande
                if (get().type === 'pending' && data) {
                    let numberOfNewExecutionsInPending = 0;
                    data.forEach(execution => {
                        if (execution.etatextranet < 3) {
                            numberOfNewExecutionsInPending += 1;
                        }
                    })
                    set({
                        executionsInPendingCount: numberOfNewExecutionsInPending,
                        activeRing
                    });
                    get().setExecutionsAutoAccepted(data);
                }
            });
        }

        set({isLoading: false});
    },
    setExecutionsInPendingCount: (numberOfNewExecutionsInPending, florist) => {
        set({
            activeRing: true,
            executionsInPendingCount: numberOfNewExecutionsInPending
        });

        if (get().type === 'pending' && numberOfNewExecutionsInPending > 0) {
            get().getExecutions(get().florist === null ? florist : get().florist, true);
        }
    },
    setExecutionsAutoAccepted: (executions) => { // Permet de rafraîchir le tableau des exécutions acceptées
        let numberOfNewExecutionsAutoAccepted = 0;
        executions.forEach(execution => {
            if (execution.etatextranet === 40) {
                numberOfNewExecutionsAutoAccepted += 1;
            }
        });

        if (numberOfNewExecutionsAutoAccepted !== get().executionsAutoAcceptedCount) {
            set({executionsAutoAcceptedCount: numberOfNewExecutionsAutoAccepted});
        }
    },
    resetSearch: () => {
        get().setType('pending');
    }
}))