import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import TableCustom from "../../../ui/table/TableCustom";
import FermeturesAPI from "../../../../services/FermeturesAPI";
import ModalCustom from "../../../ui/modal/ModalCustom";
import AlertCustom from "../../../ui/alert/AlertCustom";
import AuthContext from "../../../../contexts/AuthContext";

export const HolidaysContentTab = ({holidays, isLoading, fetchData, isError}) => {
    const {user} = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState({});

    async function deleteHoliday(holidayId) {
        FermeturesAPI.deleteHoliday(holidayId)
            .then((response) => {
                setIsSuccess({
                    variant: 'success',
                    content: response,
                });
                fetchData(FermeturesAPI.getFermetures(user.CodeFl));
            })
            .catch(({response}) => {
                setIsSuccess({
                    variant: 'danger',
                    content: response.data['hydra:description'],
                });
            })
            .finally(() => {
                setShowModal(true);
            })
        ;
        sessionStorage.removeItem('holiday_current');
    }

    return (
        <>
            <TableCustom
                headers={['Date de début', 'Date de fin', '']}
                data={holidays}
                isError={isError}
                config={[
                    {type: 'date', key: 'datecongesdeb'},
                    {type: 'date', key: 'datecongesfin'},
                    {
                        type: 'simple_buttons',
                        colClass: 'd-flex justify-content-end',
                        key: [
                            {
                                name: 'Supprimer',
                                color: 'red',
                                icon: 'trash',
                                iconColor: 'white',
                                classnames: 'm-1',
                                func: deleteHoliday,
                                funcParam: 'idsfleuristesConges',
                            }
                        ],
                    },
                ]}
                sorting={['datecongesdeb', 'datecongesfin']}
                isLoading={isLoading}
            />

            <ModalCustom
                show={showModal}
                id={'deleteModal'}
                onHide={() => setShowModal(false)}
                content={(
                    <AlertCustom
                        variant={isSuccess.variant}
                        heading={true}
                        headingTitle={"Information"}
                        headingIconName={"info-circle"}
                        bodyContent={isSuccess.content}
                        transition={true}
                    />
                )}
            />
        </>
    );
}

HolidaysContentTab.propTypes = {
    holidays: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isLoading: PropTypes.bool.isRequired,
    fetchData: PropTypes.func,
    isError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}