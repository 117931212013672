import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMercureSubscriber} from "../../../hooks/useMercureSubscriber";
import ModalCustom from "./ModalCustom";
import {ACCESSORIES_STOCK_PATH} from "../../../constants/RoutesPaths";

export const StockAccessoriesNotification = () => {
    const [showModal, setShowModal] = useState(false);
    const {result} = useMercureSubscriber(['/stock/accessories/notifications']);
    const navigate = useNavigate();

    useEffect(() => {
        if (result.data) {
            setShowModal(true);
        }
    }, [result]);

    function handleSee() {
        navigate(ACCESSORIES_STOCK_PATH);
    }

    return (
        <>
            <ModalCustom
                show={showModal}
                id={'stockAccessoriesModal'}
                onHide={() => setShowModal(false)}
                content={
                    <>
                        <h5>N'oubliez pas d’actualiser la liste des accessoires disponibles dans votre boutique.</h5>
                    </>
                }
                bodyClassname={'m-3'}
                backdrop={'static'}
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Fermer',
                        func: () => setShowModal(false),
                    },
                    {
                        text: 'Voir',
                        func: () => handleSee(),
                    }
                ]}
            />
        </>
    );
}