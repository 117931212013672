import {getAccordionStateNames, getSubAccordionStateNames} from "../constants/SideMenuConstants";

const SideMenuReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_ACCORDION':
      const accordionResult = {...state};

      getAccordionStateNames().forEach((stateName) => {
        accordionResult[stateName] = state[stateName]
      })

      accordionResult.accordionDefaultKey = null
      accordionResult.subAccordionDefaultKey = null

      return accordionResult
    case 'HANDLE_SUB_ACCORDION':
      const subAccordionResult = {...state};

      getSubAccordionStateNames().forEach((stateName) => {
        subAccordionResult[stateName] = state[stateName]
      })

      return subAccordionResult
    case 'HANDLE_DEFAULT_KEYS':
      return {
        ...state,
        accordionDefaultKey: action.accordionDefaultKey,
        subAccordionDefaultKey: action.subAccordionDefaultKey,
      }
    case 'HANDLE_MODAL':
      return {
        ...state,
        modalShow: action.modalShow,
      }
    case 'RELOAD_RING':
      return {
        ...state,
        reloadRing: action.reloadRing,
      }
    case 'HANDLE_ACTIVE_MENU':
      return {
        ...state,
        activeMenu: action.activeMenu,
      }
    default:
      throw new Error()
  }
}

export default SideMenuReducer;
