import {useContext, useEffect} from "react";
import TagManager from 'react-gtm-module';
import AuthContext from "../../../contexts/AuthContext";

export const NeedHelp = () => {
    const {user} = useContext(AuthContext);

    useEffect(() => {
            if (user) {
                const tagManagerArgs = {
                    gtmId: 'GTM-WZ68HC5',
                    dataLayer: {
                        code: `${user ? user.CodeFl : ''}`,
                        email: `${user ? user.Email : ''}`,
                        full_name: user ? `${user.Nom} ${user.Prenom}` : ''
                    }
                };

                TagManager.initialize(tagManagerArgs);
            }
    }, [user])

    return (<></>);
}