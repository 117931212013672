import React from 'react'

const LoginPresentationFooter = () => {
  return (
    <div className="bg-white-dark text-center px-0 py-3">
      <div>
        Vous n’avez pas encore vos codes d’accès ou souhaitez contacter le
        service client Florajet :
      </div>
      <a role="button" className="text-black-light text-no-underline ml-1">
        <b>0800 24 24 24 - commercial@florajet.com</b>
      </a>
    </div>
  )
}

export default LoginPresentationFooter
