/**
 * Fixe le nombre de chiffre après la virgule,
 * que {number} soit un nombre ou une chaine de caractère.
 *
 * @param number
 * @param fractionDigits
 *
 * @returns {*}
 */
export function parseFloatCustom(number, fractionDigits = undefined) {
  let result = number

  if (typeof number === 'string') {
    result = parseFloat(number.replace(',', '.'))
  }

  if (fractionDigits) {
    result = result.toFixed(fractionDigits)
  }

  return result
}
