import PropTypes from 'prop-types'
import React from 'react'

const Count = ({totalItems, name, classNames}) => {
  return (
    <div className={classNames}>
      <b>{totalItems}</b>
      {` ${name}${totalItems > 1 ? 's' : ''}`}
    </div>
  )
}

Count.propTypes = {
  totalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  name: PropTypes.string,
  classNames: PropTypes.string,
}

export default Count
