import React from 'react';
import PropTypes from "prop-types";
import {TabMenu} from "../../ui/menu/TabMenu";


const MagasinMenu = ({currentTab, setCurrentTab}) => {
    const tabs = [
        {type: 'magasin', title: 'Informations', pathname: 'magasin', linkActif: true},
        {type: 'conges', title: 'Congés/fermetures', pathname: 'conges', linkActif: true},
        {type: 'perimetre', title: 'Périmètre de livraison', pathname: 'perimetre', linkActif: true},
        {type: 'compteurs', title: 'Mes compteurs', pathname: 'compteurs', linkActif: true},
    ];

  return (
      <>
          <TabMenu currentTab={currentTab} tabs={tabs} numberTabsToShow={2} setCurrentTabFunction={setCurrentTab} />
      </>
  );
}

MagasinMenu.propTypes = {
    currentTab: PropTypes.string.isRequired,
    setCurrentTab: PropTypes.func.isRequired,
}

export default MagasinMenu
