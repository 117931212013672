import {format, sub} from "date-fns";
import API from './API'
import {
  CLOSURES_API,
  CLOSURES_POST_API, MASTER_HOLIDAYS_API,
  ITEMS_PER_PAGE,
} from '../constants/Constants'
import APIv2 from "./APIv2";

function getFermetures(codefl, sortType, sortOrder, currentPage = 1) {
  const dateStart = format(sub(new Date(), {
    years: 2,
  }), 'yyyy-01-01');

  return {
    name: 'congés',
    method: 'get',
    url: `${CLOSURES_API}?pagination=true&codefl=${codefl}&order%5B${sortType}%5D=${
      sortOrder || 'asc'
    }&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE}&datecongesfin[after]=${dateStart}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

async function postFermetures(codefl, datecongesdeb, datecongesfin) {
  return API({
    name: 'post_fermetures',
    method: 'post',
    url: CLOSURES_POST_API,
    data: {
      codefl,
      datecongesdeb,
      datecongesfin,
    },
  })
}

async function getCurrentHoliday(floristCode) {
  return API({
    name: 'congé',
    method: 'get',
    url: `${CLOSURES_API}/current?floristCode=${floristCode}`
  })
}

async function deleteHoliday(id) {
  return APIv2.requestData({
    name: 'delete_holiday',
    method: 'delete',
    url: `${MASTER_HOLIDAYS_API}/${id}`,
    headers: {
      accept: 'application/json',
    }
  })
}

async function countHolidaysByDate(date) {
  return APIv2.requestData({
    name: 'count_holiday_by_date',
    method: 'get',
    url: `${MASTER_HOLIDAYS_API}/${date}/count`
  })
}

export default {
  getFermetures,
  postFermetures,
  getCurrentHoliday,
  deleteHoliday,
  countHolidaysByDate
}
