import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Title from "../../ui/page/Title";
import {SavsFilters} from "./SavsFilters";
import {SavsTab} from "./SavsTab";

export function SavNew() {
    return (
        <>
            <Title title="Mes SAV" />

            <Col xs={12}>
                <SavsFilters />
                <Row>
                    <Col xs={12}>
                        <SavsTab />
                    </Col>
                </Row>
            </Col>
        </>
    );
}
