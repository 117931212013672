import {createContext, useReducer} from "react";
import PropTypes from "prop-types";
import SideMenuReducer from "../reducers/SideMenuReducer";
import {
  SIDE_MENU_AIDE, SIDE_MENU_BOUTIQUE, SIDE_MENU_MES_COMMANDES,
  SIDE_MENU_MES_OUTILS, SIDE_MENU_MON_COMPTE,
  SIDE_MENU_MON_FLORAJET,
  SIDE_MENU_PARAMETRES, SIDE_MENU_SOFTWARE
} from "../constants/SideMenuConstants";

export const Store = createContext();

export const initialSideMenuState = {
  activeMenu: false,
  modalShow: false,
  [SIDE_MENU_MON_FLORAJET.stateName]: true,
  [SIDE_MENU_MES_OUTILS.stateName]: false,
  [SIDE_MENU_AIDE.stateName]: false,
  [SIDE_MENU_PARAMETRES.stateName]: false,
  [SIDE_MENU_MES_COMMANDES.stateName]: true,
  [SIDE_MENU_MON_COMPTE.stateName]: false,
  [SIDE_MENU_BOUTIQUE.stateName]: false,
  [SIDE_MENU_SOFTWARE.stateName]: true,
  accordionDefaultKey: SIDE_MENU_MON_FLORAJET.eventKey,
  subAccordionDefaultKey: SIDE_MENU_MES_COMMANDES.eventKey,
};

export const MenuProvider = ({children}) => {
  const [state, dispatch] = useReducer(SideMenuReducer, initialSideMenuState);

  // Gestion de l'active menu
  const toggleMenu = () => {
    dispatch({
      type: 'HANDLE_ACTIVE_MENU',
      activeMenu: !state.activeMenu,
    })
  }
  
  return <Store.Provider value={{ state, dispatch, toggleMenu }}>{children}</Store.Provider>;
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired
}
