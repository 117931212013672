import Prelevements from '../../components/pages/comptabilite/Prelevements'
import Shoppers from '../../components/pages/shoppers/Shoppers'
import Utilisateurs from '../../components/pages/utilisateurs/Utilisateurs'
import Route from '../../class/Route'
import AdminRoute from '../AdminRoute'
import {LEVY_PATH, SHOPPERS_PATH, USERS_PATH} from '../../constants/RoutesPaths'

export const ADMIN_ROUTES = [
  new Route(LEVY_PATH, <AdminRoute component={Prelevements} />),
  new Route(SHOPPERS_PATH, <AdminRoute component={Shoppers} />),
  new Route(USERS_PATH, <AdminRoute component={Utilisateurs} />),
]
