import Alert from "react-bootstrap/Alert";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Holidays} from "./holidays/Holidays";
import {ExceptionalOpenings} from "./exceptional_openings/ExceptionalOpenings";
import Box from "../../ui/box/Box";

export const HolidaysAndExceptionalOpenings = () => {
    return (
        <>
            <Box
                boxName="infos"
                boxClassNames="mb-3 mt-3"
                boxContentPadding="p-3"
                boxContentClassNames="fs-14 rounded"
                boxContentBg="grey-light"
                content={
                    <>
                        <div>
                            <FontAwesomeIcon
                                icon="info-circle"
                                color="var(--primary)"
                                size="lg"
                                className="mr-2"
                            />
                            Dans le cas où vous ne pouvez supprimer vos congés, merci de contacter le service commercial au 0826.107.707. choix 2.
                        </div>
                    </>
                }
            />
            <Holidays/>
            <ExceptionalOpenings/>
        </>
    );
}
