import {formatISO} from "date-fns";

export const dataTransformer = data => {
    if (data instanceof Date) {
        // do your specific formatting here
        return formatISO(data)
    }
    if (Array.isArray(data)) {
        return data.map(val => dataTransformer(val))
    }
    if (typeof data === "object" && data !== null) {
        try {
            return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
                [ key, dataTransformer(val) ]))
        } catch (e) {
            console.log(e)
            if (e instanceof TypeError && e.message === "Object.fromEntries is not a function") {
                throw new Error('Merci de mettre à jour votre navigateur.')
            }
        }

    }
    return data
}