import {BANNERS_API} from '../constants/Constants'
import API from "./API";

function getBandeaux() {
  return API({
    name: 'bandeaux',
    method: 'get',
    url: BANNERS_API,
  })
}

export default {
  getBandeaux,
}
