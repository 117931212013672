import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

const Checkbox = ({
  id,
  type,
  className,
  isValid = false,
  label,
  labelClassname,
  inputClassname,
  checked = false,
  feedbackIsValid = true,
  feedbackTxt,
  onChange,
    disabled = false,
}) => (
  <Form.Check
    type={type}
    id={id}
    className={`custom-control custom-checkbox ${className || ''}`}>
    <Form.Check.Input
      type={type}
      isValid={isValid}
      className={`custom-control-input ${inputClassname || ''}`}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <Form.Check.Label
      className={`custom-control-label ${labelClassname || ''}`}>
      {label}
    </Form.Check.Label>
    {(feedbackTxt && (
      <Form.Control.Feedback type={(feedbackIsValid && 'valid') || 'invalid'}>
        {feedbackTxt}
      </Form.Control.Feedback>
    )) || <></>}
  </Form.Check>
)

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['checkbox', 'radio']),
  className: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassname: PropTypes.string,
  inputClassname: PropTypes.string,
  checked: PropTypes.bool,
  feedbackIsValid: PropTypes.bool,
  feedbackTxt: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default Checkbox
