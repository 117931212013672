import {Col} from 'react-bootstrap'
import React, {useContext, useEffect, useState} from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import ComptabiliteAPI from '../../../services/ComptabiliteAPI'
import Count from '../../ui/page/Count'
import {ITEMS_PER_PAGE_COMPTABILITE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import TableCustom from '../../ui/table/TableCustom'
import useFetch from '../../../hooks/useFetch'
import ComptaLayout from "../../ui/layout/ComptaLayout";

const Cheques = () => {
  const {user} = useContext(AuthContext)
  const [sortType, setSortType] = useState('date')
  const [sortOrder, setSortOrder] = useState('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)

  const [{data: cheques, isLoading, isError}, fetchData] = useFetch(
    ComptabiliteAPI.getCheques(
      user.CodeFl,
      sortType,
      sortOrder,
      currentPage,
      ITEMS_PER_PAGE_COMPTABILITE,
      false,
    ),
  )

  const [{data: count}, fetchDataCount] = useFetch(
    ComptabiliteAPI.getCheques(
      user.CodeFl,
      sortType,
      sortOrder,
      currentPage,
      ITEMS_PER_PAGE_COMPTABILITE,
      true,
    ),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      fetchData(
        ComptabiliteAPI.getCheques(
          user.CodeFl,
          sortType,
          sortOrder,
          currentPage,
          ITEMS_PER_PAGE_COMPTABILITE,
          false,
        ),
      )

      fetchDataCount(
        ComptabiliteAPI.getCheques(
          user.CodeFl,
          sortType,
          sortOrder,
          currentPage,
          ITEMS_PER_PAGE_COMPTABILITE,
          true,
        ),
      )
    }
  }, [user.CodeFl, sortType, sortOrder, currentPage])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
  }, [user.CodeFl])

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = (newSortType) => {
    let sort
    if (sortType !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setCurrentPage(1)
    setSortType(newSortType)
    setSortOrder(sort)
    setReload(true)
  }

  return (
    <>
      <ComptaLayout content={
        <>
          {count > 0 && !isLoading && (
            <Count
              classNames="text-right px-3 pt-2 bg-grey"
              totalItems={count}
              name="chèque"
            />
          )}

          <TableCustom
            isLoading={isLoading}
            isError={isError}
            headers={[
              'Date du chèque',
              'Numéro de pièce',
              'Libellé',
              'Montant',
            ]}
            sorting={['date', 'numero', 'libelle', 'montante']}
            handleSortOrder={handleSortOrder}
            sortType={sortType}
            sortOrder={sortOrder}
            data={cheques}
            config={[
              {
                type: 'date',
                key: 'date',
              },
              {
                type: 'text',
                key: 'numero',
              },
              {
                type: 'text',
                key: 'libelle',
              },
              {
                type: 'currency',
                key: 'montante',
              },
            ]}
          />

          {isLoading && <Loader />}

          {!isLoading && ITEMS_PER_PAGE_COMPTABILITE < count && (
            <PaginationCustom
              currentPage={currentPage || 1}
              itemsPerPage={ITEMS_PER_PAGE_COMPTABILITE}
              totalPages={count}
              handlePage={handlePage}
            />
          )}
        </>
      } />
    </>
  )
}

export default Cheques
