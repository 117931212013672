import API from './API'
import {POINTAGES_API} from '../constants/Constants'

function getPointagesPDF(codefl, type, dateDebut, dateFin) {
  return API({
    name: 'pointages',
    method: 'get',
    url: `${POINTAGES_API}?codefl=${codefl}&type=${type}${
      type === 'by-last-days' ? '&days=30' : ''
    }${
      type === 'search'
        ? `&date_debut=${dateDebut || ''}&date_fin=${dateFin || ''}`
        : ''
    }`,
  })
}

export default {
  getPointagesPDF,
}
