import {TOP10_FL_API, TOP10_NET_API} from '../constants/Constants'

function getTop10Net() {
  return {
    name: 'bouquets',
    method: 'get',
    url: TOP10_NET_API,
  }
}

function getTop10Fl() {
  return {
    name: 'bouquets',
    method: 'get',
    url: TOP10_FL_API,
  }
}

export default {
  getTop10Net,
  getTop10Fl,
}
