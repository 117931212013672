import React, {useContext, useEffect, useState} from "react";
import OrderCardGroup from "../../ui/card/OrderCardGroup";
import {ITEMS_PER_PAGE} from "../../../constants/Constants";
import {useStoreExecutions} from "../../../store/useStoreExecutions";
import AuthContext from "../../../contexts/AuthContext";
import {useExecutions} from "../../../hooks/executions/useExecutions";
import {useShopperPrebook} from "../../../hooks/useShoppers";
import {TakePicture} from "../../ui/image/TakePicture";
import {useActionsButtons} from "../../../hooks/executions/useActionsButtons";
import ModalCustom from "../../ui/modal/ModalCustom";
import ShopperModals from "../../ui/modal/ShopperModals";

export const ExecutionsCards = () => {
    const {user} = useContext(AuthContext);
    const getExecutions = useStoreExecutions(state => state.getExecutions);
    const executions = useStoreExecutions(state => state.executions);
    const isLoading = useStoreExecutions(state => state.isLoading);
    const isError = useStoreExecutions(state => state.isError);
    const {
        actionButtons,
        showModalInformation,
        setShowModalInformation,
        modalSize,
        modalTitle,
        modalContent,
        buttons,
        handleCancellationModal
    } = useActionsButtons();

    // Permet de charger une première fois les données avec Zustand
    useEffect(() => {
        getExecutions(user);
    }, [getExecutions]);

    return (
        <>
            {executions && executions.length > 0 && (
                <OrderCardGroup
                    isLoading={isLoading}
                    isError={isError}
                    data={executions}
                    actionButtons={actionButtons}
                    max={ITEMS_PER_PAGE}
                    user={user}
                />
            )}
            <ModalCustom
                show={showModalInformation}
                id={'executions-modal'}
                onHide={() => setShowModalInformation(false)}
                size={modalSize}
                title={modalTitle}
                titleClassname={!modalTitle && 'text-center mb-3'}
                header={false}
                bodyClassname={'text-left'}
                content={
                    <>
                        {modalContent}
                    </>
                }
                footer={true}
                buttonsFooter={[
                    ...buttons,
                    ...[
                        {
                            text: 'Fermer',
                        },
                    ]
                ]}
            />

            <ShopperModals
                handleCancellationModal={handleCancellationModal}
                executions={executions}
            />
        </>
    );
}