import API from './API'
import {CLIENTS_API, CP_VILLE_API, PAYS_API} from '../constants/Constants'

function getClients(codeFl) {
  return {
    name: 'clients',
    method: 'get',
    url: `${CLIENTS_API}?codfl=${codeFl}&actif=1&pagination=false`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

async function postClient(data) {
  return API({
    name: 'post_client',
    method: 'post',
    url: CLIENTS_API,
    data,
  })
}

async function updateClient(id, data) {
  return API({
    name: 'post_client',
    method: 'patch',
    url: `${CLIENTS_API}/${id}`,
    data,
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  })
}

function getPays(search) {
  return API({
    name: 'pays',
    method: 'get',
    url: `${PAYS_API}&libelle=${search}&itemsPerPage=ALL`,
    single: true,
  })
}

function getPaysByCode(codiso) {
  return API({
    name: 'pays',
    method: 'get',
    url: `${PAYS_API}&codiso=${codiso}&itemsPerPage=ALL`,
    single: true,
  })
}

function getPaysByLabel(label) {
  return API({
    name: 'pays',
    method: 'get',
    url: `${PAYS_API}&libelle=${label}&itemsPerPage=ALL`,
    single: true,
  })
}

function getClientByName(codeFl, search) {
  return API({
    name: 'clients',
    method: 'get',
    url: `${CLIENTS_API}?codfl=${codeFl}&nom=${search}&actif=1&pagination=false`,
  })
}

function getClientByTel(codeFl, search) {
  return API({
    name: 'clients',
    method: 'get',
    url: `${CLIENTS_API}?codfl=${codeFl}&tel=${search}&actif=1&pagination=false`,
  })
}

function getClientBySearch(codeFl, searchType, search) {
  return {
    name: 'clients',
    method: 'get',
    url: `${CLIENTS_API}?codfl=${codeFl}${
      searchType ? `&${searchType}=${search}` : ''
    }&actif=1&pagination=false`,
  }
}

function getCodePostal(search) {
  return API({
    name: 'code postal',
    method: 'get',
    url: `${CP_VILLE_API}?codpostal=${search}&actif=1&pagination=false`,
    single: true,
  })
}

function getLocalite(search) {
  return API({
    name: 'localités',
    method: 'get',
    url: `${CP_VILLE_API}?localite=${search}&actif=1&pagination=false`,
    female: true,
  })
}

export default {
  getClients,
  postClient,
  updateClient,
  getPays,
  getPaysByCode,
  getPaysByLabel,
  getClientByName,
  getClientByTel,
  getCodePostal,
  getLocalite,
  getClientBySearch,
}
