import React, {useContext, useState} from "react";
import {useExecutions} from "./useExecutions";
import {useShopperPrebook} from "../useShoppers";
import AuthContext from "../../contexts/AuthContext";
import {TakePicture} from "../../components/ui/image/TakePicture";

export const useActionsButtons = () => {
    const {user} = useContext(AuthContext);
    const {
        handleShowOrder,
        handleRefusal,
        handleState,
        handleViewPic,
        isLoading: isLoadingButtonsActions,
        modalContent,
        showModalInformation,
        setShowModalInformation,
        modalTitle,
        modalSize,
        buttons
    } = useExecutions();
    const {
        handleShoppersModal,
        handleShowPrebookButton,
        handleShowCancellationPrebookButton,
        handleCancellationShoppersModal,
        handleCancellationModal,
    } = useShopperPrebook(user);

    const handleTakePic = (order) => {
        const [modalTakePicture, setModalTakePicture] = useState(true);

        return (
            <>
                <TakePicture
                    showModalTakePicture={modalTakePicture}
                    order={order}
                    onHide={setModalTakePicture}
                />
            </>
        )
    }

    const actionButtons = [
        {
            name: 'Visualiser',
            nameParam: 'numcde',
            color: 'primary',
            icon: 'eye',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleShowOrder,
            funcParam: 'numcde',
            filter: 'actions',
            filterParam: 'visu',
            isLoading: isLoadingButtonsActions,
        },
        {
            name: 'Voir la photo',
            color: 'primary',
            icon: 'image',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleViewPic,
            funcParam: 'url_photo1',
            filter: 'actions',
            filterParam: 'view_pic',
        },
        {
            name: 'Prendre une nouvelle photo',
            color: 'primary',
            icon: 'camera',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleTakePic,
            funcParam: 'numcde',
            filter: 'actions',
            filterParam: 'webcam',
        },
        {
            name: 'Refuser la commande',
            color: 'red',
            icon: 'times',
            iconColor: 'white',
            iconClassNames: 'margin-icon',
            classnames: 'm-1',
            func: handleRefusal,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_3',
        },
        {
            name: 'Accepter la commande',
            color: 'green-darkest',
            icon: 'check',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_4',
        },
        {
            name: 'Destinataire appelé',
            color: 'orange',
            icon: 'phone',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_41',
        },
        {
            name: 'Destinataire ne répond pas',
            color: 'red',
            icon: 'phone',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_42',
        },
        {
            name: 'Bouquet préparé',
            color: 'blue',
            icon: 'gift',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_43',
        },
        {
            name: `Passer à l'état "En cours de livraison"`,
            color: 'primary-dark',
            icon: 'truck',
            iconColor: 'white',
            classnames: 'm-1 padding-icon-xl',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_5',
        },
        {
            name: `Passer à l'état "Commande livrée"`,
            color: 'primary-dark',
            icon: 'smile',
            iconColor: 'white',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_6',
        },
        {
            name: `Passer à l'état "Avis de passage déposé ou destinataire appelé"`,
            color: 'orange',
            icon: 'map-marker-alt',
            iconColor: 'white',
            iconClassNames: 'margin-icon',
            classnames: 'm-1',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_7',
        },
        {
            name: 'Passer à l\'état "Commande livrée après avis de passage"',
            color: 'primary',
            icon: 'smile',
            iconColor: 'white',
            classnames: 'm-1 padding-icon-xl',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_60',
        },
        {
            name: `Passer à l'état "Refus par le client final"`,
            color: 'red',
            icon: 'exclamation-triangle',
            iconColor: 'white',
            classnames: 'm-1 padding-icon',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_8',
        },
        {
            name: `Passer à l'état "Commande refusée après avis de passage"`,
            color: 'red',
            icon: 'exclamation-triangle',
            iconColor: 'white',
            classnames: 'm-1 padding-icon',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_80',
        },
        {
            name: 'Arrêter la sonnerie',
            color: 'primary',
            icon: 'bell-slash',
            iconColor: 'white',
            classnames: 'm-1 padding-icon-xl',
            func: handleState,
            isLoading: isLoadingButtonsActions,
            filter: 'actions',
            filterParam: 'statut_20',
        },
        {
            name: 'Demander une livraison',
            color: 'yellow-base',
            icon: 'biking',
            iconColor: 'white',
            classnames: 'm-1 padding-icon-xl',
            func: handleShoppersModal, // Ouvre la modale de séléction d'un shopper.
            isLoading: isLoadingButtonsActions,
            view: handleShowPrebookButton, // Affiche ou pas le bouton.
        },
        {
            name: 'Annuler livraison',
            color: 'red',
            icon: 'biking',
            iconColor: 'white',
            classnames: 'm-1 padding-icon-xl',
            func: handleCancellationShoppersModal, // Ouvre la modale de séléction d'un shopper.
            isLoading: isLoadingButtonsActions,
            view: handleShowCancellationPrebookButton, // Affiche ou pas le bouton.
        },
    ];

    return {
        actionButtons,
        handleShowOrder,
        showModalInformation,
        modalSize,
        modalTitle,
        modalContent,
        buttons,
        setShowModalInformation,
        handleCancellationModal
    };
}