import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FooterInfos from './FooterInfos'
import FooterLegende from './FooterLegende'
import FooterSocial from './FooterSocial'

const Footer = () => {
  return (
      <div></div>
    // <Row className="footer fixed-bottom d-flex align-items-center bg-grey no-gutters p-3 d-print-none">
    //   <Col xl={9}>
    //     <FooterInfos />
    //   </Col>
    //   <Col
    //     xl={3}
    //     className="d-flex flex-row justify-content-end align-items-center col-xl-3 mt-1 mt-xl-0">
    //     <FooterLegende />
    //     <FooterSocial />
    //   </Col>
    // </Row>
  )
}

export default Footer
