import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";

export function BannerContent({banner}) {
    // Redimensionne la partie "message" lorsque la bannière est chargée
    function resizeHeader() {
        const header = $('#layout-header');
        const info = $('#layout-header .info');
        const infoContent = $(header).find('.content');
        const banner = $('#layout-header img.banner');

        if (window.innerWidth > 992) {
            const height = Math.round($(banner).innerHeight());
            $(header).css('max-height', Math.round(height));
            $(info).css('max-height', Math.round(height));
            $(infoContent).css('max-height', Math.round(height-60)); // Pour éviter que l'overflow ne déborde
        } else {
            $(header).css('height', '');
        }
    }

    return (
        <>
            {banner.urlBandeau
                ? (
                    <a href={banner.urlBandeau} target={'_blank'} rel={'noreferrer'} download>
                        <img
                            className="banner img-fluid"
                            src={banner.fichier}
                            alt={banner.fichier}
                            onLoad={resizeHeader}
                        />
                    </a>
                )
                : (
                    <img
                        className="banner img-fluid"
                        src={banner.fichier}
                        alt={banner.fichier}
                        onLoad={resizeHeader}
                    />
                )
            }

        </>
    );
}

BannerContent.propTypes = {
    banner: PropTypes.object.isRequired
}