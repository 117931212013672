import DatePicker, {registerLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import React, {useEffect, useState, useContext, useRef} from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {BrowserView, MobileView} from 'react-device-detect'
import moment from 'moment'
import {useFormik} from 'formik'
import {useLocation, useNavigate} from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'
import * as Yup from 'yup'
import {format} from 'date-fns'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import Box from '../../ui/box/Box'
import CommandeAPI from '../../../services/CommandeAPI'
import Field from '../../ui/form/Field'
import {INTERVAL_RELOAD, ITEMS_PER_PAGE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import NavItemCustom from '../../../router/NavItemCustom'
import SavAPI from '../../../services/SavAPI'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import TransmissionsAPI from '../../../services/TransmissionsAPI'
import useFetch from '../../../hooks/useFetch'
import Count from '../../ui/page/Count'
import OrderCardGroup from '../../ui/card/OrderCardGroup'
import {TabMenu} from '../../ui/menu/TabMenu'
import {navigate} from "../../../slice/globalSlice";
import {ORDER_ENTRY_PATH} from "../../../constants/RoutesPaths";

registerLocale('fr', fr)

const Transmissions = () => {
  const {user} = useContext(AuthContext)
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [searchText, setSearchText] = useState()
  const [searchDateDebut, setSearchDateDebut] = useState()
  const [searchDateFin, setSearchDateFin] = useState()
  const [searchActive, setSearchActive] = useState()
  const [dateDebut, setDateDebut] = useState()
  const [dateFin, setDateFin] = useState()
  const [order, setOrder] = useState()
  const [modalOrder, setModalOrder] = useState(false)
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false)
  const [modalDeleteMessage, setModalDeleteMessage] = useState(false)
  const [sav, setSav] = useState(false)
  const [modalSav, setModalSav] = useState(false)
  const [isLoadingSav, setIsLoadingSav] = useState(false)
  const [isErrorSav, setIsErrorSav] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isErrorDelete, setIsErrorDelete] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isErrorPdf, setIsErrorPdf] = useState(false)
  const [transition, setTransition] = useState(false)
  const isUnmounted = useRef(false)
  const location = useLocation()
  const [type, setType] = useState(
    location.state && location.state.type ? location.state.type : 'today',
  )
  const isEnattente = () => {
    return ['enattente', 'enattente_search'].includes(type)
  }
  const [sortType, setSortType] = useState(isEnattente() ? 'datliv' : 'numcde')

  const [types] = useState([
    {type: 'enattente', title: 'En attente', pathname: 'transmissions'},
    {type: 'today', title: `Aujourd'hui`, pathname: 'transmissions'},
    {type: 'yesterday', title: 'Hier', pathname: 'transmissions'},
    {type: 'last-week', title: 'Semaine dernière', pathname: 'transmissions'},
    {type: 'last-month', title: 'Mois dernier', pathname: 'transmissions'},
    {
      type: 'by-last-days',
      title: '30 derniers jours',
      pathname: 'transmissions',
    },
    {type: 'search', title: 'Recherche', pathname: 'transmissions'},
  ])

  const validationSchema = Yup.object().shape({
    reclaReclamant: Yup.string().matches(
      /^(?!default$).*/,
      '* Ce champ est requis.',
    ),
    reclaMotif: Yup.string().matches(
      /^(?!default$).*/,
      '* Ce champ est requis.',
    ),
    reclaDesc: Yup.string()
      .min(2, '* Ce champ doit contenir au minimum 2 caractères')
      .required('* Ce champ est requis.'),
  })

  const formik = useFormik({
    initialValues: {
      reclaCommande: sav.reclaCommande,
      reclaDesc: sav.reclaDesc || '',
      reclaReclamant: sav.reclaReclamant || 'default',
      reclaMotif: sav.reclaMotif || 'default',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, {setSubmitting}) => {
      const data = {
        reclaCommande: values.reclaCommande,
        reclaDesc: values.reclaDesc,
        reclaMotif: parseInt(values.reclaMotif, 10),
        reclaReclamant: parseInt(values.reclaReclamant, 10),
      }
      postSav(data, setSubmitting)
    },
  })

  const [savReclamants, setSavReclamants] = useState([]);
  const [savMotifs, setSavMotifs] = useState([]);

  useEffect(() => {
    setSavReclamantsInSession();
    setSavMotifsInSession();
  }, []);

  async function setSavReclamantsInSession() {
    if (sessionStorage.getItem('list-reclamants') === null) {
      await SavAPI.getSavReclamants().then((response) => {
        sessionStorage.setItem('list-reclamants', JSON.stringify(response.data));
        setSavReclamants(response.data);
      });
    } else {
      setSavReclamants(JSON.parse(sessionStorage.getItem('list-reclamants')));
    }
  }

  async function setSavMotifsInSession() {
    if (sessionStorage.getItem('list-motifs') === null) {
      await SavAPI.getSavMotifs().then((response) => {
        sessionStorage.setItem('list-motifs', JSON.stringify(response.data));
        setSavMotifs(response.data);
      });
    } else {
        setSavMotifs(JSON.parse(sessionStorage.getItem('list-motifs')));
    }
  }

  const [
    {data: transmissions, isLoading, isError, totalItems: count},
    fetchData,
  ] = useFetch(
    TransmissionsAPI.getTransmissions(
      type,
      sortType,
      sortOrder,
      currentPage,
      '',
      '',
      '',
      ITEMS_PER_PAGE,
    ),
  )

  // Gestion du rechargement de page
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(false)
    }, INTERVAL_RELOAD)

    return () => clearInterval(interval)
  }, [])

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      fetchData(
        TransmissionsAPI.getTransmissions(
          type,
          sortType,
          sortOrder,
          currentPage,
          searchText,
          dateDebut && format(dateDebut, 'dd-MM-yyyy HH:mm:ss'),
          dateFin && format(dateFin, 'dd-MM-yyyy HH:mm:ss'),
          ITEMS_PER_PAGE,
        ),
      )
    }
  }, [
    type,
    searchText,
    searchDateDebut,
    searchDateFin,
    sortType,
    sortOrder,
    currentPage,
  ])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
  }, [user.CodeFl])

  // Gestion du changement de type depuis le param location du menu
  useEffect(() => {
    if (location.state) {
      setSortType(sortType)
      setSortOrder(sortOrder)
      setCurrentPage(1)
      setReload(true)
    }
  }, [location])

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = (newSortType) => {
    let sort
    if (sortType !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setCurrentPage(1)
    setSortType(newSortType)
    setSortOrder(sort)
    setReload(true)
  }

  // Gestion des champs recherche
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value)
  }

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setType(
      ['enattente', 'enattente_search'].includes(type)
        ? 'enattente_search'
        : 'search',
    )
    setSearchActive(true)
    setSearchText(searchInput)
    setSearchDateDebut(dateDebut)
    setSearchDateFin(dateFin)
    setSortType(sortType)
    setSortOrder(sortOrder)
    setReload(true)
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setSearchInput('')
    setSearchText()
    setSearchActive()
    setType('today')
    setSearchDateDebut()
    setSearchDateFin()
    setDateDebut()
    setDateFin()
    setSortType(sortType)
    setSortOrder(sortOrder)
    setReload(true)
  }

  // Gestion du bouton Visualiser la commande
  const handleShowOrder = async ([numcde, id]) => {
    if (!isUnmounted.current) {
      setIsLoadingPdf(true)
      setTransition(false)
      setIsErrorPdf(false)
      setModalOrder(true)
      setOrder(() => {
        return numcde !== null && numcde !== '' ? numcde : id
      })
    }

    try {
      if (!isUnmounted.current) {
        const result = await CommandeAPI.getPDF(user.CodeFl, numcde, id)
        if (!result.data) {
          setIsErrorPdf({
            variant: 'danger',
            heading: true,
            content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else {
          setOrder((prevState) => ({...prevState, pdf: result.data}))
          setIsLoadingPdf(false)
        }
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingPdf(false)
        setTransition(true)
        setIsErrorPdf({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
        })
      }
    }
  }

  // Gestion du bouton Modifier la commande
  const handleEditOrder = (order) => {
    navigate(ORDER_ENTRY_PATH, {state: {order}})
  }

  // Gestion du bouton Supprimer la commande
  const handleShowModalDelete = (order) => {
    setModalDeleteOrder(true)
    setOrder(order)
  }

  // Gestion de la suppression de la commande
  const handleDeleteOrder = async (order) => {
    setIsLoadingDelete(true)
    setTransition(false)
    setIsErrorDelete(false)

    try {
      if (!isUnmounted.current) {
        await TransmissionsAPI.deleteTransmissions(order.idsenattente)
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo !',
          headingIconName: 'info-circle',
          content: 'Votre commande a bien été supprimée.',
        })

        fetchData(
          TransmissionsAPI.getTransmissions(
            type,
            sortType,
            sortOrder,
            currentPage,
            searchText,
            dateDebut,
            dateFin,
            type === 'enattente' ? 'ALL' : ITEMS_PER_PAGE,
          ),
        )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être supprimée, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setModalDeleteOrder(false)
    setModalDeleteMessage(true)
  }

  // Gestion du bouton Ajouter/Modifier un SAV
  const handleViewSav = async (sav) => {
    setIsLoadingSav(false)
    setTransition(false)
    setIsErrorSav(false)
    setModalSav(true)

    if (sav && sav[1]) {
      try {
        const result = await SavAPI.getSav(sav[1])

        const motifId = savMotifs.filter(
          (motif) => motif.motifDesc === result.data.reclaMotif,
        )

        setSav({
          reclaCommande: sav[0],
          reclaDesc: result.data.reclaTxtplaignant,
          reclaReclamant: result.data.reclaPlaignant,
          reclaMotif: motifId[0].motifIdx,
          type: 'update',
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      setSav({reclaCommande: sav[0], type: 'create'})
    }
  }

  // Gestion de l'envoi du SAV
  const postSav = async (values, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingSav(true)
      setIsErrorSav(false)
      setTransition(false)
    }
    try {
      if (!isUnmounted.current) {
        await SavAPI.postSav(user.CodeFl, sav.type, values)
        setIsLoadingSav(false)
        setTransition(true)
        setIsErrorSav({
          variant: 'success',
          heading: true,
          headingTitle:
            'Bravo ! Votre demande de création SAV a bien été envoyée.',
          headingIconName: 'info-circle',
          content: 'Votre demande sera traitée par le service commercial.',
        })
        formik.resetForm()
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingSav(false)
        setTransition(true)
        setIsErrorSav({
          variant: 'danger',
          heading: true,
          content: error.response.data.detail,
        })
      }
    }
    setSubmitting(false)
  }

  const actionButtons = [
    {
      name: 'Visualiser',
      nameParam: 'numcde',
      color: 'primary',
      icon: 'eye',
      iconColor: 'white',
      classnames: 'm-1 padding-icon',
      func: handleShowOrder,
      funcParam: ['numcde', 'idsgribouil'],
      filter: 'actions',
      filterParam: 'visu',
    },
    {
      name: 'Créer un dossier SAV',
      color: 'primary',
      icon: 'file-contract',
      iconColor: 'white',
      iconClassNames: 'margin-icon',
      classnames: 'm-1',
      func: handleViewSav,
      funcParam: ['numcde', 'recla_idx'],
      filter: 'actions',
      filterParam: 'sav_creat',
    },
    {
      name: 'Modifier un dossier SAV',
      color: 'primary-dark',
      icon: 'file-signature',
      iconColor: 'white',
      classnames: 'm-1 padding-icon',
      func: handleViewSav,
      funcParam: ['numcde', 'recla_idx'],
      filter: 'actions',
      filterParam: 'sav_view',
    },
  ]

  const actionPendingButtons = [
    {
      name: 'Modifier la commande',
      color: 'primary',
      icon: 'pen',
      iconColor: 'white',
      classnames: 'm-1 padding-icon',
      func: handleEditOrder,
    },
    {
      name: 'Supprimer la commande',
      color: 'red',
      icon: 'trash',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleShowModalDelete,
    },
  ]

  const buildHeaders = () => {
    return isEnattente()
      ? [
          'Date et moment de livraison',
          'Destinataire',
          'Code postal ville',
          'Montant',
          'Actions',
        ]
      : [
          'Etat',
          'Numéro',
          'Destinataire',
          'Livraison',
          'Code postal ville',
          'Montant',
          'Exécutant',
          'Actions',
        ]
  }

  const buildConfig = () => {
    return isEnattente()
      ? [
          {
            type: 'multilines',
            key: [
              {type: 'date', key: 'datliv'},
              {type: 'moment', key: 'momentjour'},
            ],
          },
          {type: 'text', key: 'nomdes'},
          {
            type: 'multilines',
            key: [
              {type: 'text', key: 'codpostal'},
              {type: 'text', key: 'localite'},
            ],
          },
          {type: 'currency', key: 'amountvat'},
          {
            type: 'buttons',
            key: actionPendingButtons,
            view: true,
          },
        ]
      : [
          {
            type: 'picto',
            key: 'etatextranet',
          },
          {
            type: 'multilines',
            key: [
              {
                type: 'link_modal',
                key: 'numcde',
                defaultKey: 'idsgribouil',
                func: handleShowOrder,
                funcParam: ['numcde', 'idsgribouil'],
              },
            ],
          },
          {
            type: 'text',
            key: 'nomdes',
          },
          {
            type: 'multilines',
            key: [
              {type: 'date', key: 'datliv'},
              {type: 'moment', key: 'momentjour'},
            ],
          },
          {
            type: 'multilines',
            key: [
              {type: 'text', key: 'codpostal'},
              {type: 'text', key: 'localite'},
            ],
          },
          {type: 'currency', key: 'amountvat'},
          {
            type: 'multilines',
            key: [
              {type: 'text', key: 'codex'},
              {type: 'phone', key: 'telseek', array: 'extra'},
            ],
          },
          {
            type: 'buttons',
            key: actionButtons,
          },
        ]
  }

  const buildSorting = () => {
    return isEnattente()
      ? ['datliv', 'nomdes', 'localite', 'montantcdee', 'codex']
      : ['', 'numcde', 'nomdes', 'datliv', 'localite', 'montantcdee', 'codex']
  }

  return (
    <>
      <Title title="Mes transmissions" />

      <Col xs={12} className="filter">
        <Form onSubmit={handleSearch} className={'w-100 d-flex'}>
          <div className="d-flex flex-column flex wrap mr-md-4 mb-3 px-0 col-md-6">
            <div className="form-label mr-4 mb-1">
              Localité ou numéro de commande :
            </div>
            <Field
              name="searchTransmissions"
              placeholder="Localité ou numéro de commande"
              style={{width: '100%'}}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>

          <div className="d-flex flex-column flex wrap mr-4 mb-3">
            <div className="form-label mb-1">Date de début :</div>
            <DatePicker
              name="start"
              onChange={setDateDebut}
              selected={dateDebut}
              locale={'fr'}
              dateFormat={'dd/MM/yyyy'}
              showLeadingZeros={true}
              calendarIcon={null}
              className={'form-control'}
              placeholderText={'--/--/----'}
            />
          </div>

          <div className="d-flex flex-column flex wrap mr-4 mb-3">
            <div className="form-label mb-1">Date de fin :</div>
            <DatePicker
              name="end"
              onChange={setDateFin}
              selected={dateFin}
              locale={'fr'}
              dateFormat={'dd/MM/yyyy'}
              showLeadingZeros={true}
              calendarIcon={null}
              className={'form-control'}
              placeholderText={'--/--/----'}
            />
          </div>

          <div className="d-flex flex-row align-self-end mb-3">
            <Button
              type="submit"
              className="d-flex btn-square align-items-center mr-2"
              disabled={searchInput === '' && !dateDebut && !dateFin}>
              <FontAwesomeIcon icon="search" color="white" />
            </Button>
            <Button
              className="btn-red btn-square d-flex align-items-center"
              onClick={handleCancel}
              disabled={searchInput === '' && !dateDebut && !dateFin}>
              <FontAwesomeIcon
                icon="times"
                color="white"
                className="margin-icon"
              />
            </Button>
          </div>
        </Form>
      </Col>

      <Box
        boxName="transmissions"
        boxContentBg="white"
        content={
          <>
            <BrowserView renderWithFragment={true}>
              <TabMenu
                setCurrentTabFunction={setType}
                currentTab={type}
                tabs={types}
                isLoading={isLoading}
                numberOfElements={count}
              />
            </BrowserView>

            <MobileView renderWithFragment={true}>
              <TabMenu
                currentTab={type}
                tabs={types}
                numberTabsToShow={3}
                setCurrentTabFunction={setType}
              />

              {count > 0 && !isLoading && (
                <Count
                  classNames="text-right p-2 bg-grey rounded mb-2"
                  totalItems={count}
                  name="transmission"
                />
              )}
            </MobileView>

            <BrowserView>
              <TableCustom
                isLoading={isLoading}
                isError={isError}
                headers={(() => buildHeaders())()}
                sorting={(() => buildSorting())()}
                handleSortOrder={handleSortOrder}
                sortType={sortType}
                sortOrder={sortOrder}
                data={transmissions}
                config={(() => buildConfig())()}
              />

              {isLoading && <Loader />}
            </BrowserView>

            <MobileView>
              <OrderCardGroup
                isLoading={isLoading}
                isError={isError}
                data={transmissions}
                actionButtons={
                  type === 'enattente' ? actionPendingButtons : actionButtons
                }
                max={ITEMS_PER_PAGE}
                user={user}
              />
            </MobileView>
          </>
        }
      />

      {order && (
        <>
          <ModalCustom
            show={modalOrder}
            onHide={() => setModalOrder(false)}
            id="modal-order"
            bodyClassname="text-left"
            content={
              <>
                <AlertCustom
                  variant={isErrorPdf.variant}
                  heading={isErrorPdf.heading}
                  bodyContent={isErrorPdf.content}
                  transition={transition}
                />

                {isLoadingPdf && <Loader />}

                {order.pdf && (
                  <div
                    className="embed-responsive embed-responsive-1by1 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: `<iframe
                  src="${order.pdf}"
                   class="embed-responsive-item"
                   allowtransparency="true"></iframe>`,
                    }}></div>
                )}
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: 'Fermer',
              },
            ]}
          />

          <ModalCustom
            show={modalDeleteOrder}
            onHide={() => setModalDeleteOrder(false)}
            id="modal-order-delete"
            bodyClassname="p-5"
            content={
              <>
                <h3>Etes-vous sûr de vouloir supprimer cette commande ?</h3>
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: isLoadingDelete ? (
                  <Loader variant="white" size="sm" classname="my-1 mx-2" />
                ) : (
                  'Confirmer'
                ),
                func: () => handleDeleteOrder(order),
                classNames: 'mx-2',
              },
              {
                text: 'Annuler',
                classNames: 'btn-grey-darker mx-2',
              },
            ]}
          />

          <ModalCustom
            show={modalDeleteMessage}
            onHide={() => setModalDeleteMessage(false)}
            id="modal-delete-message"
            content={
              <>
                <AlertCustom
                  classname="text-left"
                  variant={isErrorDelete.variant}
                  heading={isErrorDelete.heading}
                  headingTitle={isErrorDelete.headingTitle}
                  headingIconName={isErrorDelete.headingIconName}
                  bodyContent={isErrorDelete.content}
                  bodyContentItalic={isErrorDelete.detail}
                  transition={transition}
                />
              </>
            }
            buttons={[
              {
                text: 'Fermer',
                classNames: 'mx-2',
              },
            ]}
          />
        </>
      )}

      <ModalCustom
        show={modalSav}
        onHide={() => setModalSav(false)}
        id="modal-sav"
        title={`${
          sav.type === 'create' ? 'Ajouter un' : 'Modifier le'
        } SAV pour la commande ${sav.reclaCommande ? sav.reclaCommande : ''}`}
        titleClassname="text-center mb-3"
        bodyClassname="text-left"
        content={
          <>
            <Col md={{span: 10, offset: 1}} className="text-left">
              <AlertCustom
                variant={isErrorSav.variant}
                heading={isErrorSav.heading}
                headingTitle={isErrorSav.headingTitle}
                headingIconName={isErrorSav.headingIconName}
                bodyContentHtml={isErrorSav.content}
                transition={transition}
                dismissible={true}
                onClose={() => setTransition(false)}
                classname="m-0"
              />

              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-wrap justify-content-between mt-3">
                  <div className="d-flex flex-wrap mb-3">
                    <div className="mr-1">N° commande :</div>
                    <div className="ff-bold">{sav.reclaCommande}</div>
                  </div>
                  <div className="d-flex flex-wrap mb-3">
                    <div className="mr-1">Date d'ouverture :</div>
                    <div className="ff-bold">
                      {moment(new Date()).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </div>

                <Form.Group controlId="reclaReclamant" className="mb-3">
                  <Form.Label>
                    Réclamant<span className="text-red"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className={
                      (formik.touched.reclaReclamant &&
                        formik.errors.reclaReclamant &&
                        'is-invalid') ||
                      (formik.touched.reclaReclamant &&
                        !formik.errors.reclaReclamant &&
                        'is-valid')
                    }
                    value={formik.values.reclaReclamant}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    <option value="default">Sélectionner un réclamant</option>
                    {savReclamants &&
                      savReclamants.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                  </Form.Control>
                  {formik.touched.reclaReclamant &&
                    formik.errors.reclaReclamant && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.reclaReclamant}
                      </Form.Control.Feedback>
                    )}
                </Form.Group>

                <Form.Group controlId="reclaMotif" className="mb-3">
                  <Form.Label>
                    Motif<span className="text-red"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className={
                      (formik.touched.reclaMotif &&
                        formik.errors.reclaMotif &&
                        'is-invalid') ||
                      (formik.touched.reclaMotif &&
                        !formik.errors.reclaMotif &&
                        'is-valid')
                    }
                    value={formik.values.reclaMotif}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    <option value="default">Sélectionner un motif</option>
                    {savMotifs &&
                      savMotifs.map((item, index) => (
                        <option key={index} value={item.motifIdx}>
                          {item.motifDesc}
                        </option>
                      ))}
                  </Form.Control>
                  {formik.touched.reclaMotif && formik.errors.reclaMotif && (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.reclaMotif}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Field
                  as="textarea"
                  rows={10}
                  label="Description du SAV"
                  labelRequired={true}
                  name="reclaDesc"
                  value={formik.values.reclaDesc}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Entrez votre description"
                  classNames="mb-3"
                  error={formik.touched.reclaDesc && formik.errors.reclaDesc}
                  success={formik.touched.reclaDesc && !formik.errors.reclaDesc}
                />

                <Button
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                  className="btn-primary">
                  {isLoadingSav ? (
                    <Loader variant="white" size="sm" classname="my-1 mx-3" />
                  ) : (
                    'Envoyer'
                  )}
                </Button>
              </Form>
            </Col>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: 'Fermer',
          },
        ]}
      />
    </>
  )
}

export default Transmissions
