import API from './API'
import {HISTORIQUE_API, HISTORIQUE_PDF_API} from '../constants/Constants'

function getHistorique(
  codefl,
  searchText,
  searchDate,
  sortType,
  sortOrder,
  currentPage,
  itemsPerPage,
) {
  return {
    name: 'commandes',
    method: 'get',
    url: `${HISTORIQUE_API}&osCodefl=${codefl}${
      searchText ? `&osBoncmd=${searchText}` : ''
    }${searchDate ? `&osDateAdd=${searchDate}` : ''}&order%5B${sortType}%5D=${
      sortOrder || 'desc'
    }&page=${currentPage}&itemsPerPage=${itemsPerPage}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

function getPDF(codefl, osBoncmd) {
  return API({
    name: 'fichiers',
    method: 'get',
    url: `${HISTORIQUE_PDF_API}?codefl=${codefl}&boncmd=${osBoncmd}`,
  })
}

export default {
  getHistorique,
  getPDF,
}
