import Text from '../helpers/Text'

const FetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {...state, isLoading: true, isError: false}
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        totalItems: action.totalItems,
      }
    case 'FETCH_SUCCESS_EMPTY':
      return {
        ...state,
        isLoading: false,
        isError: {
          variant: 'warning',
          icon: true,
          content: `Il n'y a actuellement aucun${action.female && 'e'} ${
            action.single
              ? action.name
              : action.name && Text.RemoveLastChar(action.name)
          }.`,
        },
        data: action.payload,
        totalItems: action.totalItems,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: {
          variant: 'danger',
          heading: true,
          content: `Un problème réseau est survenu lors du chargement ${
            action.single ? (action.female ? 'de la' : 'du') : 'des'
          } ${action.name}.`,
          message: action.error.message,
          detail: action.error.response
            ? action.error.response.data['hydra:description']
              ? action.error.response.data['hydra:description']
              : action.error.response.data.detail
            : '',
        },
        data: action.payload,
        totalItems: action.totalItems,
      }
    default:
      throw new Error()
  }
}

export default FetchReducer
