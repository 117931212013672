import PropTypes from 'prop-types'
import React, {useContext, useState} from 'react'

import AchatsAPI from '../services/AchatsAPI'
import AuthContext from './AuthContext'

export const CartContext = React.createContext()

const CartContextProvider = ({children}) => {
  const [cartItems, setCartItems] = useState([])
  const [cartMontantHT, setCartMontantHT] = useState(0)
  const [cartDeliveries, setCartDeliveries] = useState(0)
  const [cartTotalHT, setCartTotalHT] = useState(0)
  const [cartTVA, setCartTVA] = useState(0)
  const [cartTotalTTC, setCartTotalTTC] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState('prélèvement');

  const {user} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  // Gestion de l'ajout au panier
  const addToCart = (product, quantity, size, sizeId, priceUnit) => {
    const updatedCart = [...cartItems]

    const priceTotal = handlePriceTotal(priceUnit, quantity)
    const delivery = handleDelivery(product, sizeId, quantity)
    const totalHT = priceTotal + delivery
    const montantTVA = handleTVA(totalHT, product.articlesExtranetTva)
    const totalTTC = totalHT + montantTVA

    // On vérifie si l'article existe déjà (index = -1: n'existe pas)
    const index = updatedCart.findIndex(
      (item) =>
        item.idSarticles === product.idSarticles && item.cartSizeId === sizeId,
    )

    // Si l'article existe on le met à jour sinon on l'ajoute
    if (index !== -1) {
      const updatedItem = {
        ...updatedCart[index],
      }
      updatedItem.cartQuantity = quantity
      updatedItem.cartSize = size
      updatedItem.cartSizeId = sizeId
      updatedItem.cartPriceUnit = priceUnit
      updatedItem.cartPriceTotal = priceTotal
      updatedItem.cartDelivery = delivery
      updatedItem.cartTotalHT = totalHT
      updatedItem.cartMontantTVA = montantTVA
      updatedItem.cartTotalTTC = totalTTC

      updatedCart[index] = updatedItem
    } else {
      updatedCart.unshift({
        ...product,
        cartQuantity: quantity,
        cartSize: size,
        cartSizeId: sizeId,
        cartPriceUnit: priceUnit,
        cartPriceTotal: priceTotal,
        cartDelivery: delivery,
        cartTotalHT: totalHT,
        cartMontantTVA: montantTVA,
        cartTotalTTC: totalTTC,
      })
    }
    setCartItems(updatedCart)

    handleMontantHT(updatedCart)
    handleTotalTVA(updatedCart)
    handleDeliveries(updatedCart)
    handleTotalHT(updatedCart)
    handleTotalTTC(updatedCart)
  }

  // Gestion de la suppression du panier
  const removeFromCart = (product, sizeId) => {
    const updatedCart = [...cartItems]

    // On vérifie si l'article existe déjà (index = -1: n'existe pas)
    const index = updatedCart.findIndex(
      (item) =>
        item.idSarticles === product.idSarticles &&
        (item.cartSizeId === product.cartSizeId || item.cartSizeId === sizeId),
    )

    // Si l'article existe on le supprime
    if (index !== -1) {
      updatedCart.splice(index, 1)
    }
    setCartItems(updatedCart)

    handleMontantHT(updatedCart)
    handleTotalTVA(updatedCart)
    handleDeliveries(updatedCart)
    handleTotalHT(updatedCart)
    handleTotalTTC(updatedCart)
  }

  // Calcul du prix total d'un article
  const handlePriceTotal = (priceTotal, quantity) => {
    return priceTotal * quantity
  }

  // Calcul de la TVA d'un article
  const handleTVA = (montantHT, tva) => {
    return (montantHT * tva) / 100
  }

  // Calcul des frais de livraison d'un article
  const handleDelivery = (product, sizeId, quantity) => {
    let type = {}
    product.articleTypes.forEach((articleType) => {
      if (articleType.articlesExtranetTypeId === sizeId) {
        type = articleType
      }
    })

    let price = 0
    type.livraisons.forEach((livraison) => {
      // Si quantité = quantité de la livraison de l'article type
      // OU
      // quantité de la livraison de l'article type = 0
      // SINON on prend la 1ère livraison du 1er article type
      if (
        quantity === livraison.articlesExtranetLivraisonQuantite ||
        livraison.articlesExtranetLivraisonQuantite === 0
      ) {
        product.cartDelivery = livraison.articlesExtranetLivraisonPrix
      } else {
        price =
          product.articleTypes[0].livraisons[0].articlesExtranetLivraisonPrix

        // Si les frais de livraison sont < au nouveau prix sélectionné
        if (
          product.cartDelivery === undefined ||
          product.cartDelivery < price
        ) {
          product.cartDelivery = price
        }
      }
    })

    return product.cartDelivery
  }

  // Calcul du total montant HT
  const handleMontantHT = (updatedCart) => {
    setCartMontantHT(0)
    const newCartMontantHT = updatedCart.reduce(function (accumulator, item) {
      return accumulator + item.cartPriceTotal
    }, 0)
    setCartMontantHT(newCartMontantHT)
  }

  // Calcul du total TVA
  const handleTotalTVA = (updatedCart) => {
    setCartTVA(0)
    const newCartMontantTVA = updatedCart.reduce(function (accumulator, item) {
      return accumulator + item.cartMontantTVA
    }, 0)
    setCartTVA(newCartMontantTVA)
  }

  // Calcul du total des frais de livraison
  const handleDeliveries = (updatedCart) => {
    setCartDeliveries(0)
    const newCartDeliveries = updatedCart.reduce(function (accumulator, item) {
      return accumulator + item.cartDelivery
    }, 0)
    setCartDeliveries(newCartDeliveries)
  }

  // Calcul du total HT
  const handleTotalHT = (updatedCart) => {
    setCartTotalHT(0)
    const newCartTotalHT = updatedCart.reduce(function (accumulator, item) {
      return accumulator + item.cartTotalHT
    }, 0)
    setCartTotalHT(newCartTotalHT)
  }

  // Calcul du total TTC
  const handleTotalTTC = (updatedCart) => {
    setCartTotalTTC(0)
    const newCartTotalTTC = updatedCart.reduce(function (accumulator, item) {
      return accumulator + item.cartTotalTTC
    }, 0)
    setCartTotalTTC(newCartTotalTTC)
  }

  function handlePaymentMethod(paymentMethod) {
    if (cartTotalTTC < 100 && paymentMethod !== 'prélèvement') {
      paymentMethod = 'prélèvement';
    }

    setPaymentMethod(paymentMethod);
  }

  // Gestion du vidage du panier
  const handleClear = () => {
    setCartItems([])
    setIsError(false)
  }

  // Gestion de la validation du panier
  const handleCheckout = async () => {
    setIsError(false)
    setIsLoading(true)

    try {
      const lines = []
      cartItems.forEach((item) => {
        const line = {}
        line.idProduct = item.idSarticles
        line.idSousProduct = item.cartSizeId
        line.oslQuantity = item.cartQuantity
        line.oslPrixTotal = item.cartPriceTotal
        line.oslFraisLivraison = item.cartDelivery
        line.oslMontantTotal = item.cartTotalHT
        line.oslTauxTva = item.articlesExtranetTva
        line.oslMontantTva = item.cartMontantTVA
        line.oslMontantTtc = item.cartTotalTTC

        lines.push(line)
      })

      await AchatsAPI.postAchats({
        osCodefl: user.CodeFl,
        osPrixTotal: cartTotalHT.toString(),
        osFraisLivraison: cartDeliveries.toString(),
        osMontantTotal: cartMontantHT.toString(),
        osMontantTotalTva: cartTVA,
        osMontantTotalTtc: cartTotalTTC,
        paymentMethod,
        lines,
      })

      setIsLoading(false)
      setIsError({
        variant: 'success',
        heading: true,
        headingTitle: 'Bravo ! Votre panier a bien été validé.',
        headingIconName: 'info-circle',
        content: 'Nous traitons votre demande dans les plus brefs délais.',
      })

      setCartItems([])
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setIsError({
        variant: 'danger',
        heading: true,
        content: `Votre panier n'a pas pu être validé, veuillez réessayer ultérieurement.`,
      })
    }
  }

  const contextValues = {
    cartItems,
    addToCart,
    removeFromCart,
    cartMontantHT,
    cartDeliveries,
    cartTotalHT,
    cartTVA,
    cartTotalTTC,
    handlePriceTotal,
    handleMontantHT,
    handleTotalTVA,
    handleDeliveries,
    handleTotalHT,
    handleTotalTTC,
    handleClear,
    handleCheckout,
    handlePaymentMethod,
    isLoading,
    isError,
    setIsError,
  }

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  )
}

CartContextProvider.propTypes = {
  children: PropTypes.any,
}

export default CartContextProvider
