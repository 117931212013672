import {useState} from "react";

export const useSavs = () => {
    const [showModalOrder, setShowModalOrder] = useState(false);
    const [showModalSav, setShowModalSav] = useState(false);
    const [orderNumber, setOrderNumber] = useState();
    const [sav, setSav] = useState();

    function handleShowOrder(orderNumber) {
        setOrderNumber(orderNumber);
        setShowModalOrder(true);
    }

    const handleShowSav = (sav) => {
        setShowModalSav(true);
        setSav(sav);
    }

    return {
        showModalOrder,
        setShowModalOrder,
        orderNumber,
        setOrderNumber,
        handleShowOrder,
        showModalSav,
        setShowModalSav,
        sav,
        setSav,
        handleShowSav
    };
}