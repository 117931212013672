import {useSavs} from "./useSavs";

export const useActionsButtons = () => {
    const {
        handleShowOrder,
        handleShowSav,
        orderNumber,
        setShowModalOrder,
        sav,
        setShowModalSav,
        showModalOrder,
        showModalSav
    } = useSavs();

    const actionsButtons = [
        {
            name: 'Visualiser',
            nameParam: 'reclaNumcde',
            color: 'primary',
            icon: 'eye',
            iconColor: 'white',
            classnames: 'm-1 padding-icon',
            func: handleShowOrder,
            funcParam: 'reclaNumcde',
        },
        {
            name: 'Afficher les détails du SAV',
            color: 'primary',
            icon: 'file-contract',
            iconColor: 'white',
            iconClassNames: 'margin-icon',
            classnames: 'm-1',
            func: handleShowSav,
        },
    ];

    return {
        actionsButtons,
        orderNumber,
        setShowModalOrder,
        sav,
        setShowModalSav,
        showModalOrder,
        showModalSav,
        handleShowOrder
    };
}