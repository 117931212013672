import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import ModalCustom from '../../ui/modal/ModalCustom'
import Loader from '../../ui/loader/Loader'
import CancellationModalContent from './CancellationModalContent'
import {
  selectShoppersModals,
  showCancellationShoppersModal,
} from '../../../slice/shoppersModalsSlice'

const CancellationModal = ({handleCancellationModal, executions}) => {
  const dispatch = useDispatch()
  const {cancellationShow, cancelBtnClass, loading} = useSelector(
    selectShoppersModals,
  )

  /** Ouvre la modale permettant de supprimer un prebook */
  const handleShopperCancellationOnHide = () => {
    dispatch(showCancellationShoppersModal({show: false}))
  }

  return (
    <>
      <ModalCustom
        id={'shopperCancellationModal'}
        title="Annulation de livraison"
        titleClassname="text-center mb-3"
        bodyClassname="text-left"
        footer={true}
        show={cancellationShow}
        onHide={handleShopperCancellationOnHide}
        buttonsFooter={[
          {
            text: 'Annuler la livraison',
            onClick: handleCancellationModal,
            classNames: cancelBtnClass,
          },
          {
            text: 'Fermer',
            onClick: handleShopperCancellationOnHide,
          },
        ]}
        content={
          !loading ? (
            <CancellationModalContent executions={executions} />
          ) : (
            <Loader />
          )
        }
      />
    </>
  )
}

CancellationModal.propTypes = {
  handleCancellationModal: PropTypes.func.isRequired,
  executions: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.object.isRequired,
  ]),
}

export default CancellationModal
