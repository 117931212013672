import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "../../ui/form/Select";
import Count from "../../ui/page/Count";
import {useStoreSavs} from "../../../store/useStoreSavs";

export const SavsFilters = () => {
    const sortClaimantLabel = useStoreSavs(state => state.sortClaimantLabel);
    const setSortClaimant = useStoreSavs(state => state.setSortClaimant);
    const sortStateLabel = useStoreSavs(state => state.sortStateLabel);
    const setSortState = useStoreSavs(state => state.setSortState);
    const sortStatusLabel = useStoreSavs(state => state.sortStatusLabel);
    const setSortStatus = useStoreSavs(state => state.setSortStatus);
    const savsCount = useStoreSavs(state => state.savsCount);

    return (
        <>
            <Row className={'mb-3'}>
                <Col>
                    <Select
                        label={'Réclamant'}
                        defaultValue={sortClaimantLabel}
                        value={sortClaimantLabel}
                        onChange={(event) =>
                            setSortClaimant(
                                event.target.options[event.target.options.selectedIndex].getAttribute('id'),
                                event.target.options[event.target.options.selectedIndex].value
                            )
                        }
                        options={
                            <>
                                <option id={''}>Indifférent</option>
                                <option id={'1'}>Client expéditeur</option>
                                <option id={'2'}>Fleuriste transmetteur (TR)</option>
                                <option id={'3'}>Florajet</option>
                                <option id={'4'}>Fleuriste exécutant (EX)</option>
                                <option id={'5'}>Transporteur</option>
                            </>
                        }
                    />
                </Col>
                <Col>
                    <Select
                        label={'État'}
                        defaultValue={sortStateLabel}
                        value={sortStateLabel}
                        onChange={(event) => setSortState(
                            event.target.options[event.target.options.selectedIndex].getAttribute('id'),
                            event.target.options[event.target.options.selectedIndex].value
                        )}
                        options={
                            <>
                                <option id={''}>Indifférent</option>
                                <option id={'0'}>En cours</option>
                                <option id={'1'}>Clôturé</option>
                            </>
                        }
                    />
                </Col>
                <Col>
                    <Select
                        label={'Statut'}
                        defaultValue={sortStatusLabel}
                        value={sortStatusLabel}
                        onChange={(event) => setSortStatus(
                            event.target.options[event.target.options.selectedIndex].getAttribute('id'),
                            event.target.options[event.target.options.selectedIndex].value
                        )}
                        options={
                            <>
                                <option id={''}>Indifférent</option>
                                <option id={'1'}>Fondé</option>
                                <option id={'0'}>Non fondé</option>
                            </>
                        }
                    />
                </Col>
                <Col className={'text-right mt-auto'}>
                    <Count
                        classNames={'mr-auto'}
                        totalItems={savsCount}
                        name={'dossier'}
                    />
                </Col>
            </Row>
        </>
    );
}