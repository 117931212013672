import {FLORIST_API} from '../constants/Constants'
import API from './API'

function getFleuristes(search) {
  return API({
    name: 'fleuristes',
    method: 'get',
    url: `${FLORIST_API}?pagination=false&codefl=${search}&itemsPerPage=ALL`,
  })
}

function getFleuristeByCode(codefl) {
  return API({
    name: 'fleuriste',
    method: 'get',
    url: `${FLORIST_API}?pagination=false&codefl=${codefl}`,
    single: true,
  })
}

function getFloristsByFloristCodeAndExcludedContracts(search) {
  return API({
    name: 'fleuristes',
    method: 'get',
    url: `${FLORIST_API}/contracts/excluded?floristCode=${search}`
  })
}

export default {
  getFleuristes,
  getFleuristeByCode,
  getFloristsByFloristCodeAndExcludedContracts,
}
