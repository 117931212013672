import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import {Navigate} from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'
import AuthAPI from '../services/AuthAPI'
import {ROOT_PATH} from '../constants/RoutesPaths'

const AdminRoute = ({component: Component}) => {
  const {isAuthenticated, user} = useContext(AuthContext)
  const [isAdmin, setIsAdmin] = useState(AuthAPI.isAdministrator(user))

  return (
    (isAuthenticated && isAdmin && <Component />) || <Navigate to={ROOT_PATH} />
  )
}

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default AdminRoute
