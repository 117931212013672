import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {getIn, useFormik} from 'formik'

import PropTypes from "prop-types";
import SearchCommuneFormGroup from '../commune/SearchCommuneFormGroup'
import {parseFloatCustom} from '../../../../Tools/Numbers'
import {
  addOnPending,
  selectPerimetre,
} from '../../../../slice/perimetreSlice'
import {
  handleShowModalForm,
  initEditingPerimetre,
  selectPerimetrePage,
} from '../../../../slice/perimetrePageSlice'
import Sperimetr from '../../../../class/Sperimetr'
import SperimetrValidation from '../../../../class/SperimetrValidation'
import AuthContext from '../../../../contexts/AuthContext'

const PerimetreForm = ({scrollToPending}) => {
  const {user} = useContext(AuthContext)

  const [disabledIdscommunes, setDisabledIdscommunes] = useState([])
  const [selectedCommune, setSelectedCommune] = useState([
    {codpostal: '', localite: '', idscommunes: ''},
  ])

  const dispatch = useDispatch()

  const {data: sperimetr, onPending, currentOnPending} = useSelector(selectPerimetre)
  const {editingPerimetre} = useSelector(selectPerimetrePage)

  const formik = useFormik({
    initialValues:
      editingPerimetre instanceof Sperimetr
        ? editingPerimetre.initializedValues()
        : Sperimetr.initialeValues(),
    validationSchema: Sperimetr.validationSchemas(),
    onSubmit: (values) => {
      const [communeData] = selectedCommune
      const newSperimetrValidation = new SperimetrValidation(
        {...values, ...{codefl: user.CodeFl}},
        communeData,
      )

      dispatch(addOnPending(newSperimetrValidation))
      dispatch(initEditingPerimetre())
      dispatch(handleShowModalForm(false))
      scrollToPending()
    },
  })

  useEffect(() => {
    setDisabledIdscommunes(
      Object.values({...sperimetr, ...onPending, ...currentOnPending}).map(
        (perimetr) => perimetr.idscommunes,
      ),
    )
  }, [sperimetr, onPending, currentOnPending])

  useEffect(() => {
    if (editingPerimetre instanceof Sperimetr) {
      setSelectedCommune([editingPerimetre.buildCommune()])
    }
  }, [editingPerimetre])

  return (
    <Form className={'mt-4'} onSubmit={formik.handleSubmit}>
      <SearchCommuneFormGroup
        disabled={editingPerimetre instanceof Sperimetr}
        formGroupZipcodeClassname={
          'd-flex justify-content-between align-items-center mb-2'
        }
        formGroupLocaliteClassname={
          'd-flex justify-content-between align-items-center mb-2'
        }
        selectedCommune={selectedCommune}
        disabledIdscommunes={disabledIdscommunes}
        onChange={(value) => {
          setSelectedCommune(value)

          if (value.length > 0) {
            formik.setFieldValue('idscommunes', value[0].idscommunes)
          }
        }}
        zipcodeClassname={`col-5 ${
          (getIn(formik.touched, 'idscommunes') &&
            getIn(formik.errors, 'idscommunes') &&
            'is-invalid') ||
          ''
        }`}
        localiteClassname={`col-5 ${
          (getIn(formik.touched, 'idscommunes') &&
            getIn(formik.errors, 'idscommunes') &&
            'is-invalid') ||
          ''
        }`}
      />

      <Form.Group
        controlId={'montantminie'}
        className={'d-flex justify-content-between align-items-center mb-2'}>
        <Form.Label>
          Montant minimum<span className="text-red">*</span>
        </Form.Label>

        <Form.Control
          className={'col-5'}
          type={'number'}
          step={0.5}
          min={0}
          value={formik.values.montantminie}
          onChange={formik.handleChange}
          onBlur={() => {
            if (formik.values.montantminie !== undefined) {
              formik.setFieldValue(
                'montantminie',
                parseFloatCustom(formik.values.montantminie, 2),
              )
            }
          }}
        />
      </Form.Group>

      <Form.Check
        type={'checkbox'}
        label={'Dépannage'}
        name={'depannage'}
        onChange={formik.handleChange}
        checked={formik.values.depannage}
      />

      <Button type={'submit'} className={'w-100 mt-4'}>
        Ajouter
      </Button>
    </Form>
  )
}

PerimetreForm.propTypes = {
  scrollToPending: PropTypes.func.isRequired,
}

export default PerimetreForm
