export default class Shopper {
  constructor({
    reference,
    shopperConfig,
    credentialPayload,
  }) {
    this.reference = reference
    this.config = shopperConfig
    this.credentialPayload = credentialPayload
  }
}
