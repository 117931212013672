import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import Count from '../../ui/page/Count'
import Field from '../../ui/form/Field'
import HistoriqueAPI from '../../../services/HistoriqueAPI'
import {ITEMS_PER_PAGE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'

const Historique = () => {
  const {user} = useContext(AuthContext)
  const [sortType, setSortType] = useState('osDateAdd')
  const [sortOrder, setSortOrder] = useState('desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [searchText, setSearchText] = useState()
  const [order, setOrder] = useState()
  const [modalOrder, setModalOrder] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isErrorPdf, setIsErrorPdf] = useState(false)
  const [transition, setTransition] = useState(false)
  const isUnmounted = useRef(false)

  const [
    {data: historique, totalItems, isLoading, isError},
    fetchData,
  ] = useFetch(
    HistoriqueAPI.getHistorique(
      user.CodeFl,
      '',
      '',
      sortType,
      sortOrder,
      currentPage,
      ITEMS_PER_PAGE,
    ),
  )

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      fetchData(
        HistoriqueAPI.getHistorique(
          user.CodeFl,
          searchText,
          '',
          sortType,
          sortOrder,
          currentPage,
          ITEMS_PER_PAGE,
        ),
      )
    }
  }, [user.CodeFl, searchText, sortType, sortOrder, currentPage])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
  }, [user.CodeFl])

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = (newSortType) => {
    let sort
    if (sortType !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setCurrentPage(1)
    setSortType(newSortType)
    setSortOrder(sort)
    setReload(true)
  }

  // Gestion des champs recherche
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value)
  }

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setSearchText(searchInput)
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setSearchInput('')
    setSearchText()
  }

  // Gestion du bouton Visualiser la commande
  const handleShowOrder = async (id) => {
    if (!isUnmounted.current) {
      setIsLoadingPdf(true)
      setTransition(false)
      setIsErrorPdf(false)
      setOrder({id})
      setModalOrder(true)
    }
    try {
      if (!isUnmounted.current) {
        const result = await HistoriqueAPI.getPDF(user.CodeFl, id)

        if (!result.data) {
          setIsErrorPdf({
            variant: 'danger',
            heading: true,
            content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else {
          setOrder((prevState) => ({...prevState, pdf: result.data}))
        }
        setIsLoadingPdf(false)
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingPdf(false)
        setTransition(true)
        setIsErrorPdf({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
        })
      }
    }
  }

  return (
    <>
      <Title title="Mon historique" />

      <Col
        xs={12}
        className="filter d-flex flex-row flex-wrap align-items-end justify-content-between">
        <Form onSubmit={handleSearch} className={'d-flex'}>
          <div className="d-flex flex-column flex wrap mr-4 mb-3">
            <div className="form-label mr-4 mb-1">Numéro de commande :</div>
            <Field
              name="searchHistorique"
              placeholder="Numéro de commande"
              style={{width: '100%'}}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>

          <div className="d-flex flex-row align-self-end mb-3">
            <Button
              type="submit"
              className="btn-primary btn-square d-flex align-items-center mr-2"
              disabled={searchInput === ''}>
              <FontAwesomeIcon icon="search" color="white" />
            </Button>
            <Button
              className="btn-red btn-square d-flex align-items-center"
              onClick={handleCancel}
              disabled={searchInput === ''}>
              <FontAwesomeIcon
                icon="times"
                color="white"
                className="margin-icon"
              />
            </Button>
          </div>
        </Form>

        <Count classNames="mb-3" totalItems={totalItems} name="commande" />
      </Col>

      <Col xs={12}>
        <TableCustom
          isLoading={isLoading}
          isError={isError}
          full={!isMobile}
          headers={[
            'Numéro',
            'N° commande',
            'Date',
            'Total HT',
            'Total TVA',
            'Total TTC',
            'Email',
            'Actions',
          ]}
          sorting={[
            'osId',
            'osBoncmd',
            'osDateAdd',
            'osMontantTotal',
            'osMontantTotalTva',
            'osMontantTotalTtc',
            'osAdresseEmail',
          ]}
          handleSortOrder={handleSortOrder}
          sortType={sortType}
          sortOrder={sortOrder}
          data={historique}
          config={[
            {type: 'text', key: 'osId'},
            {type: 'text', key: 'osBoncmd', required: true},
            {type: 'text', key: 'osDateAdd', required: true},
            {type: 'currency', key: 'osMontantTotal', required: true},
            {type: 'currency', key: 'osMontantTotalTva', required: true},
            {type: 'currency', key: 'osMontantTotalTtc', required: true},
            {type: 'text', key: 'osAdresseEmail'},
            {
              type: 'buttons',
              view: true,
              required: true,
              key: [
                {
                  name: 'Visualiser',
                  nameParam: 'osBoncmd',
                  color: 'primary',
                  icon: 'eye',
                  iconColor: 'white',
                  classnames: 'm-1 padding-icon',
                  func: handleShowOrder,
                  funcParam: 'osBoncmd',
                },
              ],
            },
          ]}
        />

        {isLoading && <Loader />}

        {!isLoading && ITEMS_PER_PAGE < totalItems && (
          <PaginationCustom
            currentPage={currentPage || 1}
            itemsPerPage={ITEMS_PER_PAGE}
            totalPages={totalItems}
            handlePage={handlePage}
          />
        )}
      </Col>

      {order && (
        <>
          <ModalCustom
            show={modalOrder}
            onHide={() => setModalOrder(false)}
            id="modal-order"
            title={`Visualiser la commande ${order.id}`}
            titleClassname="text-center mb-3"
            bodyClassname="text-left"
            content={
              <>
                <AlertCustom
                  variant={isErrorPdf.variant}
                  heading={isErrorPdf.heading}
                  bodyContent={isErrorPdf.content}
                  transition={transition}
                />

                {isLoadingPdf && <Loader />}

                {order.pdf && (
                  <div
                    className="embed-responsive embed-responsive-1by1 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: `<iframe
                  src="${order.pdf}"
                   class="embed-responsive-item"
                   allowtransparency="true"></iframe>`,
                    }}></div>
                )}
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: 'Fermer',
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default Historique
