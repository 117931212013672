import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useState} from "react";
import Count from "../../../ui/page/Count";
import useFetch from "../../../../hooks/useFetch";
import OuverturesAPI from "../../../../services/OuverturesAPI";
import AuthContext from "../../../../contexts/AuthContext";
import {ExceptionalOpeningsContentTab} from "./ExceptionalOpeningsContentTab";
import {ExceptionalOpeningsForm} from "./ExceptionalOpeningsForm";

export const ExceptionalOpeningsContent = () => {
    const {user} = useContext(AuthContext);
    const [showAddExceptionalOpenings, setShowAddExceptionalOpenings] = useState(false);
    const [{data: exceptionalOpenings, totalItems, isLoading, isError}, fetchData] = useFetch(OuverturesAPI.getOuvertures(user.CodeFl));

    return (
        <>
            <Container fluid>
                <Row className={'bg-light p-3'}>
                    <Col className={'my-auto'}>
                        <h3>Ouvert le</h3>
                    </Col>
                    <Col className={'my-auto d-lg-flex justify-content-end'}>
                        <Button
                            onClick={() => setShowAddExceptionalOpenings(true)}
                            variant={'primary'}
                            className={'mr-3'}
                        >
                            <FontAwesomeIcon
                                icon="plus-circle"
                                color="white"
                                className="mr-2"
                            />
                            Ajouter
                        </Button>
                        <Count totalItems={totalItems} name={'ouverture'} classNames={'my-auto'}/>
                    </Col>
                </Row>
            </Container>
            <hr className="m-0"/>
            <ExceptionalOpeningsContentTab exceptionalOpenings={exceptionalOpenings} isLoading={isLoading} fetchData={fetchData} isError={isError}/>

            <ExceptionalOpeningsForm show={showAddExceptionalOpenings} onHide={() => setShowAddExceptionalOpenings(false)} fetchData={fetchData} />
        </>
    );
}