import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {FormCheck} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useContext, useState} from "react";
import Text from "../../../../helpers/Text";
import AuthContext from "../../../../contexts/AuthContext";
import AccessoryAPI from "../../../../services/AccessoryAPI";

export const StockAccessory = ({accessory}) => {
    const {user} = useContext(AuthContext)
    const [accessoryHasAccessoryFlorist, setAccessoryHasAccessoryFlorist] = useState(accessory.accessoryFlorist);
    const [inStock, setInStock] = useState(accessory.accessoryFlorist && accessory.accessoryFlorist.hasAccessory);

    const createOrUpdateAccessoryFlorist = (floristHasAccessory) => {
        if (accessoryHasAccessoryFlorist) {
            AccessoryAPI.updateAccessoryFlorist(accessory.id, user.IdFleuriste, floristHasAccessory);
        } else {
            AccessoryAPI.createAccessoryFlorist(accessory.id, user.IdFleuriste, floristHasAccessory)
                .then(() => {
                    setAccessoryHasAccessoryFlorist(true);
                });
        }
    }

    const handleChangeinStock = (inStock) => {
        setInStock(inStock);
        createOrUpdateAccessoryFlorist(inStock)
    }

    return (
        <>
            <Col>
                <Card className={'mb-3'}>
                    <Card.Img
                        variant="top"
                        className={'img-fluid rounded mx-auto'}
                        src={`https://medias.florajet.com/_w_/design/acc/${accessory.reference}/${accessory.reference}_amb.jpg`}
                    />
                    <Card.Body className={'pb-0 d-flex flex-column justify-content-end align-items-center'}>
                        <Card.Title className={'text-center'}>{Text.CapitalizeAll(accessory.label)}</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <Form className={'mx-auto'}>
                            <FormCheck
                                inline
                                type={"radio"}
                                id={`inline-${accessory.id}-yes`}
                                name={`stock-${accessory.id}`}
                                label={'En stock'}
                                checked={inStock}
                                onChange={() => handleChangeinStock(true)}
                            />
                            <FormCheck
                                inline
                                type={"radio"}
                                id={`inline-${accessory.id}-no`}
                                name={`stock-${accessory.id}`}
                                label={'Rupture'}
                                checked={!inStock}
                                className={'mr-0 negative'}
                                onChange={() => handleChangeinStock(false)}
                            />
                        </Form>
                    </Card.Footer>
                </Card>
            </Col>
        </>
    );
}

StockAccessory.propTypes = {
    accessory: PropTypes.object.isRequired,
}