import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'

import Loader from '../components/ui/loader/Loader'
import {isBool, isFunction} from '../Tools/TypeOf'

const ActionButtons = ({item: itemAction, buttons}) => {
  const item = {...itemAction, ...{actions: {}}}

  if (
    item.extra &&
    (Object.keys(item.extra).length > 0 || item.extra.length > 0)
  ) {
    if (item.extra.actions && Object.keys(item.extra.actions).length > 0) {
      for (const [actionKey, actionValue] of Object.entries(
        item.extra.actions,
      )) {
        item.actions[actionKey] = actionValue
      }
    }
    if (item.extra.url_photo1) {
      item.url_photo1 = item.extra.url_photo1
    }
  }

  const isDisabled = (disabled, item) => {
    let result = false

    if (isFunction(disabled)) {
      result = disabled(item)
    } else if (isBool(disabled)) {
      result = disabled
    }

    return result
  }

  return (
    <>
      {buttons.map(
        (button, i) =>
          ((typeof button.view === typeof (() => {}) &&
            button.view(item, button.viewParams || null, button)) ||
            button.view === undefined ||
            (typeof button.view === typeof false && button.view)) &&
          (button.filter && item[button.filter] ? (
            Object.keys(item[button.filter]).map(
              (filter, i) =>
                filter === button.filterParam && (
                  <OverlayTrigger
                    key={i}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${filter}`}>
                        {button.name}
                        {button.nameParam && ` ${item[button.nameParam]}`}
                      </Tooltip>
                    }>
                    <Button
                      key={i}
                      onClick={() =>
                        button.func &&
                        button.func(
                          button.funcParam
                            ? button.funcParam instanceof Array
                              ? button.funcParam.map((param) => item[param])
                              : item[button.funcParam]
                            : {item, id: button.filterParam},
                        )
                      }
                      className={`btn-${button.color} ${
                        (isFunction(button.classnames) &&
                          button.classnames({item})()) ||
                        button.classnames
                      }`}>
                      {button.isLoading === true || (button.isLoading &&
                      button.isLoading.type.key ===
                        item[button.isLoading.key] &&
                      (button.isLoading.type.value === true || button.isLoading)) ? (
                        <Loader
                          variant="white"
                          size="sm"
                          classname="my-1"
                          style={{marginLeft: '2px', marginRight: '2px'}}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={button.icon}
                          className={button.iconClassNames}
                          color={button.iconColor}
                        />
                      )}
                    </Button>
                  </OverlayTrigger>
                ),
            )
          ) : (
            <OverlayTrigger
              key={i}
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${i}`}>
                  {button.name}
                  {button.nameParam && ` ${item[button.nameParam]}`}
                </Tooltip>
              }>
              <Button
                key={i}
                onClick={() =>
                  button.func &&
                  button.func(
                    button.funcParam
                      ? button.funcParam instanceof Array
                        ? button.funcParam.map((param) => item[param])
                        : item[button.funcParam]
                      : item,
                  )
                }
                className={`mr-1 ml-1 btn-${button.color} ${
                  (isFunction(button.classnames) && button.classnames(item)) ||
                  button.classNames
                }`}
                disabled={isDisabled(button.disabled, item)}>
                {button.isLoading === true || (button.isLoading &&
                button.isLoading.type.key === item[button.isLoading.key] &&
                button.isLoading.type.value === true) ? (
                  <Loader variant="white" size="sm" classname="my-1 mx-0" />
                ) : (
                  <FontAwesomeIcon
                    icon={button.icon}
                    className={button.iconClassNames}
                    color={button.iconColor}
                  />
                )}
              </Button>
            </OverlayTrigger>
          )),
      )}
    </>
  )
}

export default ActionButtons

ActionButtons.propTypes = {
  item: PropTypes.object,
  buttons: PropTypes.array.isRequired,
}
