export const BASE_URL = 'https://preprod-www.monflorajet.com/'

export const INTERVAL_RELOAD = 600000
export const INTERVAL_NOTIFICATIONS = 120000
export const INTERVAL_RELOAD_SAVS = 300000

export const INTERVAL_RELOAD_BANNERS = 1800000

export const ITEMS_PER_PAGE = 9999
export const ITEMS_PER_PAGE_DASHBOARD_EXEC = 10
export const ITEMS_PER_PAGE_DASHBOARD_TRANS = 20
export const ITEMS_PER_PAGE_DASHBOARD_SAV = 4
export const ITEMS_PER_PAGE_VIDEOS = 9
export const ITEMS_PER_PAGE_ACHATS = 12
export const ITEMS_PER_PAGE_CATALOGUE = 12
export const ITEMS_PER_PAGE_COMPTEURS = 12
export const YEARS_PER_TABS_COMPTEURS = 2
export const ITEMS_PER_PAGE_COMPTABILITE = 12

export const ADMIN_ROLE = 'ROLE_ADMIN'
export const USER_ROLE = 'ROLE_USER'

export const LOGIN_API = 'login/extranet'
export const PRESENTATION_API = '/extra_manager/presentation/1'
export const BANNERS_API = '/extra_manager/bandeaux/extranet?order%5Bnb%5D=asc'
export const DOCUMENTS_API =
  '/extra_manager/documents?actif=OUI&pagination=true'
export const DOCUMENTS_LIST_API =
  '/extra_manager/documents/list?actif=OUI&pagination=true'
export const CATEGORIES_DOCUMENTS_API = '/extra_manager/categories_documents'
export const VIDEOS_API = '/extra_manager/movies?pagination=true'
export const MSG_INFOS_API = '/extra_manager/messages_informations/extranet'
export const MSG_ALERTES_API = '/extra_manager/messages/alerts/active'
export const TRACE_MSG_ALERTES_API =
  '/extra_manager/trace_msg_alerts_sfleuristes'
export const PURCHASES_API = '/florajet/articles_extranets?pagination=true'
export const PURCHASES_POST_API = '/florajet_pro/pro_order_shop'
export const CATALOGUE_API = '/florajet/scompos'
export const FLORIST_CATALOGS_API = '/florajet/florist/catalogs'
export const SCHEDULES_API = '/florajet/sfleuristes_ouvertures'
export const SCHEDULES_IFRAME_API =
  '/florajet/sfleuristes_ouvertures/getUrlUpdate'
export const CLOSURES_API = '/florajet/sfleuristes_conges'
export const CLOSURES_POST_API = '/florajet_pro/sfleuristes_conges'

export const MASTER_HOLIDAYS_API = '/master/florajet/florist/holidays'
export const OPENINGS_API = '/florajet/sfleuristes_saufles?pagination=true'
export const OPENINGS_POST_API = '/florajet_pro/pro_sfleuristes_saufles'

export const EXCEPTIONAL_OPENINGS_API = '/master/florajet/florist/exceptional-openings'
export const EXCEPTIONAL_OPENINGS_API_DELETE = EXCEPTIONAL_OPENINGS_API

export const EXCEPTIONAL_OPENINGS_API_NEW = `${EXCEPTIONAL_OPENINGS_API}/new`
export const PERIMETERS_API = '/florajet/sperimetrs'
export const PERIMETERS_ON_PENDING_API = '/florajet_pro/sperimetr_validations'
export const PERIMETERS_POST_API = '/florajet_pro/sperimetr_validations'
export const PERIMETERS_PATCH_API = '/master/florajet/sperimetr_masters'
export const YEARS_COMPTEURS_API = '/florajet/scptmens/annees'
export const COMPTEURS_API = '/florajet/scptmens'
export const FAQ_API = '/extra_manager/faqs'
export const CONTACT_API = '/florajet_pro/messages/subjects'
export const CONTACT_POST_API = '/florajet_pro/messages/send'
export const TRANSMISSIONS_API = '/florajet/transmissions'
export const WAITING_TRANSMISSIONS_API = '/florajet_pro/transmissions/enattente'
export const TRANSMISSIONS_DELETE_API = '/florajet_pro/transmissions/enattente'
export const EXECUTIONS_API = '/florajet/executions'
export const EXECUTIONS_ACCEPTED = `${EXECUTIONS_API}/accepted`
export const EXECUTIONS_POST_API = '/florajet_pro/executions/post-action'
export const EXECUTIONS_REFUS_API = '/florajet/refus_refs'
export const NOTIFICATIONS_API = '/florajet/executions/notifications'
export const UPLOAD_PHOTO_API = '/florajet_pro/upload-image'
export const SAV_API = '/florajet/mes-sav'
export const SAV_RECLAMANTS_API = '/florajet/list-reclamants-sav'
export const SAV_MOTIFS_API = '/florajet/list-motifs-sav'
export const SAV_POST_API = '/florajet_pro/transmissions/post-sav'
export const SAV_POST_COMMENT_API = '/florajet_pro/transmissions/post-sav'
export const SAV_GET_API = '/florajet/recla_dats'
export const CLIENTS_API = '/florajet_pro/client-dat'
export const CP_VILLE_API = '/florajet/cities'
export const PAYS_API = '/florajet/spays?pagination=false'
export const FLORIST_API = '/florajet/sfleuristes_generals'
export const CHOIX_FLORAUX_API = '/florajet/choix-floraux'
export const OCCASIONS_API = '/florajet/occasions'
export const HORAIRES_LIVRAISON_API = '/florajet/horaires-maximum-de-livraison'
export const MOMENTS_LIVRAISON_API = '/florajet/moments-jour'
export const PDF_API = '/florajet/visu-commande/view-pdf'
export const COMMANDE_POST_API = '/florajet_pro/saisir-une-commande'
export const ORDER_NEW_API = '/florajet_pro/order/new'
export const HISTORIQUE_API =
  '/florajet_pro/historique-de-commande?pagination=true'
export const HISTORIQUE_PDF_API =
  '/florajet_pro/historique-de-commande/view-pdf'
export const COMPTA_RELEV_PDF_API = '/florajet/ma-comptabilite/releves'
export const COMPTA_PRELEV_API = '/florajet/ma-comptabilite/prelevements'
export const COMPTA_VIREMENTS_API = '/florajet/ma-comptabilite/virements'
export const COMPTA_CHEQUES_API = '/florajet/ma-comptabilite/cheques-emis'
export const COMPTA_FACTURES_API = '/florajet/ma-comptabilite/factures'
export const COMPTA_FACTURES_PDF_API =
  '/florajet/ma-comptabilite/factures/view-pdf'
export const POINTAGES_API = '/florajet/mes-pointages/view-pdf'
export const TOP10_NET_API = '/florajet/top-10-net'
export const TOP10_FL_API = '/florajet/top-10-fl'
export const SHOPPERS_API = '/florajet/shoppers'

export const EXTRANET_STATE_API = '/florajet/etats_ref'

export const FLORIST_FORGOT_PASSWORD = '/password/forgot'
export const FLORIST_RESET_PASSWORD = '/password/reset'
export const FLORIST_RESET_PASSWORD_TOKEN = '/password/resetToken'

export const TOKEN = '/florajet_pro/token'

export const PARTNERS_API = '/extra_manager/partners/active'

export const FLOWER_FEAST = '/florajet/perio_dat'

export const CATEGORY_API = '/florajet/categ_ref'

export const ACCESSORY_API = '/master/florajet/accessories'

export const ACCESSORIES_OLD_API = '/master/florajet/accessories_old';

export const ACCESSORIES_FLORIST_API = '/master/florajet/accessory_florists'

export const MIN_FREESTYLE_PRODUCT_PRICE = 20
export const MAX_FREESTYLE_PRODUCT_PRICE = 180
export const SIMPLE_PRODUCT_PRICE_FACTOR = 2

export const BOOTSTRAP_VARIANTS = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
  'body',
  'white',
  'transparent',
]
