import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'

const TableHeader = ({
  headers,
  sorting,
  handleSortOrder,
  sortType,
  sortOrder,
  config,
  full = true,
  className,
  bulkSelect
 }) => {
  const [currentHeaders, setCurrentHeaders] = useState();
  const isUnmounted = useRef(false);

  const sortHeaders = (full) => {
    let newHeaders = [];
    if (config) {
      if (!full) {
        config.forEach((conf) => {
          const index = headers.indexOf(conf.header);
          if (index >= 0) {
            newHeaders.push(headers[index]);
          }
        });
      } else {
        newHeaders = headers;
      }
    }

    return newHeaders;
  }

  useEffect(() => {
    if (!isUnmounted.current) {
      setCurrentHeaders(
        bulkSelect ? [...sortHeaders(full), ...['']] : sortHeaders(full)
      );
    }
  }, [headers]);

  const handleSortOrderChange = (newSortType) => {
    handleSortOrder(newSortType);
  }

  return (
    <thead className={className}>
      <tr>
         {currentHeaders && currentHeaders.map((item, index) => (
          <th key={index}>
            <span className="d-flex flex-row align-items-center">
              {item}
              {sorting && sorting[index] && (
                <FontAwesomeIcon
                  onClick={() => handleSortOrderChange(sorting[index])}
                  icon={`sort${
                    sortType === sorting[index] && sortOrder === 'asc'
                      ? '-up'
                      : sortType === sorting[index] && sortOrder === 'desc'
                      ? '-down'
                      : ''
                  }`}
                  color="var(--grey-darker)"
                  className={`ml-2 ${(sortType === sorting[index] && sortOrder) && 'active' || ''}`}
                />
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  handleSortOrder: PropTypes.func,
  headers: PropTypes.array.isRequired,
  className: PropTypes.string,
  sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  sortType: PropTypes.string,
  sorting: PropTypes.array,
  config: PropTypes.array,
  full: PropTypes.bool,
  bulkSelect: PropTypes.bool
}

export default TableHeader
