import React from 'react'

import FooterSocialItem from './FooterSocialItem'

const FooterSocial = () => {
    return (
        <>
            <FooterSocialItem
                href="https://www.facebook.com/groups/785419208712500"
                iconSize="2x"
                iconType="fab"
                iconName="facebook"
                iconColor="primary"
                iconClassname="ml-3"
            />
            <FooterSocialItem
                href="https://www.instagram.com/florajetfleur"
                iconSize="2x"
                iconType="fab"
                iconName="instagram"
                iconColor="primary"
                iconClassname="ml-2"
            />
        </>
    )
}

export default FooterSocial
