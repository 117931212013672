import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ModalCustom from '../modal/ModalCustom'
import Picto from '../picto/Picto'

const FooterLegende = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Button onClick={() => setModalShow(true)} className="btn-rounded ml-1">
        Légende
      </Button>

      <ModalCustom
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        id="modal-legende"
        content={
          <>
            <h3 className="text-left mb-3">Etats</h3>
            <Row>
              <Col xs={12} lg={6} className="d-flex flex-column">
                <Picto
                  id="0"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="1"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="2"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="20"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="3"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="4"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="40"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
              </Col>

              <Col xs={12} lg={6} className="d-flex flex-column">
                <Picto
                  id="5"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="6"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="60"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="7"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="8"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  id="80"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
              </Col>
            </Row>

            <h3 className="text-left my-3">Actions</h3>
            <Row>
              <Col xs={12} lg={6} className="d-flex flex-column">
                <Picto
                  icon="eye"
                  color="primary"
                  title="Visualiser"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="download"
                  color="primary"
                  title="Télécharger"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="pen"
                  color="primary"
                  title="Modifier"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="trash-alt"
                  color="red"
                  title="Supprimer"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="camera"
                  color="primary"
                  title="Prendre une nouvelle photo"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="image"
                  color="primary"
                  title="Voir la photo"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="file-contract"
                  color="primary"
                  title="Créer un dossier SAV"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="file-signature"
                  color="primary-dark"
                  title="Modifier un dossier SAV"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
              </Col>

              <Col xs={12} lg={6} className="d-flex flex-column">
                <Picto
                  icon="bell-slash"
                  color="primary"
                  title="Arrêter la sonnerie"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="times"
                  color="red"
                  title="Refuser la commande"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="check"
                  color="green-darkest"
                  title="Accepter la commande"
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="truck"
                  color="primary-dark"
                  title={`Passer à l'état "En cours de livraison"`}
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="smile"
                  color="primary-dark"
                  title={`Passer à l'état "Commande livrée"`}
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="map-marker-alt"
                  color="orange"
                  title={`Passer à l'état "Avis de passage déposé ou destinataire appelé"`}
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
                <Picto
                  icon="exclamation-triangle"
                  color="red"
                  title={`Passer à l'état "Refus par le client final"`}
                  desc={true}
                  classNames="mb-3"
                  pictoClassNames="mr-3"
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  )
}

export default FooterLegende
