import PropTypes from "prop-types";
import React, {useState} from "react";
import {format} from "date-fns";
import Table from "react-bootstrap/Table";
import Picto from "../../ui/picto/Picto";

export const SavModalBody = ({sav}) => {
    return (
        <>
            <Table striped>
                <tbody>
                <tr className={'font-weight-bold'}>
                    <td>N° commande :</td>
                    <td>Réclamant :</td>
                    <td>Date d'ouverture :</td>
                    <td>Date de fermeture :</td>
                </tr>
                <tr>
                    <td>{sav.reclaNumcde}</td>
                    <td>{sav.complainantLabel}</td>
                    <td>{format(new Date(sav.reclaDteopen), 'yyyy-MM-dd')}</td>
                    <td>{format(new Date(sav.reclaDteclose), 'yyyy-MM-dd')}</td>
                </tr>
                <tr className={'font-weight-bold'}>
                    <td>Motif :</td>
                    <td>Fautif :</td>
                    <td>Statut du SAV :</td>
                    <td>Etat du SAV :</td>
                </tr>
                <tr>
                    <td>{sav.reclaMotif}</td>
                    <td>{sav.whoFault}</td>
                    <td>
                        <Picto
                            id={sav.reclaFondee}
                            sorting="statut"
                            iconSize="xs"
                            pictoSize="xs"
                            pictoClassNames="circle"
                            classNames={'justify-content-center'}
                            desc={true}
                        />
                    </td>
                    <td>
                        <Picto
                            id={sav.reclaCloture}
                            sorting="etat"
                            iconSize="xs"
                            pictoSize="xs"
                            pictoClassNames="mr-1 circle"
                            classNames={'justify-content-center'}
                            desc={true}
                        />
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    )
}

SavModalBody.propTypes = {
    sav: PropTypes.object,
}