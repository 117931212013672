import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useReducer} from 'react'
import CardGroup from "react-bootstrap/CardGroup";
import Loader from "../loader/Loader";
import {OrderCard} from "./OrderCard";

const OrderCardGroup = ({
    isLoading = false,
    isError = false,
    data = [],
    actionUrl = '#',
    actionButtons = [],
    max = 3,
    user = false,
}) => {
    return (
        <>
        <div className={"mb-3"}>
            {!isLoading && !isError && (
                data.slice(0, max).map((item, i) => {
                    return (
                        <OrderCard key={i} item={item} index={i} actionButtons={actionButtons} user={user}/>
                    )
                })
            )}

            {!isLoading && !isError && actionUrl !== '#' && (
                <div className={"d-flex justify-content-center"}>
                    <a href={actionUrl}
                       className="btn btn-primary">
                        Voir plus
                    </a>
                </div>
            )}

            {isLoading && <Loader/>}
        </div>
        </>
    )
}

OrderCardGroup.propTypes = {
    isLoading: PropTypes.bool,
    isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    actionUrl: PropTypes.string,
    actionButtons: PropTypes.array,
    max: PropTypes.number,
    user: PropTypes.object,
}

export default OrderCardGroup
