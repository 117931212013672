import {format as formatFns, formatISO, setHours, setMinutes} from "date-fns";

export const today = (dateFormat = 'yyyy-MM-dd') => {
  return format(new Date(), dateFormat)
}

export const format = (date : Date, dateFormat :string) => {
  if (dateFormat) {
    return formatFns(date, dateFormat)
  }

  return formatISO(date)
}

/**
 * Arrondie une date à l'heure en fonction de limit
 *
 * @param {Date|null} date
 * @param {number} limit Doit être compris entre 0 et 59
 *
 * @returns {Date}
 */
export const aroundHourDate = (date = null, limit = 30) => {
  if (date instanceof Date || date === null) {
    if (date === null) {
      date = new Date();
    }

    if (limit < 0 || limit > 60) {
      throw new Error('Limit parameter must be between 0 and 59');
    }

    const hoursAroundAdjust = (date.getMinutes() > limit) ? 1 : 0;

    return setHours(setMinutes(date, 0), date.getHours() + hoursAroundAdjust);
  }

  throw new Error('The parameter of aroundHourDate must be an instance of Date');
}