import {
  CHOIX_FLORAUX_API,
  OCCASIONS_API,
  HORAIRES_LIVRAISON_API,
  MOMENTS_LIVRAISON_API,
  PDF_API,
  COMMANDE_POST_API,
  FLORIST_API, ORDER_NEW_API,
} from '../constants/Constants'
import API from './API'

function getChoixFloraux() {
  return {
    name: 'catégories',
    method: 'get',
    url: CHOIX_FLORAUX_API,
    female: true,
  }
}

function getOccasions() {
  return {
    name: 'occasions',
    method: 'get',
    url: OCCASIONS_API,
    female: true,
  }
}

function getHorairesMax() {
  return {
    name: 'horaires',
    method: 'get',
    url: HORAIRES_LIVRAISON_API,
  }
}

function getMomentsJour() {
  return {
    name: 'moments',
    method: 'get',
    url: MOMENTS_LIVRAISON_API,
  }
}

function getPDF(codefl, numcde = null, id = null) {
  return API({
    name: 'fichiers',
    method: 'get',
    url: `${PDF_API}?codefl=${codefl}&numcde=${numcde}&id=${id}`,
  });
}

async function postOrder(codefl, type, data) {
  return API({
    name: 'post_order',
    method: 'post',
    url: `${COMMANDE_POST_API}?codefl=${codefl}&type=${type}`,
    data,
  })
}

async function postOrderNew(codefl, type, data) {
  return API({
    name: 'post_order',
    method: 'post',
    url: `${ORDER_NEW_API}?codefl=${codefl}&type=${type}`,
    data,
  })
}

export default {
  getChoixFloraux,
  getOccasions,
  getHorairesMax,
  getMomentsJour,
  getPDF,
  postOrder,
  postOrderNew,
}
