import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {convertObjectKeysToBase64, builderAddMatchers} from '../Tools/Slice'
import ShopperApi from '../services/ShopperApi'
import {base64} from '../Tools/Tools'
import {isArray} from '../Tools/TypeOf'
import Prebook from '../class/Prebook'
import {
  configureShoppersModals,
  removeChosenPrebook,
} from './shoppersModalsSlice'
import SfleuristeShopper from '../class/SfleuristeShopper'

const initialState = {
  totalItems: 0,
  isLoading: false,
  isError: false,
  data: {},
  prebooks: {},
  globalConfig: {},
  error: '',
};

export const fetchShoppers = createAsyncThunk('fetchShoppers', async () => {
  return ShopperApi.getShoppers()
})

export const fetchPrebooks = createAsyncThunk(
  'fetchPrebook',
  async ({numcde, deliveryDate}) => {
    return ShopperApi.getPrebooks({numcde, deliveryDate})
  },
)

export const asyncCheckShopperCredentials = createAsyncThunk(
  'CheckShopperCredentials',
  async ({sfleuristeShopper}, {rejectWithValue}) => {
    try {
      const response = await ShopperApi.checkShopperCredential(
        sfleuristeShopper,
      )

      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const asyncPatchShopper = createAsyncThunk(
  'patchShoppers',
  async ({sfleuristeShopper}) => {
    return ShopperApi.patchShoppers(sfleuristeShopper)
  },
)

export const asyncValidatePrebook = createAsyncThunk(
  'validatePrebook',
  async ({prebook}, {rejectWithValue}) => {
    try {
      return await ShopperApi.validatePrebook(prebook)
    } catch (err) {
      return rejectWithValue(err.response.data.error)
    }
  },
)

export const shoppersSlice = createSlice({
  name: 'shoppers',
  initialState,
  reducers: {
    resetPrebooks: (state) => {
      state.prebooks = {}
    },
    resetShopperBadCredentials: (state, {payload: sfleuristeShopper}) => {
      sfleuristeShopper.badCredentialsError = false
      state.data[base64(sfleuristeShopper.ident)] = sfleuristeShopper
    },
    resetShoppersState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShoppers.fulfilled, (state, {payload}) => {
        state.data = convertObjectKeysToBase64(
          payload.map((data) => new SfleuristeShopper(data)),
          'ident',
        )
        state.totalItems = payload.length
      })
      .addCase(fetchPrebooks.fulfilled, (state, {payload}) => {
        if (isArray(payload)) {
          state.prebooks = convertObjectKeysToBase64(
            payload.map((data) => new Prebook(data)),
            'id',
          )
        }
      })
      .addCase(asyncPatchShopper.fulfilled, (state, {payload}) => {
        state.data[base64(payload.ident)] = new SfleuristeShopper(payload)
      })
      .addCase(asyncValidatePrebook.fulfilled, (state, {payload}) => {
        for (const prebookBase64Id in state.prebooks) {
          if (
            Object.hasOwnProperty.call(state.prebooks, prebookBase64Id) &&
            state.prebooks[prebookBase64Id].id === payload.id
          ) {
            state.prebooks[prebookBase64Id] = payload
          }
        }
      })
      .addCase(asyncValidatePrebook.rejected, (state, {payload}) => {
        state.error = payload;
      })
      .addCase(
        asyncCheckShopperCredentials.rejected,
        (
          state,
          {
            payload,
            meta: {
              arg: {sfleuristeShopper},
            },
          },
        ) => {
          sfleuristeShopper.badCredentialsError = payload
          state.data[base64(sfleuristeShopper.ident)] = sfleuristeShopper
        },
      )

    builderAddMatchers(builder, {
      fetchShoppers,
      asyncPatchShopper,
      asyncCheckShopperCredentials,
      asyncValidatePrebook,
    })
  },
})

export const patchShopper = ({formikValues, sfleuristeShopper}) => async (
  dispatch,
  getState,
) => {
  sfleuristeShopper.active =
    formikValues[`active-${sfleuristeShopper.shopper.reference}`]
  delete formikValues[`active-${sfleuristeShopper.shopper.reference}`]

  for (const prop in formikValues) {
    if (Object.prototype.hasOwnProperty.call(formikValues, prop)) {
      sfleuristeShopper.credentials[
        prop.replace(`-${sfleuristeShopper.shopper.reference}`, '')
      ] = formikValues[prop]
    }
  }

  await dispatch(asyncCheckShopperCredentials({sfleuristeShopper}))

  if (
    !getState().shoppers.data[base64(sfleuristeShopper.ident)]
      .badCredentialsError
  ) {
    dispatch(asyncPatchShopper({sfleuristeShopper}))
  }
}

export const cancelPrebook = (prebook) => async (dispatch) => {
  dispatch(configureShoppersModals({loading: true}))

  await ShopperApi.cancelPrebook(prebook).then((response) => {
    dispatch(configureShoppersModals({loading: false}))

    const deletedPrebook = new Prebook(response)

    if (deletedPrebook.error) {
      dispatch(
        configureShoppersModals({
          error: true,
          cancelled: false,
          errorText: deletedPrebook.error,
        }),
      )
    }

    if (deletedPrebook.aborted) {
      dispatch(removeChosenPrebook({prebook: deletedPrebook}))
      dispatch(
        configureShoppersModals({
          cancellationShow: deletedPrebook.error,
        }),
      )
    }
  })
}

export const {
  resetPrebooks,
  resetShopperBadCredentials,
  resetShoppersState
} = shoppersSlice.actions

export const selectShoppers = (state) => state.shoppers

export default shoppersSlice.reducer
