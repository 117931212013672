import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {ListGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const Partner = ({key, partner}) => {
  return (
      <>
          <Col xs={12} sm={12} md={12} lg={6} xl={3} className={'d-flex align-items-stretch mt-2'}>
              <Card key={key} className={'text-center partner'}>
                  <Card.Img variant={"top"} src={`${process.env.REACT_APP_FTP_BASE_URI}/partners/${partner.image}`} alt={partner.name} />
                  <ListGroup className={"list-group-flush"}>
                      <ListGroup.Item><h5>{partner.name}</h5></ListGroup.Item>
                  </ListGroup>
                  <Card.Body>
                      <div className={'description'} dangerouslySetInnerHTML={{__html: partner.description}}></div>
                  </Card.Body>
                  <Card.Footer className={'mt-auto'}>
                      <Button
                          href={partner.urlTo}
                          target={'_blank'}
                          rel={'noreferrer'}
                          className={'btn btn-primary btn-lg btn-block'}
                      >Voir</Button>
                  </Card.Footer>
              </Card>
          </Col>
      </>
  );
}

Partner.propTypes = {
    key: PropTypes.number,
    partner: PropTypes.object,
};