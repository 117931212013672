import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  allowSubmit: false,
  allowSubmitOnPending: false,
  showModalForm: false,
  showBulkPerimetreModal: false,
  showModalDeleteRequest: false,
  editingPerimetre: null,
};

export const perimetrePageSlice = createSlice({
  name: 'perimetrePage',
  initialState,
  reducers: {
    handlePerimetrePageAllowSubmit: (state, {payload}) => {
      state.allowSubmit = payload
    },
    handlePerimetrePageAllowOnPendingSubmit: (state, {payload}) => {
      state.allowSubmitOnPending = payload
    },
    handleShowModalForm: (state, {payload}) => {
      state.showModalForm = payload
    },
    handleShowDeleteRequest: (state) => {
      state.showModalDeleteRequest = !state.showModalDeleteRequest
    },
    setEditingPerimetre: (state, {payload}) => {
      state.editingPerimetre = payload
    },
    initEditingPerimetre: (state) => {
      state.editingPerimetre = null
    },
    resetPerimetrePageState: () => initialState
  },
})

export const {
  handlePerimetrePageAllowSubmit,
  handlePerimetrePageAllowOnPendingSubmit,
  handleShowModalForm,
  handleShowDeleteRequest,
  setEditingPerimetre,
  initEditingPerimetre,
  resetPerimetrePageState
} = perimetrePageSlice.actions

export const selectPerimetrePage = (state) => state.perimetrePage

export default perimetrePageSlice.reducer
