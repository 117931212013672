import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  reloadExecutions: false,
}

export const executionSlice = createSlice({
  name: 'executions',
  initialState,
  reducers: {
    configureReloadExecutions: (state, payload) => {
      state.reload = payload
    },
  },
})

export const {configureReloadExecutions} = executionSlice.actions

export const selectExecutions = (state) => state.executions

export default executionSlice.reducer
