import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

const FooterSocialItem = ({
  href,
  iconSize,
  iconType,
  iconName,
  iconColor,
  iconClassname,
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <FontAwesomeIcon
        size={iconSize}
        icon={[`${iconType}`, `${iconName}`]}
        color={`var(--${iconColor})`}
        className={iconClassname}
      />
    </a>
  )
}

FooterSocialItem.propTypes = {
  href: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconClassname: PropTypes.string,
}

export default FooterSocialItem
