import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import {isMobile, useMobileOrientation} from 'react-device-detect'

import Footer from '../footer/Footer'
import Header from '../header/Header'
import ScrollToTop from '../scroll/ScrollToTop'
import SideMenu from '../side_menu/SideMenu'
import {Store} from '../../../providers/MenuProvider'
import {StockAccessoriesNotification} from "../modal/StockAccessoriesNotification";

const Layout = ({children}) => {
  const {state: stateMenu, toggleMenu} = useContext(Store)
  const {isPortrait} = useMobileOrientation()

  return (
    <Container fluid className="bg-white">
      <Row className={'flex-nowrap'}>
        {isMobile && isPortrait ? (
          <>
            <Collapse in={stateMenu.activeMenu}>
              <Col
                xs={9}
                sm={7}
                md={5}
                lg={3}
                xl={3}
                className="side-menu bg-grey-light pt-6 p-4 min-vh-100 col-xxl-2 position-fixed"
                id="menu-collapse">
                   <SideMenu toggleMenu={toggleMenu} />
              </Col>
            </Collapse>
            <div
              className={`d-lg-none overlay ${stateMenu.activeMenu && 'active'}`}
              onClick={toggleMenu}></div>
          </>
        ) : (
          <Col
            xs={9}
            sm={9}
            md={4}
            lg={3}
            xl={3}
            className="side-menu bg-grey-light p-4 min-vh-100 col-xxl-2"
            id="menu-collapse">
            <SideMenu toggleMenu={toggleMenu} />
            <StockAccessoriesNotification />
          </Col>
        )}


        <Col className="wrapper px-0">
          <Header toggleMenu={toggleMenu} />

          <Container id={'globalContentContainer'} fluid size="lg">
            <Row>{children}</Row>
          </Container>

          <Footer />
        </Col>
      </Row>
      <ScrollToTop />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
