import React, {useContext, useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import AuthContext from "../../../contexts/AuthContext";
import {useStoreExecutionsAccepted} from "../../../store/useStoreExecutionsAccepted";
import {useStoreExecutions} from "../../../store/useStoreExecutions";

const SideMenuDelivery = () => {
    const getExecutionsAccepted = useStoreExecutionsAccepted(state => state.getExecutionsAccepted);
    const isLoading = useStoreExecutionsAccepted(state => state.isLoading);
    const acceptedExecutions = useStoreExecutionsAccepted(state => state.executionsAccepted);
    const executionsAutoAcceptedCount = useStoreExecutions(state => state.executionsAutoAcceptedCount);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        getExecutionsAccepted(user);
    }, [executionsAutoAcceptedCount]);

    return (
        <>
            {Object.keys(acceptedExecutions).length !== 0 && !isLoading && (
                <div className="bg-white rounded px-2 pt-2 mt-3">
                    <span className="ff-bold">Livraisons aujourd'hui</span>
                    <Table responsive size="sm" className="my-1">
                        <thead>
                            <tr>
                                <th>Commandes</th>
                                <th>Divers</th>
                                <th>Deuil</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Matin</td>
                                <td>{acceptedExecutions.morning.other}</td>
                                <td>{acceptedExecutions.morning.mourning}</td>
                                <td>{acceptedExecutions.morning.count}</td>
                            </tr>
                            <tr>
                                <td>Après-midi</td>
                                <td>{acceptedExecutions.afternoon.other}</td>
                                <td>{acceptedExecutions.afternoon.mourning}</td>
                                <td>{acceptedExecutions.afternoon.count}</td>
                            </tr>
                            <tr>
                                <td>Journée</td>
                                <td>{acceptedExecutions.day.other}</td>
                                <td>{acceptedExecutions.day.mourning}</td>
                                <td>{acceptedExecutions.day.count}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{acceptedExecutions.other}</td>
                                <td>{acceptedExecutions.mourning}</td>
                                <td>{acceptedExecutions.count}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    )
}

export default SideMenuDelivery
