import {useLocation} from "react-router-dom";
import {OrderEntryForm} from "./OrderEntryForm";

export function OrderEntry() {
    const location = useLocation();

    return (
        <>
            {location.state && location.state.order ? (<OrderEntryForm order={location.state.order} />) : <OrderEntryForm />}
        </>
    )
}