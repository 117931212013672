import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'

/**
 * Create <h{headingLevel}> html tag
 *
 * @param title
 * @param headingLevel
 * @param className
 * @return {JSX.Element}
 *
 * @constructor
 */
export const Heading = ({title, headingLevel, className}) => {
  return (
    <>
      {
        (headingLevel === 1 && <h1 className={`title ${className}`}>{title}</h1>) ||
        (headingLevel === 2 && <h2 className={`title ${className}`}>{title}</h2>) ||
        (headingLevel === 3 && <h3 className={`title ${className}`}>{title}</h3>) ||
        (headingLevel === 4 && <h4 className={`title ${className}`}>{title}</h4>) ||
        (headingLevel === 5 && <h5 className={`title ${className}`}>{title}</h5>) ||
        (headingLevel === 6 && <h6 className={`title ${className}`}>{title}</h6>) ||
        (<h1 className={`title ${className}`}>{title}</h1>)
      }
    </>
  )
}

/**
 * Create Col block with a <h{headingLevel}> tag inside
 *
 * @param title
 * @param headingLevel
 * @param headingClassName
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Title = ({title, headingLevel, headingClassName}) => {
  return (
    <Col xs={12}>
      <Heading title={title} headingLevel={headingLevel} className={headingClassName} />
    </Col>
  )
}

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
}

Title.propTypes = {
  title: Heading.propTypes.title,
  headingLevel: Heading.propTypes.headingLevel,
  headingClassName: Heading.propTypes.className,
}

export default Title
