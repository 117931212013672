import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

if (!new RegExp(/^dev/).test(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn:
      'https://6f626f8950fe656331db4fa99082d320@sentry.florajet.io/5',
    integrations: [],
  })
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
