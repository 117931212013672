import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getIn, useFormik} from 'formik'
import Form from 'react-bootstrap/Form'
import {string, object} from 'yup'
import {Alert} from 'react-bootstrap'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import ShopperFormHeading from './ShopperFormHeading'
import Field from '../Field'
import {isArray, isObject} from '../../../../Tools/TypeOf'
import {base64} from '../../../../Tools/Tools'
import {
  patchShopper,
  resetShopperBadCredentials,
} from '../../../../slice/shoppersSlice'

/**
 *
 * @param shopper
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const ShopperFormGroup = ({reference, key}) => {
  const [formikIdents, setFormikIdents] = useState([])
  const [formikInitialValues, setFormikInitialValues] = useState({})
  const [formikInitialValuesMd5, setFormikInitialValuesMd5] = useState(null)
  const [formikValidationSchema, setFormikValidationSchema] = useState({})
  const [isFormikInitialized, setFormikInitialized] = useState(false)

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [submitSpinner, setSubmitSpinner] = useState(false)

  const dispatch = useDispatch()
  const sfleuristeShopper = useSelector(({shoppers}) => {
    const data = Object.values(shoppers.data)

    if (isArray(data)) {
      return data.find(({shopper}) => shopper.reference === reference)
    }

    return undefined
  })

  const {credentials, shopper, active, badCredentialsError} = sfleuristeShopper
  const {
    config: {logoSrc},
    credentialPayload: {payloadConfig},
  } = shopper

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: object().shape(formikValidationSchema),
    onSubmit(values) {
      dispatch(resetShopperBadCredentials(sfleuristeShopper))
      dispatch(patchShopper({formikValues: values, sfleuristeShopper}))
    },
  })

  const handleChange = (evt) => {
    dispatch(resetShopperBadCredentials(sfleuristeShopper))
    formik.handleChange(evt)
  }

  useEffect(() => {
    if (!isFormikInitialized) {
      const activeIdent = `active-${reference}`

      Object.keys(payloadConfig).forEach((ident) => {
        const formikId = `${ident}-${reference}`
        formikIdents.push(ident)

        formikInitialValues[formikId] =
          isObject(credentials) &&
          Object.prototype.hasOwnProperty.call(credentials, ident)
            ? credentials[ident]
            : ''
        formikValidationSchema[formikId] = string().required(
          'Ce champs est obligatoire',
        )

        setFormikInitialValues(formikInitialValues)
        setFormikValidationSchema(formikValidationSchema)
      })

      formikInitialValues[activeIdent] = active || false

      setFormikInitialValues(formikInitialValues)
      setFormikValidationSchema(formikValidationSchema)
      setFormikIdents(formikIdents)


      setFormikInitialized(true)
    }
  }, [
    isFormikInitialized,
    setFormikInitialized,
    setFormikInitialized,
    setFormikIdents,
    setFormikValidationSchema,
  ])

  useEffect(() => {
    if (!formikInitialValuesMd5 && isFormikInitialized) {
      setFormikInitialValuesMd5(base64(formikInitialValues))
    }
  }, [
    isFormikInitialized,
    formikInitialValues,
    formikInitialValuesMd5,
    setFormikInitialValuesMd5,
  ])

  useEffect(() => {
    if (
      formikInitialValuesMd5 &&
      formikInitialValuesMd5 !== base64(formik.values)
    ) {
      setDisableSubmit(false)
    }
  }, [formik.values, formikInitialValuesMd5, setDisableSubmit])

  useEffect(() => {
    if (badCredentialsError) {
      for (const prop in badCredentialsError) {
        if (Object.prototype.hasOwnProperty.call(badCredentialsError, prop)) {
          formik.setFieldError(
            `${prop}-${reference}`,
            badCredentialsError[prop],
          )
        }
      }
    }
  }, [badCredentialsError, reference])

  return (
    (isFormikInitialized && (
      <Form
        key={key}
        className="bg-grey-light p-4 rounded col-10"
        onSubmit={formik.handleSubmit}>
        <ShopperFormHeading headingText={reference} logoSrc={logoSrc} />

        {badCredentialsError && (
          <Alert variant={'danger'} className={'p-1'}>
            <p className={'h2'}>Identifiants incorrects</p>

            <p className={'m-0'}>
              Merci de vous rapprocher du service de livraison pour vérifier vos
              identifiants
            </p>
          </Alert>
        )}

        {formikIdents.map((ident) => (
          <Field
            key={`${ident}-${reference}`}
            label={payloadConfig[ident]}
            labelRequired={true}
            fieldClassNames={'form-control bg-white'}
            name={`${ident}-${reference}`}
            value={formik.values[`${ident}-${reference}`]}
            onChange={(evt) => {
              handleChange(evt)
            }}
            onBlur={formik.handleBlur}
            error={getIn(formik.errors, `${ident}-${reference}`)}
          />
        ))}

        <Form.Check
          custom={false}
          id={`active-${reference}`}
          aria-label="Activer le livreur"
          className="my-2">
          <Form.Check.Input
            type="checkbox"
            name={`active-${reference}`}
            checked={formik.values[`active-${reference}`]}
            onChange={formik.handleChange}
          />

          <Form.Check.Label htmlFor={`active-${reference}`}>
            Actif
          </Form.Check.Label>
        </Form.Check>

        <small className="form-text font-italic">
          Identifiant{formikIdents.length > 1 && 's'} fourni par {reference}
        </small>

        <p>
          <small>
            <em>
              <sup>*</sup> Champs obligatoires
            </em>
          </small>
        </p>

        <div className="d-flex justify-content-end">
          <Button
            type={'submit'}
            className={'align-self-end btn ml-4 btn-primary'}
            disabled={disableSubmit}>
            Enregistrer
            {submitSpinner && (
              <Spinner
                as={'span'}
                animation={'border'}
                size={'sm'}
                className={'ml-2'}
              />
            )}
          </Button>
        </div>
      </Form>
    )) || <></>
  )
}

ShopperFormGroup.propTypes = {
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reference: PropTypes.string.isRequired,
}

export default ShopperFormGroup
