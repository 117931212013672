import PropTypes from "prop-types";
import {BrowserView, MobileView} from "react-device-detect";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Numbers from "../../../helpers/Numbers";
import CardCustom from "../../ui/card/CardCustom";
import Text from "../../../helpers/Text";
import ModalCustom from "../../ui/modal/ModalCustom";
import {CatalogueItemModal} from "./CatalogueItemModal";
import {CatalogueItem} from "./CatalogueItem";


export const CatalogueItems = ({catalogue, max, showTFCatalog}) => {
    if (max === undefined) {
        max = catalogue.length;
    }

    return (
        <>
            <Col lg={12} sm={12} xs={12}>
                <Row xs={1} md={2} lg={3} className="g-4">
                    {catalogue.slice(0, max).map((product, i) => (
                        <>
                            <CatalogueItem product={product} index={i} show={showTFCatalog || product.nomcat !== 'TF'}/>
                        </>
                    ))}
                </Row>
            </Col>
        </>
    );
}

CatalogueItems.propTypes = {
    catalogue: PropTypes.array.isRequired,
    max: PropTypes.number,
    showTFCatalog: PropTypes.bool,
}