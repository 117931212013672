import API from './API'
import {
  TRANSMISSIONS_API,
  TRANSMISSIONS_DELETE_API, WAITING_TRANSMISSIONS_API,
} from '../constants/Constants'

function getTransmissions(type, sortType, sortOrder, currentPage, searchText, dateDebut, dateFin, itemsPerPage)
{
  return {
    name: 'transmissions',
    method: 'get',
    url: `${TRANSMISSIONS_API}?type=${type}${
      type === 'by-last-days' ? '&days=30' : ''
    }${
      (type === 'search' || type === 'enattente_search')
        ? `&numcdelocalite=${searchText || ''}&date_debut=${
            dateDebut || ''
          }&date_fin=${dateFin || ''}`
        : ''
    }&orderBy=${sortType}&way=${
      sortOrder || 'desc'
    }&itemsPerPage=${itemsPerPage}`,
    // }&page=${currentPage}&itemsPerPage=${itemsPerPage}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

function getTransmissionsInWaiting(type, sortType, sortOrder, currentPage, searchText, dateDebut, dateFin, itemsPerPage) {
  return {
    name: 'transmissions',
    method: 'get',
    url: `${WAITING_TRANSMISSIONS_API}?type=${type}${
        type === 'by-last-days' ? '&days=30' : ''
    }${
      (type === 'search' || type === 'enattente_search')
        ? `&numcdelocalite=${searchText || ''}&date_debut=${
                dateDebut || ''
            }&date_fin=${dateFin || ''}`
            : ''
    }&orderBy=${sortType}&way=${
        sortOrder || 'desc'
    }`,
    // }&page=${currentPage}&itemsPerPage=${itemsPerPage}`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
    params: {
      floristCode: codefl,
    },
  };
}

function getTransmissionsCount(
  type,
  sortType,
  sortOrder,
  searchText,
  dateDebut,
  dateFin,
  itemsPerPage,
) {
  return {
    name: 'transmissions',
    method: 'get',
    url: `${TRANSMISSIONS_API}?type=${type}${
      type === 'by-last-days' ? '&days=30' : ''
    }${
      (type === 'search' || type === 'enattente_search')
        ? `&numcdelocalite=${searchText || ''}&date_debut=${
            dateDebut || ''
          }&date_fin=${dateFin || ''}`
        : ''
    }&orderBy=${sortType}&way=${
      sortOrder || 'asc'
    // }&page=${currentPage}&itemsPerPage=${itemsPerPage}&count=true`,
    }&count=true`,
    headers: {
      accept: 'application/ld+json',
    },
    female: true,
  }
}

async function deleteTransmissions(id) {
  return API({
    name: 'transmissions',
    method: 'delete',
    url: `${TRANSMISSIONS_DELETE_API}/${id}`,
    // data,
  })
}

async function uploadPicture(codefl, numcde, data) {
  return API({
    name: 'upload_photo',
    method: 'post',
    url: `${UPLOAD_PHOTO_API}?codefl=${codefl}&numcde=${numcde}`,
    data,
  })
}

export default {
  getTransmissions,
  getTransmissionsCount,
  deleteTransmissions,
  uploadPicture,
  getTransmissionsInWaiting,
}
