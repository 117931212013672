import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Row from "react-bootstrap/Row";

import Count from '../../ui/page/Count'
import Field from '../../ui/form/Field'
import Select from '../../ui/form/Select'
import AuthContext from "../../../contexts/AuthContext";
import useFetch from "../../../hooks/useFetch";
import CatalogueAPI from "../../../services/CatalogueAPI";

const CatalogueFilters = ({
  handleSearch,
  searchInput,
  searchText,
  searchType,
  handleSearchInput,
  handleCancel,
  handleSortCatalog,
  sortCatalog,
  handleSortType,
  sortOrder,
  handleSortOrder,
  totalItems,
}) => {
  const {user} = useContext(AuthContext);
  const [{data: catalogues, isLoading: isLoadingCatalogues, totalItems: totalCataloguesItems}] = useFetch(CatalogueAPI.getCatalogsByFlorist(user.CodeFl));

  return (
    <Col
      xs={12}
      className="filter d-lg-flex d-xl-flex d-md-flex justify-content-between">
      <Form onSubmit={handleSearch} inline className={'mb-3'}>
        <Row>
          <Col lg={"auto"}>
            <div className="form-label mb-1">Libellé :</div>
            <Field
                name="libcompo"
                placeholder="Libellé"
                className={"form-control"}
                value={searchInput.libcompo}
                onChange={handleSearchInput}
            />
          </Col>
          <Col lg={"auto"}>
            <div className="form-label mb-1">Référence :</div>
            <Field
                name="reference"
                placeholder="Référence"
                className={"form-control"}
                value={searchInput.reference}
                onChange={handleSearchInput}
            />
          </Col>
          <Col lg={"auto"} className={"d-flex align-items-end mt-2"}>
            <Button
                type="submit"
                className="btn-primary btn-square d-flex align-items-center mr-2"
                disabled={
                    (searchInput.libcompo === '' && searchInput.reference === '')
                }>
              <FontAwesomeIcon icon="search" color="white" />
            </Button>
            <Button
                className="btn-red btn-square d-flex align-items-center"
                onClick={handleCancel}
                disabled={
                    searchInput.libcompo === '' && searchInput.reference === ''
                }>
              <FontAwesomeIcon
                  icon="times"
                  color="white"
                  style={{marginLeft: '2px', marginRight: '2px'}}
              />
            </Button>
          </Col>
        </Row>
      </Form>

      <div className="d-flex flex-row flex-wrap align-items-end align-items-sm-center align-content-end">
        <Select
          controlId="catalogueTypeSelect"
          label="Type de catalogue"
          classNamesForm="d-flex flex-row flex-wrap mb-0 mr-4 mb-3"
          defaultValue={sortCatalog || ''}
          onChange={handleSortCatalog}
          options={(!isLoadingCatalogues && (
                <>
                  <option id="">Tous</option>
                  {catalogues && totalCataloguesItems > 0 && catalogues.map((catalog, i) => {
                    return (<option key={i} id={catalog} selected={catalog === '2022'}>{catalog}</option>);
                  })}
                </>
              ))
          }
        />
        <Select
          controlId="catalogueSelect"
          label="Trier par"
          classNamesForm="mb-0 mr-4 mb-3"
          defaultValue="Référence"
          onChange={handleSortType}
          options={
            <>
              <option id="reference">Référence</option>
              <option id="libcompo">Libellé</option>
              <option id="categCode">Catégorie</option>
            </>
          }
        />
        <FontAwesomeIcon
          onClick={() => handleSortOrder()}
          className="mr-4 mb-3"
          size="lg"
          icon={`sort${
            sortOrder === 'asc' ? '-amount-up-alt' : '-amount-down'
          }`}
          color="var(--grey-darker)"
        />
        <Count totalItems={totalItems} name="produit" classNames="mb-3" />
      </div>
    </Col>
  )
}

CatalogueFilters.propTypes = {
  sortCatalog: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSortCatalog: PropTypes.func,
  handleSortType: PropTypes.func,
  handleSearchInput: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSortOrder: PropTypes.func,
  sortOrder: PropTypes.string,
  searchInput: PropTypes.shape({
    libcompo: PropTypes.string,
    reference: PropTypes.string,
  }),
  searchText: PropTypes.string,
  searchType: PropTypes.string,
  totalItems: PropTypes.number,
}

export default CatalogueFilters
