import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {dataTransformer} from '../Tools/TransformerAxios'

export default class APIv2 {
  static debug = false

  static request(config) {
    let headers = {
      accept: 'application/ld+json',
    }

    if (config.headers) {
      headers = {...headers, ...config.headers}
    }

    const options = {
      baseURL: process.env.REACT_APP_API_URL,
      transformRequest: [dataTransformer].concat(
        axios.defaults.transformRequest,
      ),
      headers,
      ...config,
    }

    const token = window.localStorage.authToken
    if (token) {
      const {exp: expiration} = jwtDecode(token)

      if (expiration * 1000 > new Date().getTime()) {
        options.headers.Authorization = `Bearer ${token}`
      }
    }

    if (APIv2.debug) {
      console.log(config)
    }

    return axios(options)
  }

  static async requestData(config) {
    return this.parseResponse((await APIv2.request(config)).data)
  }

  static parseResponse(dataFromJsonLd) {
    switch (dataFromJsonLd['@type']) {
      case 'hydra:Collection':
        return this.buildCollectionResponse(dataFromJsonLd)
      default:
        return dataFromJsonLd
    }
  }

  static buildCollectionResponse(collectionData) {
    return {
      data: collectionData['hydra:member'],
      totalItems: collectionData['hydra:totalItems'],
    }
  }
}
