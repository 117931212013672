import Sperimetr from './Sperimetr'

export default class SperimetrValidation extends Sperimetr {
  constructor(data, communeData = null) {
    super(data)

    this.idSperimetrValidation = data.idSperimetrValidation
    this.validationApplied = data.validationApplied
    this.deletion = data.deletion

    if (communeData) {
      this.buildFormattedAddress(communeData)
    }
  }
}