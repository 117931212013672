import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {EXTRANET_STATE_DELIVERING, useExtranetState} from './useExtranetState'
import {
  cancelPrebook,
  fetchShoppers,
  resetPrebooks,
  selectShoppers,
} from '../slice/shoppersSlice'
import {
  fetchGlobalConfig,
  resetShoppersModalState,
  selectShoppersModals,
  showCancellationShoppersModal,
  showShoppersModal,
} from '../slice/shoppersModalsSlice'
import {isArray} from '../Tools/TypeOf'
import {useStoreExecutions} from "../store/useStoreExecutions";

export const SHOPPERS_PICKUP_INTERVAL = 60

/**
 * Contient differentes functions qui premettent de tester
 * la validitée d'une commande pour les prebooks des shoppers
 *
 * @returns {
 *   {
 *      hasPrebook: (function(Object)),
 *      canDrive: (function(Object, integer)),
 *      stateHasPrebook: (function(Object, string): any)
 *   }
 * }
 */
export function useShopperTestPrebook() {
  const {chosenPrebooks} = useSelector(selectShoppersModals)

  /**
   * Test si une commande a déja un prebook
   * et que celui-ci est toujour valide (pas expiré).
   * Un prebook est valide environ 20 minutes.
   *
   * @param {object} order
   *
   * @returns {boolean}
   */
  const hasPrebook = (order) => {
    return (
      Object.prototype.hasOwnProperty.call(order, 'prebook') &&
      typeof order.prebook.id !== typeof undefined
    )
  }

  /**
   * Vérifie si une commande à une livraison choisi.
   *
   * @param {object} prebook
   *
   * @returns {boolean}
   */
  const hasMission = (prebook) => {
    return prebook && prebook.mission && !prebook.aborted
  }

  /**
   * Vérifie si une commande a déja un prébook dans le state courant.
   *
   * @param {string} numcde
   *
   * @returns {boolean}
   */
  const stateHasPrebook = (numcde) => {
    return (
      Object.values(chosenPrebooks).find(
        (prebook) => prebook.numcde === numcde,
      ) !== undefined
    )
  }

  return {hasPrebook, hasMission, stateHasPrebook}
}

/**
 * @param {Object} user
 *
 * @returns {
 *  {
 *    handleShowPrebookButton: (function({etatextranet: string, numcde: string}): *),
 *    handleCancellationModal: ((function(): Promise<void>)|*),
 *    handleShowCancellationPrebookButton: (function({numcde: string, etatextranet: string}): *),
 *    handleCancellationShoppersModal: handleCancellationShoppersModal,
 *    handleShoppersModal: handleShoppersModal
 *   }
 * }
 */
export function useShopperPrebook(user) {
  const dispatch = useDispatch()
  const {data: shoppers} = useSelector(selectShoppers)
  const getExecutions = useStoreExecutions((state) => state.getExecutions)
  const minimumDeliveryTimestamp = user.shoppers instanceof Object ? user.shoppers.globalConfig.deliveryDatetimeDelay : useSelector(selectShoppersModals);
  const prebookToCancel = useSelector(({shoppersModals}) => {
    const prebooks = Object.values(shoppersModals.chosenPrebooks)

    if (isArray(prebooks)) {
      return Object.values(prebooks).find(
        (prebook) => prebook.numcde === shoppersModals.currentPrebookNumcde,
      )
    }

    return undefined
  })

  const [initialized, setInitialzed] = useState(false)
  const [hasShoppers, setHasShoppers] = useState(false)

  const {stateHasPrebook} = useShopperTestPrebook()
  const {extranetStateIsDeliverable} = useExtranetState()

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchShoppers())

      setInitialzed(true)
    }
  }, [initialized])

  useEffect(() => {
    if (shoppers && Object.values(shoppers).length > 0) {
      Object.values(shoppers).every((shopper) => {
        if (shopper.active) {
          setHasShoppers(shopper.active)

          return false
        }

        return true
      })
    }
  }, [shoppers])

  useEffect(() => {
    if (typeof minimumDeliveryTimestamp === typeof null) {
      dispatch(fetchGlobalConfig())
    }
  }, [minimumDeliveryTimestamp])

  /**
   * Réinitialise l'état des modales
   */
  const initModalState = () => {
    dispatch(resetShoppersModalState({preserveChosenPrebooks: true}))
  }

  /**
   * Ouvre la modale de demande de livraison
   *
   * @param {string} numcde
   */
  const handleShoppersModal = ({numcde}) => {
    dispatch(resetShoppersModalState({preserveChosenPrebooks: true}))
    dispatch(resetPrebooks())

    dispatch(
      showShoppersModal({
        show: true,
        currentPrebookNumcde: numcde,
      }),
    )
  }

  /**
   * Affiche ou pas le bouton de demande de livraison.
   * Cache le bouton si un prebook a été choisi.
   *
   * @param {any} order
   *
   * @returns {boolean}
   */
  const handleShowPrebookButton = (order) => {
    const {etatextranet, numcde} = order;

    return (
        hasShoppers && !stateHasPrebook(numcde)
        && (
            extranetStateIsDeliverable(etatextranet)
            || etatextranet === EXTRANET_STATE_DELIVERING
        )
    )
  }

  /**
   * Affiche ou pas un bouton qui informe qu'un prebook a été choisi.
   *
   * @param {string} numcde
   * @param {string} etatextranet
   *
   * @returns {boolean}
   */
  const handleShowCancellationPrebookButton = ({numcde, etatextranet}) => {
    return hasShoppers
        && extranetStateIsDeliverable(etatextranet)
        && stateHasPrebook(numcde)
  }

  /**
   * Ouvre la modale de demande de livraison
   *
   * @param {string} numcde
   */
  const handleCancellationShoppersModal = ({numcde}) => {
    initModalState()

    dispatch(
      showCancellationShoppersModal({
        show: true,
        currentPrebookNumcde: numcde,
      }),
    )
  }

  /**
   *
   */
  const handleCancellationModal = async () => {
    if (prebookToCancel) {
      await dispatch(cancelPrebook(prebookToCancel))

      getExecutions(user, true, false)
    }
  }

  return {
    handleShoppersModal,
    handleShowPrebookButton,
    handleShowCancellationPrebookButton,
    handleCancellationShoppersModal,
    handleCancellationModal,
  }
}
