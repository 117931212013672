import {
  PURCHASES_API,
  ITEMS_PER_PAGE_ACHATS, PURCHASES_POST_API,
} from '../constants/Constants'
import API from './API'

function getAchats(sortType, sortOrder) {
  return {
    name: 'articles',
    method: 'get',
    url: `${PURCHASES_API}&order%5B${sortType}%5D=${sortOrder}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

async function postAchats(data) {
  return API({
    name: 'articles',
    method: 'post',
    url: PURCHASES_POST_API,
    data,
  })
}

export default {
  getAchats,
  postAchats,
}
