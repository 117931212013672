import PropTypes from 'prop-types'
import React from 'react'
import Form from 'react-bootstrap/Form'

const Select = ({
  name,
  value,
  error = '',
  label,
  labelRequired,
  controlId,
  classNames,
  classNamesForm,
  classNamesGroup,
  defaultValue,
  onChange,
  options,
  inline = true,
  disabled = false
}) => {
  return (
    <div className={classNamesForm}>
      <Form.Group controlId={controlId} className={classNamesGroup}>
        {label && (
          <Form.Label>
            {label}
            {labelRequired && <span className="text-red"> *</span>}
          </Form.Label>
        )}
        <Form.Select
          className={`form-control ${classNames} ${error && ' is-invalid'}`}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          name={name}
          disabled={disabled}
        >
          {options}
        </Form.Select>
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  )
}

Select.propTypes = {
  classNames: PropTypes.string,
  classNamesForm: PropTypes.string,
  classNamesGroup: PropTypes.string,
  controlId: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  error: PropTypes.string,
  label: PropTypes.string,
  labelRequired: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Select
