const LoginFormReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isError: false,
        isLoading: true,
        errorMessage: false,
        errorField: false,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isError: false,
        isLoading: false,
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.errorMessage,
        errorField: {
          username: ' ',
          password: ' ',
        },
      }
    case 'HANDLE_CHANGE':
      return {
        ...state,
        credentials: action.credentials,
      }
    case 'HANDLE_FOCUS':
      return {
        ...state,
        isError: false,
        errorField: false,
      }
    case 'FETCH_ERROR_BAD_CREDENTIALS':
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: 'Identifiant ou mot de passe incorrect',
        errorField: {
          username: ' ',
          password: ' ',
        },
      }
    case 'FETCH_SUBSCRIPTION_MODAL':
      return {
        ...state,
        modalShow: action.modalShow,
      }
    default:
      throw new Error()
  }
}

export default LoginFormReducer
