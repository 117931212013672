import {create} from "zustand";
import ExecutionsAPI from "../services/ExecutionsAPI";

export const useStoreExecutionsAccepted = create((set, get) => ({
    executionsAccepted: {},
    isLoading: false,
    getExecutionsAccepted: async (florist, forceReload = false) => {
        if (Object.keys(get().executionsAccepted).length === 0 || forceReload) {
            set({isLoading: true});
            await ExecutionsAPI.getAcceptedExecutions(
                florist,
                true
            ).then(({data}) => {
                set({
                    executionsAccepted: data,
                    isLoading: false
                });
            })

        }
    },
}));