import {useContext} from "react";
import {isObject} from '../Tools/TypeOf'
import CommandeAPI from '../services/CommandeAPI'
import AuthContext from "../contexts/AuthContext";

export function useOrder(user) {
  async function downloadOrder(orderNumber) {
    let orderDocumentLink = null
    const {user} = useContext(AuthContext);
    if (orderNumber !== '') {
      const result = await CommandeAPI.getPDF(user.CodeFl, orderNumber)

      if (result.data) {
        orderDocumentLink = `${process.env.REACT_APP_HTTP_SCHEME}:${result.data}`
      }
    }

    return orderDocumentLink
  }

  return {downloadOrder}
}

/**
 * @param orders
 *
 * @returns {{getOrder: (function(string): null)}}
 */
export function useOrderSimple(orders = null) {
  /**
   * Retoune la commande {numcde} d'un tableau de commande
   *
   * @param {string} numcde
   *
   * @returns {undefined|object}
   */
  function getOrder(numcde) {
    const ordersArray = (isObject(orders) && Object.values(orders)) || orders

    return (
      (ordersArray && ordersArray !== null && ordersArray.length > 0 &&
        ordersArray.find((order) => order.numcde === numcde)) ||
      undefined
    )
  }

  return {getOrder}
}
