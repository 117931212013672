import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import {format} from "date-fns";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import {useState} from "react";
import ModalCustom from "../../ui/modal/ModalCustom";
import Box from "../../ui/box/Box";
import Moments from "../../../helpers/Moments";
import Numbers from "../../../helpers/Numbers";

export function SummaryModal({orderValues, show, onHide, onSubmit}) {
    const mourningWeedingOccasions = [5, '5', 2, '2'];
    const moment = mourningWeedingOccasions.includes(orderValues.occasion)
        ? `${orderValues.delivery.hour === '' ? '00' : orderValues.delivery.hour}:${orderValues.delivery.minutes === '' ? '00' : orderValues.delivery.minutes}`
        : Moments.format(orderValues.delivery.moment);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);

    return (
        <ModalCustom
            show={show}
            id={'summary'}
            onHide={() => {
                onHide();
                setAlreadySubmitted(false);
            }}
            header={true}
            headerTitle={'Récapitulatif de la commande'}
            footer={true}
            buttonsFooter={[
                {
                    text: `${isLoading ? 'Chargement…' : 'Modifier'}`,
                    func: function hide() {
                        onHide();
                        setAlreadySubmitted(false);
                    }
                },
                {
                    text: `${isLoading ? 'Chargement…' : 'Confirmer'}`,
                    func: function submit() {
                        if (!alreadySubmitted) {
                            setIsLoading(true);
                            onSubmit();
                            setAlreadySubmitted(true);
                        }
                    },
                    classNames: `${alreadySubmitted ? 'd-none ' : ''}mx-2`,
                    type: 'submit',
                    formId: 'order_form',
                }
            ]}
            content={
                <>
                    <Row className={'mt-3'}>
                        <Box
                            boxXs={12}
                            boxSm={6}
                            boxName={'receiver'}
                            boxContentClassNames={'text-left'}
                            headerClassNames={'rounded-top'}
                            header={true}
                            headerTextColor={'white'}
                            headerContent={<h3 className="my-auto">Livraison</h3>}
                            headerPadding={'p-3'}
                            boxContentPadding={'p-3'}
                            content={
                                <>
                                    <div><strong>Raison sociale :</strong> {orderValues.receiver.companyname}</div>
                                    <div><strong>Nom :</strong> {orderValues.receiver.lastname}</div>
                                    <div><strong>Prénom :</strong> {orderValues.receiver.firstname}</div>
                                    <div><strong>Téléphone :</strong> {orderValues.receiver.tel}</div>
                                    <div>
                                        <Table
                                            bordered={false}
                                            className={'text-left'}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td className={'p-0 align-top'}><strong>Adresse : </strong></td>
                                                    <td className={'p-0 align-top'}>
                                                        {orderValues.receiver.address1}<br/>
                                                        {orderValues.receiver.address2 !== '' && (
                                                            <>
                                                                {orderValues.receiver.address2}
                                                                <br/>
                                                            </>
                                                        )}
                                                        {orderValues.receiver.address3 !== '' && (
                                                            <>
                                                                {orderValues.receiver.address3}
                                                                <br/>
                                                            </>
                                                        )}
                                                        {orderValues.receiver.zipcode} {orderValues.receiver.city}<br/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div><strong>Pays :</strong> {orderValues.receiver.countryLabel}</div>
                                    <div><strong>Sur lieu de travail :</strong> {orderValues.delivery.workplace ? 'Oui' : 'Non'}</div>
                                    <div>
                                        <strong>Date : </strong>
                                        {orderValues.delivery.date !== null && format(orderValues.delivery.date, 'dd/MM/yyyy')} - {moment}</div>
                                    {(orderValues.occasion === 5 || orderValues.occasion === '5') && (<div><strong>Lieu :</strong> {orderValues.delivery.mourningplace}</div>)}
                                    <div><strong>Informations livreur :</strong> {orderValues.delivery.information}</div>
                                    <div><strong>Fleuriste exécutant :</strong> {orderValues.codex} - {orderValues.codexLabel}</div>
                                </>
                            }
                        />
                        <Box
                            boxXs={12}
                            boxSm={6}
                            boxName={'sender'}
                            boxContentClassNames={'text-left'}
                            headerClassNames={'rounded-top'}
                            header={true}
                            headerTextColor={'white'}
                            headerContent={<h3 className="my-auto">Client expéditeur</h3>}
                            headerPadding={'p-3'}
                            boxContentPadding={'p-3'}
                            content={
                                <>
                                    <div><strong>Raison sociale :</strong> {orderValues.sender.companyname}</div>
                                    <div><strong>Nom :</strong> {orderValues.sender.lastname}</div>
                                    <div><strong>Prénom :</strong> {orderValues.sender.firstname}</div>
                                    <div><strong>Téléphone :</strong> {orderValues.sender.tel}</div>
                                    <div><strong>Email :</strong> {orderValues.sender.email}</div>
                                    <div><strong>Anonyme :</strong> {orderValues.sender.anonymous ? 'Oui' : 'Non'}</div>
                                </>
                            }
                        />
                    </Row>
                    <Row>
                        <Box
                            boxXs={12}
                            boxName={'product'}
                            headerClassNames={'rounded-top'}
                            header={true}
                            headerTextColor={'white'}
                            headerContent={<h3 className="my-auto">Produit</h3>}
                            headerPadding={'p-3'}
                            boxContentPadding={'p-3'}
                            content={
                                <>
                                    <Row>
                                        <Col xs={12} sm={3} className={'my-auto'}>
                                            {(
                                                <Image
                                                    onError={(e) => {
                                                        e.target.src =
                                                            'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                                                    }}
                                                    src={(orderValues.scompo.id !== ''
                                                            ? `https://cdn.florajet.com/produits/300/${orderValues.scompo.id}.jpg`
                                                            : 'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                                                    )}
                                                    alt={orderValues.scompo.reference}
                                                    className="img-fluid mb-1 mx-auto d-block"
                                                />
                                            )}
                                            <small className={'d-flex justify-content-center'}>{orderValues.scompo.reference}</small>
                                        </Col>
                                        <Col xs={12} sm={9} className={'text-left'}>
                                            <div><strong>Nom :</strong> {orderValues.scompo.label}</div>
                                            <div><strong>Description :</strong> {orderValues.scompo.description}</div>
                                            <div><strong>Choix floral :</strong> {orderValues.scompo.categoryLabel}</div>
                                            <div><strong>Occasion :</strong> {orderValues.occasionLabel}</div>
                                            <div><strong>Message :</strong> {orderValues.message}</div>
                                            {orderValues.bubble && (<div><strong>Bulle :</strong> Oui</div>)}
                                            {orderValues.hasRibbon && (<div><strong>Ruban :</strong> {orderValues.ruban}</div>)}
                                            <br/>
                                            <div><strong>Frais de transmission :</strong> {Numbers.ConvertToEuro(orderValues.transmissioncosts)}</div>
                                            <div><strong>Montant TTC :</strong> {Numbers.ConvertToEuro(orderValues.amountvat)}</div>
                                            <div><strong>TVA :</strong> {orderValues.vat} %</div>
                                            <div><strong>Total :</strong> {Numbers.ConvertToEuro(parseFloat(orderValues.amountvat) + parseFloat(orderValues.transmissioncosts))}</div>
                                        </Col>
                                    </Row>
                                </>
                            }
                        />
                    </Row>
                </>
            }
        >

        </ModalCustom>

    );
}

SummaryModal.propTypes = {
    orderValues: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}
