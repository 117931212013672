import {createSlice} from '@reduxjs/toolkit'

import {resetPerimetrePageState} from './perimetrePageSlice'
import {resetPerimetreState} from './perimetreSlice'
import {resetShoppersModalState} from './shoppersModalsSlice'
import {resetShoppersState} from './shoppersSlice'

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState: {},
  reducers: {},
})

export const resetState = () => (dispatch) => {
  dispatch(resetPerimetrePageState())
  dispatch(resetPerimetreState())
  dispatch(resetShoppersModalState({preserveChosenPrebooks: false}))
  dispatch(resetShoppersState())
}

export const {navigate} = globalSlice.actions

export default globalSlice.reducer
