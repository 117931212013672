import {useDispatch, useSelector} from 'react-redux'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import DatePicker, {registerLocale} from 'react-datepicker'
import {format} from 'date-fns'
import fr from 'date-fns/locale/fr'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import Box from '../../ui/box/Box'
import CommandeAPI from '../../../services/CommandeAPI'
import Count from '../../ui/page/Count'
import ExecutionsAPI from '../../../services/ExecutionsAPI'
import Field from '../../ui/form/Field'
import {INTERVAL_RELOAD, ITEMS_PER_PAGE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import useFetch from '../../../hooks/useFetch'
import OrderCardGroup from '../../ui/card/OrderCardGroup'
import {TakePicture} from '../../ui/image/TakePicture'
import {TabMenu} from '../../ui/menu/TabMenu'
import {useShopperPrebook} from '../../../hooks/useShoppers'
import ShopperModals from '../../ui/modal/ShopperModals'
import {configureShoppersModals} from '../../../slice/shoppersModalsSlice'
import {base64} from '../../../Tools/Tools'
import {selectExecutions} from '../../../slice/executionSlice'

registerLocale('fr', fr)

const Executions = () => {
  const dispatch = useDispatch()
  const {reloadExecutions} = useSelector(selectExecutions)

  const {user} = useContext(AuthContext)
  const [sortType, setSortType] = useState('etatextranet')
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [searchText, setSearchText] = useState()
  const [searchDateDebut, setSearchDateDebut] = useState()
  const [searchDateFin, setSearchDateFin] = useState()
  const [searchActive, setSearchActive] = useState()
  const [type, setType] = useState('pending')
  const [dateDebut, setDateDebut] = useState()
  const [dateFin, setDateFin] = useState()
  const [order, setOrder] = useState()
  const [modalOrder, setModalOrder] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isErrorPdf, setIsErrorPdf] = useState(false)
  const [picture, setPicture] = useState()
  const [modalPicture, setModalPicture] = useState(false)
  const [modalTakePicture, setModalTakePicture] = useState(false)
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [isErrorState, setIsErrorState] = useState(false)
  const [isErrorUpload, setIsErrorUpload] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [isLoadingRefus, setIsLoadingRefus] = useState(false)
  const [modalRefus, setModalRefus] = useState(false)
  const [enableValidation, setEnableValidation] = useState(true)
  const [transition, setTransition] = useState(false)
  const [preview, setPreview] = useState()
  const isUnmounted = useRef(false)
  const location = useLocation()
  const webcamRef = useRef(null)
  const previewRef = useRef()
  const [refus, setRefus] = useState()
  const [types] = useState([
    {type: 'pending', title: 'En cours', pathname: 'executions'},
    {type: 'today', title: `Aujourd'hui`, pathname: 'executions'},
    {type: 'yesterday', title: 'Hier', pathname: 'executions'},
    {type: 'last-week', title: 'Semaine dernière', pathname: 'executions'},
    {type: 'last-month', title: 'Mois dernier', pathname: 'executions'},
    {type: 'by-last-days', title: '30 derniers jours', pathname: 'executions'},
    {type: 'search', title: 'Recherche', pathname: 'executions'},
  ]);

  const {
    handleShoppersModal,
    handleShowPrebookButton,
    handleShowCancellationPrebookButton,
    handleCancellationShoppersModal,
    handleCancellationModal,
  } = useShopperPrebook(user)

  let validationSchemaFile
  // Si pas de preview on créé l'objet de validation pour le champ upload photo
  if (!preview) {
    validationSchemaFile = Yup.object().shape({
      file: Yup.mixed().required('* Ce champ est requis.'),
    })
  }

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemaFile,
    onSubmit: (values, {setSubmitting}) => {
      postFile(preview, setSubmitting)
    },
    onReset: () => {
      previewRef.current.value = null
    },
  })

  const validationSchemaRefus = Yup.object().shape({
    refusmessage: Yup.string()
      .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
      .required('* Ce champ est requis.'),
  })

  const formikRefus = useFormik({
    initialValues: {
      refus: '1',
      refusmessage: '',
    },
    enableReinitialize: true,
    validationSchema: enableValidation ? validationSchemaRefus : false,
    onSubmit: (values, {setSubmitting}) => {
      postRefus(values, order, setSubmitting)
    },
  })

  const [
    {data: executions, isLoading, isError, totalItems: count},
    fetchData,
  ] = useFetch(
    ExecutionsAPI.getExecutions(
      user.CodeFl,
      type,
      sortType,
      sortOrder,
      currentPage,
      '',
      '',
      '',
      type === 'pending' ? 'ALL' : ITEMS_PER_PAGE,
    ),
  )

  // Gestion du rechargement de page
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(false)
    }, INTERVAL_RELOAD)
    return () => clearInterval(interval)
  }, [])

  // Gestion du changement d'url
  useEffect(() => {
    if (reload || reloadExecutions) {
      fetchData(
        ExecutionsAPI.getExecutions(
          user.CodeFl,
          type,
          sortType,
          sortOrder,
          currentPage,
          searchText,
          dateDebut && format(dateDebut, 'dd-MM-yyyy HH:mm:ss'),
          dateFin && format(dateFin, 'dd-MM-yyyy HH:mm:ss'),
          type === 'pending' ? 'ALL' : ITEMS_PER_PAGE,
        ),
      )
    }
  }, [
    user.CodeFl,
    type,
    searchText,
    searchDateDebut,
    searchDateFin,
    sortType,
    sortOrder,
    currentPage,
    reloadExecutions,
  ])

  useEffect(() => {
    if (executions.length) {
      const chosenPrebooks = {}

      executions.forEach((execution) => {
        if (execution.prebook && execution.prebook.mission) {
          chosenPrebooks[base64(execution.prebook.id)] = execution.prebook
        }
      })

      dispatch(configureShoppersModals({chosenPrebooks}))
    }
  }, [executions])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
  }, [user.CodeFl])

  // Gestion du changement de type depuis le param location du menu
  useEffect(() => {
    if (location.state) {
      setCurrentPage(1)
      setReload(true)
    }
  }, [location])

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = (newSortType) => {
    let sort
    if (sortType !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setCurrentPage(1)
    setSortType(newSortType)
    setSortOrder(sort)
    setReload(true)
  }

  // Gestion des champs recherche
  const handleSearchInput = (event) => {
    setSearchInput(event.target.value)
  }

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setType('search')
    setSearchActive(true)
    setSearchText(searchInput)
    setSearchDateDebut(dateDebut)
    setSearchDateFin(dateFin)
    setSortType(sortType)
    setSortOrder(sortOrder)
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setCurrentPage(1)
    setType('pending')
    setSearchActive()
    setSearchInput('')
    setSearchText()
    setSearchDateDebut()
    setSearchDateFin()
    setDateDebut()
    setDateFin()
    setSortType(sortType)
    setSortOrder(sortOrder)
  }

  // Gestion du bouton Visualiser la commande
  const handleShowOrder = async (id) => {
    // window.open(`https://www.monflorajet.com/v2/visu_commande.php?id=${id}&codex=${user.CodeFl}`);
    if (!isUnmounted.current) {
      setIsLoadingPdf(true)
      setTransition(false)
      setIsErrorPdf(false)
      setModalOrder(true)
      setOrder({id})
    }
    try {
      if (!isUnmounted.current) {
        const windowRef = window.open()
        const result = await CommandeAPI.getPDF(user.CodeFl, id)

        if (result.data === null) {
          setIsErrorPdf({
            variant: 'warning',
            heading: false,
            content: `Il n'y a actuellement aucun élément à afficher.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else if (!result.data) {
          setIsErrorPdf({
            variant: 'danger',
            heading: result.data !== null,
            content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else {
          setOrder((prevState) => ({...prevState, pdf: result.data}))
          setIsLoadingPdf(false)

          windowRef.location = process.env.REACT_APP_FRONT_HOST + result.data

          setModalOrder(false)
        }
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingPdf(false)
        setTransition(true)
        setIsErrorPdf({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
        })
      }
    }
  }

  // Gestion du bouton Voir la photo
  const handleViewPic = (picture) => {
    setModalPicture(true)
    setPicture(picture)
    setIsErrorUpload(false)
    setIsUploaded(false)
    setTransition(false)
  }

  // Gestion du bouton Prendre la photo
  const handleTakePic = (numcde) => {
    setPreview()
    setModalTakePicture(true)
    setOrder((prevState) => ({...prevState, numcde}))
  }

  // Gestion de la capture webcam
  const handleCapturePic = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot()
    setPreview(screenshot)
    formik.resetForm()
  }, [webcamRef, setPreview])

  // Gestion de la preview de l'upload photo
  const handleUploadChange = (e) => {
    setPreview()
    setIsLoadingPreview(true)
    formik.handleChange(e)

    const reader = new FileReader()

    reader.onloadend = () => {
      setPreview(reader.result)
      setIsLoadingPreview(false)
    }

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0])
    } else {
      setPreview()
      setIsLoadingPreview(false)
    }
  }

  // Gestion de la sauvegarde de l'upload photo
  const postFile = async (preview, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingUpload(true)
      setIsErrorUpload(false)
      setTransition(false)
    }
    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.uploadPicture(user.CodeFl, order.numcde, {
          content: preview,
        })

        setIsLoadingUpload(false)
        setTransition(true)
        setIsErrorUpload({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo ! Votre photo a bien été envoyée.',
          headingIconName: 'info-circle',
          content: `Vous pouvez consulter votre photo avec l'action "Voir la photo"`,
        })
        setPreview()
        formik.resetForm()
        setIsUploaded(true)

        fetchData(
          ExecutionsAPI.getExecutions(
            user.CodeFl,
            type,
            sortType,
            sortOrder,
            currentPage,
            searchText,
            dateDebut,
            dateFin,
            type === 'pending' ? 100 : 10,
          ),
        )

        fetchDataCount(
          ExecutionsAPI.getExecutionsCount(
            user.CodeFl,
            type,
            sortType,
            sortOrder,
            currentPage,
            searchText,
            dateDebut,
            dateFin,
            type === 'pending' ? 100 : 10,
          ),
        )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingUpload(false)
        setIsUploaded(false)
        setTransition(true)
        setIsErrorUpload({
          variant: 'danger',
          heading: true,
          content: `Votre photo n'a pas pu être envoyée, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setSubmitting(false)
  }

  // Gestion du changement d'état
  const handleState = async (state) => {
    if (!isUnmounted.current) {
      setIsLoadingState({key: state.item.numcde, value: true})
      setIsErrorState(false)
      setTransition(false)
    }

    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.postExecutions(user.CodeFl, {
          etatextranet: parseInt(state.item.actions[state.id].id, 10),
          etatextranetlibelle: state.item.actions[state.id].label,
          idsgribouil: state.item.idsgribouil,
          numcde: state.item.numcde,
        })

        setIsLoadingState({key: state.item.numcde, value: false})

        fetchData(
          ExecutionsAPI.getExecutions(
            user.CodeFl,
            type,
            sortType,
            sortOrder,
            currentPage,
            searchText,
            dateDebut,
            dateFin,
            type === 'pending' ? 100 : 10,
          ),
        )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingState({key: state.item.numcde, value: false})
        setTransition(true)
        setIsErrorState({
          variant: 'danger',
          heading: true,
          content: error.response.data.detail,
        })
        setModalState(true)
      }
    }
  }

  // Gestion du bouton refus
  async function handleRefus(state) {
    await ExecutionsAPI.getRefus().then((result) => {
      if (result.data) {
        setRefus(result.data)
      }
    })
    setModalRefus(true)
    setOrder(state)
  }

  // Gestion du changement des refus
  const handleChangeRefus = (event) => {
    formikRefus.setFieldValue('refus', event.target.value)
    formikRefus.setFieldTouched('refus', true)
    if (event.target.value === '1') {
      setEnableValidation(true)
    } else {
      setEnableValidation(false)
    }
  }

  // Gestion du changement d'état refus
  const postRefus = async (values, state, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingState({key: state.item.numcde, value: true})
      setIsErrorState(false)
      setTransition(false)
      setIsLoadingRefus(true)
    }

    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.postExecutions(user.CodeFl, {
          etatextranet: parseInt(state.item.actions[state.id].id, 10),
          etatextranetlibelle: state.item.actions[state.id].label,
          idsgribouil: state.item.idsgribouil,
          numcde: state.item.numcde,
          motif: formikRefus.values.refus,
          motifdesc: formikRefus.values.refusmessage,
        })

        setIsLoadingState({key: state.item.numcde, value: false})
        setIsLoadingRefus(false)

        fetchData(
          ExecutionsAPI.getExecutions(
            user.CodeFl,
            type,
            sortType,
            sortOrder,
            currentPage,
            searchText,
            dateDebut,
            dateFin,
            type === 'pending' ? 100 : 10,
          ),
        )

        setModalRefus(false)
        formikRefus.resetForm()
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingState({key: state.item.numcde, value: false})
        setTransition(true)
        setIsErrorState({
          variant: 'danger',
          heading: true,
          content: error.response.data.detail,
        })
        setIsLoadingRefus(false)
        setModalRefus(false)
        setModalState(true)
      }
    }
    setSubmitting(false)
    setEnableValidation(true)
  }

  const actionButtons = [
    {
      name: 'Visualiser',
      nameParam: 'numcde',
      color: 'primary',
      icon: 'eye',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleShowOrder,
      funcParam: 'numcde',
      filter: 'actions',
      filterParam: 'visu',
    },
    {
      name: 'Voir la photo',
      color: 'primary',
      icon: 'image',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleViewPic,
      funcParam: 'url_photo1',
      filter: 'actions',
      filterParam: 'view_pic',
    },
    {
      name: 'Prendre une nouvelle photo',
      color: 'primary',
      icon: 'camera',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleTakePic,
      funcParam: 'numcde',
      filter: 'actions',
      filterParam: 'webcam',
    },
    {
      name: 'Refuser la commande',
      color: 'red',
      icon: 'times',
      iconColor: 'white',
      iconClassNames: 'margin-icon',
      classnames: 'm-1',
      func: handleRefus,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_3',
    },
    {
      name: 'Accepter la commande',
      color: 'green-darkest',
      icon: 'check',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_4',
    },
    {
      name: 'Destinataire appelé',
      color: 'orange',
      icon: 'phone',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_41',
    },
    {
      name: 'Destinataire ne répond pas',
      color: 'red',
      icon: 'phone',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_42',
    },
    {
      name: 'Bouquet préparé',
      color: 'blue',
      icon: 'gift',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_43',
    },
    {
      name: `Passer à l'état "En cours de livraison"`,
      color: 'primary-dark',
      icon: 'truck',
      iconColor: 'white',
      classnames: 'm-1 padding-icon-xl',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_5',
    },
    {
      name: `Passer à l'état "Commande livrée"`,
      color: 'primary-dark',
      icon: 'smile',
      iconColor: 'white',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_6',
    },
    {
      name: `Passer à l'état "Avis de passage déposé ou destinataire appelé"`,
      color: 'orange',
      icon: 'map-marker-alt',
      iconColor: 'white',
      iconClassNames: 'margin-icon',
      classnames: 'm-1',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_7',
    },
    {
      name: 'Passer à l\'état "Commande livrée après avis de passage"',
      color: 'primary',
      icon: 'smile',
      iconColor: 'white',
      classnames: 'm-1 padding-icon-xl',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_60',
    },
    {
      name: `Passer à l'état "Refus par le client final"`,
      color: 'red',
      icon: 'exclamation-triangle',
      iconColor: 'white',
      classnames: 'm-1 padding-icon',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_8',
    },
    {
      name: `Passer à l'état "Commande refusée après avis de passage"`,
      color: 'red',
      icon: 'exclamation-triangle',
      iconColor: 'white',
      classnames: 'm-1 padding-icon',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_80',
    },
    {
      name: 'Arrêter la sonnerie',
      color: 'primary',
      icon: 'bell-slash',
      iconColor: 'white',
      classnames: 'm-1 padding-icon-xl',
      func: handleState,
      isLoading: {key: 'numcde', type: isLoadingState},
      filter: 'actions',
      filterParam: 'statut_20',
    },
    {
      name: 'Demander une livraison',
      color: 'yellow-base',
      icon: 'biking',
      iconColor: 'white',
      classnames: 'm-1 padding-icon-xl',
      func: handleShoppersModal, // Ouvre la modale de séléction d'un shopper.
      isLoading: {key: 'numcde', type: isLoadingState},
      view: handleShowPrebookButton, // Affiche ou pas le bouton.
    },
    {
      name: 'Annuler livraison',
      color: 'red',
      icon: 'biking',
      iconColor: 'white',
      classnames: 'm-1 padding-icon-xl',
      func: handleCancellationShoppersModal, // Ouvre la modale de séléction d'un shopper.
      isLoading: {key: 'numcde', type: isLoadingState},
      view: handleShowCancellationPrebookButton, // Affiche ou pas le bouton.
    },
  ]

  return (
    <>
      <Title title="Mes exécutions" />

      <Col xs={12} className="filter">
        <Form onSubmit={handleSearch} className={'d-flex w-100'}>
          <div className="d-flex flex-column flex wrap mr-md-4 mb-3 px-0 col-md-6">
            <div className="form-label mr-4 mb-1">
              Localité ou numéro de commande :
            </div>
            <Field
              name="searchExecutions"
              placeholder="Localité ou numéro de commande"
              style={{width: '100%'}}
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>
          <div className="d-flex flex-column flex wrap mr-4 mb-3">
            <div className="form-label mb-1">Date de début :</div>
            <DatePicker
              name="start"
              selected={dateDebut}
              onChange={setDateDebut}
              dateFormat={'dd/MM/yyyy'}
              className={'form-control'}
              placeholderText={'--/--/----'}
              openToDate={new Date()}
              locale={'fr'}
              showLeadingZeros={true}
              calendarIcon={null}
            />
          </div>

          <div className="d-flex flex-column flex wrap mr-4 mb-3">
            <div className="form-label mb-1">Date de fin :</div>
            <DatePicker
              name="end"
              selected={dateFin}
              onChange={setDateFin}
              dateFormat={'dd/MM/yyyy'}
              className={'form-control'}
              placeholderText={'--/--/----'}
              openToDate={new Date()}
              locale={'fr'}
              showLeadingZeros={true}
              calendarIcon={null}
            />
          </div>

          <div className="d-flex flex-row align-self-end mb-3">
            <Button
              type="submit"
              className="d-flex btn-square align-items-center mr-2"
              disabled={searchInput === '' && !dateDebut && !dateFin}>
              <FontAwesomeIcon icon="search" color="white" />
            </Button>
            <Button
              className="btn-red btn-square d-flex align-items-center"
              onClick={handleCancel}
              disabled={searchInput === '' && !dateDebut && !dateFin}>
              <FontAwesomeIcon
                icon="times"
                color="white"
                className="margin-icon"
              />
            </Button>
          </div>
        </Form>
      </Col>

      <Box
        boxName="executions-infos"
        boxClassNames="mb-3"
        boxContentPadding="p-3"
        boxContentClassNames="fs-14 rounded"
        boxContentBg="grey-light"
        content={
          <>
            <div>
              <FontAwesomeIcon
                icon="info-circle"
                color="var(--primary)"
                size="lg"
                className="mr-2"
              />
              <span className="ff-bold">Information :</span> Pour une visibilité
              optimale, les commandes acceptées automatiquement apparaîtront
              dorénavant <span className="bg-blue-light ff-bold">en bleu</span>{' '}
              dans l'onglet EN COURS.
            </div>
          </>
        }
      />

      <Box
        boxName="executions"
        // boxClassNames="flex-column"
        boxContentBg="white"
        content={
          <>
            <BrowserView renderWithFragment={true}>
              <TabMenu
                currentTab={type}
                tabs={types}
                setCurrentTabFunction={setType}
                isLoading={isLoading}
                numberOfElements={count && count}
              />
            </BrowserView>

            <MobileView renderWithFragment={true}>
              <TabMenu
                tabs={types}
                numberTabsToShow={3}
                searchActive={searchActive}
                setCurrentTabFunction={setType}
                currentTab={type}
              />

              {count > 0 && !isLoading && (
                <Count
                  classNames="text-right p-2 bg-grey rounded mb-2"
                  totalItems={count}
                  name="exécution"
                />
              )}
            </MobileView>

            <BrowserView>
              <TableCustom
                isLoading={isLoading}
                isError={isError}
                headers={[
                  'Etat',
                  'Numéro',
                  'Date et moment de livraison',
                  'Code postal ville',
                  'Accessoire',
                  'Montant',
                  'Actions',
                ]}
                sorting={[
                  'etatextranet',
                  'numcde',
                  'datliv',
                  'localite',
                  'occasion',
                  'montantcdee',
                ]}
                handleSortOrder={handleSortOrder}
                sortType={sortType}
                sortOrder={sortOrder}
                data={executions}
                configLine={
                  type === 'pending' && {
                    key: 'etatextranet',
                    filter: 40,
                    color: 'blue-light',
                    text_class: 'font-weight-bold',
                  }
                }
                config={[
                  {
                    type: 'picto',
                    key: 'etatextranet',
                  },
                  {
                    type: 'multilines',
                    key: [
                      {
                        type: 'link_modal_order',
                        key: 'numcde',
                        func: handleShowOrder,
                        funcParam: 'numcde',
                      },
                      {type: 'text', key: 'nomdes'},
                    ],
                  },
                  {
                    type: 'multilines',
                    key: [
                      {type: 'date', key: 'datliv'},
                      {type: 'text', key: 'momentjourvalue'},
                    ],
                  },
                  {
                    type: 'multilines',
                    key: [
                      {type: 'text', key: 'codpostal'},
                      {type: 'text', key: 'localite'},
                    ],
                  },
                  {
                    type: 'list',
                    key: 'accessories',
                    labelField: 'accessory_label',
                    quantityField: 'accessory_number',
                    compactList: true,
                    withQuantity: true,
                  },
                  {type: 'currency', key: 'amountvat'},
                  {
                    type: 'buttons',
                    key: actionButtons,
                  },
                ]}
              />

              {isLoading && <Loader />}
            </BrowserView>

            <MobileView>
              {executions && executions.length > 0 && (
                <OrderCardGroup
                  isLoading={isLoading}
                  isError={isError}
                  data={executions}
                  actionButtons={actionButtons}
                  max={ITEMS_PER_PAGE}
                  user={user}
                />
              )}
            </MobileView>
          </>
        }
      />

      {order && (
        <ModalCustom
          show={modalOrder}
          onHide={() => setModalOrder(false)}
          size={'sm'}
          id="modal-order"
          header={false}
          bodyClassname="text-left"
          content={
            <>
              <AlertCustom
                variant={isErrorPdf.variant}
                heading={isErrorPdf.heading}
                bodyContent={isErrorPdf.content}
                transition={transition}
              />

              {isLoadingPdf && <Loader />}
            </>
          }
          footer={true}
          buttonsFooter={[
            {
              text: 'Fermer',
            },
          ]}
        />
      )}

      <ModalCustom
        show={modalPicture}
        onHide={() => setModalPicture(false)}
        id="modal-picture"
        size="md"
        bodyClassname="p-3"
        content={
          <>
            <div className="mb-3">
              <Image
                onError={(e) => {
                  e.target.src =
                    'https://www.monflorajet.com/v2/themes/extranet_v2/img/mini_def.png'
                }}
                src={picture}
                className="rounded-xl img-fluid mx-auto"
              />
            </div>
          </>
        }
        buttons={[
          {
            text: 'Fermer',
          },
        ]}
      />

      <TakePicture
        showModalTakePicture={modalTakePicture}
        order={order}
        onHide={setModalTakePicture}
      />

      <ModalCustom
        show={modalState}
        onHide={() => setModalState(false)}
        id="modal-state"
        content={
          <>
            <AlertCustom
              classname="text-left"
              variant={isErrorState.variant}
              heading={isErrorState.heading}
              headingTitle={isErrorState.headingTitle}
              headingIconName={isErrorState.headingIconName}
              bodyContentHtml={isErrorState.content}
              transition={transition}
            />
          </>
        }
        buttons={[
          {
            text: 'Fermer',
          },
        ]}
      />

      {refus && order && (
        <ModalCustom
          show={modalRefus}
          onHide={() => setModalRefus(false)}
          id="modal-refus"
          title="Motif de refus de la commande"
          titleClassname="text-center mb-3"
          bodyClassname="text-left"
          content={
            <>
              <Col md={{span: 10, offset: 1}} className="text-left">
                <div className="fs-20 text-center my-3">
                  Merci de nous indiquer le motif de refus de la commande
                  <span className="ff-bold">
                    {' '}
                    {(order.item && order.item.numcde) || order.id}
                  </span>
                </div>

                <Form
                  onSubmit={(event) => {
                    event.preventDefault()
                    formikRefus.handleSubmit()
                  }}
                  className="mb-3">
                  {refus &&
                    refus.map((item, index) => (
                      <Form.Check
                        key={index}
                        custom
                        type="radio"
                        className="mb-3"
                        label={item.refusDesc}
                        name="refus"
                        value={item.refusIdx}
                        id={`refus-${item.refusIdx}`}
                        defaultChecked={
                          formikRefus.values.refus === item.refusIdx
                        }
                        onChange={(event) => handleChangeRefus(event)}
                      />
                    ))}
                  {formikRefus.values.refus === '1' && (
                    <Field
                      as="textarea"
                      rows={8}
                      name="refusmessage"
                      value={formikRefus.values.refusmessage}
                      onChange={formikRefus.handleChange}
                      onBlur={formikRefus.handleBlur}
                      placeholder="Merci de préciser le choix floral demandé *"
                      fieldClassNames="bg-white"
                      classNames="my-3"
                      error={
                        formikRefus.touched.refusmessage &&
                        formikRefus.errors.refusmessage
                      }
                      success={
                        formikRefus.touched.refusmessage &&
                        !formikRefus.errors.refusmessage
                      }
                    />
                  )}
                  <Button
                    type="submit"
                    disabled={formikRefus.isSubmitting}
                    className="">
                    {isLoadingRefus ? (
                      <Loader
                        variant="white"
                        size="sm"
                        classname="my-1"
                        style={{
                          marginLeft: '21px',
                          marginRight: '20px',
                        }}
                      />
                    ) : (
                      'Enregistrer'
                    )}
                  </Button>
                </Form>
              </Col>
            </>
          }
          footer={true}
          buttonsFooter={[
            {
              text: 'Fermer',
            },
          ]}
        />
      )}

      <ShopperModals
        handleCancellationModal={handleCancellationModal}
        executions={executions}
      />
    </>
  )
}

export default Executions
