import {useContext, useEffect, useReducer} from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import {useHistory, useLocation, useNavigate} from 'react-router-dom'
import LoginFormReducer from '../reducers/LoginFormReducer'
import AuthAPI from '../services/AuthAPI'
import AuthContext from '../contexts/AuthContext'
import {TokenManager} from './TokenManager'
import {useFlowerFeast} from './useFlowerFeast'
import {useStoreExecutions} from "../store/useStoreExecutions";

export function useLogin(location) {
  const {setIsAuthenticated, setUser} = useContext(AuthContext)
  const navigate = useNavigate()
  let referer = location ? location.state.referer : '/tableau-de-bord'
  const source = axios.CancelToken.source()
  const hasFlowerFeast =
    sessionStorage.getItem('flower_feast') === 'true' ||
    sessionStorage.getItem('flower_feast') > 0
  const history = useLocation();
  const setFlorist = useStoreExecutions(state => state.setFlorist);

  if (hasFlowerFeast) {
    referer = '/executions'
  }

  // Etat par défaut
  const [state, dispatch] = useReducer(LoginFormReducer, {
    isError: false,
    isLoading: false,
    errorMessage: false,
    errorField: false,
    modalShow: false,
    credentials: {
      codefl: '',
      password: '',
    },
  })

  // Gestion des champs
  function handleChange({currentTarget}) {
    const {value, name} = currentTarget
    dispatch({
      type: 'HANDLE_CHANGE',
      credentials: {...state.credentials, [name]: value}, // La clé name dans crédentials ancien état change par value
    })
  }

  async function fetchLogin() {
    dispatch({
      type: 'FETCH_INIT',
    })

    try {
      const results = await AuthAPI.authenticate(state.credentials)
      dispatch({
        type: 'FETCH_SUCCESS',
      })
      const tokenManager = TokenManager()
      tokenManager.setToken(results.data.token)
      setUser(jwtDecode(results.data.token)) // On save le user dans le contexte
      setFlorist(jwtDecode(results.data.token));
      setIsAuthenticated(true)
      sessionStorage.removeItem('flower_feast')
      navigate(referer) // Redirection
    } catch (error) {
      if (error.response && error.response.data.message === 'Bad credentials') {
        dispatch({
          type: 'FETCH_ERROR_BAD_CREDENTIALS',
        })
      } else {
        console.log(error)
        dispatch({
          type: 'FETCH_ERROR',
          errorMessage: error.message,
        })
      }
    }
  }

  // Gestion du submit
  async function handleSubmit(event) {
    event.preventDefault()
    fetchLogin()
  }

  // gestion de la modal du formulaire de demande d'adhésion.
  function handleSubscriptionModal(active = false) {
    dispatch({
      type: 'FETCH_SUBSCRIPTION_MODAL',
      modalShow: active,
    })
  }

  useEffect(() => {
    return () => {
      source.cancel('Component LoginForm is unmounted')
      console.log(source.token.reason.message)}
  }, [])

  return {state, dispatch, handleChange, handleSubmit, handleSubscriptionModal}
}
