import {Nav} from 'react-bootstrap'
import React, {useState} from 'react'

import NavItemCustom from '../../../router/NavItemCustom'

const ComptaMenu = () => {
  const [types] = useState([
    {type: 'prelevements', title: 'Les prélèvements'},
    {type: 'virements', title: 'Les virements'},
    {type: 'cheques-emis', title: 'Les chèques émis'},
  ])

  return (
    <Nav className="menu d-flex flex-column flex-sm-row bg-primary rounded-top-xl px-3 py-2">
      {types.map((item, index) => (
        <NavItemCustom
          key={index}
          eventKey={item.type}
          toPathname={item.type}
          classnamelink="menu-item py-1 px-3 px-sm-4 my-1 my-sm-2 mr-sm-2"
          title={item.title}
        />
      ))}
    </Nav>
  )
}

export default ComptaMenu
