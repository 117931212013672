import React, {useState} from "react";
import Box from "../../../ui/box/Box";
import MagasinMenu from "../MagasinMenu";
import {HolidaysContent} from "./HolidaysContent";

export const Holidays = () => {
    const [currentTab, setCurrentTab] = useState('conges');
    return (
        <>
            <Box
                boxXxl={'8'}
                boxHd={'8'}
                boxName={'conges'}
                boxClassNames={'flex-column'}
                header={true}
                headerClassNames={'rounded-top'}
                headerContent={
                    <>
                        <MagasinMenu currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                    </>
                }
                content={<HolidaysContent />}
            />
        </>
    );
}