import {
  COMPTEURS_API,
  ITEMS_PER_PAGE_COMPTEURS,
  YEARS_COMPTEURS_API,
  YEARS_PER_TABS_COMPTEURS,
} from '../constants/Constants'

function getYearsCompteurs() {
  return {
    name: 'compteurs',
    method: 'get',
    url: `${YEARS_COMPTEURS_API}?count=${YEARS_PER_TABS_COMPTEURS}`,
  }
}

function getCompteurs(year, codefl, currentPage) {
  return {
    name: 'compteurs',
    method: 'get',
    url: `${COMPTEURS_API}?pagination=true&mois=${year}&codefl=${codefl}&page=${currentPage}&itemsPerPage=${ITEMS_PER_PAGE_COMPTEURS}`,
    headers: {
      accept: 'application/ld+json',
    },
  }
}

function getCompteursGraphique(year, codefl) {
  return {
    name: 'compteurs',
    method: 'get',
    url: `${COMPTEURS_API}?mois=${year}&codefl=${codefl}`,
  }
}

export default {
  getYearsCompteurs,
  getCompteurs,
  getCompteursGraphique,
}
