class Numbers {
  static ConvertToEuro = (number) => {
    return `${Number(number).toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} €`
  }
}

export default Numbers
