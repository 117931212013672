import {useDispatch, useSelector} from 'react-redux'
import React, {useCallback, useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import {AsyncTypeahead, Menu, MenuItem, Token} from 'react-bootstrap-typeahead'
import PropTypes from 'prop-types'
import {
  initCommuneSearch,
  searchByLocalite,
  searchByPostalCode,
  searchCity,
  selectCommuneSearch,
} from '../../../../slice/communeSearchSlice'

const SearchCommuneFormGroup = ({
  onChange,
  selectedCommune,
  enableSearchByLocalite,
  zipcodeClassname,
  localiteClassname,
  formGroupZipcodeClassname,
  formGroupLocaliteClassname,
  disabledIdscommunes,
  disabled,
}) => {
  const {data: communes, result: optionsZipcode, isLoading} = useSelector(
    selectCommuneSearch,
  )
  const [initialized, setInitialized] = useState(false)
  const dispatch = useDispatch()

  const handleSearch = useCallback((search) => {
    dispatch(searchCity(search))
  })

  const renderMenu = (options, props) => (
    <Menu {...props}>
      {options.map((option, index) => (
        <MenuItem
          key={`menu-${index}`}
          option={option}
          position={index}
          disabled={
            disabledIdscommunes &&
            disabledIdscommunes.includes(option.idscommunes)
          }>
          <span>{option.codpostal}</span>
          <span>&nbsp;{option.localite !== '' && `(${option.localite})`}</span>
        </MenuItem>
      ))}
    </Menu>
  )

  useEffect(() => {
    if (!initialized) {
      dispatch(initCommuneSearch())

      setInitialized(true)
    }
  }, [initialized, setInitialized])

  return (
    <>
      <Form.Group className={formGroupZipcodeClassname || ''}>
        <Form.Label>
          Code postal {!disabled && <span className="text-red">*</span>}
        </Form.Label>
        <AsyncTypeahead
          id={'zipcode'}
          minLength={2}
          maxResults={10}
          filterBy={() => true}
          promptText={'Entrez votre recherche'}
          searchText={'Chargement...'}
          emptyLabel={'Aucun résultat'}
          paginationText={'Afficher plus de résultats'}
          labelKey={'codpostal'}
          placeholder={'Code postal'}
          className={zipcodeClassname || ''}
          isLoading={isLoading}
          selected={selectedCommune}
          options={optionsZipcode}
          onSearch={handleSearch}
          onChange={onChange}
          useCache={false}
          disabled={isLoading || disabled}
          renderMenu={renderMenu}
        />
      </Form.Group>

      <Form.Group className={formGroupLocaliteClassname || ''}>
        <Form.Label>
          Localité {!disabled && <span className="text-red">*</span>}
        </Form.Label>

        {(enableSearchByLocalite && (
          <AsyncTypeahead
            id={'localite'}
            minLength={2}
            maxResults={10}
            filterBy={() => true}
            promptText={'Entrez votre recherche'}
            searchText={'Chargement...'}
            emptyLabel={'Aucun résultat'}
            paginationText={'Afficher plus de résultats'}
            labelKey={'localite'}
            placeholder={'Localité'}
            isLoading={isLoading}
            selected={selectedCommune}
            options={optionsZipcode}
            onSearch={handleSearch}
            useCache={false}
            renderMenu={renderMenu}
            onChange={onChange}
            className={localiteClassname || ''}
          />
        )) || (
          <Form.Control
            readOnly
            placeholder={'Localité'}
            defaultValue={selectedCommune[0] && selectedCommune[0].localite}
            className={localiteClassname || ''}
          />
        )}
      </Form.Group>
    </>
  )
}

SearchCommuneFormGroup.propTypes = {
  onChange: PropTypes.func,
  selectedCommune: PropTypes.any,
  enableSearchByLocalite: PropTypes.bool,
  zipcodeClassname: PropTypes.string,
  localiteClassname: PropTypes.string,
  formGroupZipcodeClassname: PropTypes.string,
  formGroupLocaliteClassname: PropTypes.string,
  disabledIdscommunes: PropTypes.array,
  disabled: PropTypes.bool,
}

export default SearchCommuneFormGroup
