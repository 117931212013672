import axios from 'axios'
import {useEffect, useReducer, useState} from 'react'

import FetchReducer from '../reducers/FetchReducer'
import API from '../services/API'

const useFetch = (configAPI, noInit) => {
  const source = axios.CancelToken.source()
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URL}${configAPI.url}`)
  const [state, dispatch] = useReducer(FetchReducer, {
    isLoading: false,
    isError: false,
    data: [],
    totalItems: 0,
  })

  const fetchData = async () => {
    dispatch({type: 'FETCH_INIT'})

    try {
      const response = await API(url, {
        method: configAPI.method,
        headers: configAPI.headers,
        data: configAPI.data,
      })

      // CASE NO RESPONSE DATA (=== null)
      if (!response.data) {
        dispatch({
          type: 'FETCH_SUCCESS_EMPTY',
          payload: response.data,
          totalItems: 0,
          name: configAPI.name,
          single: configAPI.single || '',
          female: configAPI.female || '',
        })

        // CASE RESPONSE DATA ARRAY
      } else if (response.data instanceof Array) {
        // CASE RESPONSE DATA ARRAY EMPTY
        // FIRST CASE : Empty data & No hydra member (normal case)
        // OR
        // SECOND CASE : Empty data & Empty hydra member (hydra case)

        if (
          (!response.data.length && !response.data['hydra:member']) ||
          (!response.data.length && !response.data['hydra:member'].length) ||
            response.data.length === 0
        ) {
          dispatch({
            type: 'FETCH_SUCCESS_EMPTY',
            payload: response.data,
            totalItems: 0,
            name: configAPI.name,
            single: configAPI.single || '',
            female: configAPI.female || '',
          })

          // CASE RESPONSE DATA ARRAY FULL
          // Full data & Hydra or No hydra member
        } else {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: response.data['hydra:member']
              ? response.data['hydra:member']
              : response.data,
            totalItems: response.data['hydra:totalItems']
              ? response.data['hydra:totalItems']
              : response.data.length,
          })
        }

        // CASE RESPONSE DATA OBJECT
      } else if (response.data instanceof Object) {
        // CASE RESPONSE DATA OBJECT EMPTY
        // ONLY CASE : Hydra case & Empty hydra member
        if (
          response.data['hydra:member'] &&
          !response.data['hydra:member'].length
        ) {
          dispatch({
            type: 'FETCH_SUCCESS_EMPTY',
            payload: response.data,
            totalItems: 0,
            name: configAPI.name,
            single: configAPI.single || '',
            female: configAPI.female || '',
          })

          // CASE RESPONSE DATA OBJECT FULL
          // Full data & Hydra or No hydra member
        } else {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: response.data['hydra:member']
              ? response.data['hydra:member']
              : response.data,
            totalItems: response.data['hydra:totalItems']
              ? response.data['hydra:totalItems']
              : response.data.length || 1,
          })
        }

        // CASE DEFAULT SUCCESS
      } else {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.data['hydra:member']
            ? response.data['hydra:member']
            : response.data,
          totalItems: response.data['hydra:totalItems']
            ? response.data['hydra:totalItems']
            : response.data.length,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'FETCH_ERROR',
        payload: null,
        totalItems: 0,
        name: configAPI.name,
        single: configAPI.single || '',
        female: configAPI.female || '',
        error,
      })
    }
  }

  useEffect(() => {
    if (!noInit) {
      fetchData()
    }

    return () => {
      source.cancel(`FetchHook ${configAPI.name} is unmounted`)
    }
  }, [url])

  return [state, setUrl]
}

export default useFetch
