import React from "react";
import PropTypes from 'prop-types'
import AlertCustom from "../../alert/AlertCustom";

const PasswordFormAlert = ({state}) => {
  return (
    <>
      <AlertCustom
        heading={false}
        variant={(!state.isError) ? 'success' : 'danger'}
        bodyContent={(state.message) ?? state.errorMessage}
        transition={(Boolean(state.message) || Boolean(state.errorMessage))}
        dismissible={false}
      />
    </>
  )
}

PasswordFormAlert.propTypes = {
  state: PropTypes.object,
}

export default PasswordFormAlert
