import {Button, Col, Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import Count from '../../ui/page/Count'
import Field from '../../ui/form/Field'

const CatalogueFilters = ({
  handleSearch,
  searchInput,
  searchText,
  searchType,
  handleSearchInput,
  handleCancel,
  totalItems,
  handleEdit,
}) => {
  return (
    <Col
      xs={12}
      className="filter d-flex flex-row flex-wrap align-items-center justify-content-between">
      <Form onSubmit={handleSearch}>
        <div className="d-flex flex-column flex wrap mr-4">
          <div className="form-label mr-4 mb-1">Nom :</div>
          <Field
            name="nom"
            placeholder="Nom"
            classNames="mb-3 mr-2"
            value={searchInput.nom}
            onChange={handleSearchInput}
          />
        </div>

        <div className="d-flex flex-column flex wrap mr-4">
          <div className="form-label mr-4 mb-1">Téléphone :</div>
          <Field
            name="tel"
            placeholder="Téléphone"
            classNames="mb-3 mr-2"
            value={searchInput.tel}
            onChange={handleSearchInput}
          />
        </div>

        <div className="d-flex flex-row align-self-end">
          <Button
            type="submit"
            className="btn-primary btn-square d-flex align-items-center mb-3 mr-2"
            disabled={searchInput.nom === '' && searchInput.tel === ''}>
            <FontAwesomeIcon icon="search" color="white" />
          </Button>
          <Button
            className="btn-red btn-square d-flex align-items-center mb-3"
            onClick={handleCancel}
            disabled={searchInput.nom === '' && searchInput.tel === ''}>
            <FontAwesomeIcon
              icon="times"
              color="white"
              style={{marginLeft: '2px', marginRight: '2px'}}
            />
          </Button>
        </div>
      </Form>

      <div className="d-flex align-self-end mb-3">
        <Count totalItems={totalItems} name="client" />
      </div>
    </Col>
  )
}

CatalogueFilters.propTypes = {
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSearchInput: PropTypes.func,
  handleSearch: PropTypes.func,
  searchInput: PropTypes.shape({
    nom: PropTypes.string,
    tel: PropTypes.string,
  }),
  searchText: PropTypes.string,
  searchType: PropTypes.string,
  totalItems: PropTypes.number,
}

export default CatalogueFilters
