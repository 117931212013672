import PropTypes from "prop-types";
import React from "react";
import Box from "../../ui/box/Box";
import Field from "../../ui/form/Field";

export function MessageForm({formik, colXs, colSm}) {
    return (
        <>
            <Box
                boxXs={colXs}
                boxSm={colSm}
                boxName={'message'}
                headerClassNames={'rounded-top'}
                header={true}
                headerTextColor={'white'}
                headerContent={
                    <h3 className="my-auto">
                        Message
                        {(formik.values.occasion === 5 || formik.values.occasion === '5') && ' / Ruban'}
                    </h3>
                }
                headerPadding={'p-3'}
                boxContentPadding={'p-3'}
                content={
                    <>
                        <Field
                            id={'message'}
                            name={'message'}
                            label={'Message'}
                            as={'textarea'}
                            rows={5}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                        />

                        {(formik.values.occasion === 5 || formik.values.occasion === '5') && (
                            <Field
                                id={'ruban'}
                                name={'ruban'}
                                label={'Ruban'}
                                value={formik.values.ruban}
                                onChange={(event) => {
                                    const {value} = event.target;
                                    formik.setFieldValue('ruban', value);
                                    formik.setFieldValue('hasRibbon', value.length > 0);
                                }}
                            />
                        )}
                    </>
                }
            />
        </>
    );
}

MessageForm.propTypes = {
    formik: PropTypes.object.isRequired,
    colXs: PropTypes.number,
    colSm: PropTypes.number
}
