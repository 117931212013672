import {Button, Col, Form, Row} from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, {useContext, useEffect, useRef, useState} from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import Box from '../../ui/box/Box'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import PointagesAPI from '../../../services/PointagesAPI'
import Text from '../../../helpers/Text'
import Title from '../../ui/page/Title'

const Pointages = () => {
  const {user} = useContext(AuthContext)
  const [type, setType] = useState('today')
  const [reload, setReload] = useState(false)
  const [pointage, setPointage] = useState()
  const [modalPointage, setModalPointage] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isErrorPdf, setIsErrorPdf] = useState(false)
  const [transition, setTransition] = useState(false)
  const isUnmounted = useRef(false)
  const [dateDebut, setDateDebut] = useState()
  const [dateFin, setDateFin] = useState()
  const [searchDateFin, setSearchDateFin] = useState()
  const [searchDateDebut, setSearchDateDebut] = useState()
  const currentYear = moment().startOf('year').toDate()

  const [types] = useState([
    {
      type: 'today',
      title: `Aujourd'hui`,
      date: moment(new Date()).format('DD/MM/YYYY'),
    },
    {
      type: 'yesterday',
      title: 'Hier',
      date: moment().subtract(1, 'days').format('DD/MM/YYYY'),
    },
    {
      type: 'last-week',
      title: 'Semaine dernière',
      date: `${moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format('DD/MM/YYYY')} au ${moment()
        .subtract(1, 'weeks')
        .endOf('week')
        .format('DD/MM/YYYY')}`,
    },
    {
      type: 'last-month',
      title: 'Mois dernier',
      date: `mois de ${Text.CapitalizeFirst(
        moment().subtract(1, 'months').format('MMMM'),
      )}`,
    },
    {
      type: 'by-last-days',
      title: '30 derniers jours',
      date: `${moment().subtract(30, 'days').format('DD/MM/YYYY')} au ${moment(
        new Date(),
      ).format('DD/MM/YYYY')}`,
    },
  ])

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      handleShow('search')
    }
  }, [searchDateDebut, searchDateFin])

  // Gestion du submit recherche
  const handleSearch = (event) => {
    event.preventDefault()
    setType('search')
    setSearchDateDebut(dateDebut)
    setSearchDateFin(dateFin)
    setReload(true)
  }

  // Gestion du cancel recherche
  const handleCancel = (event) => {
    event.preventDefault()
    setType('today')
    setSearchDateDebut()
    setSearchDateFin()
    setDateDebut()
    setDateFin()
    setReload(false)
  }

  // Gestion de l'affichage des pdf pointages
  const handleShow = async (item) => {
    if (!isUnmounted.current) {
      setIsLoadingPdf(true)
      setTransition(false)
      setIsErrorPdf(false)
      setModalPointage(true)
      setPointage({title: item.title})
    }
    try {
      if (!isUnmounted.current) {
        const result = await PointagesAPI.getPointagesPDF(
          user.CodeFl,
          item.type || type,
          searchDateDebut,
          searchDateFin,
        )

        if (!result.data) {
          setIsErrorPdf({
            variant: 'danger',
            heading: true,
            content: `Votre pointage n'a pas pu être récupéré, veuillez réessayer ultérieurement.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else {
          setPointage((prevState) => ({...prevState, pdf: result.data}))
          setIsLoadingPdf(false)
        }
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingPdf(false)
        setTransition(true)
        setIsErrorPdf({
          variant: 'danger',
          heading: true,
          content: `Votre pointage n'a pas pu être récupéré, veuillez réessayer ultérieurement.`,
        })
      }
    }
  }

  return (
    <>
      <Title title="Mes pointages" />

      <Col xs={12} className="filter">
        <Form onSubmit={handleSearch} className={'mb-3'}>
          <Row>
            <Col lg={'auto'}>
              <div className="form-label mb-1">Date de début :</div>
              <DatePicker
                name="start"
                onChange={setDateDebut}
                value={dateDebut}
                locale="fr-FR"
                minDate={currentYear || dateDebut}
                calendarIcon={null}
                showLeadingZeros={true}
                className={'form-control'}
              />
            </Col>

            <Col lg={'auto'}>
              <div className="form-label mb-1">Date de fin :</div>
              <DatePicker
                name="end"
                onChange={setDateFin}
                value={dateFin}
                locale="fr-FR"
                minDate={dateDebut}
                calendarIcon={null}
                showLeadingZeros={true}
                className={'form-control'}
              />
            </Col>
            <Col lg={'auto'} className={'d-flex align-items-end mt-2'}>
              <Button
                type="submit"
                className="btn-primary btn-square d-flex align-items-center mr-2"
                disabled={!dateDebut && !dateFin}>
                <FontAwesomeIcon icon="search" color="white" />
              </Button>
              <Button
                className="btn-red btn-square d-flex align-items-center"
                onClick={handleCancel}
                disabled={!dateDebut && !dateFin}>
                <FontAwesomeIcon
                  icon="times"
                  color="white"
                  className="margin-icon"
                />
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col xs={12} className="mb-3">
        <Row className="d-flex flex-row align-content-stretch">
          {types.map((item, index) => (
            <Box
              key={index}
              boxName="pointages"
              boxXs={12}
              boxSm={4}
              boxXl={3}
              boxCol={'xxl'}
              boxClassNames="d-flex mb-3"
              boxContentBorder={true}
              boxContentBorderColor="grey"
              boxContentBg="grey"
              boxContentClassNames="d-flex flex-column text-center rounded"
              boxContentPadding="p-3"
              style={{cursor: 'pointer'}}
              onClick={() => handleShow(item)}
              content={
                <>
                  <h3 className="d-flex flex-column flex-fill ff-bold text-primary">
                    {item.title}
                  </h3>
                  <div className="d-flex flex-column flex-fill">
                    Les commandes du
                    <div className="d-flex flex-column flex-fill">
                      {item.date}
                    </div>
                  </div>
                </>
              }
            />
          ))}
        </Row>
      </Col>

      {pointage && (
        <>
          <ModalCustom
            show={modalPointage}
            onHide={() => setModalPointage(false)}
            id="modal-pointage"
            title={`Visualiser le pointage ${pointage.title || 'Recherche'}`}
            titleClassname="text-center mb-3"
            bodyClassname="text-left"
            content={
              <>
                <AlertCustom
                  variant={isErrorPdf.variant}
                  heading={isErrorPdf.heading}
                  bodyContent={isErrorPdf.content}
                  transition={transition}
                />

                {isLoadingPdf && <Loader />}

                {pointage.pdf && (
                  <div
                    className="embed-responsive embed-responsive-1by1 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: `<iframe
                  src="${pointage.pdf}"
                   class="embed-responsive-item"
                   allowtransparency="true" type="application/pdf"></iframe>`,
                    }}></div>
                )}
              </>
            }
            footer={true}
            buttonsFooter={[
              {
                text: 'Fermer',
              },
            ]}
          />
        </>
      )}
    </>
  )
}

Pointages.propTypes = {}

export default Pointages
