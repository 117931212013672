import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Ratio from 'react-bootstrap/Ratio'

const VideosItem = ({src, title}) => {
  return (
    <Col md={4} className="mb-3">
      <Ratio aspectRatio="16x9">
        <video
          controls
          controlsList="nodownload"
          className="embed-responsive-item img-fluid">
          <source src={src} type="video/webm"></source>
        </video>
      </Ratio>
      <h3 className="mt-2">{title}</h3>
      <div className="text-center fs-14">
        <span>Si vous avez des problèmes de son,</span>
        <a
          role="button"
          className="text-primary ml-1"
          href={src}
          rel="noreferrer"
          target="_blank">
          cliquez ici
        </a>
      </div>
    </Col>
  )
}

VideosItem.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default VideosItem
