import {ACCESSORIES_FLORIST_API, ACCESSORY_API} from "../constants/Constants";
import API from "./API";

const getAccessories = () => {
  return {
      name: 'accessoires',
      method: 'get',
      url: `${ACCESSORY_API}?order[label]=asc&visible=1`
  }
}

const createAccessoryFlorist = (accessory, florist, floristHasAccessory) => {
    return API({
        name: 'accessoire',
        method: 'post',
        url: ACCESSORIES_FLORIST_API,
        data: {
            accessory: parseInt(accessory, 10),
            floristId: florist,
            hasAccessory: floristHasAccessory
        }
    });
}

const updateAccessoryFlorist = (accessoryId, floristId, floristHasAccessory) => {
    return API({
        name: 'accessoire',
        method: 'patch',
        url: `${ACCESSORIES_FLORIST_API}/${accessoryId}/${floristId}`,
        headers: {
            'Content-Type': 'application/merge-patch+json',
        },
        data: {
            hasAccessory: floristHasAccessory
        },
    });
}

export default {
    getAccessories,
    createAccessoryFlorist,
    updateAccessoryFlorist
}