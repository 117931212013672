import {create} from "zustand";
import SavAPI from "../services/SavAPI";
import {INTERVAL_RELOAD_SAVS, ITEMS_PER_PAGE} from "../constants/Constants";

export const useStoreSavs = create((set, get) => ({
    savs: [],
    savsFiltered: [],
    savsCount: 0,
    savsOpenedCount: 0,
    isLoading: false,
    sortClaimant: '',
    sortClaimantLabel: 'Indifférent',
    sortState: '',
    sortStateLabel: 'Indifférent',
    sortStatus: '',
    sortStatusLabel: 'Indifférent',
    sortOrder: 'desc',
    sortType: 'reclaDteopen',
    currentPage: 1,
    isError: false,
    initialised: false,
    setSortClaimant: (newSortClaimant, newSortClaimantLabel) => {
        set({
            sortClaimant: newSortClaimant,
            sortClaimantLabel: newSortClaimantLabel
        });

        get().setSavsFiltered(get().savs);
    },
    setSortState: (newSortState, newSortStateLabel) => {
        set({
            sortState: newSortState,
            sortStateLabel: newSortStateLabel
        });

        get().setSavsFiltered(get().savs);
    },
    setSortStatus: (newSortStatus, newSortStatusLabel) => {
        set({
            sortStatus: newSortStatus,
            sortStatusLabel: newSortStatusLabel
        });

        get().setSavsFiltered(get().savs);
    },
    setSortOrder: (newSortOrder) => {
        set({sortOrder: newSortOrder});
    },
    setSortType: (newSortType) => {
        set({sortType: newSortType});
    },
    getSavs: (florist, forceReload = false) => {
        if (!get().initialised || forceReload) {
            set({isLoading: true});

            SavAPI.getSavs(
                florist.CodeFl,
                '',
                '',
                '',
                get().sortType,
                get().sortOrder,
                1,
                ITEMS_PER_PAGE,
                30
            ).then(({data}) => {
                if (data.length === 0) {
                    set({isError: {
                            variant: 'warning',
                            icon: true,
                            content: 'Il n\'y a actuellement aucun SAV'
                        }}
                    );
                }

                if (data.length > 0) {
                    set({
                        savs: data,
                        savsCount: data.length
                    });

                    let numberOfOpenendSavs = 0;
                    data.forEach(sav => {
                        if (sav.reclaCloture === 0) {
                            numberOfOpenendSavs += 1;
                        }
                    });
                    set({savsOpenedCount: numberOfOpenendSavs});
                    get().setSavsFiltered(data);
                }
            }).finally(() => set({
                isLoading: false,
                initialised: true
            }));
        }
    },
    setSavsFiltered: (savs) => {
        let savsFiltered = savs;

        if (get().sortClaimant !== '') {
            savsFiltered = savsFiltered.filter(sav => sav.reclaPlaignant === Number(get().sortClaimant));
        }

        if (get().sortState !== '') {
            savsFiltered = savsFiltered.filter(sav => sav.reclaCloture === Number(get().sortState));
        }

        if (get().sortStatus !== '') {
            savsFiltered = savsFiltered.filter(sav => sav.reclaFondee === Number(get().sortStatus));
        }

        set({
            savsFiltered,
            isError: savsFiltered.length === 0 && {
                variant: 'warning',
                icon: true,
                content: 'Aucun SAV ne correspond à vos critères de recherche'
            },
            savsCount: savsFiltered.length
        });
    },
    clearSavs: () => set({savs: []}),
    resetFilters: () =>
        set({
            sortClaimant: '',
            sortClaimantLabel: 'Indifférent',
            sortState: '',
            sortStateLabel: 'Indifférent',
            sortStatus: '',
            sortStatusLabel: 'Indifférent',
        })
    ,
}));