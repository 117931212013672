import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

const Loader = ({animation, classname, size, variant, style}) => {
  return (
    <Col className="d-flex justify-content-center p-0 w-100">
      <Spinner
        animation={animation || 'border'}
        variant={variant || 'primary'}
        size={size || 'lg'}
        className={classname || 'm-3'}
        style={style}
      />
    </Col>
  )
}

Loader.propTypes = {
  animation: PropTypes.string,
  classname: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
}

export default Loader
