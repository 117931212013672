import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import AuthContext from '../../../contexts/AuthContext'
import CompteursAPI from '../../../services/CompteursAPI'
import Count from '../../ui/page/Count'
import {ITEMS_PER_PAGE_COMPTEURS} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import Numbers from '../../../helpers/Numbers'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import TableCustom from '../../ui/table/TableCustom'
import useFetch from '../../../hooks/useFetch'

const CompteursTable = ({year}) => {
  const {user} = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [totals, setTotals] = useState([])

  const [
    {data: compteurs, totalItems, isLoading, isError},
    fetchData,
  ] = useFetch(CompteursAPI.getCompteurs(year, user.CodeFl, currentPage))

  // Gestion du changement d'url
  useEffect(() => {
    if (reload || currentPage)
      fetchData(CompteursAPI.getCompteurs(year, user.CodeFl, currentPage))
  }, [user.CodeFl, currentPage])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
  }, [user.CodeFl])

  useEffect(() => {
    if (compteurs && compteurs.length) {
      handleTotals()
    }
  }, [compteurs])

  // Gestion des totaux
  const handleTotals = () => {
    const totals = []
    let totalTrans = 0
    let totalExec = 0
    let totalCaTrans = 0
    let totalCaExec = 0
    let totalRefus = 0
    compteurs
      .filter((item) => item.mois.slice(0, 4) === year)
      .map((item) => {
        totalTrans += parseFloat(item.nbtrans)
        totalExec += parseFloat(item.nbexec)
        totalCaTrans += parseFloat(item.catranse)
        totalCaExec += parseFloat(item.caexece)
        totalRefus += parseFloat(item.nbrefus)
        return {totalTrans, totalExec, totalCaTrans, totalCaExec, totalRefus}
      })
    totals.push(
      totalTrans,
      totalExec,
      Numbers.ConvertToEuro(totalCaTrans),
      Numbers.ConvertToEuro(totalCaExec),
      totalRefus,
    )
    setTotals(totals)
  }

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  return (
    <>
      <Count
        classNames="text-right px-4 pt-2 bg-grey"
        totalItems={totalItems}
        name="compteur"
      />
      <TableCustom
        isLoading={isLoading}
        isError={isError}
        headers={[
          'MOIS',
          'NB. TRANS.',
          'NB. EXE.',
          'C.A. TRANS.',
          'C.A. EXE',
          'NB. REFUS',
        ]}
        data={compteurs}
        config={[
          {type: 'month', key: 'mois'},
          {type: 'text', key: 'nbtrans'},
          {type: 'text', key: 'nbexec'},
          {type: 'currency', key: 'catranse'},
          {type: 'currency', key: 'caexece'},
          {type: 'text', key: 'nbrefus'},
        ]}
        totals={{title: `Total ${year}`, values: totals}}
      />

      {isLoading && <Loader />}

      <AlertCustom
        heading={isError.heading}
        variant={isError.variant}
        bodyIcon={isError.icon}
        bodyContent={isError.content}
        bodyContentBold={isError.message}
        bodyContentItalic={isError.detail}
        transition={!!isError}
      />

      {!isLoading && ITEMS_PER_PAGE_COMPTEURS < totalItems && (
        <PaginationCustom
          currentPage={currentPage || 1}
          itemsPerPage={ITEMS_PER_PAGE_COMPTEURS}
          totalPages={totalItems}
          handlePage={handlePage}
        />
      )}
    </>
  )
}

CompteursTable.propTypes = {
  year: PropTypes.string,
}

export default CompteursTable
