import PropTypes from 'prop-types'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AlertCustom from '../../ui/alert/AlertCustom'
import {CodeflField, PwdField} from '../../ui/form/login/LoginFields'
import Loader from '../../ui/loader/Loader'
import {useLogin} from "../../../hooks/useLogin";
import EasiwareForm from "../../ui/form/easiware/EasiwareForm";

const LoginForm = ({handleSelectedTab, location}, history) => {
  const {state, dispatch, handleChange, handleSubmit, handleSubscriptionModal} = useLogin(location, history);

  return (
    <>
      <div className="px-3 py-4">
        <Row>
          <Col
            md={{span: 10, offset: 1}}
            xl={{span: 8, offset: 2}}
            className="col-hd-6 offset-hd-3">
            <h1>Me connecter</h1>

            <AlertCustom
              heading={false}
              variant="danger"
              bodyContent={state.errorMessage}
              transition={!!state.isError}
              dismissible={false}
              onClose={() =>
                dispatch({
                  type: 'HANDLE_FOCUS',
                })
              }
            />
              
            <Form onSubmit={handleSubmit}>
              <CodeflField
                classNames="mb-3"
                value={state.credentials.codefl}
                onChange={handleChange}
                onFocus={() =>
                  dispatch({type: 'HANDLE_FOCUS'})
                }
                error={state.errorField.username}
              />

              <PwdField
                label="Mot de passe"
                placeholder="Mot de passe"
                isRequired={true}
                name="password"
                value={state.credentials.password}
                onChange={handleChange}
                onFocus={() => dispatch({type: 'HANDLE_FOCUS'})}
                error={state.errorField.password}
              />

              <div className="d-flex flex-columns align-items-center justify-content-between mt-3">
                <div>
                  <Button type="submit" className="btn-primary mr-3">
                    {state.isLoading ? (
                      <Loader variant="white" size="sm" classname="my-1 mx-3" /> // Indique le loading du dispatch 'FETCH_INIT'
                    ) : (
                      'Valider'
                    )}
                  </Button>
                </div>
                <div>
                  <div>
                    <a
                      role="button"
                      onClick={() => handleSelectedTab('password')}
                      className="btn-link text-grey-darker">
                      J'ai oublié mon mot de passe
                    </a>
                  </div>
                  <div>
                    <a
                      role="button"
                      className="btn-link text-grey-darker"
                      onClick={() => { handleSubscriptionModal(true) }}
                    >
                      Adhérer au service par email
                    </a>
                  </div>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>

      <EasiwareForm
        state={state}
        handleModalShow={handleSubscriptionModal}
        formId={3}
        headerTitle={"Formulaire d'adhésion"}
      />
    </>
  )
}

LoginForm.propTypes = {
  handleSelectedTab: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      referer: PropTypes.string,
    }),
  }),
}

export default LoginForm
