import React from 'react'
import PropTypes from 'prop-types'
import {BrowserView, MobileView} from 'react-device-detect'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'

import NavItemCustom from '../../../router/NavItemCustom'
import Count from '../page/Count'

/**
 * Genère un menu inline
 *
 * @param types
 * @param numberTabsToShow
 * @param searchActive
 * @param currentTab
 * @param setCurrentTabFunction
 * @param numberOfElements
 * @param isLoading
 * @returns {JSX.Element}
 * @constructor
 */
export const TabMenu = ({
  tabs,
  numberTabsToShow,
  searchActive,
  currentTab,
  setCurrentTabFunction,
  numberOfElements,
  isLoading,
}) => {
  return (
    <>
      <MobileView renderWithFragment={true}>
        <Nav fill variant="pills" className={'nav-underline mb-2 fs-16'}>
          {tabs.slice(0, numberTabsToShow).map((item, i) => (
            <NavItemCustom
              key={i}
              id={item.type}
              eventKey={item.type}
              toPathname={item.pathname || ''}
              toState={{type: item.type}}
              onClick={() => setCurrentTabFunction(item.type)}
              className={
                item.type !== 'search'
                  ? 'd-block'
                  : searchActive
                  ? 'd-block'
                  : 'd-none'
              }
              isActive={() => {
                return currentTab === item.type
              }}
              classnamelink={'text-body'}
              title={item.title}
            />
          ))}
          <NavDropdown title="..." id="nav-dropdown">
            {tabs.slice(numberTabsToShow).map((item, i) => (
              <NavItemCustom
                key={i}
                id={item.type}
                eventKey={item.type}
                toPathname={item.pathname || ''}
                toState={{type: item.type}}
                onClick={() => setCurrentTabFunction(item.type)}
                className={
                  item.type !== 'search'
                    ? 'd-block'
                    : searchActive
                    ? 'd-block'
                    : 'd-none'
                }
                isActive={() => {
                  return currentTab === item.type
                }}
                classnamelink={'text-body'}
                title={item.title}
              />
            ))}
          </NavDropdown>
        </Nav>
      </MobileView>

      <BrowserView renderWithFragment={true}>
        <Nav className="menu d-flex px-3 py-2 rounded-top">
          {tabs.map((item, index) => (
            <NavItemCustom
              key={index}
              classnameItem={
                item.type !== 'search'
                  ? 'd-block'
                  : searchActive
                  ? 'd-block'
                  : 'd-none'
              }
              eventKey={item.type}
              id={item.type}
              toPathname={item.pathname || ''}
              toState={{type: item.type}}
              onClick={() => setCurrentTabFunction(item.type)}
              isActive={item.linkActif || currentTab === item.type}
              classnamelink="menu-item"
              title={item.title}
            />
          ))}
          <Nav.Item className={'ms-auto'}>
             {numberOfElements >= 0 && !isLoading && (
              <Count
                classNames="menu-item text px-2 pt-2 bg-grey text-dark"
                totalItems={numberOfElements}
                name="élément"
              />
             )}
          </Nav.Item>
        </Nav>
      </BrowserView>
    </>
  )
}

TabMenu.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      pathname: PropTypes.string,
    }),
  ).isRequired,
  numberTabsToShow: PropTypes.number,
  searchActive: PropTypes.bool,
  setCurrentTabFunction: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  numberOfElements: PropTypes.number,
  isLoading: PropTypes.bool,
}
