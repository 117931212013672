import React, {useState, useEffect, useContext} from 'react'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from "react-bootstrap/Badge";
import {ListGroup} from "react-bootstrap";
import uuid from "react-uuid";
import {useDispatch, useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

// import '../../../assets/scss/_perimetre.scss'

import AuthContext from '../../../contexts/AuthContext'
import Box from '../../ui/box/Box'
import Count from '../../ui/page/Count'
import {ITEMS_PER_PAGE} from '../../../constants/Constants'
import Loader from '../../ui/loader/Loader'
import MagasinMenu from './MagasinMenu'
import ModalCustom from '../../ui/modal/ModalCustom'
import PaginationCustom from '../../ui/pagination/PaginationCustom'
import TableCustom from '../../ui/table/TableCustom'
import Title from '../../ui/page/Title'
import {
  addOnPending,
  removeOnPending,
  fetchOnPending,
  fetchPerimetres,
  flushOnPendingPerimetres,
  patchState,
  selectPerimetre,
  updatePerimetre, addPerimetreBulk, removePerimetreBulk, resetPerimetreBulkState, resetPerimetreState,
} from '../../../slice/perimetreSlice'
import {
  handlePerimetrePageAllowOnPendingSubmit,
  handlePerimetrePageAllowSubmit,
  handleShowDeleteRequest,
  handleShowModalForm,
  initEditingPerimetre,
  selectPerimetrePage,
  setEditingPerimetre,
} from '../../../slice/perimetrePageSlice'
import {globalSlice, resetState} from '../../../slice/globalSlice'
import PerimetreForm from '../../ui/form/perimetre/PerimetreForm'
import {isBool} from '../../../Tools/TypeOf'
import {base64} from '../../../Tools/Tools'
import SperimetrValidation from '../../../class/SperimetrValidation'
import Sperimetr from "../../../class/Sperimetr";

const Perimetre = () => {
  const {user} = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [sortType, setSortType] = useState('localite')
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentTab, setCurrentTab] = useState('perimetre')
  /* ----------------------------------------- */
  const [isInitialized, setInitialized] = useState(false)

  const perimetreSelector = useSelector(selectPerimetre)
  const {
    totalItems,
    isLoading,
    isError,
    data: perimetres,
    onPending,
    currentOnPending,
    onPendingIsLoading,
    onPendingTotalItems,
    bulk: selectedPerimetres
  } = perimetreSelector
  const {
    allowSubmit,
    allowSubmitOnPending,
    showModalForm,
    showModalDeleteRequest,
    editingPerimetre,
  } = useSelector(selectPerimetrePage)
  const dispatch = useDispatch()

  /**
   * Return true if the {localite} is the florist city localite
   *
   * @param {any} user
   * @param {string} localite
   *
   * @returns {boolean}
   */
  const isFloristsCityPerimeter = (user, localite) => {
    return user.Localite.indexOf(localite) >= 0;
  }

  const isOnPendingItem = (item) => {
    const onPendings = [...Object.values(onPending), ...Object.values(currentOnPending)];

    return onPendings.find((sperimetreValidation) => sperimetreValidation.idsperimetr === item.idsperimetr) !== undefined
  }

  const isCurrentOnPendingItem = (item) => {
    return Object.values(currentOnPending).find((sperimetrValidation) => sperimetrValidation.idsperimetr === item.idsperimetr) !== undefined
  }


  const scrollToPending = () => {
    const onPendingTable = document.getElementById('perimetreOnPending')

    if (onPendingTable) {
      window.scrollTo({top: onPendingTable.getBoundingClientRect().top, behavior: "smooth"})
    }
  }

  // Gestion du changement de page
  const handlePage = (page) => {
    setCurrentPage(page)
    setReload(true)
  }

  // Gestion du changement de tri
  const handleSortOrder = (newSortType) => {
    let sort
    if (sortType !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    setCurrentPage(1)
    setSortType(newSortType)
    setSortOrder(sort)
    setReload(true)
  }

  // Gestion du changement dynamique des checkbox Veille Faf
  const handleCheckboxes = ({target: {id, checked}}) => {
    dispatch(updatePerimetre({id, prop: 'veillefaf', value: checked}))
    dispatch(updatePerimetre({id, prop: 'updated', value: true}))
    dispatch(handlePerimetrePageAllowSubmit(true))
  }

  /**
   * Ouvre ou ferme la modale contenant
   * le formulaire des périmetres.
   *
   * @param {boolean} show
   */
  const handleModalForm = (show) => {
    if (isBool(show)) {
      dispatch(handleShowModalForm(show))

      if (!show) {
        dispatch(initEditingPerimetre())
      }
    }
  }

  /**
   * Ouvre ou ferme la modale contenant
   * la confirmation de suppression d'un perimetre.
   */
  const handleModalDeleteRequest = (idsperimetr) => {
    dispatch(setEditingPerimetre(perimetres[base64(idsperimetr)]))
    dispatch(handleShowDeleteRequest())
  }

  /**
   * Envoit les modifications apportées aux périmetres
   * non soumises à validation des commerciaux.
   */
  const handleSubmit = () => {
    dispatch(patchState(perimetreSelector))
  }

  /**
   * Met à jour l'état (PerimetrePage) qui spécifie
   * le périmetre qui est actuellement en édition.
   *
   * @param {number} idsperimetr
   */
  const handleEdit = (idsperimetr) => {
    dispatch(setEditingPerimetre(perimetres[base64(idsperimetr)]))
    handleModalForm(true)
  }

  /**
   * Envoie la requète qui demande aux commerciaux
   * la possibilité de supprimer un périmetre.
   *
   * @param {Sperimetr|[Sperimetr]} data
   */
  const handleDelete = (data) => {
    const toDelete =  (data instanceof Sperimetr) ? [data] : data.map((idsperimetre) => perimetres[base64(idsperimetre)])

    toDelete.forEach((perimetre) => {
      dispatch(
        addOnPending(
          new SperimetrValidation({
            ...perimetre,
            ...{deletion: true},
          }),
        ),
      )
    })

    dispatch(handleShowDeleteRequest())
    dispatch(initEditingPerimetre())
    dispatch(resetPerimetreBulkState())

    scrollToPending()
  }

  /**
   * Assigne aux boutons d'édition/suppression la class "disabled"
   * si un périmetre à une demande en cours.
   *
   * @param {Sperimetr} item
   *
   * @returns {string}
   */
  const handleEditClassNames = (item) => {
    return `m-1 padding-icon ${isOnPendingItem(item) && 'disabled'}`
  }

  /**
   * Assigne aux boutons d'édition/suppression l'attribut "disabled"
   * si un périmetre à une demande en cours.
   *
   * @param {Sperimetr} item
   *
   * @returns {boolean}
   */
  const handleDeleteDisabled = (item) => {
    return isFloristsCityPerimeter(user, item.localite) || isOnPendingItem(item);
  }

  /**
   * Disable && hide the delete button if the perimetr is on the florist city.
   *
   * @param {SperimetrValidation} item
   *
   * @returns {string}
   */
  const handleDeleteClassNames = (item) => {
    return `${handleEditClassNames(item)} ${handleDeleteDisabled(item) && 'd-none'}`
  }

  const handleOnPendingSubmit = () => {
    dispatch(flushOnPendingPerimetres(currentOnPending))
    dispatch(handlePerimetrePageAllowOnPendingSubmit(false))
  }


  const handleDeleteRow = (item) => {
    dispatch(removeOnPending(item))
  }

  /**
   * Créer une configuration
   * pour le tableau des demandes en cours de validation
   * en fonction d'un item.
   *
   * @param item
   *
   * @returns {[{type: string, key: string}]}
   */
  const handleTableBodyConf = (item) => {
    let config = [{type: 'text', key: 'formattedAddress'}]

    if (item && item.deletion) {
      config.push({
        type: 'text',
        text: 'Demande de suppression',
        colSpan: 2,
      })
    } else {
      config = config.concat([
        {type: 'currency', key: 'montantminie'},
        {
          type: 'checkbox',
          key: 'depannage',
          readOnly: true,
        },
      ])
    }

    return config
  }

  const handleConfigLine = (item) => {
    return (isCurrentOnPendingItem(item) && 'bg-secondary text-white') || ''
  }

  const bulkIsSelectable = (item) => {
    return !handleDeleteDisabled(item)
  }

  const isSelected = (item) => {
    return selectedPerimetres.includes(item.idsperimetr)
  }

  const handleOnSelect = (item) => {
    if (isSelected(item)) {
      dispatch(removePerimetreBulk(item.idsperimetr))
    } else {
      dispatch(addPerimetreBulk(item.idsperimetr))
    }
  }

  const handleBulkAction = () => {
    if (selectedPerimetres && selectedPerimetres.length) {
      dispatch(handleShowDeleteRequest())
    }
  }

  useEffect(() => {
    if (!isInitialized) {
      dispatch(
        fetchPerimetres({
          sortType,
          sortOrder: '',
          currentPage: 1,
          codefl: user.CodeFl,
        }),
      )

      dispatch(
        fetchOnPending({
          sortType,
          sortOrder: '',
          currentPage: 1,
          codefl: user.CodeFl,
        }),
      )

      setInitialized(true)
    }
  }, [isInitialized, setInitialized])

  useEffect(() => {
    dispatch(handlePerimetrePageAllowSubmit(false))
  }, [isLoading])

  // Gestion du changement d'url
  useEffect(() => {
    if (reload) {
      dispatch(
        fetchPerimetres({
          sortType,
          sortOrder,
          currentPage,
          codefl: user.CodeFl,
        }),
      )
    }
  }, [user.CodeFl, sortType, sortOrder, currentPage])

  // Gestion du changement de magasin
  useEffect(() => {
    setCurrentPage(1)
    setReload(true)
    dispatch(resetPerimetreState())
  }, [user.CodeFl])

  useEffect(() => {
    dispatch(
      handlePerimetrePageAllowOnPendingSubmit(
        Object.values(currentOnPending).length > 0,
      ),
    )
  }, [currentOnPending])

  return (
    <>
      <Title title="Mon magasin" />

      <Box
        boxName="perimetres"
        boxClassNames="flex-column"
        boxContentBg="white"
        headerBg={"white"}
        header
        stickyHeader
        targetStickyClasses={'sticky-with-header'}
        headerClassNames="rounded-top"
        headerContent={
          <>
            <MagasinMenu
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <Button
              id={'addPerimeters'}
              className={'position-absolute'}
              onClick={() => handleModalForm(true)}
              variant={'light'}>
              <FontAwesomeIcon icon={'plus-circle'} className={'mr-1'} />
              Ajouter une localité
            </Button>
          </>
        }
        content={
          <>
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-between bg-grey-light p-3 sticky-with-header">
              <h3 className="px-sm-3 mb-2 mb-sm-0">Périmètre de livraison</h3>
              <div className={"d-flex flex-column align-items-end"}>
                <Count totalItems={totalItems} name="localité" />
                {(selectedPerimetres && selectedPerimetres.length && (
                  <div className={'d-flex align-items-end'}>
                    <Button variant={"danger"} className={"p-1"} onClick={handleBulkAction}>
                      <FontAwesomeIcon icon={"trash"} color={"white"} size={"lg"} />
                      <Badge className={"ml-1"} bg="light" text="dark" style={{transform: 'scale(0.7)'}}>
                        {selectedPerimetres.length}
                      </Badge>
                    </Button>
                  </div>
                ) || <></>)}
              </div>
            </div>

            <hr className="m-0" />

            <TableCustom
              id={'active-perimeter'}
              isLoading={isLoading}
              isError={isError}
              headers={[
                'VILLE (code postal)',
                'FRAIS DE LIVRAISON',
                'MONTANT MINIMUM',
                'FÊTES À FLEURS',
                'EDITER / SUPPR.',
              ]}
              sorting={['localite']}
              handleSortOrder={handleSortOrder}
              sortType={sortType}
              sortOrder={sortOrder}
              lineVariant={(item) => {
                return (
                  ((item.depannage || item.isSurvey) && 'secondary') ||
                  'transparent'
                )
              }}
              data={Object.values(perimetres)}
              bulkSelect
              bulkIsSelectable={bulkIsSelectable}
              onSelect={handleOnSelect}
              isSelected={isSelected}
              config={[
                {type: 'text', key: 'formattedAddress'},
                {type: 'currency', key: 'fraislive'},
                {type: 'currency', key: 'montantminie'},
                {
                  type: 'checkbox',
                  key: 'veillefaf',
                  id: 'idsperimetr',
                  func: handleCheckboxes,
                },
                {
                  type: 'buttons_sav',
                  key: [
                    {
                      name: 'Editer',
                      color: 'primary',
                      icon: 'pen',
                      iconColor: 'white',
                      func: handleEdit,
                      funcParam: 'idsperimetr',
                      classnames: handleEditClassNames,
                      disabled: isOnPendingItem,
                    },
                    {
                      name: 'Suppr.',
                      color: 'danger',
                      icon: 'trash',
                      iconColor: 'white',
                      func: handleModalDeleteRequest,
                      funcParam: 'idsperimetr',
                      classnames: handleDeleteClassNames,
                      disabled: handleDeleteDisabled,
                    },
                  ],
                },
              ]}
            />

            {isLoading && <Loader />}

            <div>
              <p className={'d-inline-flex align-items-center'}>
                <span
                  className={'mr-2'}
                  style={{
                    display: 'inline-block',
                    height: '16px',
                    width: '50px',
                    backgroundColor: 'rgba(108, 117, 125, 0.3)',
                  }}></span>{' '}
                Localités en dépannage
              </p>

              {!isLoading && allowSubmit && (
                <>
                  <Col xs={12} className="d-flex justify-content-end p-0 mb-3">
                    <Button
                      onClick={handleSubmit}
                      variant="primary"
                      className="btn-rounded ff-bold">
                      {isLoading ? (
                        <Loader
                          variant="white"
                          size="sm"
                          classname="my-1 mx-4"
                        />
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon="save"
                            color="white"
                            className="mr-2"
                          />
                          <span>Valider</span>
                        </>
                      )}
                    </Button>
                  </Col>

                  <PaginationCustom
                    currentPage={currentPage || 1}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalPages={totalItems}
                    handlePage={handlePage}
                  />
                </>
              )}
            </div>

            <div className={'col col-lg-6 d-flex flex-column align-items-stretch'}>
              <div className="d-flex align-items-center justify-content-between bg-grey-light p-3 mt-4">
                <h3 className="px-sm-3 mb-2 mb-sm-0">
                  Périmètre en cours de traitement
                </h3>
                <div className="d-flex flex-row flex-wrap align-items-center px-sm-3">
                  <Count totalItems={onPendingTotalItems} name={`localités`} />
                </div>
              </div>

              <hr className="m-0" />

              <TableCustom
                id={"perimetreOnPending"}
                isLoading={onPendingIsLoading}
                isError={isError}
                headers={['VILLE (code postal)', 'Montant mini', 'Dépannage']}
                sorting={['localite']}
                handleSortOrder={handleSortOrder}
                sortType={sortType}
                sortOrder={sortOrder}
                data={[
                  ...Object.values(onPending),
                  ...Object.values(currentOnPending),
                ]}
                handleConfig={handleTableBodyConf}
                configLine={handleConfigLine}
                rowDeletable
                handleDeletableRow={isCurrentOnPendingItem}
                deletableRowOnClick={handleDeleteRow}
                responsive={false}
              />

              {onPendingIsLoading && <Loader />}

              {allowSubmitOnPending && (
                <div className={'d-flex justify-content-between'}>
                  <p>
                    Enregistrez vos localités afin qu'elles puissent être
                    validées par notre service commercial.
                  </p>
                  <div className={'d-flex align-items-center'}>
                    <Button type={'button'} onClick={handleOnPendingSubmit}>
                      Enregistrer
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        }
      />

      <ModalCustom
        id="modal-faf"
        contentClassname={'overflow-visible'}
        header
        headerTitle={`${
          (editingPerimetre && 'Modifier') || 'Ajouter'
        } une localité`}
        show={showModalForm}
        onHide={() => handleModalForm(false)}
        backdrop={'static'}
        content={
          <>
            <PerimetreForm scrollToPending={scrollToPending} />
          </>
        }
      />

      {showModalDeleteRequest && (
        <ModalCustom
          id="modal-faf"
          header
          headerClassname={'bg-danger text-white'}
          headerTitle={"Suppression d'une localité"}
          headerTitleClassname={'bg-danger'}
          show={showModalDeleteRequest}
          onHide={handleModalDeleteRequest}
          backdrop={'static'}
          content={
            <div
              className={
                'd-flex justify-content-around align-items-center bg-yellow-light w-75 mx-auto mt-4 px-2'
              }>
              <FontAwesomeIcon
                className={'mx-3 d-none d-md-block'}
                icon={'exclamation-triangle'}
                size={'3x'}
                color={'red'}
              />

              {(selectedPerimetres && selectedPerimetres.length &&
                (<div className={"py-2"}>
                  <p className={'py-2 m-0 flex-grow-1'}>
                  Attention, vous êtes en train de supprimer {selectedPerimetres.length} localités.
                  <br/>
                  Avant d'effectuer cette action, <strong>pensez au dépannage</strong> ! En cochant la case dépannage, nous vous contacterons avant de vous confier une commande. Cela peut ouvrir un potentiel complémentaire !
                  <br/>
                  <br/>
                  Merci de <strong>confirmer la suppression</strong> des
                  localités :
                  </p>
                  <ListGroup variant="flush">
                  {
                    selectedPerimetres.map(
                      (idPerimetres) =>
                      (<ListGroup.Item key={uuid()}>{perimetres[base64(idPerimetres)].formattedAddress}</ListGroup.Item>)
                    )
                  }
                  </ListGroup>
                </div>)
                || (<p className={'py-2 m-0 flex-grow-1'}>
                  Attention, vous êtes en train de supprimer une localité.
                  <br/>
                  Avant d'effectuer cette action, pensez au dépannage ! En cochant la case, nous vous contacterons avant de vous confier une commande. Cela peut ouvrir un potentiel complémentaire !
                  <br/>
                  <br/>
                  Merci de <strong>confirmer la suppression</strong> de la
                  localité :
                  <span className={'d-block mt-2'}>
                  <strong>{editingPerimetre.formattedAddress}</strong>
                </span>
                </p>)
              )}
            </div>
          }
          footer
          buttonsFooter={[
            {
              text: 'Confirmer',
              onClick: handleDelete,
              onClickParams: (selectedPerimetres && selectedPerimetres.length) ? selectedPerimetres : editingPerimetre,
              classNames: 'mx-2',
            },
            {
              text: 'Annuler',
              classNames: 'mx-2',
              onClick: () => {
                dispatch(handleShowDeleteRequest())
              },
              variant: 'danger',
            },
          ]}
        />
      )}
    </>
  )
}

export default Perimetre
