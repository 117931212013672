import {Col, Row} from 'react-bootstrap'
import React from 'react'

import AlertCustom from '../../ui/alert/AlertCustom'
import Loader from '../../ui/loader/Loader'
import Title from '../../ui/page/Title'
import Top10API from '../../../services/Top10API'
import Top10Item from './Top10Item'
import useFetch from '../../../hooks/useFetch'

const Top10 = () => {
  const [
    {data: top10Net, isLoading: isLoadingNet, isError: isErrorNet},
  ] = useFetch(Top10API.getTop10Net())

  const [
    {data: top10Fl, isLoading: isLoadingFl, isError: isErrorFl},
  ] = useFetch(Top10API.getTop10Fl())

  return (
    <>
      <Title title="Top 10 des bouquets sur Internet" />

      {isLoadingNet && <Loader />}

      <Col xs={12} className="d-flex justify-content-center p-0">
        <AlertCustom
          heading={isErrorNet.heading}
          variant={isErrorNet.variant}
          bodyIcon={isErrorNet.icon}
          bodyContent={isErrorNet.content}
          bodyContentBold={isErrorNet.message}
          bodyContentItalic={isErrorNet.detail}
          transition={!!isErrorNet}
        />
      </Col>

      <Col xs={12} className="mb-3">
        <Row className="d-flex d-xxl-none flex-row align-content-stretch">
          {!isLoadingNet &&
            !isErrorNet &&
            top10Net &&
            top10Net.map((item, index) => (
              <Top10Item key={index} item={item} />
            ))}
        </Row>

        <Row className="d-none d-xxl-flex flex-row align-content-stretch">
          {!isLoadingNet &&
            !isErrorNet &&
            top10Net &&
            top10Net
              .filter((item, index) => index < 5)
              .map((item, index) => <Top10Item key={index} item={item} />)}
        </Row>
        <Row className="d-none d-xxl-flex flex-row align-content-stretch">
          {!isLoadingNet &&
            !isErrorNet &&
            top10Net &&
            top10Net
              .filter((item, index) => index >= 5)
              .map((item, index) => <Top10Item key={index} item={item} />)}
        </Row>
      </Col>

      <Title title="Top 10 des bouquets pour les fleuristes" />

      {isLoadingFl && <Loader />}

      <Col xs={12} className="d-flex justify-content-center p-0">
        <AlertCustom
          heading={isErrorFl.heading}
          variant={isErrorFl.variant}
          bodyIcon={isErrorFl.icon}
          bodyContent={isErrorFl.content}
          bodyContentBold={isErrorFl.message}
          bodyContentItalic={isErrorFl.detail}
          transition={!!isErrorFl}
        />
      </Col>

      <Col xs={12} className="mb-3">
        <Row className="d-flex d-xxl-none flex-row align-content-stretch">
          {!isLoadingFl &&
            !isErrorFl &&
            top10Fl &&
            top10Fl.map((item, index) => <Top10Item key={index} item={item} />)}
        </Row>

        <Row className="d-none d-xxl-flex flex-row align-content-stretch">
          {!isLoadingFl &&
            !isErrorFl &&
            top10Fl &&
            top10Fl
              .filter((item, index) => index < 5)
              .map((item, index) => <Top10Item key={index} item={item} />)}
        </Row>
        <Row className="d-none d-xxl-flex flex-row align-content-stretch">
          {!isLoadingFl &&
            !isErrorFl &&
            top10Fl &&
            top10Fl
              .filter((item, index) => index >= 5)
              .map((item, index) => <Top10Item key={index} item={item} />)}
        </Row>
      </Col>
    </>
  )
}

export default Top10
