import { EXTRANET_STATE_API } from "../constants/Constants"
import API from "./API";

/**
 * Retourne la config global des Livreurs
 *
 * @returns {*}
 */
const getExtranetStates = () => {
    return API({
        name: 'extranetStates',
        method: 'GET',
        url: `${EXTRANET_STATE_API}/unmapped`,
        headers: {
            accept: 'application/json',
        },
    })
}

export default {getExtranetStates}