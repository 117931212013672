import {PARTNERS_API} from "../constants/Constants";

function getPartners() {
    return {
        name: 'partenaires',
        method: 'get',
        url: PARTNERS_API,
    }
}

export default {
    getPartners,
}