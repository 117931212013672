import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

const Picto = ({
  id,
  sorting,
  desc,
  title,
  classNames,
  color,
  icon,
  iconSize,
  iconClassNames,
  iconColor,
  pictoClassNames,
  pictoSize,
}) => {
  const [newIcon, setNewIcon] = useState()
  const [newTitle, setNewTitle] = useState()
  const [newColor, setNewColor] = useState()
  const [newPictoClassNames, setNewPictoClassNames] = useState()
  const [newIconSize, setNewIconSize] = useState()
  const [newPictoSize, setNewPictoSize] = useState()

  // Gestion du choix du picto
  const handlePicto = () => {
    if (sorting === 'statut') {
      if (Number(id) === 0) {
        setNewIcon('times')
        setNewTitle('Non fondée')
        setNewColor('red')
        setNewPictoClassNames('circle')
        setNewIconSize('sm')
        setNewPictoSize('sm')
      } else {
        setNewIcon('check')
        setNewTitle('Fondée')
        setNewColor('green')
        setNewPictoClassNames('circle')
        setNewIconSize('sm')
        setNewPictoSize('sm')
      }
    } else if (sorting === 'etat') {
      if (Number(id) === 0) {
        setNewIcon('check')
        setNewTitle('En cours')
        setNewColor('green')
        setNewPictoClassNames('circle')
        setNewIconSize('sm')
        setNewPictoSize('sm')
      } else {
        setNewIcon('times')
        setNewTitle('Clôturé')
        setNewColor('red')
        setNewPictoClassNames('circle')
        setNewIconSize('sm')
        setNewPictoSize('sm')
      }
    } else if (Number(id) === 0) {
      setNewIcon('file-alt')
      setNewTitle('Etat initial')
      setNewColor('primary')
    } else if (Number(id) === 1) {
      setNewIcon('hourglass-half')
      setNewTitle('Etat en traitement')
      setNewColor('yellow-base')
    } else if (Number(id) === 2) {
      setNewIcon('pause')
      setNewTitle('Commande transmise')
      setNewColor('yellow-base')
    } else if (Number(id) === 3) {
      setNewIcon('times')
      setNewTitle('Exécutant refuse la commande')
      setNewColor('red')
    } else if (Number(id) === 4) {
      setNewIcon('check')
      setNewTitle('Exécutant accepte la commande')
      setNewColor('green-darkest')
    } else if (Number(id) === 5) {
      setNewIcon('truck')
      setNewTitle('Commande en cours de livraison')
      setNewColor('primary-dark')
    } else if (Number(id) === 6) {
      setNewIcon('smile')
      setNewTitle('Commande livrée')
      setNewColor('primary-dark')
    } else if (Number(id) === 7) {
      setNewIcon('map-marker-alt')
      setNewTitle('Avis de passage déposé ou destinataire appelé')
      setNewColor('orange')
    } else if (Number(id) === 8) {
      setNewIcon('exclamation-triangle')
      setNewTitle('Refus par le client final')
      setNewColor('red')
    } else if (Number(id) === 20) {
      setNewIcon('bell-slash')
      setNewTitle('Arrêt sonnerie')
      setNewColor('primary')
    } else if (Number(id) === 30) {
      setNewIcon('exclamation-triangle')
      setNewTitle('Refus après TO')
      setNewColor('red-darkest')
    } else if (Number(id) === 40) {
      setNewIcon('check-double')
      setNewTitle('Commande auto-acceptée')
      setNewColor('green-darkest')
    } else if (Number(id) === 41) {
      setNewIcon('phone')
      setNewTitle('Destinataire appelé')
      setNewColor('green-darkest')
    } else if (Number(id) === 42) {
      setNewIcon('phone-slash')
      setNewTitle('Destinataire ne répond pas')
      setNewColor('red-darkest')
    } else if (Number(id) === 43) {
      setNewIcon('gift')
      setNewTitle('Bouquet préparé')
      setNewColor('blue')
    } else if (Number(id) === 44) {
      setNewIcon('calendar')
      setNewTitle('Demande de livraison par un livreur envoyée.')
      setNewColor('yellow-base')
    } else if (Number(id) === 45) {
      setNewIcon('handshake')
      setNewTitle('La livraison a été acceptée par un livreur.')
      setNewColor('primary-dark')
    } else if (Number(id) === 60) {
      setNewIcon('smile-wink')
      setNewTitle('Commande livrée après avis de passage')
      setNewColor('primary-dark')
    } else if (Number(id) === 80) {
      setNewIcon('exclamation-triangle')
      setNewTitle('Refus par le client final après avis de passage')
      setNewColor('red-darkest')
    } else {
      return false
    }
    return true
  }

  useEffect(() => {
    handlePicto()
  }, [])

  return (
    <>
      {(newIcon || icon) && (
        <>
          <div
            className={`d-flex flex-row align-items-center ${
              !desc ? 'justify-content-center' : ''
            }${classNames ? ` ${classNames}` : ''}`}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${id}`}>{newTitle || title}</Tooltip>
              }>
              <div
                className={`picto${
                  pictoSize
                    ? `-${pictoSize}`
                    : newPictoSize
                    ? `-${newPictoSize}`
                    : ''
                } text-${newColor || color}${
                  pictoClassNames
                    ? ` ${pictoClassNames}`
                    : newPictoClassNames
                    ? ` ${newPictoClassNames}`
                    : ''
                }`}>
                <FontAwesomeIcon
                  icon={newIcon || icon || 'home'}
                  size={iconSize || newIconSize || 'lg'}
                  className={iconClassNames}
                  // color={iconColor || 'white'}
                />
              </div>
            </OverlayTrigger>
            {desc && <div className="text-left">{newTitle || title}</div>}
          </div>
        </>
      )}
    </>
  )
}

Picto.propTypes = {
  classNames: PropTypes.string,
  color: PropTypes.string,
  desc: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconClassNames: PropTypes.string,
  iconColor: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pictoClassNames: PropTypes.string,
  pictoSize: PropTypes.string,
  sorting: PropTypes.string,
  title: PropTypes.string,
}

export default Picto
