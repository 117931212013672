import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect, useState, useRef} from 'react'
import $ from 'jquery'
import {isMobile} from "react-device-detect";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)
  const [fullBtn, setFullBtn] = useState(false)
  const isUnmounted = useRef(false)

  useEffect(() => {
    return () => {
      isUnmounted.current = true
    }
  }, [])

  const checkScrollToTop = () => {
    if (!isUnmounted.current) {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }

      if (isMobile && window.pageYOffset + window.innerHeight > $(document).outerHeight(false) - 100) {
        setFullBtn(true)
      } else {
        setFullBtn(false)
      }
    }
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  window.addEventListener('scroll', checkScrollToTop)

  return (
    <Button
      onClick={scrollToTop}
      variant="primary"
      className={`${
        showScroll ? 'd-block' : 'd-none'
      } scrolltop btn-rounded-sm fs-14 ${((fullBtn) && 'full')}`}>
      <FontAwesomeIcon
        icon="arrow-up"
        color="white"
        className="mr-1"
        style={{marginLeft: '2px', marginRight: '2px'}}
      />
      <span>Retour en haut de page</span>
    </Button>
  )
}

export default ScrollToTop
