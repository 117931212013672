class Moments {
    static format = (id) => {
        return (id === '1' || id === 1
            ? 'MATIN'
                : id === '2' || id === 2
            ? 'APRÈS-MIDI'
                : id === '3' || id === 3
            ? 'JOURNÉE'
                :id === '4' || id === 4
            ? 'APRÈS 18H'
            : id
        )
    }
}

export default Moments
