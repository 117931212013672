import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'

import Box from '../../ui/box/Box'
import {CartContext} from '../../../contexts/CartContext'
import Loader from '../../ui/loader/Loader'
import ModalCustom from '../../ui/modal/ModalCustom'
import Numbers from '../../../helpers/Numbers'
import Text from '../../../helpers/Text'

const ModalCartItem = ({article}) => {
  const [quantity, setQuantity] = useState(article.cartQuantity)
  const [modalShowRemove, setModalShowRemove] = useState(false)
  const [isLoadingModalRemove, setIsLoadingModalRemove] = useState()

  const {addToCart, removeFromCart} = useContext(CartContext)

  // Gestion de l'ajout au panier au changement de quantités
  useEffect(() => {
    addToCart(
      article,
      quantity,
      article.cartSize,
      article.cartSizeId,
      article.cartPriceUnit,
    )
  }, [quantity])

  // Gestion du retrait de quantités
  const removeQuantity = () => {
    setQuantity(quantity - 1)
  }

  // Gestion de l'ajout de quantités
  const addQuantity = () => {
    setQuantity(quantity + 1)
  }

  // Gestion de la suppression d'un article
  const handleRemoveFromCart = (article) => {
    removeFromCart(article)
    setModalShowRemove(false)
  }

  return (
    <>
      <Box
        boxXs={12}
        boxName="modal-cart-item"
        boxClassNames="mb-0"
        boxContentClassNames="d-flex flex-column bg-white"
        boxContentPadding="p-1"
        content={
          <>
            <div className="d-flex flex-row flex-wrap">
              <Col xs={4} className="p-0">
                <div
                  style={{
                    height: '130px',
                    background: `center / cover no-repeat url(https://www.monflorajet.com/ftp-extranet/OrderShop/Product/${article.idSarticles}/01.jpg)`,
                  }}
                  alt="Mon Florajet"
                  className="rounded-xl unselectable border border-xl mb-3 mb-sm-0"></div>
              </Col>

              <Col
                xs={8}
                className="d-flex flex-column flex-wrap align-items-start pl-3">
                <div className="d-flex flex-row flex-wrap mb-2">
                  <h3 className="mb-0 mr-3">
                    {Text.CapitalizeAll(article.articlesExtranetLibelle)}
                  </h3>
                  <p className="ff-bold text-primary text-center mb-0">
                    {!article.cartPriceUnit
                      ? 'NC'
                      : article.cartPriceUnit === '0'
                      ? 'GRATUIT'
                      : Numbers.ConvertToEuro(Number(article.cartPriceUnit))}
                  </p>
                </div>

                <div className="description fs-14">
                  {Text.CapitalizeAll(article.articlesExtranetDescription)}
                </div>

                {article.articleTypes.length > 1 && (
                  <div className="text-primary ff-bold">{article.cartSize}</div>
                )}

                <div className="d-flex flex-row flex-wrap align-items-center">
                  <Button
                    className="btn-trans p-0"
                    onClick={removeQuantity}
                    disabled={quantity < 2 && true}>
                    <FontAwesomeIcon
                      size="lg"
                      icon="minus-circle"
                      color="var(--black-light)"
                    />
                  </Button>

                  <div className="quantity mx-3 unselectable">{quantity}</div>

                  <Button
                    className="btn-trans p-0 mr-3"
                    onClick={addQuantity}
                    disabled={
                      quantity > article.articlesExtranetMaxi - 1 && true
                    }>
                    <FontAwesomeIcon
                      size="lg"
                      icon="plus-circle"
                      color="var(--black-light)"
                    />
                  </Button>

                  <p className="price ff-bold text-primary fs-20 mb-0">
                    {Numbers.ConvertToEuro(Number(article.cartPriceTotal))}
                  </p>
                </div>

                <p className="quantity-max fs-14 mb-0 unselectable">
                  Max : {article.articlesExtranetMaxi}
                </p>

                <Button
                  className="btn-trans btn-remove text-grey-darker mt-2 fs-14 p-0"
                  onClick={() => setModalShowRemove(true)}>
                  <FontAwesomeIcon
                    icon="trash-alt"
                    color="grey-darker"
                    className="mr-2"
                  />
                  <span>Supprimer</span>
                </Button>
              </Col>
            </div>
            <hr className="w-100" />
          </>
        }
      />

      <ModalCustom
        show={modalShowRemove}
        onHide={() => setModalShowRemove(false)}
        id="modal-remove"
        bodyClassname="p-5"
        content={
          <>
            <h3>Etes-vous sûr de vouloir supprimer cet article ?</h3>
          </>
        }
        footer={true}
        buttonsFooter={[
          {
            text: isLoadingModalRemove ? (
              <Loader variant="white" size="sm" classname="my-1 mx-2" />
            ) : (
              'Confirmer'
            ),
            func: () => handleRemoveFromCart(article),
            classNames: 'mx-2',
          },
          {
            text: 'Annuler',
            classNames: 'mx-2',
          },
        ]}
      />
    </>
  )
}

ModalCartItem.propTypes = {
  article: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ModalCartItem
