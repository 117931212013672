import {SHOPPERS_API} from '../constants/Constants'
import APIv2 from './APIv2'

/**
 * Retourne la config global des Livreurs
 *
 * @returns {*}
 */
const getGlobalConfig = () => {
  return APIv2.requestData({
    name: 'shoppersGlobalConfig',
    method: 'GET',
    url: `${SHOPPERS_API}/global-config`,
    headers: {
      accept: 'application/json',
    },
  })
}

/**
 * Retourne la liste des shoppers disponibles
 *
 * @returns {{headers: {accept: string}, method: string, name: string, url: string}}
 */
const getShoppers = (codefl = null) => {
  return APIv2.requestData({
    name: 'list',
    method: 'GET',
    url: codefl
      ? `${SHOPPERS_API}/list?codeFl=${codefl}`
      : `${SHOPPERS_API}/list`,
    headers: {
      accept: 'application/json',
    },
  })
}

/**
 * Met à jour les identifiants du fleuriste pour un shopper
 *
 * @param {object} shopper
 * @returns {{headers: {accept: string}, method: string, data, name: string, url: string}}
 */
const checkShopperCredential = (sfleuristeShopper) => {
  return APIv2.request({
    name: 'patch-shoppers',
    method: 'POST',
    url: `${SHOPPERS_API}/check/credentials`,
    data: sfleuristeShopper,
    headers: {
      accept: 'application/json',
    },
  })
}

/**
 * Met à jour les identifiants du fleuriste pour un shopper
 *
 * @param {object} sfleuristeShopper
 * @returns {{headers: {accept: string}, method: string, data, name: string, url: string}}
 */
const patchShoppers = (sfleuristeShopper) => {
  return APIv2.requestData({
    name: 'patch-shoppers',
    method: 'PATCH',
    url: `${SHOPPERS_API}/patch`,
    data: sfleuristeShopper,
    headers: {
      accept: 'application/json',
    },
  })
}

/**
 * Retourne la liste de prebooks disponible par shopper
 *
 * @param {string} numcde
 * @param {Date} deliveryDate
 *
 * @returns {*}
 */
const getPrebooks = ({numcde, deliveryDate}) => {
  return APIv2.requestData({
    name: 'prebooks',
    method: 'POST',
    url: `${SHOPPERS_API}/prebook`,
    data: {numcde, deliveryDate: deliveryDate.getTime()},
    headers: {
      accept: 'application/ld+json',
    },
  })
}

/**
 * Retourne le prebook choisi.
 *
 * @param {{shopperReference: string, prebookId: string,  invoiceNumber: string}} data
 * @returns {*}
 */
const validatePrebook = (prebook) => {
  return APIv2.requestData({
    name: 'validate-prebook',
    method: 'POST',
    url: `${SHOPPERS_API}/prebook/validate`,
    data: prebook,
    headers: {
      accept: 'application/ld+json',
    },
  })
}

const cancelPrebook = (data) => {
  return APIv2.requestData({
    name: 'cancel-prebook',
    method: 'POST',
    url: `${SHOPPERS_API}/prebook/cancel`,
    data,
    headers: {
      accept: 'application/ld+json',
    },
  })
}

export default {
  getGlobalConfig,
  getShoppers,
  checkShopperCredential,
  patchShoppers,
  getPrebooks,
  validatePrebook,
  cancelPrebook,
}
