import API from "./API";
import {CLOSURES_POST_API, FLOWER_FEAST} from "../constants/Constants";

function getCurrentFlowerFeastId(floristCode = null) {
    let query = '';

    if (floristCode !== null) {
        query += `?florist_code=${floristCode}`
    }

    return API({
        method: 'get',
        url: `${FLOWER_FEAST}/current${query}`,
    });
}

function closeFloristShop(flowerFeastId) {
    return API({
        method: 'post',
        url: `${CLOSURES_POST_API}/flowerfeast/${flowerFeastId}`,
    });
}

export default {
    getCurrentFlowerFeastId,
    closeFloristShop
}