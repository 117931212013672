import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import React, {useContext, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import {differenceInDays, formatISO} from "date-fns";
import Loader from "../../../ui/loader/Loader";
import ModalCustom from "../../../ui/modal/ModalCustom";
import ExecutionsAPI from "../../../../services/ExecutionsAPI";
import {format} from "../../../../Tools/Date";
import AlertCustom from "../../../ui/alert/AlertCustom";
import FermeturesAPI from "../../../../services/FermeturesAPI";
import AuthContext from "../../../../contexts/AuthContext";

export const HolidaysForm = ({show, onHide}) => {
    const {user} = useContext(AuthContext);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
    const [showAddHolidaysConfirmationModal, setShowAddHolidaysConfirmationModal] = useState(false);
    const [showhasExecutionsAcceptedInHolidays, setShowHasExecutionsAcceptedInHolidays] = useState(false);
    const [hasErrorOnAddHolidays, setHasErrorOnAddHolidays] = useState(false);
    const [periodIsGreaterThanMaxDays, setPeriodIsGreaterThanMaxDays] = useState(false);

    // Vérifie si des commandes ont été acceptées pendant la période de congés sélectionnée
    async function hasExecutionsAccepted() {
        let hasExecutionsAccepted = false;

        await ExecutionsAPI.getAllAcceptedExecutions(formatISO(dateStart, { representation: 'date' }), formatISO(dateEnd, { representation: 'date' })).then((result) => {
            hasExecutionsAccepted = result.data > 0;
        });

        return hasExecutionsAccepted;
    }

    function checkHasExecutionsAcceptedInHolidays() {
        setIsLoadingConfirmation(true);
        setHasErrorOnAddHolidays(false);

        hasExecutionsAccepted()
            .then((result) => {
                if (result) {
                    setShowHasExecutionsAcceptedInHolidays(true);
                    onHide();
                } else {
                    addHolidays();
                }
            });
    }

    async function addHolidays() {
        setShowHasExecutionsAcceptedInHolidays(false);

        try {
            await FermeturesAPI.postFermetures(
                user.CodeFl,
                format(dateStart),
                format(dateEnd),
            );
            setShowAddHolidaysConfirmationModal(true);
        } catch (error) {
            setHasErrorOnAddHolidays({
                'error': true,
                'errorMessage': error.response.data.detail,
            });
            setShowAddHolidaysConfirmationModal(true);
        }

        const now = (new Date()).setHours(0, 0, 0, 0);

        if (now >= dateStart && now <= dateEnd) {
            sessionStorage.setItem('holiday_current', 'true');
        }

        onHide();
    }

    // Affiche un message d'erreur si la période sélectionnée est supérieure à 30 jours
    useEffect(() => {
        if (differenceInDays(dateEnd.setHours(0, 0, 0, 0), dateStart.setHours(0, 0, 0, 0)) > 30) {
            setPeriodIsGreaterThanMaxDays(true);
        } else {
            setPeriodIsGreaterThanMaxDays(false);
        }
    }, [dateStart, dateEnd]);

    return (
        <>
            <ModalCustom
                show={show}
                id={'modal-holidays'}
                onHide={onHide}
                header={true}
                headerTitle={'Ajouter un nouveau congé/une nouvelle fermeture'}
                modalClassname={'date-picker-container'}
                content={
                    <Form className={'mt-2 date-picker-control'}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date de début :</Form.Label>
                                    <DatePicker
                                        name={'dateStart'}
                                        locale={'fr'}
                                        required={true}
                                        openToDate={dateStart ?? new Date()}
                                        minDate={new Date()}
                                        dateFormat={'dd/MM/yyyy'}
                                        placeholderText={'--/--/----'}
                                        showLeadingZeros={true}
                                        selected={dateStart ?? new Date()}
                                        className={`form-control ${periodIsGreaterThanMaxDays ? 'is-invalid' : ''}`}
                                        disabledKeyboardNavigation
                                        onChange={(value) => {
                                            setDateStart(value);

                                            if (value > dateEnd) {
                                                setDateEnd(value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date de fin :</Form.Label>
                                    <DatePicker
                                        name={'dateEnd'}
                                        locale={'fr'}
                                        required={true}
                                        openToDate={dateEnd ?? dateStart ?? new Date()}
                                        minDate={dateStart}
                                        dateFormat={'dd/MM/yyyy'}
                                        placeholderText={'--/--/----'}
                                        showLeadingZeros={true}
                                        selected={dateEnd ?? new Date()}
                                        className={`form-control ${periodIsGreaterThanMaxDays ? 'is-invalid' : ''}`}
                                        disabledKeyboardNavigation
                                        onChange={(value) => setDateEnd(value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {periodIsGreaterThanMaxDays && (
                            <Row>
                                <Col>
                                    <AlertCustom
                                        variant={'danger'}
                                        heading={false}
                                        bodyContent={'La période de congés saisie est supérieure à 30 jours.'}
                                        bodyIcon={true}
                                        bodyIconName={'exclamation-triangle'}
                                        transition={true}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Form>
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Annuler',
                        classNames: 'mx-2',
                    },
                    {
                        text: isLoadingConfirmation
                            ? <Loader variant="white" size="sm" classname="my-1 mx-2" />
                            : 'Confirmer',
                        classNames: 'mx-2',
                        func: () => checkHasExecutionsAcceptedInHolidays(),
                        disabled: periodIsGreaterThanMaxDays,
                    }
                ]}
            />

            <ModalCustom
                show={showhasExecutionsAcceptedInHolidays}
                id={'modal-executions-in-holidays'}
                onHide={() => setShowHasExecutionsAcceptedInHolidays(false)}
                content={
                    <AlertCustom
                        classname="text-left"
                        variant="warning"
                        heading={true}
                        headingTitle="Information"
                        headingIconName="info-circle"
                        bodyContent={'Attention, vous avez une ou plusieurs commandes acceptées sur votre extranet à livrer pendant vos congés. Si vous souhaitez les réaffecter, vous pouvez nous contacter par tchat ou par téléphone au 0826.107.707. choix 3'}
                        transition={true}
                    />
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Annuler',
                        classNames: 'ml-auto',
                        func: () => setShowHasExecutionsAcceptedInHolidays(false),
                    },
                    {
                        text: 'J\'ai compris',
                        classNames: 'mr-auto',
                        func: () => addHolidays(),
                    }
                ]}
            />

            <ModalCustom
                show={showAddHolidaysConfirmationModal}
                id={'modal-holidays-confirmation'}
                onHide={() => setShowAddHolidaysConfirmationModal(false)}
                content={
                    <AlertCustom
                        classname={"text-left"}
                        variant={hasErrorOnAddHolidays === false ? "success" : "danger"}
                        heading={true}
                        headingTitle={"Information"}
                        headingIconName={"info-circle"}
                        bodyContent={
                            hasErrorOnAddHolidays === false
                            ?
                                <h3>
                                    Enregistrement effectué ! <br />
                                    Prise en compte dans quelques instants.
                                </h3>
                            :
                                (<div dangerouslySetInnerHTML={{__html: hasErrorOnAddHolidays.errorMessage}}></div>)
                        }
                        transition={true}
                    />
                }
                footer={true}
                buttonsFooter={[
                    {
                        text: 'Fermer',
                        classNames: 'mx-auto',
                    }
                ]}
            />
        </>
    );
}

HolidaysForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}