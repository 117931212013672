import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import AlertCustom from "../../ui/alert/AlertCustom"
import { useOrderSimple } from "../../../hooks/useOrder";
import { selectShoppersModals } from "../../../slice/shoppersModalsSlice";

/**
 * Affiche le contenu de la modal
 * permettant d'annuler une livraison.
 *
 * @returns {JSX.Element}
 */
const CancellationModalContent = ({executions}) => {
    const {
        currentPrebookNumcde,
        error,
        errorText,
        cancelled
      } = useSelector(selectShoppersModals)

    let textContent = `Êtes-vous sûr de vouloir annuler la livraison pour la commande <strong>${currentPrebookNumcde}</strong> ?`

    const {getOrder} = useOrderSimple(executions)
    const exec = getOrder(currentPrebookNumcde)

    const customAlertVariant = (error) ? 'danger' : (cancelled) ? 'success' : 'warning'

    if (cancelled || error) {
        textContent = (error)
            ? errorText
            : `La commande <strong>${currentPrebookNumcde}</strong> à bien été annulée`
    }

    return (
        ((exec)
        && <AlertCustom
                transition={true}
                bodyContentHtml={`<span style="font-size: initial">${textContent}</span>`}
                bodyIcon={(error || !cancelled)}
                variant={customAlertVariant}
            />) || <></>
    )
}

CancellationModalContent.propTypes = {
    executions: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired,
    ]),
}

export default CancellationModalContent;
