import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useBox} from "../../../hooks/useBox";

const Box = ({
 boxXs,
 boxSm,
 boxMd,
 boxLg,
 boxXl,
 boxCol,
 boxName,
 boxXxl,
 boxHd,
 boxClassNames,
 boxContentClassNames,
 header,
 headerContent,
 stickyHeader,
 targetStickyClasses,
 content,
 onClick,
 style,
...useBoxProps}) => {

  const {
    headerBoxClassNames,
    contentBoxClassNames
  } = useBox(useBoxProps, stickyHeader)

  const handleSticky = () => {
    const stickyHeaderElt = document.querySelector('[data-sticky-header]')

    if (stickyHeaderElt) {
      const stickyWithHeader = Array.from(
        document.getElementsByClassName(targetStickyClasses)
      ).sort(
        (sticky1, sticky2) => {
          const {top: top1} = sticky1.getBoundingClientRect()
          const {top: top2} = sticky2.getBoundingClientRect()

          return (top1 < top2) ? -1 : 1
        }
      )

      stickyWithHeader.forEach((elt) => {
          stickyHeaderElt.append(elt)
          stickyHeaderElt.style.color = 'black'
          stickyHeaderElt.style.padding = '0'
      })
    }
  }

  useEffect(() => {
    if (stickyHeader) {
      handleSticky()
    }
  }, [stickyHeader]);

  return (
    <Col
      xs={boxXs || 12}
      sm={boxSm || ''}
      md={boxMd || ''}
      lg={boxLg || ''}
      xl={boxXl || ''}
      className={`box ${boxName} ${boxXxl ? `col-xxl-${boxXxl} ` : ''}${
        boxHd ? `col-hd-${boxHd} ` : ''
      }${boxCol ? `col-${boxCol} ` : ''}${
        boxClassNames ? `${boxClassNames} ` : 'mb-3 '
      }d-inline-block`}>
      {header && (
        <div
          className={`header ${headerBoxClassNames}`} data-sticky-header={''}>
          {headerContent}
        </div>
      )}
      <div
        onClick={onClick}
        style={style}
        className={`content ${contentBoxClassNames} w-100 ${boxContentClassNames}`}>
        {content}
      </div>
    </Col>
  )
}

Box.propTypes = {
  boxContentBg: PropTypes.string,
  boxContentBorder: PropTypes.bool,
  boxContentBorderColor: PropTypes.string,
  boxContentClassNames: PropTypes.string,
  boxContentTextColor: PropTypes.string,
  boxContentRounded: PropTypes.string,
  boxContentPadding: PropTypes.string,
  boxClassNames: PropTypes.string,
  boxCol: PropTypes.string,
  boxLg: PropTypes.number,
  boxHd: PropTypes.string,
  boxMd: PropTypes.number,
  boxName: PropTypes.string,
  boxSm: PropTypes.number,
  boxXl: PropTypes.number,
  boxXs: PropTypes.number,
  boxXxl: PropTypes.string,
  content: PropTypes.object,
  header: PropTypes.bool,
  headerBg: PropTypes.string,
  headerBorder: PropTypes.bool,
  headerBorderColor: PropTypes.string,
  headerClassNames: PropTypes.string,
  headerContent: PropTypes.object,
  headerPadding: PropTypes.string,
  headerRounded: PropTypes.string,
  headerTextColor: PropTypes.string,
  stickyHeader: PropTypes.bool,
  targetStickyClasses: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

Box.defaultProps = {
  boxContentBg: 'grey-light',
  boxContentPadding: 'p-0',
}

export default Box
