import React, {useContext, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'

import useFetch from './useFetch'
import ExecutionsAPI from '../services/ExecutionsAPI'
import {
  INTERVAL_RELOAD,
  ITEMS_PER_PAGE_DASHBOARD_EXEC,
  ITEMS_PER_PAGE_DASHBOARD_SAV,
  ITEMS_PER_PAGE_DASHBOARD_TRANS,
} from '../constants/Constants'
import CommandeAPI from '../services/CommandeAPI'
import TransmissionsAPI from '../services/TransmissionsAPI'
import SavAPI from '../services/SavAPI'
import AuthContext from '../contexts/AuthContext'
import {configureShoppersModals} from '../slice/shoppersModalsSlice'
import { base64 } from '../Tools/Tools'

export function dashboardManager() {
  const {user} = useContext(AuthContext)
  const history = useLocation()
  const dispatch = useDispatch()
  const [modalOrder, setModalOrder] = useState(false)
  const [order, setOrder] = useState()
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isErrorPdf, setIsErrorPdf] = useState(false)
  const [reload, setReload] = useState(false)

  // Gestion du rechargement de page
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload(false)
  //   }, INTERVAL_RELOAD)
  //   return () => clearInterval(interval)
  // }, [])

  /** ************** EXECUTIONS ******************************** */
  const [sortTypeExec, setSortTypeExec] = useState('etatextranet')
  const [sortOrderExec, setSortOrderExec] = useState('asc')
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [isErrorState, setIsErrorState] = useState(false)
  const [modalState, setModalState] = useState(false)
  const [picture, setPicture] = useState()
  const [modalPicture, setModalPicture] = useState(false)
  const [modalTakePicture, setModalTakePicture] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [isErrorUpload, setIsErrorUpload] = useState(false)
  const [preview, setPreview] = useState()
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [isLoadingRefus, setIsLoadingRefus] = useState(false)
  const [modalRefus, setModalRefus] = useState(false)
  const [enableValidation, setEnableValidation] = useState(true)
  const webcamRef = useRef(null)
  const previewRef = useRef()
  const [refus, setRefus] = useState()
  const [scrollTable, setScrollTable] = useState(false)

  /** ************** TRANSMISSIONS ******************************** */
  const [sortTypeTrans, setSortTypeTrans] = useState('numcde')
  const [sortOrderTrans, setSortOrderTrans] = useState('desc')
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isErrorDelete, setIsErrorDelete] = useState(false)
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false)
  const [modalDeleteMessage, setModalDeleteMessage] = useState(false)

  /** ************** SAV ******************************** */
  const [sortTypeSav, setSortTypeSav] = useState('reclaDteopen')
  const [sortOrderSav, setSortOrderSav] = useState('desc')
  const [sav, setSav] = useState({})
  const [modalSav, setModalSav] = useState(false)
  const [isLoadingSavComment, setIsLoadingSavComment] = useState(false)
  const [isErrorSavComment, setIsErrorSavComment] = useState(false)
  const [showComment, setShowComment] = useState(false)
  const [transition, setTransition] = useState(false)
  const isUnmounted = useRef(false)

  /** ************** EXECUTIONS ******************************** */
  let validationSchemaFile
  // Si pas de preview on créé l'objet de validation pour le champ upload photo
  if (!preview) {
    validationSchemaFile = Yup.object().shape({
      file: Yup.mixed().required('* Ce champ est requis.'),
    })
  }

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemaFile,
    onSubmit: (values, {setSubmitting}) => {
      postFile(preview, setSubmitting)
    },
    onReset: () => {
      previewRef.current.value = null
    },
  })

  const validationSchemaRefus = Yup.object().shape({
    refusmessage: Yup.string()
      .min(2, 'Ce champ doit contenir au minimum 2 caractères.')
      .required('* Ce champ est requis.'),
  })

  const formikRefus = useFormik({
    initialValues: {
      refus: '1',
      refusmessage: '',
    },
    enableReinitialize: true,
    validationSchema: enableValidation ? validationSchemaRefus : false,
    onSubmit: (values, {setSubmitting}) => {
      postRefus(values, order, setSubmitting)
    },
  })

  // Gestion du changement de magasin
  useEffect(() => {
    setReload(true)
  }, [user.CodeFl])


  // Gestion du scroll table exécutions
  // useEffect(() => {
  //   setScrollTable(false)
  //
  //   if (!isLoadingExec && !isErrorExec && executions.length) {
  //     const chosenPrebooks = {}
  //
  //     executions.forEach((execution) => {
  //       if (execution.prebook && execution.prebook.mission) {
  //         chosenPrebooks[base64(execution.prebook.id)] = execution.prebook
  //       }
  //     })
  //
  //     dispatch(configureShoppersModals({chosenPrebooks}))
  //   }
  //
  //   if (executions && executions.length > 3) {
  //     setScrollTable(true)
  //   }
  // }, [executions])

  // Gestion du changement de tri exécutions
  const handleSortOrderExec = (newSortType) => {
    let sort
    if (sortTypeExec !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrderExec === 'asc' ? 'desc' : 'asc'
    }
    setSortTypeExec(newSortType)
    setSortOrderExec(sort)
    setReload(true)
  }

  /* async function handleShowOrderTr(id, isTransmission = true) {
        await handleShowOrder(id, isTransmission);
    } */

  // Gestion du bouton Visualiser la commande
  const handleShowOrder = async (id /* isTransmission = false */) => {
    // if (!isTransmission) {
    //     window.open(`https://www.monflorajet.com/v2/visu_commande.php?id=${id}&codex=${user.CodeFl}`);
    // }
    if (!isUnmounted.current) {
      setIsLoadingPdf(true)
      setTransition(false)
      setIsErrorPdf(false)
      setModalOrder(true)
      setOrder({id})
    }
    try {
      if (!isUnmounted.current) {
        const windowRef = window.open()
        const result = await CommandeAPI.getPDF(user.CodeFl, id)

        if (result.data === null) {
          setIsErrorPdf({
            variant: 'warning',
            heading: false,
            content: `Il n'y a actuellement aucun élément à afficher.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else if (!result.data) {
          setIsErrorPdf({
            variant: 'danger',
            heading: true,
            content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
          })
          setTransition(true)
          setIsLoadingPdf(false)
        } else {
          setOrder((prevState) => ({...prevState, pdf: result.data}))
          setIsLoadingPdf(false)
          windowRef.location = process.env.REACT_APP_FRONT_HOST + result.data
          setModalOrder(false)
        }
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingPdf(false)
        setTransition(true)
        setIsErrorPdf({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être récupérée, veuillez réessayer ultérieurement.`,
        })
      }
    }
  }

  // Gestion du bouton Voir la photo
  const handleViewPic = (picture) => {
    setModalPicture(true)
    setPicture(picture)
    setIsErrorUpload(false)
    setIsUploaded(false)
    setTransition(false)
  }

  // Gestion du bouton Prendre la photo
  const handleTakePic = (numcde) => {
    setPreview()
    setModalTakePicture(true)
    setOrder((prevState) => ({...prevState, numcde}))
  }

  // Gestion de la capture webcam
  const handleCapturePic = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot()
    setPreview(screenshot)
    formik.resetForm()
  }, [webcamRef, setPreview])

  // Gestion de la preview de l'upload photo
  const handleUploadChange = (e) => {
    setPreview()
    setIsLoadingPreview(true)
    formik.handleChange(e)

    const reader = new FileReader()

    reader.onloadend = () => {
      setPreview(reader.result)
      setIsLoadingPreview(false)
    }

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0])
    } else {
      setPreview()
      setIsLoadingPreview(false)
    }
  }

  // Gestion de la sauvegarde de l'upload photo
  const postFile = async (preview, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingUpload(true)
      setIsErrorUpload(false)
      setTransition(false)
    }
    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.uploadPicture(user.CodeFl, order.numcde, {
          content: preview,
        })

        setIsLoadingUpload(false)
        setTransition(true)
        setIsErrorUpload({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo ! Votre photo a bien été envoyée.',
          headingIconName: 'info-circle',
          content: `Vous pouvez consulter votre photo avec l'action "Voir la photo"`,
        })
        setPreview()
        formik.resetForm()
        setIsUploaded(true)

        // fetchDataExec(
        //   ExecutionsAPI.getExecutions(
        //     user.CodeFl,
        //     'pending',
        //     sortTypeExec,
        //     sortOrderExec,
        //     1,
        //     '',
        //     '',
        //     '',
        //     ITEMS_PER_PAGE_DASHBOARD_EXEC,
        //   ),
        // )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        // console.log(error)
        setIsLoadingUpload(false)
        setIsUploaded(false)
        setTransition(true)
        setIsErrorUpload({
          variant: 'danger',
          heading: true,
          content: `Votre photo n'a pas pu être envoyée, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setSubmitting(false)
  }

  // Gestion du changement d'état
  const handleState = async (state) => {
    if (!isUnmounted.current) {
      setIsLoadingState({key: state.item.numcde, value: true})
      setIsErrorState(false)
      setTransition(false)
    }

    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.postExecutions(user.CodeFl, {
          etatextranet: parseInt(state.item.actions[state.id].id, 10),
          etatextranetlibelle: state.item.actions[state.id].label,
          idsgribouil: state.item.idsgribouil,
          numcde: state.item.numcde,
        })

        setIsLoadingState({key: state.item.numcde, value: false})

        // fetchDataExec(
        //   ExecutionsAPI.getExecutions(
        //     user.CodeFl,
        //     'pending',
        //     sortTypeExec,
        //     sortOrderExec,
        //     1,
        //     '',
        //     '',
        //     '',
        //     ITEMS_PER_PAGE_DASHBOARD_EXEC,
        //   ),
        // )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingState({key: state.item.numcde, value: false})
        setTransition(true)
        setIsErrorState({
          variant: 'danger',
          heading: true,
          content: error.response.data.detail,
        })
        setModalState(true)
      }
    }
  }

  async function handleRefus(state) {
    await ExecutionsAPI.getRefus().then((result) => {
      if (result.data) {
        setRefus(result.data)
      }
    })
    setModalRefus(true)
    setOrder(state)
  }

  // Gestion du changement des refus
  const handleChangeRefus = (event) => {
    formikRefus.setFieldValue('refus', event.target.value)
    formikRefus.setFieldTouched('refus', true)
    if (event.target.value === '1') {
      setEnableValidation(true)
    } else {
      setEnableValidation(false)
    }
  }

  // Gestion du changement d'état refus
  const postRefus = async (values, state, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingState({key: state.item.numcde, value: true})
      setIsErrorState(false)
      setTransition(false)
      setIsLoadingRefus(true)
    }

    try {
      if (!isUnmounted.current) {
        await ExecutionsAPI.postExecutions(user.CodeFl, {
          etatextranet: parseInt(state.item.actions[state.id].id, 10),
          etatextranetlibelle: state.item.actions[state.id].label,
          idsgribouil: state.item.idsgribouil,
          numcde: state.item.numcde,
          motif: formikRefus.values.refus,
          motifdesc: formikRefus.values.refusmessage,
        })

        setIsLoadingState({key: state.item.numcde, value: false})
        setIsLoadingRefus(false)

        // fetchDataExec(
        //   ExecutionsAPI.getExecutions(
        //     user.CodeFl,
        //     'pending',
        //     sortTypeExec,
        //     sortOrderExec,
        //     1,
        //     '',
        //     '',
        //     '',
        //     ITEMS_PER_PAGE_DASHBOARD_EXEC,
        //   ),
        // )
        setModalRefus(false)
        formikRefus.resetForm()
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingState({key: state.item.numcde, value: false})
        setTransition(true)
        setIsErrorState({
          variant: 'danger',
          heading: true,
          content: error.response.data.detail,
        })
        setIsLoadingRefus(false)
        setModalRefus(false)
        setModalState(true)
      }
    }
    setSubmitting(false)
    setEnableValidation(true)
  }

  /** ************** TRANSMISSIONS ******************************** */
  const [
    {data: transmissions, isLoading: isLoadingTrans, isError: isErrorTrans},
    fetchDataTrans,
  ] = useFetch(
    TransmissionsAPI.getTransmissions(
      user.CodeFl,
      sortTypeTrans,
      sortOrderTrans,
      1,
      '',
      '',
      '',
      ITEMS_PER_PAGE_DASHBOARD_TRANS,
    ),
  )

  // Gestion du changement d'url transmissions
  useEffect(() => {
    if (reload) {
      fetchDataTrans(
        TransmissionsAPI.getTransmissions(
          user.CodeFl,
          sortTypeTrans,
          sortOrderTrans,
          1,
          '',
          '',
          '',
          ITEMS_PER_PAGE_DASHBOARD_TRANS,
        ),
      )
    }
  }, [user.CodeFl, sortTypeTrans, sortOrderTrans])

  // Gestion du changement de tri Transmissions
  const handleSortOrderTrans = (newSortType) => {
    let sort
    if (sortTypeTrans !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrderTrans === 'asc' ? 'desc' : 'asc'
    }
    setSortTypeTrans(newSortType)
    setSortOrderTrans(sort)
    setReload(true)
  }

  // Gestion du bouton Modifier la commande
  const handleEditOrder = (order) => {
    history.push({
      pathname: '/saisir-commande',
      state: {order},
    })
  }

  // Gestion du bouton Supprimer la commande
  const handleShowModalDelete = (order) => {
    setModalDeleteOrder(true)
    setOrder(order)
  }

  // Gestion de la suppression de la commande
  const handleDeleteOrder = async (order) => {
    setIsLoadingDelete(true)
    setTransition(false)
    setIsErrorDelete(false)

    try {
      if (!isUnmounted.current) {
        await TransmissionsAPI.deleteTransmissions(order.idsenattente)
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo !',
          headingIconName: 'info-circle',
          content: 'Votre commande a bien été supprimée.',
        })

        fetchDataTrans(
          TransmissionsAPI.getTransmissions(
            user.CodeFl,
            'enattente',
            'datliv',
            'desc',
            1,
            '',
            '',
            ITEMS_PER_PAGE_DASHBOARD_TRANS,
          ),
        )
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingDelete(false)
        setTransition(true)
        setIsErrorDelete({
          variant: 'danger',
          heading: true,
          content: `Votre commande n'a pas pu être supprimée, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setModalDeleteOrder(false)
    setModalDeleteMessage(true)
  }

  /** ************** SAV ******************************** */
  const validationSchemaSav = Yup.object().shape({
    comment: Yup.string()
      .min(2, '* Ce champ doit contenir au minimum 2 caractères')
      .required('* Ce champ est requis.'),
  })

  const formikSav = useFormik({
    initialValues: {
      comment: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemaSav,
    onSubmit: (values, {setSubmitting}) => {
      postComment(values, setSubmitting)
    },
  })

  // Gestion du changement de tri SAV
  const handleSortOrderSav = (newSortType) => {
    let sort
    if (sortTypeSav !== newSortType) {
      sort = 'asc'
    } else {
      sort = sortOrderSav === 'asc' ? 'desc' : 'asc'
    }
    setSortTypeSav(newSortType)
    setSortOrderSav(sort)
    setReload(true)
  }

  // Gestion du bouton Créer un SAV
  const handleShowSav = (sav) => {
    setModalSav(true)
    setSav(sav)
  }

  // Gestion du bouton Ajouter un commentaire au SAV
  const handleShowComment = () => {
    setShowComment(!showComment)
  }

  // Gestion de l'envoi du commentaire du SAV
  const postComment = async (values, setSubmitting) => {
    if (!isUnmounted.current) {
      setIsLoadingSavComment(true)
      setIsErrorSavComment(false)
      setTransition(false)
    }
    try {
      if (!isUnmounted.current) {
        // await SavAPI.createSav(values)
        setIsLoadingSavComment(false)
        setTransition(true)
        setIsErrorSavComment({
          variant: 'success',
          heading: true,
          headingTitle: 'Bravo ! Votre commentaire a bien été ajouté.',
          headingIconName: 'info-circle',
          content:
            'Vous pouvez retrouver votre commentaire dans la description du SAV.',
        })
        formikSav.resetForm()
      }
    } catch (error) {
      if (!isUnmounted.current) {
        console.log(error)
        setIsLoadingSavComment(false)
        setTransition(true)
        setIsErrorSavComment({
          variant: 'danger',
          heading: true,
          content: `Votre commentaire n'a pas pu être ajouté, veuillez réessayer ultérieurement.`,
        })
      }
    }
    setSubmitting(false)
  }

  return {
    user,
    scrollTable,
    sortTypeExec,
    sortOrderExec,
    isLoadingState,
    isLoadingTrans,
    isErrorTrans,
    sortTypeTrans,
    sortOrderTrans,
    transmissions,
    sortTypeSav,
    sortOrderSav,
    modalPicture,
    picture,
    modalTakePicture,
    isErrorUpload,
    transition,
    webcamRef,
    isLoadingPreview,
    preview,
    previewRef,
    isLoadingUpload,
    modalState,
    isErrorState,
    order,
    sav,
    modalSav,
    isErrorSavComment,
    showComment,
    formik,
    isUploaded,
    refus,
    modalRefus,
    setModalRefus,
    formikRefus,
    isLoadingRefus,
    modalOrder,
    setModalOrder,
    isErrorPdf,
    isLoadingPdf,
    modalDeleteOrder,
    isLoadingDelete,
    modalDeleteMessage,
    isErrorDelete,
    handleSortOrderExec,
    handleShowOrder,
    // handleShowOrderTr,
    handleViewPic,
    handleTakePic,
    handleState,
    handleSortOrderTrans,
    handleEditOrder,
    handleDeleteOrder,
    handleSortOrderSav,
    handleShowSav,
    handleCapturePic,
    handleUploadChange,
    handleShowComment,
    handleChangeRefus,
    handleShowModalDelete,
    setModalTakePicture,
    setModalPicture,
    setModalSav,
  }
}
