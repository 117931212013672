import {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";
import FlowerFeastAPI from "../services/FlowerFeastAPI";

export function useFlowerFeast() {
    const {user, isAuthenticated} = useContext(AuthContext);
    const [hasFlowerFeast, setHasFlowerFeast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // sessionStorage.removeItem('flower_feast');

        if (sessionStorage.getItem('flower_feast') === null) {
            setIsLoading(true);
            FlowerFeastAPI.getCurrentFlowerFeastId(user && user.CodeFl ? user.CodeFl : null)
                .then((result) => {
                    const data = result.data === null ? false : result.data;
                    sessionStorage.setItem('flower_feast', data)
                    setHasFlowerFeast(data);
                })
            ;
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        setHasFlowerFeast(sessionStorage.getItem('flower_feast') === 'true' || sessionStorage.getItem('flower_feast') > 0);
    }, [sessionStorage.getItem('flower_feast')]);

    return {hasFlowerFeast, isLoading};
}