import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import FlowerFeastAPI from "../../../services/FlowerFeastAPI";
import ModalCustom from "../modal/ModalCustom";
import AlertCustom from "../alert/AlertCustom";
import Loader from "../loader/Loader";
import AuthContext from "../../../contexts/AuthContext";
import {useFlowerFeast} from "../../../hooks/useFlowerFeast";
import {ButtonWithConfirmation} from "../../../helpers/Actions/ButtonWithConfirmation";

export const FlowerFeastButton = ({eventKey}) => {
    const [showModal, setShowModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {hasFlowerFeast} = useFlowerFeast();

    async function closeFloristShop() {
        setIsLoading(true);

        FlowerFeastAPI.closeFloristShop(sessionStorage.getItem('flower_feast'))
            .then((result) => {
                if (result.data instanceof Object) {
                    setIsError({
                        variant: 'success',
                        heading: false,
                        bodyIconName: 'info-circle',
                        content: `Votre magasin est bien fermé pour la période de cette Fête à Fleurs`,
                    });
                    sessionStorage.setItem('flower_feast', 'false');
                    sessionStorage.setItem('holiday_current', 'true');
                    setShowModal(true);
                }
            })
            .catch((error) => {
                setIsError({
                    variant: 'danger',
                    heading: false,
                    content: `Une erreur est survenue lors de la fermeture du magasin, veuillez réessayer.`,
                });
                setShowModal(true);
            })
        ;

        setIsLoading(false);
    }
    
    return (
        <>
            {hasFlowerFeast  && (
                <ButtonWithConfirmation
                    func={() => closeFloristShop()}
                    button={{
                        color: 'blue',
                        icon: faLock,
                        iconClassNames: 'my-auto mx-2',
                        classnames: 'mt-3 py-1 btn-block',
                        title: 'Fermer mon magasin',
                        messageInModal: 'Êtes-vous sûr de fermer votre magasin pour la période de fête à fleurs ?'
                    }}
                />
            )}
            <ModalCustom
                show={showModal}
                id={'flowerFeastModal'}
                onHide={() => setShowModal(false)}
                content={
                    <AlertCustom
                        classname="text-left"
                        variant={isError.variant}
                        heading={isError.heading}
                        headingTitle={isError.headingTitle}
                        bodyIconName={isError.bodyIconName}
                        bodyContent={isError.content}
                        bodyContentItalic={isError.detail}
                        transition={true}
                    />
                }
            />
        </>
    );
}

FlowerFeastButton.propTypes = {
    eventKey: PropTypes.string.isRequired
}